import React, { useEffect, useRef, useState } from "react";
import { Formik, useFormik } from "formik";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import Select from "react-select";
import { AssignPageField, FileUploadField, FormField } from "../../common/FormFields";
import {
  addHeaderDetails,
  getAccentColorBaseOnBrandTmpList,
  GetAllHeaderPMCpageList,
  getHeaderDetails,
  getHeaderDropDownList,
  getHeadersPageList,
  updateHeaderDetails,
} from "../../../services/LayoutHeaderServices";
import AssignPagePopup from "./AssignPagePopup";
import PermissionsGate from "../../../utils/permissionGate";
import AssignQualificationPopup from "./AssignQualificationPopup";
import { id } from "date-fns/locale";
import { values } from "underscore";
import { URL_REGEX } from "../../../utils/Constants";

const HeaderInformation = ({ setHeaderName }) => {
  const { subId, subTab, type } = useParams();
  const history = useHistory();
  const [statusArr, setStatusArr] = useState([]);
  const [headerTypeArr, setHeaderTypeArr] = useState([]);
  const [headerButtonTypeArr, setHeaderButtonTypeArr] = useState([]);
  const [brandArr, setBrandArr] = useState([]);
  const [colorArr, setColorArr] = useState([]);
  const [brandData, setBrandData] = useState({});
  const [assignedPage, setAssignedPage] = useState([]);
  const [assignedQualification, setAssignedQualification] = useState({});
  const [assignedPopupPages, setAssignedPopupPages] = useState({});
  const [updateData, setUpdateData] = useState(Date.now())
  const assignedPageRef = useRef(assignedPopupPages);
  const [headerData, setHeaderData] = useState([])
  const assignedQualificationRef = useRef(assignedQualification);
  const [refresh, setRefresh]=useState(Date.now())

  useEffect(() => {
    assignedPageRef.current = assignedPopupPages;
  }, [assignedPopupPages]);

  useEffect(() => {
    assignedQualificationRef.current = assignedQualification;
  }, [assignedQualification]);


  const isObjectNotEmpty = (obj) => {
    return obj && Object.keys(obj).length > 0;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getHeaderDropDownList();
        setStatusArr(res.data.headerStatus || []);
        setBrandArr(res.data.brandList || []);
        setHeaderTypeArr(res.data.headerType || []);
        setHeaderButtonTypeArr(res.data.headerButtonType || []);
      } catch (error) {
        // if (!axios.isCancel(error)) {
        console.error(error);
        // }
      }
    };

    fetchData();
  }, [refresh]);

  const getAccentColor = async (value) => {
    try {
      let res = await getAccentColorBaseOnBrandTmpList({
        brandID: value ? value : [],
      });
      setColorArr(res?.data?.result || []);
    } catch (error) {
      // if (!axios?.isCancel(error)) {
      console.error(error);
      // }
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        let res = await getHeaderDetails(subId);
        res.data.data.brandTemplete = res.data.data?.brandTemplete?.map(
          (el) => el.BrandID
        );
        getAccentColor([...res.data.data.brandTemplete]);
        setBrandData(res.data?.data);
        setHeaderName(res.data?.data?.SliderName);
        localStorage.setItem("headerName", res.data?.data?.SliderName);
      } catch (error) {
        // if (!axios?.isCancel(error)) {
        console.error(error);
        // }
      }
    };
    if (subId) {
      fetchData();
    }
  }, [subId, updateData,refresh]);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      SliderName: brandData?.SliderName || "",
      SliderStatus: brandData?.SliderStatus,
      IsDefault: brandData?.IsDefault,
      buttonType: brandData?.buttonType,
      Brand: brandData?.brandTemplete || [],
      buttonText: brandData?.buttonText || "",
      assignedPage: brandData?.assignedPage || {},
      titleHeader: brandData?.titleHeader || "",
      subtitleHeader: brandData?.subtitleHeader || "",
      ItemImgURL: brandData?.ItemImgURL || "",
      ItemVideo: brandData?.ItemVideo || "",
      SliderColor: brandData?.SliderColor || "",
      headerCID: brandData?.headerCID || "",
      SliderItemID: brandData?.SliderItemID || "",
      LinkURL: brandData?.LinkURL || "",
      contactFormActive: brandData?.contactFormActive || 0,
      isTitleUnderline: brandData?.isTitleUnderline || 0,
      isShowContent: brandData?.isShowContent || 0,
      isShowButton: brandData?.isShowButton || 0,
      isOverrideColor: brandData?.isOverrideColor || 0,
    },
    validationSchema: Yup.object().shape({
      SliderName: Yup.string().required("Name is required").nullable().trim(),
      SliderStatus: Yup.string().required("Status is required"),
      IsDefault: Yup.string().required("Header Type is required"),
      Brand: Yup.string().required("Assigned Brand Templates are required"),
      buttonText: Yup.string().required("Header Button Text is required"),
      assignedPage: Yup.object().when('buttonType', {
        is: 1, // When buttonType is 1
        then: Yup.object().shape({
          id: Yup.string().required("Assigned Qualification for Button is required"),
          type: Yup.string().required("Assigned Qualification Type is required"),
        }),
        otherwise: Yup.object().when('buttonType', {
          is: 0, // When buttonType is 0
          then: Yup.object().shape({
            id: Yup.string().required("Assigned Page for Button is required"),
            type: Yup.string().required("Assigned Page Type is required"),
          }),
          otherwise: Yup.object().nullable(), // For other button types, make it nullable
        }), 
      }),
      titleHeader: Yup.string().required("Header Title Text is required"),
      ItemImgURL: Yup.string().required("Banner Image is required"),
      SliderColor: Yup.string().required("Banner Accent Colour is required"),
      LinkURL: Yup.string().matches(/^(\/|https?:\/\/)/, "URL must start with / or a valid protocol").when("buttonType", {
        is: 2,
        then: Yup.string().required("Custom Link is required"),
      }),
    }),
    
    onSubmit: async (values, { setSubmitting }) => {
      let data = { ...values };
      if (data.buttonType == 2) {
        delete data.assignedPage;
      }
      if (data.buttonType == 1) {
        delete data.LinkURL;
      }
      if (data.buttonType == 0) {
        delete data.LinkURL;
      }
      const formData = new FormData();

      // Prepare values
      const cleanedValues = Object.fromEntries(
        Object.entries(data).map(([key, value]) => [
          key,
          value === undefined || value === null ? "" : value
        ])
      );

      // Handle specific field 'assignedPage'
      if (cleanedValues.assignedPage && typeof cleanedValues.assignedPage === 'object') {
        formData.append('assignedPage[id]', cleanedValues.assignedPage.id || "");
        formData.append('assignedPage[type]', cleanedValues.assignedPage.type ?? "");
      }

      // Append other fields to FormData
      Object.keys(cleanedValues).forEach((key) => {
        if (key !== 'assignedPage') {
          const value = cleanedValues[key];
          if (Array.isArray(value)) {
            value.forEach((val, idx) => {
              formData.append(`${key}[${idx}]`, val !== undefined && val !== null ? val : "");
            });
          } else {
            formData.append(key, value);
          }
        }
      });

      try {
        if (type == "create") {
          await addHeaderDetails(formData);
          Swal.fire(
            "Success!",
            "Header details created successfully!",
            "success"
          );
          history.push(`/websiteManagement/layout_navigation/header/table`);
        } else if (type == "open") {
          formData.append("SliderID", subId);
          await updateHeaderDetails(formData);
          Swal.fire(
            "Success!",
            "Header details updated successfully!",
            "success"
          );
          formik.resetForm()
          setUpdateData(Date.now())
          history.push(`/websiteManagement/layout_navigation/header/header_information/open/${subId}`);
        }
      } catch (error) {
        Swal.fire(
          "Error!",
          `Failed to ${type == "create" ? "create" : "update"
          } Header details`,
          "error"
        );
        console.log(error);
      }
      setSubmitting(false);
    },
  });

  useEffect(()=>{
    if(formik.values.assignedPage){
      setAssignedPage(formik.values.assignedPage)
    }
  },[formik.values.assignedPage])
  const checkboxOptions = [
    { name: "contactFormActive", label: "Show Form Block" },
    { name: "isTitleUnderline", label: "Show Title Underline" },
    { name: "isShowContent", label: "Show Content Text" },
    { name: "isShowButton", label: "Show Button" },
  ];
  useEffect(() => {
    if (assignedPopupPages && assignedPopupPages.PageID) {
      formik.setFieldValue("assignedPage", {
        id: assignedPopupPages.PageID,
        type: assignedPopupPages.flag,
      });
    }
  }, [assignedPopupPages]);

  // useEffect(() => {
  //   if (assignedPopupPages) {
  //     formik.setFieldValue("assignedPage", {
  //       id: assignedPopupPages.PageID,
  //       type: assignedPopupPages.flag,
  //     });
  //   }
  // }, [assignedPopupPages]);

  useEffect(() => {
    if (assignedQualification) {
      formik.setFieldValue("assignedPage", {
        id: assignedQualification.id,
        type: assignedQualification.flag,
      });
    }
  }, [assignedQualification]);

  useEffect(() => {
    const assingedQualification = () => {
      GetAllHeaderPMCpageList(subTab == "update" && subId)
        .then(res => {
          setHeaderData(res.data?.data)
        })
        .catch(err => console.log(err))
    }

    const assignedPage = () => {
      getHeadersPageList(subTab == "update" && subId)
        .then(res => {
          setHeaderData(res.data?.data)
        })
        .catch(err => console.log(err))
    }
    if(formik.values.buttonType == 0){
      assignedPage();
    }
    if(formik.values.buttonType == 1){
      assingedQualification();
    }
  }, [formik.values.buttonType])

  useEffect(()=>{
    if(formik.values.buttonType == 1){
      setAssignedPopupPages({})
    } 
    if(formik.values.buttonType == 0){
      setAssignedQualification({})
    }
  },[formik.values.buttonType])
  

  useEffect(() => {
    if (brandData?.buttonType == 1 ) {
      formik.setFieldValue("assignedPage", {
        id: brandData?.assignedPage?.id || "",
        type: brandData?.assignedPage?.flag || "",
      });
      setAssignedQualification(brandData?.assignedPage)
    } else {
      formik.setFieldValue("assignedPage", {
        id: brandData?.assignedPage?.id || "",
        type: brandData?.assignedPage?.flag || "",
      });
      setAssignedPopupPages(brandData?.assignedPage)
    }
  }, [brandData])
  return (
    <div className="card card-body-inr">
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-12">
            <div className="edit-icon new-card-header">
              <h4 className="card-header">Header Information</h4>
            </div>
          </div>

          <FormField
            field={{ name: "SliderName", label: "Name" }}
            formik={formik}
            placeholder="Enter Name"
          />
          <FormField
            field={{ name: "SliderStatus", label: "Status", info: true }}
            formik={formik}
            selectOptions={statusArr}
          />
          <FormField
            field={{ name: "IsDefault", label: "Header Type", info: true }}
            formik={formik}
            selectOptions={headerTypeArr}
          />
          <FormField
            field={{
              name: "buttonType",
              label: "Header Button Type",
              info: true,
            }}
            required={false}
            formik={formik}
            selectOptions={headerButtonTypeArr}
          />
          <div className="col-md-6 col-lg-6">
            <label htmlFor="Brand">
              Assigned Brand Templates * <i className="fal fa-info-circle"></i>{" "}
            </label>
            <div className="form-icon-group mb-4">
              <Select
                className={`form-control custom-select-box ${formik.touched.Brand && formik.errors.Brand && "is-invalid"
                  }`}
                name="Brand"
                value={brandArr?.filter((val) =>
                  formik.values.Brand?.includes(val.value)
                )}
                onChange={(value) => {
                  formik.setFieldValue(
                    "Brand",
                    value ? value.map((v) => v.value) : []
                  );
                  getAccentColor(value ? value.map((v) => v.value) : []);
                }}
                isClearable
                onBlur={formik.handleBlur}
                options={brandArr}
                maxMenuHeight={175}
                placeholder="Select"
                isMulti
              />
            </div>
          </div>
          <FormField
            field={{ name: "buttonText", label: "Header Button Text" }}
            formik={formik}
            col_md={6}
            col_lg={3}
            placeholder="Enter Button Text"
          />
          {
            formik.values.buttonType == 2 ? <FormField
              field={{ name: "LinkURL", label: "Custom Link URL", required: false }}
              formik={formik}
              col_md={6}
              col_lg={3}
              placeholder="Enter Custom Link URL"
            />
              :
              formik.values.buttonType == 0 ? <AssignPageField name="assignedPage" label="Assigned Page for Button*" formik={formik} dataTarget="#assignPagePopupBrandTemp" assignedPage={assignedPopupPages}
                setAssignedPage={setAssignedPopupPages} />
                :
                formik.values.buttonType == 1 ? <AssignPageField name="assignedPage" label="Assigned Qualification for Button *" formik={formik} dataTarget="#assignQualificationPopup" assignedPage={assignedQualification}
                  setAssignedPage={setAssignedQualification} buttonName="Assign Qualification" />
                  :
                  null
          }
          <FormField
            field={{ name: "titleHeader", label: "Header Title Text" }}
            formik={formik}
            col_md={12}
            col_lg={12}
            placeholder="Enter Title Text"
          />
          <FormField
            field={{ name: "subtitleHeader", label: "Header Content Text" }}
            formik={formik}
            required={false}
            col_md={12}
            col_lg={12}
            placeholder="Enter Header Content Text"
          />
          <FileUploadField
            name="ItemImgURL"
            label="Banner Image *"
            formik={formik}
            accept=".png,.jpg,.jpeg"
            col_md={6}
            col_lg={2}
          />
          <FileUploadField
            name="ItemVideo"
            label="Banner Video"
            formik={formik}
            accept="video/*"
            col_md={8}
            col_lg={2}
          />
          <div className={`col-md-6 col-lg-8`}>
            <label>Header Block Content</label>

            {/* <div className="mt-2"> */}
            <div className="form-icon-group mb-4 mt-2">
              {checkboxOptions.map((checkbox) => (
                <div
                  className="custom-control custom-checkbox text-left mb-3 mr-3"
                  key={checkbox.name}
                >
                  <input
                    type="checkbox"
                    id={checkbox.name}
                    name={checkbox.name}
                    className={`custom-control-input ${formik.touched[checkbox.name] &&
                      formik.errors[checkbox.name] &&
                      "is-invalid"
                      }`}
                    checked={formik.values[checkbox.name] === 1}
                    onChange={(e) => {
                      formik.setFieldValue(
                        checkbox.name,
                        e.target.checked ? 1 : 0
                      );
                    }}
                  />
                  <label
                    htmlFor={checkbox.name}
                    className="custom-control-label"
                  >
                    {checkbox.label}
                  </label>
                </div>
              ))}
            </div>
            {/* </div> */}
          </div>
          <div className="col-md-12">
            <hr
              className="border border-2"
              style={{ borderColor: "#E6EBF1" }}
            />
          </div>

          <div className="col-md-12">
            <div className="edit-icon new-card-header">
              <h4 className="card-header">Style Settings</h4>
            </div>
          </div>

          {
            formik.values?.isOverrideColor ?
              <>
                {[
                  { label: "Banner Accent Colour", name: "SliderColor", color: true, type: "color", },
                ].map((field, index) => (
                  <FormField key={index} field={field} formik={formik} />
                ))}
              </>
              :
              <FormField
                field={{ name: "SliderColor", label: "Banner Accent Colour" }}
                formik={formik}
                selectOptions={colorArr}
              />
          }

          <div className="col-md-6 col-lg-6 d-flex align-items-center">
            <label className="mb-0">
              Override Colour Variable <i className="fal fa-info-circle"></i>
            </label>
            <div className="toggle-switch ml-2">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={formik.values?.isOverrideColor === 1}
                  onChange={(e) => {
                    formik.setFieldValue(
                      "isOverrideColor",
                      e.target.checked ? 1 : 0
                    )
                    formik.setFieldValue(
                      "SliderColor",
                      e.target.checked ? "#000000" : ""
                    )
                  }
                  }
                />
                <span className="slider slider-round"></span>
              </label>
            </div>
          </div>
          <div className="col-md-12 form-group form-group-save-cancel">
            <PermissionsGate scopes={[subTab == "create" ? 'wbslnheadersadd' : 'wbslnheadersedit']} RenderError={() => (
              <button className="btn btn-save btn-success" disabled>
                <i className="fal fa-save"></i> Save
              </button>
            )}>

              <button
                className="btn btn-save btn-success"
                disabled={formik.isSubmitting || !formik.dirty}
                type="submit"
                title="Save"
              >
                <i className="fal fa-save"></i> Save
              </button>
            </PermissionsGate>
            <button
              className="btn btn-close btn-danger"
              type="button"
              title="Cancel"
              disabled={formik.isSubmitting}
              onClick={() => {
                formik.handleReset()
                formik.resetForm()
                setRefresh(Date.now())
              }
              }
            >
              <i className="fal fa-times"></i>Cancel
            </button>
          </div>
        </div>
        {Object.keys(formik.errors).map((key) => {
  if (formik.touched[key]) {
    const error = formik.errors[key];

    // Check if it's the assignedPage error and handle it
    if (key === 'assignedPage') {
      return (
        <div className="invalid-feedback d-block" key={key}>
          {error.id ? error.id : error.type ? error.type : "Invalid input"}
        </div>
      );
    }

    // For other fields
    const errorMessage = typeof error === 'string' ? error : error.message || "Invalid input";

    return (
      <div className="invalid-feedback d-block" key={key}>
        {errorMessage}
      </div>
    );
  }
  return null;
})}

        {/* {Object.keys(formik.errors).map((key) => {
          if (formik.touched[key]) {
            return (
              <div className="invalid-feedback d-block" key={key}>
                {formik.errors[key]}
              </div>
            );
          }
          return null;
        })} */}
      </form>

      <div
        className="topic-add-modal modal able_modal01 fade"
        id="assignPagePopupBrandTemp"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered available-assessment-modal"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-custom">
              <h5 className="modal-title">
                <i className="fal fa-file"></i> Assign Page
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <AssignPagePopup headerData={headerData} formik={formik} id={subId} assignedPage={assignedPopupPages} assignedPageRef={assignedPageRef} setAssignedPage={setAssignedPopupPages} />
            </div>
          </div>
        </div>
      </div>

      <div
        className="topic-add-modal modal able_modal01 fade"
        id="assignQualificationPopup"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered available-assessment-modal"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-custom">
              <h5 className="modal-title">
                <i className="fal fa-file"></i> Assign Qualification
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <AssignQualificationPopup formik={formik} id={subId} assignedPage={assignedQualification} setAssignedPage={setAssignedQualification} assignedQualificationRef={assignedQualificationRef} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderInformation;
