import React, { useState, useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router";
import { TermPeriods } from "../../systemadministration/DataReactSelect";
import { useFormik } from "formik";
import Select from "react-select";
import HtmlInputEditor from "../../common/HtmlInputEditor";
import * as Yup from "yup";
import Swal from "sweetalert2";
import Papa from "papaparse";
import {
  GetProgrammePageDetailCancelToken,

  GetPagesList,
  GetPageAssignedProgrammeAndShortCourseList,
  GetListProgrammeAndShortCourse,
  GetDropdownForProgrammeAndShortCourse,
  getAssignedDropdownForPage,
  getPagesListForPmcFilters,
  AddAndUpdateProgrammePageContentCancelToken,
  ProgrammesPageListCancelToken,
  ProgrammesPageListFilters,
} from "../../../services/ProgramService";
import hasPermission from "../../../utils/hasMultiplePermission";
import { useSelector, useStore } from "react-redux";
import {
  RenderProgrammeOrCourseType,
  RenderProgrammeType,
} from "../../../utils/CommonGroupingItem";
// import { RenderPMCStatus } from "../../../utils/CommonStatusItems";
import HtmlParser from "react-html-parser";
import axios from "axios";
import DataTableComponent from "../../common/DataTableComponent";
import $, { error } from "jquery";
import DataTableComponentFrontPagination from "../../common/DataTableComponentFrontPagination";
import PermissionsGate from "../../../utils/permissionGate";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import {
  RenderPMCPageStatus,
  RenderPMCStatus,
  RenderPMCStatusCol,
  RenderPageBrands,
} from "../../../utils/CommonStatusItems";
import { URL_REGEX } from "../../../utils/Constants";

const learningMethodOptionArr = [
  {
    label: "Full Time,Hybrid",
    value: "full",
  },
  {
    label: "Full Time,Online",
    value: "fulltimeonline",
  },
  {
    label: "Part Time,Online",
    value: "parttimeonline",
  },
  {
    label: "Part Time,Online",
    value: "parttimeonline",
  },
];

const ShortCourseContent = ({setUpdateData, detail, updateData}) => {
  const history = useHistory();

  const { id, type } = useParams();
  // const [detail, setDetail] = useState({});
  const [programId, setProgramID] = useState(id);
  const [disabled, setDisabled] = useState(false);
  const [AssignData, setAssignData] = useState();

  const [editorState1, setEditorState1] = useState("");
  const [subjectContent, setSubjectContent] = useState("");
  const [programmeOutline, setProgrammeOutline] = useState("");
  const [outcomes, setOutcomes] = useState("");
  const [featuresBenefits, setfeaturesBenefits] = useState("");
  const [prerequisites, setPrerequisites] = useState("");
  const [financing_options, SetFinancing_options] = useState("");
  const [admissionReq, setAdmissionReq] = useState("");
  const [accreditation, setAccreditation] = useState();
  const [articulationOption, setArticulationOption] = useState("");

  const [studyKit, setStudyKit] = useState("");
  const [careerOpportunity, setCareerOpportunity] = useState("");
  const [hardwareReq, setHardwareReq] = useState("");
  const [duration, setDuration] = useState("");
  const [regulatoryInfo, setRegulatoryInfo] = useState("");
  const [impNotes, setImpNotes] = useState("");
  const [financingOptions, setFinancingOptions] = useState("");
  const [careerVideoDec, setCareerVideoDec] = useState("");
  const [learningMethodArr, setLearningMethodArr] = useState([]);
  const [editorToShow, setEditorToShow] = useState(true);

  const [schoolList, setSchoolList] = useState([]);
  const [campusLocList, setCampusLocList] = useState([]);
  const [netsuiteProdList, setNetsuiteProdList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [popupList, setPopupList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  const [allpageListData, setAllPageListData] = useState([]);
  const [assignPageList, setAssignPageList] = useState([]);
  const [assignPageLoading, setAssignPageLoading] = useState(false);
  const [pageStatus, setPageStatus] = useState({ arr: [], checkObj: {} });
  const [pageBrand, setPageBrand] = useState({ arr: [], checkObj: {} });
  const [PageFilterData, setPageFilterdata] = useState({ status: [], BrandList: [], });
  const [assignPageTableState, setAssignPageTableState] = useState({ page: 1, perPage: 10, sortKey: "PageTitle", sortOrder: "asc", });
  const [assignPageSearch, setAssignPageSearch] = useState("");
  const [assignPageTotalRows, setAssignPageTotalRows] = useState(0);
  const [assignedPage, setAssignedPage] = useState({});

  const [articulationOptionList, setArticulationOptionList] = useState([]);
  const [articulationOptionLoading, setArticulationOptionLoading] = useState(false);
  const [articulationOptionSearch, setArticulationOptionSearch] = useState("");
  const [articulationOptionTableState, setArticulationOptionTableState] = useState({ page: 1, perPage: 10, sortKey: "name", sortOrder: "asc", });
  const [artStatus, setArtStatus] = useState({ arr: [], checkObj: {} });
  const [artProgType, setArtProgType] = useState({ arr: [], checkObj: {} });
  const [linkedPmcProgramme, setLinkedPmcProgramme] = useState({ arr: [], checkObj: {} });
  const [articulationOptionFilterData, setArticulationOptionFilterData] = useState({ status: [], programmeType: [], linkedPmcProgramme: [], type: [] });
  
  const [assignedDataList, setAssignedDataLis] = useState([])
  const [articulationAssignedTotalRow, setArticulationAssignednTotalRow] = useState("");
  const [assignedArticulationOptionLoading, setAssignedArticulationOptionLoading,] = useState(false);
  const [assignedStatus, setAssignedStatus] = useState({ arr: [], checkObj: {} });
  const [assignedProgType, setAssignedProgType] = useState({ arr: [], checkObj: {} });
  const [assignedType, setAssignedType] = useState({ arr: [], checkObj: {} });
  // const [updateData, setUpdateData] = useState(false);
  const [pmc_id, setPmc_id] = useState("");
  
  
  
  const [articulationOptionTotalRow, setArticulationOptionTotalRow] = useState("");
  const [assignedArticulationSearch, setAssignedArticulationSearch] = useState("");
  const [assignedArticulationOptionList, setAssignedArticulationOptionList] = useState([]);
  const [programArticulationFilterList, setProgramArticulationFilterList] = useState({ status: [], programmeType: [], linkPMCPrograms: [] });
  const [assignProgStatus, setAssignProgStatus] = useState({ arr: [], checkObj: {} });
  const [assignProgType, setAssignProgType] = useState({ arr: [], checkObj: {} });
  const [assignLinkPMCProg, setAssignLinkPMCProg] = useState({ arr: [], checkObj: {} });

  const [shortArticulationOptionList, setShortArticulationOptionList]=useState([])
  const [shortArticulationOptionTotalRows, setShortArticulationOptionTotalRows]=useState("")
  const [optionShortStatus, setOptionShortStatus] = useState({ arr: [], checkObj: {} });
  const [optionLinkPMCProg, setOptionLinkPMCProg] = useState({ arr: [], checkObj: {} });
  const [shortArtOptionFilterData, setShortArtOptionFilterData] = useState({ status: [], linkedPmcProgramme: [],  });
  const [shortCourseArticulationSearch, setShortCourseArticulationSearch]=useState("")
  const [shortCourseArticulationTableState, SetShortCourseArticulationTableState]=    useState({
    page: 1,
    perPage: 10,
    sortKey: "name",
    sortOrder: "asc",
  });

  const [shortCourseAssignArtDataList, setShortCourseAssignArtDataList] = useState([])
  const [shortArticulationAssignList, setShortArticulationAssignList]=useState([])
  const [AssignShortStatus, setAssignShortStatus] = useState({ arr: [], checkObj: {} });
  const [AssignLinkPMCShort, setAssignLinkPMCShort] = useState({ arr: [], checkObj: {} });
  const [shortArtAssignFilterData, setShortArtAssignFilterData] = useState({ status: [], linkPMCShortCourse: [],  });
  const [shortCourseArticulationAssignSearch, setShortCourseArticulationAssignSearch]=useState("")
  const dropDownValues = useSelector((state) => state.registerDropdownValues);

  useEffect(() => {
    if (type == "open" || type == "duplicate") {
      const payload = {
        page_id: id,
        assigne: 1,
        type_assigned: 1,
        page_type :0,
        exportStatus: "true",
        viaProgrammeType: assignedProgType.arr,
        viaType: assignedType.arr,
        viaStatus: assignedStatus.arr,
      };
      ProgrammesPageListCancelToken(payload)
        .then((res) => {
          if (res.status === 200) {
            const respData = res.data?.result;
            setAssignedArticulationOptionList(respData);
            // setArticulationAssignednTotalRow(respData?.pagination.total);
            const assignedData = respData?.map(item => item.id)
            setAssignedDataLis(assignedData)
          }
          // const assignedData = updatedList.map(item => item.id)  
          //   setAssignedDataLis(assignedData)
          // }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (type == "open" || type == "duplicate") {
      const payload = {
        page_id: id,
        assigne: 1,
        type_assigned: 1,
        page_type :1,
        exportStatus: "true",
        viaProgrammeType: AssignLinkPMCShort.arr,
        viaStatus: AssignShortStatus.arr,
      };
      ProgrammesPageListCancelToken(payload)
        .then((res) => {
          if (res.status === 200) {
            const respData = res.data?.result;
            setShortArticulationAssignList(respData);
            const assignedData = respData?.map(item => item.id)
            setShortCourseAssignArtDataList(assignedData)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [id,updateData]);

  useEffect(() => {
    const cancelTokenSources = [];
    const cancelDropdownSource6 = [];

    const getPageFilters = async () => {
      cancelDropdownSource6.forEach((source) => {
        source.cancel("New request made");
      });
      const source3 = axios.CancelToken.source();
      cancelDropdownSource6.push(source3);

      try {
        const res = await getPagesListForPmcFilters(source3.token);
        if (res.status == 200) {
          setPageFilterdata({
            BrandList: res.data?.getBrandtemplate,
            status: res.data?.getStatus,
          });
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };

    getPageFilters();

    return () => {
      cancelTokenSources.forEach((source) => {
        source.cancel("Component unmounted");
      });
    };
  }, [type]);

  useEffect(() => {
    setLearningMethodArr(learningMethodOptionArr);
  }, []);


  useEffect(() => {
    const cancelTokenSources = [];

    const getAssignPageListData = async () => {
      setAssignPageLoading(true);
      cancelTokenSources.forEach((source) => {
        source.cancel("New request made");
      });

      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);

      try {
        const payload = {
          page: assignPageTableState.page,
          perPage: assignPageTableState.perPage,
          search: assignPageSearch,
          key: assignPageTableState.sortKey,
          sort: assignPageTableState.sortOrder,
          exportStatus: false,
          setSearch: setAssignPageSearch,
          viaBrand: pageBrand.arr,
          viaStatus: pageStatus.arr,
        };
        const res = await GetPagesList(payload, source.token);
        if (res.status == 200) {
          setAssignPageTotalRows(res.data?.Data?.total);
          setAssignPageList(res.data?.Data?.data);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      } finally {
        setAssignPageLoading(false);
      }
    };

    getAssignPageListData();

    return () => {
      cancelTokenSources.forEach((source) => {
        source.cancel("Component unmounted");
      });
    };
  }, [assignPageTableState, assignPageSearch, pageStatus, pageBrand]);

  useEffect(() => {
    if (detail) {
      // setLearningMethodArr(detail.Delivery_Methods_Arr ? detail.Delivery_Methods_Arr : []);
      setSubjectContent(detail?.Description || "");
      setProgrammeOutline(detail?.programme_outline || "");
      setOutcomes(detail?.outcomes || "");
      setfeaturesBenefits(detail?.features_and_benefits || "")
      setAdmissionReq(detail?.admission_req || "");
      setAccreditation(detail?.accreditation || "");
      setArticulationOption(detail?.articultion_option || "");
      setCareerOpportunity(detail?.career_opportunities || "");
      setStudyKit(detail?.study_kit || "");
      setHardwareReq(detail?.hardware_requirement || "");
      setDuration(detail?.duration_dec || "");
      setRegulatoryInfo(detail?.regulatory_inform || "");
      setImpNotes(detail?.important_notes || "");
      setCareerVideoDec(detail?.career_video_dec || "");
      setImpNotes(detail?.important_notes || "");
      setFinancingOptions(detail?.financing_options || "");
      setPrerequisites(detail?.prerequisites || "");
      setEditorToShow(true);
      // console.log(detail?.articulationData)
    }
  }, [detail]);

  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    let response = hasPermission({
      scopes: ["pmcpedit"],
      permissions: givenPermsisions,
    });
    if (!response) {
      history.push("/noaccess");
    }
  }, []);

  useEffect(() => {
    const filterSource = axios.CancelToken.source();
    const filterSource2 = axios.CancelToken.source();
    const getFilters = async () => {
      try {
        const payload = {
          page_id: id,
          assigne: 0,
          type_assigned: 1,
          page_type:0,
        }
        const res = await ProgrammesPageListFilters(payload, filterSource.token);
        if (res.status == 200) {
          const respData = res.data
          setArticulationOptionFilterData({
            ...res.data,
            status: respData?.statusListFilter,
            programmeType: respData?.getType,
            linkedPmcProgramme: respData?.getLinkedProgrammes,
          })
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    }
    const getShortOptionArtFilters = async () => {
      try {
        const payload = {
          page_id: id,
          assigne: 0,
          type_assigned: 1,
          page_type:1
        }
        const res = await ProgrammesPageListFilters(payload, filterSource.token);
        if (res.status == 200) {
          const respData = res.data
          setShortArtOptionFilterData({
            ...res.data,
            status: respData?.statusListFilter,
            linkedPmcProgramme: respData?.getLinkedProgrammes,
          })
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    } 

    const getShortAssignArtFilters = async () => {
      try {
        const payload = {
          page_id: id,
          assigne: 1,
          type_assigned: 1,
          page_type:1
        }
        const res = await ProgrammesPageListFilters(payload, filterSource.token);
        if (res.status == 200) {
          const respData = res.data
          setShortArtAssignFilterData({
            ...res.data,
            status: respData?.statusListFilter,
            linkPMCShortCourse: respData?.getLinkedProgrammes,
          })
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    }

    const getAssignProgArtFilters = async () => {
      try {
        const payload = {
          page_id: id,
          assigne: 1,
          type_assigned: 1,
          page_type:0
        }
        const res = await ProgrammesPageListFilters(payload, filterSource.token);
        if (res.status == 200) {
          const respData = res.data
          setProgramArticulationFilterList({
            ...res.data,
            status: respData?.statusListFilter,
            programmeType: respData.getType,
            linkPMCPrograms: respData.getLinkedProgrammes,
          })
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    }


    if (type == "open" || type == "duplicate") {
      getAssignProgArtFilters()
      getShortAssignArtFilters()
    }

    getFilters()
    getShortOptionArtFilters()
    return () => {
      filterSource.cancel('Component unmounted');
      filterSource2.cancel('Component unmounted');
    };
  }, [updateData])

  useEffect(() => {
    const payload = {
      page: articulationOptionTableState.page,
      limit: articulationOptionTableState.perPage,
      key: articulationOptionTableState.sortKey,
      sort: articulationOptionTableState.sortOrder,
      exportStatus: "false",
      search: articulationOptionSearch,
      viaStatus: artStatus.arr,
      viaType: artProgType.arr,
      viaProgramme: linkedPmcProgramme.arr,
      page_id: id,
      assigne: 0,
      type_assigned: 1,
      page_type : 0,
    };
    setArticulationOptionLoading(true);
    ProgrammesPageListCancelToken(payload)
      .then((res) => {
        if (res.status === 200) {
          const respData = res.data.result;
          setArticulationOptionList(respData?.data);
          setArticulationOptionTotalRow(respData?.total);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setArticulationOptionLoading(false);
      });
  }, [
    articulationOptionTableState,
    linkedPmcProgramme,
    artProgType,
    artStatus,
    articulationOptionSearch,
    id,updateData
  ]);

  useEffect(() => {
    const payload = {
      page: shortCourseArticulationTableState.page,
      limit: shortCourseArticulationTableState.perPage,
      key: shortCourseArticulationTableState.sortKey,
      sort: shortCourseArticulationTableState.sortOrder,
      exportStatus: "false",
      search: shortCourseArticulationSearch,
      viaStatus: optionShortStatus.arr,
      viaProgramme: optionLinkPMCProg.arr,
      page_id: id,
      assigne: 0,
      type_assigned: 1,
      page_type : 1
    };
    // setShortCourseOptionLoading(true);
    ProgrammesPageListCancelToken(payload)
      .then((res) => {
        if (res.status === 200) {
          const respData = res.data.result;
          setShortArticulationOptionList(respData?.data);

          setShortArticulationOptionTotalRows(respData?.total);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        // setShortCourseOptionLoading(false);
      });
  }, [
    shortCourseArticulationTableState,
    optionLinkPMCProg,
    optionShortStatus,
    shortCourseArticulationSearch,
    id,updateData
  ]);

  useEffect(() => {
    if (type == "open" || type == "duplicate") {
      getAssignedDropdownForPage({
        id: id,
        type_assigned: 0,
      })
        .then((res) => {
          if (res.status === 200) {
            const respData = res.data;
            // setAssignedArticulationOptionFilterData({
            //   ...res.data,
            //   AssignStatus: respData.status,
            //   AssignProgrammeType: respData.programmeType,
            //   AssignType: respData.type,
            // });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  useEffect(() => {
    const assignPage = assignPageList.filter((page) => {
      return page.id === formik.values.terms_conditions_page;
    });
    setAssignedPage(assignPage);
  }, [assignPageList]);

  // useEffect(() => {
  //   setDisabled(true);
  //   const cancelTokenSources = [];
  //   try {
  //     cancelTokenSources.forEach(source => {
  //       source.cancel('New request made');
  //     });

  //     const source = axios.CancelToken.source();
  //     cancelTokenSources.push(source);
  //     setDisabled(true);
  //     const getData = async () => {
  //       const res = await GetProgrammePageDetailCancelToken({ id }, source.token);
  //       if (res.status == 200 && res.data?.data) {
  //         setDetail(res?.data?.data);
  //         setPmc_id(res?.data?.data?.pmc_id);
  //       }
  //       setDisabled(false);
  //     }
  //     if (id) {
  //       getData();
  //     }
  //     return () => {
  //       cancelTokenSources.forEach(source => {
  //         source.cancel('Component unmounted');
  //       });
  //     };
  //   } catch (error) {
  //     console.error(error);
  //     setDisabled(false);
  //   }
  //   setDisabled(false);
  // }, [id]);


  const formik = useFormik({
    enableReinitialize: type === "open" || type === "duplicate" ? true : false,
    initialValues: {
      duplicateId: "",
      important_notes: detail?.important_notes ? detail?.important_notes : false,
      financing_options: detail?.financing_options ? detail?.financing_options : "",
      toggle_important_notes: detail?.toggle_important_notes ? detail?.toggle_important_notes == 1 : false,
      features_and_benefits: detail?.features_and_benefits ? detail?.featuresBenefits : "",
      toggle_regulatory_inform: detail?.toggle_regulatory_inform ? detail?.toggle_regulatory_inform == 1 : false,
      toggle_duration_dec: detail?.toggle_duration_dec ? detail?.toggle_duration_dec == 1 : false,
      toggle_articultion_option: detail?.toggle_articultion_option ? detail?.toggle_articultion_option == 1 : false,
      toggle_financing_options: detail?.toggle_financing_options ? detail?.toggle_financing_options == 1 : false,
      toggle_hardware_requirement: detail?.toggle_hardware_requirement ? detail?.toggle_hardware_requirement == 1 : false,
      toggle_study_kit: detail?.toggle_study_kit ? detail?.toggle_study_kit == 1 : false,
      toggle_career_opportunities: detail?.toggle_career_opportunities ? detail.toggle_career_opportunities == 1 : false,
      toggle_accreditation: detail?.toggle_accreditation ? detail?.toggle_accreditation == 1 : false,
      toggle_admission_req: detail?.toggle_admission_req ? detail?.toggle_admission_req == 1 : false,

      toggle_features_and_benefits: detail?.toggle_features_and_benefits ? detail?.toggle_features_and_benefits == 1 : false,
      toggle_redirect: detail?.toggle_redirect ? detail?.toggle_redirect == 1 : false,
      toggle_overview: detail?.toggle_overview ? detail?.toggle_overview == 1 : false,
      terms_conditions_page: detail?.terms_conditions_page ? detail?.terms_conditions_page : "",
      terms_conditions_page_type: detail?.terms_conditions_page_type ? detail?.terms_conditions_page_type : "",
      Description: detail?.Description ? detail.Description : "",
      admission_req: detail?.admission_req ? detail?.admission_req : "",
      accreditation: detail?.accreditation ? detail?.accreditation : "",
      articultion_option: detail?.articultion_option ? detail?.articultion_option : "",
      articulation_programme_assign: assignedDataList,
      articulation_shortcourse_assign: shortCourseAssignArtDataList,
      career_opportunities: detail?.career_opportunities ? detail?.career_opportunities : "",
      study_kit: detail?.study_kit ? detail?.study_kit : "",
      hardware_requirement: detail?.hardware_requirement ? detail?.hardware_requirement : "",
      core_spec: detail?.core_spec ? detail?.core_spec : 0,
      med_spec: detail?.med_spec ? detail?.med_spec : 0,
      high_spec: detail?.high_spec ? detail?.high_spec : 0,
      programme_outline: detail?.programme_outline ? detail?.programme_outline : "",
      outcomes: detail?.outcomes ? detail?.outcomes : "",
      duration_dec: detail?.duration_dec ? detail?.duration_dec : 0,
      regulatory_inform: detail?.regulatory_inform ? detail?.regulatory_inform : 0,
      important_notes: detail?.important_notes ? detail?.important_notes : 0,
      career_video: detail?.career_video ? detail?.career_video : "",
      career_video_dec: detail?.career_video_dec ? detail?.career_video_dec : "",
      external_link: detail?.external_link ? detail?.external_link : "",
      prerequisites: detail?.prerequisites ? detail?.prerequisites : "",
      toggle_prerequisites: detail?.toggle_prerequisites ? detail?.toggle_prerequisites : 0,
      toggle_programme_outline: detail?.toggle_programme_outline ? detail?.toggle_programme_outline : 0,
      toggle_outcomes: detail?.toggle_outcomes ? detail?.toggle_outcomes : 0,
      page_type: "1"
    },
    validationSchema: Yup.object({
      // external_link: Yup.string().url("Please Enter valid URL").matches(URL_REGEX, "url is not valid").when("toggle_redirect", {
      //   is: true,
      //   then: Yup.string().required("External link is required"),
      // }),
      // career_video: Yup.string().url("Please Enter valid URL for career video").matches(URL_REGEX, "url is not valid")
    }),
    onSubmit: (values) => {
      setDisabled(true);
      let formData = new FormData();

      formData.append("toggle_regulatory_inform", values.toggle_regulatory_inform == true ? 1 : 0);
      formData.append("toggle_prerequisites", values.toggle_prerequisites == true ? 1 : 0);
      formData.append("toggle_programme_outline", values.toggle_programme_outline == true ? 1 : 0);
      formData.append("toggle_duration_dec", values.toggle_duration_dec == true ? 1 : 0);
      formData.append("toggle_articultion_option", values.toggle_articultion_option == true ? 1 : 0);
      formData.append("toggle_financing_options", values.toggle_financing_options == true ? 1 : 0);
      formData.append("page_type", values.page_type);
      formData.append("toggle_hardware_requirement", values.toggle_hardware_requirement == true ? 1 : 0);
      formData.append("toggle_study_kit", values.toggle_study_kit == true ? 1 : 0);
      formData.append("toggle_career_opportunities", values.toggle_career_opportunities == true ? 1 : 0);
      formData.append("toggle_accreditation", values.toggle_accreditation == true ? 1 : 0);
      formData.append("toggle_admission_req", values.toggle_admission_req == true ? 1 : 0);
      formData.append("toggle_outcomes", values.toggle_outcomes == true ? 1 : 0);

      formData.append("toggle_features_and_benefits", values.toggle_features_and_benefits == true ? 1 : 0);
      formData.append("toggle_overview", values.toggle_overview == true ? 1 : 0);
      formData.append("toggle_redirect", values.toggle_redirect == true ? 1 : 0);
      formData.append("terms_conditions_page", values.terms_conditions_page);
      formData.append("Description", subjectContent);
      formData.append("features_and_benefits", featuresBenefits);
      formData.append("programme_outline", programmeOutline);
      formData.append("admission_req", admissionReq);
      formData.append("toggle_important_notes", values.toggle_important_notes == true ? 1 : 0);
      formData.append("accreditation", accreditation);
      formData.append("articultion_option", articulationOption);

      values.articulation_programme_assign.forEach((val, idx) => {
        formData.append(`${"articulation_programme_assign"}[${idx}]`, val);
      });
      values.articulation_shortcourse_assign.forEach((val, idx) => {
        formData.append(`${"articulation_shortcourse_assign"}[${idx}]`, val);
      });
      formData.append("career_opportunities", careerOpportunity);
      formData.append("study_kit", studyKit);
      formData.append("hardware_requirement", hardwareReq);
      formData.append("important_notes", impNotes);
      formData.append("outcomes", outcomes);
      formData.append("duration_dec", duration);
      formData.append("core_spec", values.core_spec);
      formData.append("med_spec", values.med_spec);
      formData.append("high_spec", values.high_spec);
      formData.append("regulatory_inform", regulatoryInfo);
      formData.append("career_video", values.career_video);
      formData.append("career_video_dec", careerVideoDec);
      formData.append("external_link", values.external_link);
      formData.append("financing_options", financingOptions);
      formData.append("prerequisites", prerequisites);



      // if (id) {
      id && formData.append("id", id);
      AddAndUpdateProgrammePageContentCancelToken(formData)
        .then((res) => {
          setDisabled(false);
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Updated Successfully",
          });
          setUpdateData(Date.now())
          formik.resetForm();
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: err.response.data.message,
          });
          setDisabled(false);
        });
      // }
      //  else { // Create
      //   AddAndUpdateProgrammePageContentCancelToken(formData)
      //   .then((res) => {
      //     Swal.fire({
      //       icon: "success",
      //       title: "Success",
      //       text: "Added Successfully", 
      //     });
      //     setProgramID(res.data.id);
      //     history.push(
      //       `/websiteManagement/pages/programmes_and_short_courses/programme_pages/content/open/${res.data.id}`
      //     );
      //   })
      //   .catch((err) => {
      //     Swal.fire({
      //       icon: "error",
      //       title: "Error",
      //       text: err.response.data.message,
      //     });
      //     setDisabled(false);
      //   });
      // }
    },
  });

  useEffect(() => {
    const data = netsuiteProdList.filter((item) => item.value == formik.values?.netsuite_product);
    const netsuit_dId = data[0]?.departmentId;

    if (netsuit_dId) {
      formik.setFieldValue("netsuite_department_id", netsuit_dId);
    }
  }, [netsuiteProdList, formik.values?.netsuite_product]);



  const handleChangeInstallments = (value) => {
    const prevValue = formik.values.installments
      .split(",")
      .filter((item) => item != "");
    if (prevValue.includes(value)) {
      const ind = prevValue.findIndex((item) => item == value);
      prevValue.splice(ind, 1);
    } else {
      prevValue.push(value);
    }
    formik.setFieldValue("installments", prevValue.join(","));
  };

  useEffect(()=>{
    setPmc_id(detail?.pmc_id);
  },[detail])

  const handlereset = async () => {
    formik.resetForm();
  };

  const assignPageColumn = useMemo(() => [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      sortField: "PageTitle",
      // wrap: true,
      cell: (row) => (
        <span
          className="as-text-blue curser feature-name"
          // to={{
          //     pathname: `/courseAdministration/Programmes/programme/open/${row.qualification}/details`,
          // }}
          onClick={() => {
            formik.setFieldValue("terms_conditions_page", row.PageID);
            setAssignedPage({ ...row });
            $("#assignPagePopup").modal("hide");
          }}
          title={row.title}
        >
          <span className="textLimit100">
            {row.PageTitle ? row.PageTitle : "-"}
          </span>
        </span>
      ),
    },
    {
      name: "Status",
      selector: "Status",
      sortField: "status",
      sortable: true,
      cell: (row) => (row.status ? RenderPMCStatusCol(row.status).html : "-"),
    },
    {
      name: "Brand Templates",
      selector: "Brand Templates",
      sortable: false,
      cell: (row) => (
        <p className="d-flex flex-wrap">
          {row?.brandTemplete?.length > 0
            ? row?.brandTemplete.map(
              (brand, index) =>
                brand?.templateName && (
                  <div className="brandList-pmc" title={brand?.templateName}>
                    {brand?.templateName}
                  </div>
                )
            )
            : "-"}
        </p>
      ),
    },
    {
      name: "Action",
      selector: "",
      maxWidth: "50px",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            {formik.values.terms_conditions_page != row?.PageID ? (
              <button
                type="button"
                className="btn btn-primary rounded-circle"
                onClick={() => {
                  formik.setFieldValue("terms_conditions_page", row?.PageID);
                  setAssignedPage({ ...row });
                  formik.setFieldValue(
                    "terms_conditions_page_type",
                    row?.PageTitle
                  );
                  $("#assignPagePopup").modal("hide");
                }}
                title="Assign Page"
              >
                <i className="fal fa-plus"></i>
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-danger rounded-circle"
                onClick={() => {
                  formik.setFieldValue("terms_conditions_page", "");
                  formik.setFieldValue("terms_conditions_page_type", "");
                  setAssignedPage({});
                }}
                title="Assign Page"
              >
                <i className="fal fa-minus"></i>
              </button>
            )}
          </div>
        </div>
      ),
    },
  ]);

  // const dataToRenderAssignedArticulation = () => {
  //   return assignedArticulationOptionList;
  // }
  const dataToRender = () => {
    let updatedData = [];
    let allData = [...assignedArticulationOptionList];
    if (assignedArticulationSearch.length) {
      let name = allData.filter((item) => {
        let includes = item.name
          ?.toString()
          ?.toLowerCase()
          ?.includes(assignedArticulationSearch.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let data = [...name];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (assignProgStatus.arr.length) {
      let tempStatus = updatedData;
      let tempResult = tempStatus.filter((item) => {
        const startsWith = assignProgStatus.arr.find(function (post, index) {
          if (
            item.publish_status && post.toLowerCase() == item.publish_status.toLowerCase()
              ? item.publish_status
              : ""
          )
            return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempResult;
    }
    if (assignLinkPMCProg.arr.length) {
      let tempProgType = updatedData;
      let tempResult = tempProgType.filter((item) => {
        const startsWith = assignLinkPMCProg.arr.find(function (post, index) {
          if (
            item.pmc_id && post == item.pmc_id
              ? item.pmc_id
              : ""
          )
            return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempResult;
    }
    if (assignProgType.arr.length) {
      let tempProgType = updatedData;
      let tempResult = tempProgType.filter((item) => {
        const startsWith = assignProgType.arr.find(function (post, index) {
          if (
            item.qualification_type && post == item.qualification_type
              ? item.qualification_type
              : ""
          )
            return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempResult;
    }

    if (
      assignedArticulationSearch.length ||
      assignProgStatus.arr.length ||
      assignLinkPMCProg.arr.length ||
      assignProgType.arr.length
    ) {
      const uniqueData = updatedData.filter((value, index, self) =>
        index == self.findIndex((t) => (
          t.id == value.id && t?.pmc_type == value?.pmc_type && t?.type == value?.type
        ))
      );

      return uniqueData;
    } else {
      const uniqueData = allData.filter((value, index, self) =>
        index == self.findIndex((t) => (
          t.id == value.id && t?.pmc_type == value?.pmc_type && t?.type == value?.type
        ))
      );
      return uniqueData;
    }
  };

  const renderShortArticulation = () => {
    let updatedData = [];
    let allData = [...shortArticulationAssignList];
    if (shortCourseArticulationAssignSearch.length) {
      let name = allData.filter((item) => {
        let includes = item.name
          ?.toString()
          ?.toLowerCase()
          ?.includes(shortCourseArticulationAssignSearch.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let data = [...name];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (AssignShortStatus.arr.length) {
      let tempStatus = updatedData;
      let tempResult = tempStatus.filter((item) => {
        const startsWith = AssignShortStatus.arr.find(function (post, index) {
          if (
            item.publish_status && post.toLowerCase() == item.publish_status.toLowerCase()
              ? item.publish_status
              : ""
          )
            return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempResult;
    }

    if (AssignLinkPMCShort.arr.length) {
      let tempProgType = updatedData;
      let tempResult = tempProgType.filter((item) => {
        const startsWith = AssignLinkPMCShort.arr.find(function (post, index) {
          if (
            item.pmc_id && post == item.pmc_id
              ? item.pmc_id
              : ""
          )
            return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });
      updatedData = tempResult;
    }

    if (
      shortCourseArticulationAssignSearch.length ||
      AssignShortStatus.arr.length ||
      AssignLinkPMCShort.arr.length 
    ) {
      const uniqueData = updatedData.filter((value, index, self) =>
        index == self.findIndex((t) => (
          t.id == value.id && t?.pmc_type == value?.pmc_type && t?.type == value?.type
        ))
      );

      return uniqueData;
    } else {
      const uniqueData = allData.filter((value, index, self) =>
        index == self.findIndex((t) => (
          t.id == value.id && t?.pmc_type == value?.pmc_type && t?.type == value?.type
        ))
      );
      return uniqueData;
    }
  };

  const exportDataProAssignedArticulationOption = () => {
    let data = dataToRender();
    const header = ["Name", "Code", "Status", "Programme Type", "Linked PMC Programme"];
    data = data.map((row) => ({
      Name: row?.name ? row?.name : "-",
      Code: row.Code ? row.Code : "-",
      Status: row.publish_status ? row.publish_status : "-",
      "Programme Type": row.qualification_type
        ? RenderProgrammeType(row?.qualification_type).text
        : "-",
     "Linked PMC Programme" : row?.LinkedProgrammeName ? row?.LinkedProgrammeName : "-"
    }));
    const pdfData = data.map((row) => {
      return [
        row["Name"],
        row["Code"],
        row["Status"],
        row["Programme Type"],
        row["Linked PMC Programme"],
      ];
    });
    return {
      header,
      data,
      pdfData,
      columnStyles: {},
    };
  };

  const exportDataShortAssignedArticulationOption = () => {
    let data = renderShortArticulation();
    const header = ["Name", "Code", "Status", "Linked PMC Programme"];
    data = data.map((row) => ({
      Name: row?.name ? row?.name : "-",
      Code: row.Code ? row.Code : "-",
      Status: row.publish_status ? row.publish_status : "-",
      "Linked PMC Programme" : row?.LinkedProgrammeName ? row?.LinkedProgrammeName : "-"
    }));
    const pdfData = data.map((row) => {
      return [
        row["Name"],
        row["Code"],
        row["Status"],
        row["Linked PMC Programme"],
      ];
    });
    return {
      header,
      data,
      pdfData,
      columnStyles: {},
    };
  };

  const exportDataProgArticulationOption = (fileType, fileName) => {
    let exportData = [...articulationOptionList];
    const header = ["Name", "Code", "Status", "Programme Type", "Linked PMC Programme"];

    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });

    const payload = {
      page: articulationOptionTableState.page,
      limit: articulationOptionTableState.perPage,
      key: articulationOptionTableState.sortKey,
      sort: articulationOptionTableState.sortOrder,
      exportStatus: "true",
      search: articulationOptionSearch,
      viaStatus: artStatus.arr,
      viaType: artProgType.arr,
      viaProgramme: linkedPmcProgramme.arr,
      page_id: id,
      assigne: 0,
      type_assigned: 1,
      page_type:0
    };

    ProgrammesPageListCancelToken(payload)
      .then((res) => {
        exportData = res.data;
        exportData = exportData?.result?.map((row) => ({
          ...row,
          Name: row?.name ? row?.name : "-",
          Code: row.Code ? row.Code : "-",
          Status: row.publish_status ? row.publish_status : "-",
          "Programme Type": row.qualification_type
            ? RenderProgrammeType(row?.qualification_type).text
            : "-",
          "Linked PMC Programme" : row?.LinkedProgrammeName ? row?.LinkedProgrammeName : "-"
        }));

        if (fileType === "csv") {
          const csvString = Papa.unparse({ fields: header, exportData });
          const blob = new Blob([csvString], {
            type: "text/csv;charset=utf-8,",
          });

          const blobURL = window.URL.createObjectURL(blob);

          // Create new tag for download file
          const anchor = document.createElement("a");
          anchor.download = fileName;
          anchor.href = blobURL;
          anchor.dataset.downloadurl = [
            "text/csv",
            anchor.download,
            anchor.href,
          ].join(":");
          anchor.click();

          // Remove URL.createObjectURL. The browser should not save the reference to the file.
          setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            URL.revokeObjectURL(blobURL);
          }, 1000);
          Swal.close();
        } else if (fileType === "xlsx") {
          const compatibleData = exportData.map((row) => {
            const obj = {};
            header.forEach((col, index) => {
              obj[col] = row[col];
            });
            return obj;
          });

          let wb = XLSX.utils.book_new();
          let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
            header,
          });
          XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
          XLSX.writeFile(wb, `${fileName}.xlsx`);
          Swal.close();
          // Returning false as downloading of file is already taken care of
          return false;
        }
        if (fileType === "pdf") {
          const compatibleData = exportData.map((row) => {
            return [
              row["Name"],
              row["Code"],
              row["Status"],
              row["Programme Type"],
              row["Linked PMC Programme"],
            ];
          });
          const doc = new JsPDF();
          doc.autoTable({
            head: [header],
            body: compatibleData,
            styles: {
              minCellHeight: 10,
              minCellWidth: 5,
              halign: "left",
              // valign: "center",
              fontSize: 8,
            },
          });
          doc.save(`${fileName}.pdf`);
          Swal.close();
          return false;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const exportDataShortArticulationOption = (fileType, fileName) => {
    let exportData = [...articulationOptionList];
    const header = ["Name", "Code", "Status","Linked PMC Programme"];

    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });

    const payload = {
      page: articulationOptionTableState.page,
      limit: articulationOptionTableState.perPage,
      key: articulationOptionTableState.sortKey,
      sort: articulationOptionTableState.sortOrder,
      exportStatus: "true",
      search: articulationOptionSearch,
      viaStatus: optionShortStatus.arr,
      viaProgrammeType: optionLinkPMCProg.arr,
      // viaProgramme: linkedPmcProgramme.arr,
      page_id: id,
      assigne: 0,
      type_assigned: 1,
      page_type:1
    };

    ProgrammesPageListCancelToken(payload)
      .then((res) => {
        exportData = res.data;
        exportData = exportData?.result?.map((row) => ({
          ...row,
          Name: row?.name ? row?.name : "-",
          Code: row.Code ? row.Code : "-",
          Status: row.publish_status ? row.publish_status : "-",
          "Linked PMC Programme" : row?.LinkedProgrammeName ? row?.LinkedProgrammeName : "-"
        }));

        if (fileType === "csv") {
          const csvString = Papa.unparse({ fields: header, exportData });
          const blob = new Blob([csvString], {
            type: "text/csv;charset=utf-8,",
          });

          const blobURL = window.URL.createObjectURL(blob);

          // Create new tag for download file
          const anchor = document.createElement("a");
          anchor.download = fileName;
          anchor.href = blobURL;
          anchor.dataset.downloadurl = [
            "text/csv",
            anchor.download,
            anchor.href,
          ].join(":");
          anchor.click();

          // Remove URL.createObjectURL. The browser should not save the reference to the file.
          setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            URL.revokeObjectURL(blobURL);
          }, 1000);
          Swal.close();
        } else if (fileType === "xlsx") {
          const compatibleData = exportData.map((row) => {
            const obj = {};
            header.forEach((col, index) => {
              obj[col] = row[col];
            });
            return obj;
          });

          let wb = XLSX.utils.book_new();
          let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
            header,
          });
          XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
          XLSX.writeFile(wb, `${fileName}.xlsx`);
          Swal.close();
          // Returning false as downloading of file is already taken care of
          return false;
        }
        if (fileType === "pdf") {
          const compatibleData = exportData.map((row) => {
            return [
              row["Name"],
              row["Code"],
              row["Status"],
              row["Linked PMC Programme"],
            ];
          });
          const doc = new JsPDF();
          doc.autoTable({
            head: [header],
            body: compatibleData,
            styles: {
              minCellHeight: 10,
              minCellWidth: 5,
              halign: "left",
              // valign: "center",
              fontSize: 8,
            },
          });
          doc.save(`${fileName}.pdf`);
          Swal.close();
          return false;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const articulationOptionColumn = useMemo(() => [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      sortField: "name",
      cell: (row) => (
        <Link 
        to={`/websiteManagement/pages/programmes_and_short_courses/programme_pages/details/open/${row.id}`}
        onClick={() => $('#assignArticulationPopup').modal('hide')}
        className="as-text-blue curser feature-name" title={row.name}>
          <span className="textLimit100">{row.name ? row.name : "-"}</span>
        </Link>
      ),
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      sortField: "status",
      cell: (row) =>
        row.publish_status != null ? RenderPMCStatus(row.publish_status).html : "-",
    },
    {
      name: "Linked PMC Programme",
      selector: "LinkedProgrammeName",
      sortable: true,
      sortField: "LinkedProgrammeName",
      cell: (row) =>
        row.LinkedProgrammeName ? <Link
      className="as-text-blue curser feature-name"
      to={`/program-management-center/programmes/programme/open/${row.pmc_id}`}
      onClick={() => $("#assignArticulationPopup").modal("hide")}
      title={row.LinkedProgrammeName}
    >
      <span className="textLimit100">{row.LinkedProgrammeName || "-"}</span>
    </Link> : "-"
    },
    {
      name: "Code",
      selector: "Code",
      sortable: true,
      sortField: "Code",
      cell: (row) => (row.Code ? row.Code : "-"),
    },
    {
      name: "Programme Type",
      selector: "qualification_type",
      sortable: true,
      sortField: "qualification_type",
      cell: (row) =>
        row.qualification_type != null
          ? RenderProgrammeType(row.qualification_type).html
          : "-",
    },
    // {
    //   name: "Type",
    //   selector: "type",
    //   sortable: true,
    //   sortField: "type",
    //   cell: (row) =>
    //     row.type != null ? RenderProgrammeOrCourseType(row.type).html : "-",
    // },
    {
      name: "Action",
      selector: "",
      maxWidth: "50px",
      cell: (row) => {
        const isAssigned = assignedArticulationOptionList.some(
          (item) =>
            item.id == row.id &&
            (item.type || item.pmc_type) == (row.type || row.pmc_type)
        );
        const handleAssign = () => {
          setAssignedArticulationOptionList((prevList) => {
            const updatedList = isAssigned
              ? prevList.filter(
                (item) =>
                  !(
                    item.id == row.id &&
                    (item.type || item.pmc_type) == (row.type || row.pmc_type)
                  )
              )
              : [...prevList, { ...row, isAssigned: true }];

            const assignedData = updatedList.map(item => item.id)
            formik.setFieldValue("articulation_programme_assign", assignedData);
            return updatedList;
          });
        };

        return (
          <div className="assessment-08">
            <div className="as-buttons">
              <button
                type="button"
                className={`btn rounded-circle ${isAssigned ? "btn-danger" : "btn-primary"
                  }`}
                onClick={handleAssign}
                title={isAssigned ? "Unassign Page" : "Assign Page"}
              >
                <i className={`fal fa-${isAssigned ? "minus" : "plus"}`}></i>
              </button>
            </div>
          </div>
        );
      },
    },
  ]);

  const shortOptionArtColumn = useMemo(() => [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      sortField: "name",
      cell: (row) => (
        <Link
        to={`/websiteManagement/pages/programmes_and_short_courses/short_course_page/details/open/${row.id}`}
        onClick={() => $("#assignShortCourseOptionList").modal("hide")}
        className="as-text-blue curser feature-name" title={row.name}>
          <span className="textLimit100">{row.name ? row.name : "-"}</span>
        </Link>
      ),
    },
    {
      name: "Status",
      selector: "status",
      sortable: true,
      sortField: "status",
      cell: (row) =>
        row.publish_status != null ? RenderPMCStatus(row.publish_status).html : "-",
    },
    {
      name: "Linked PMC Short Course",
      selector: "LinkedProgrammeName",
      sortable: true,
      sortField: "LinkedProgrammeName",
      cell: (row) =>
        row.LinkedProgrammeName ? <Link
          className="as-text-blue curser feature-name"
          to={`/program-management-center/programmes/shortCourse/open/${row.pmc_id}`}
          onClick={() => $("#assignShortCourseOptionList").modal("hide")}
          title={row.LinkedProgrammeName}
        >
          <span className="textLimit100">{row.LinkedProgrammeName || "-"}</span>
        </Link> : "-"
    },
    {
      name: "Code",
      selector: "Code",
      sortable: true,
      sortField: "Code",
      cell: (row) => (row.Code ? row.Code : "-"),
    },
    // {
    //   name: "Programme Type",
    //   selector: "qualification_type",
    //   sortable: true,
    //   sortField: "qualification_type",
    //   cell: (row) =>
    //     row.qualification_type != null
    //       ? RenderProgrammeType(row.qualification_type).html
    //       : "-",
    // },
    // {
    //   name: "Type",
    //   selector: "type",
    //   sortable: true,
    //   sortField: "type",
    //   cell: (row) =>
    //     row.type != null ? RenderProgrammeOrCourseType(row.type).html : "-",
    // },
    {
      name: "Action",
      selector: "",
      maxWidth: "50px",
      cell: (row) => {
        const isAssigned = shortArticulationAssignList.some(
          (item) =>
            item.id == row.id &&
            (item.type || item.pmc_type) == (row.type || row.pmc_type)
        );
        const handleAssign = () => {
          setShortArticulationAssignList((prevList) => {
            const updatedList = isAssigned
              ? prevList.filter(
                (item) =>
                  !(
                    item.id == row.id &&
                    (item.type || item.pmc_type) == (row.type || row.pmc_type)
                  )
              )
              : [...prevList, { ...row, isAssigned: true }];

            const assignedData = updatedList.map(item => item.id)
            formik.setFieldValue("articulation_shortcourse_assign", assignedData);
            return updatedList;
          });
        };

        return (
          <div className="assessment-08">
            <div className="as-buttons">
              <button
                type="button"
                className={`btn rounded-circle ${isAssigned ? "btn-danger" : "btn-primary"
                  }`}
                onClick={handleAssign}
                title={isAssigned ? "Unassign Page" : "Assign Page"}
              >
                <i className={`fal fa-${isAssigned ? "minus" : "plus"}`}></i>
              </button>
            </div>
          </div>
        );
      },
    },
  ]);

  const sortingName = (rowA, rowB) => {
    const name1 = rowA.name ? rowA.name.toString() : "";
    const name2 = rowB.name ? rowB.name.toString() : "";
    return name1.localeCompare(name2);
  };

  const sortingCode = (rowA, rowB) => {
    const code1 = rowA.Code ? rowA.Code.toString() : "";
    const code2 = rowB.Code ? rowB.Code.toString() : "";
    return code1.localeCompare(code2);
  };

  const sortingProgrammeType = (rowA, rowB) => {
    const qualification_name1 = rowA.qualification_name
      ? rowA.qualification_name.toString()
      : "";
    const qualification_name2 = rowB.qualification_name
      ? rowB.qualification_name.toString()
      : "";
    return qualification_name1.localeCompare(qualification_name2);
  };

  const sortingType = (rowA, rowB) => {
    const type1 =
      rowA?.pmc_type || rowA?.type
        ? rowA?.type?.toString() || rowA?.pmc_type?.toString()
        : "";
    const type2 =
      rowB?.pmc_type || rowB?.type
        ? rowB?.type?.toString() || rowB?.pmc_type?.toString()
        : "";
    return type1.localeCompare(type2);
  };

  const ArticulationAssigncolumns = useMemo(() => [
    {
      name: "Name",
      selector: "name",
      sortFunction: sortingName,
      sortField: "name",
      sortable: true,
      cell: (row) => (
        <Link
          to={`/websiteManagement/pages/programmes_and_short_courses/programme_pages/details/open/${row.id}`}
          title={row.name}
          className="as-text-blue curser feature-name"
        >
          <span title={row.name} className="textLimit100">
            {row.name}
          </span>
        </Link>
      ),
    },
    {
      name: "Status",
      selector: "status",
      sortField: "status",
      sortable: true,
      cell: (row) =>
        row.publish_status != null ? RenderPMCStatus(row.publish_status).html : "-",
    },
    {
      name: "Linked PMC Programme",
      selector: "Linked PMC Programme",
      sortable: true,
      sortField: "Linked PMC Programme",
      cell: (row) =>
        row.LinkedProgrammeName ? <Link
          className="as-text-blue curser feature-name"
          to={`/program-management-center/programmes/programme/open/${row.pmc_id}`}
          onClick={() => $("#assignArticulationPopup").modal("hide")}
          title={row.LinkedProgrammeName}
        >
          <span className="textLimit100">{row.LinkedProgrammeName || "-"}</span>
        </Link> : "-"
    },
    {
      name: "Code",
      selector: "Code",
      sortField: "Code",
      sortFunction: sortingCode,
      sortable: true,
      cell: (row) => (row.Code ? row.Code : "-"),
    },
    {
      name: "Programme Type",
      selector: "Programme Type",
      sortField: "Programme Type",
      sortable: true,
      sortFunction: sortingProgrammeType,
      cell: (row) =>
        row.qualification_type != null
          ? RenderProgrammeType(row.qualification_type).html
          : "-",
    },
    // {
    //   name: "Type",
    //   selector: "type",
    //   sortField: "pmc_type",
    //   sortable: true,
    //   sortFunction: sortingType,
    //   cell: (row) =>
    //     row?.pmc_type !== null && row?.pmc_type !== undefined
    //   ? RenderProgrammeOrCourseType(row.pmc_type).html
    //   : row?.type !== null && row?.type !== undefined
    //   ? RenderProgrammeOrCourseType(row.type).html
    //   : "-",
    // },
    {
      name: "Actions",
      selector: "",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons">
            <Link
              to={`/websiteManagement/pages/programmes_and_short_courses/programme_pages/details/open/${row.id}`}
              className="btn btn-primary rounded-circle"
              title="Open"
            >
              <i className="fal fa-folder-open"></i>
            </Link>

            <button
              title="Delete"
              type="button"
              className="btn btn-danger rounded-circle"
              onClick={() => {
                setAssignedArticulationOptionList((prevList) => {
                  const updatedList = prevList?.filter((item) => {
                    const itemType = item.pmc_type || item.type;
                    const rowType = row.pmc_type || row.type;

                    return !(
                      item.id == row.id &&
                      itemType?.toString() == rowType?.toString()
                    );
                  });

                  const assignedData = updatedList.map(item => item.id)
                  formik.setFieldValue("articulation_programme_assign", assignedData);

                  return updatedList;
                });
              }}
            >
              <i className="fal fa-trash-alt"></i>
            </button>
          </div>
        </div>
      ),
    },
  ]);

  const assignShortCourseArticulationColumns = useMemo(() => [
    {
      name: "Name",
      selector: "name",
      sortFunction: sortingName,
      sortField: "name",
      sortable: true,
      cell: (row) => (
        <Link
        to={`/websiteManagement/pages/programmes_and_short_courses/short_course_page/details/open/${row.id}`}
          title={row.name}
          className="as-text-blue curser feature-name"
        >
          <span title={row.name} className="textLimit100">
            {row.name}
          </span>
        </Link>
      ),
    },
    {
      name: "Status",
      selector: "status",
      sortField: "status",
      sortable: true,
      cell: (row) =>
        row.publish_status != null ? RenderPMCStatus(row.publish_status).html : "-",
    },
    {
      name: "Linked PMC Short Course",
      selector: "Linked PMC Short Course",
      sortable: true,
      sortField: "Linked PMC Short Course",
      cell: (row) =>
        row.LinkedProgrammeName ? <Link
          className="as-text-blue curser feature-name"
          to={`/program-management-center/programmes/shortCourse/open/${row.pmc_id}`}
          onClick={() => $("#assignArticulationPopup").modal("hide")}
          title={row.LinkedProgrammeName}
        >
          <span className="textLimit100">{row.LinkedProgrammeName || "-"}</span>
        </Link> : "-"
    },
    {
      name: "Code",
      selector: "Code",
      sortField: "Code",
      sortFunction: sortingCode,
      sortable: true,
      cell: (row) => (row.Code ? row.Code : "-"),
    },
    {
      name: "Actions",
      selector: "",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons">
            <Link
              to={`/websiteManagement/pages/programmes_and_short_courses/short_course_page/details/open/${row.id}`}
              className="btn btn-primary rounded-circle"
              title="Open"
            >
              <i className="fal fa-folder-open"></i>
            </Link>

            <button
              title="Delete"
              type="button"
              className="btn btn-danger rounded-circle"
              onClick={() => {
                setShortArticulationAssignList((prevList) => {
                  const updatedList = prevList?.filter((item) => {
                    const itemType = item.pmc_type || item.type;
                    const rowType = row.pmc_type || row.type;

                    return !(
                      item.id == row.id &&
                      itemType?.toString() == rowType?.toString()
                    );
                  });

                  const assignedData = updatedList.map(item => item.id)
                  formik.setFieldValue("articulation_shortcourse_assign", assignedData);

                  return updatedList;
                });
              }}
            >
              <i className="fal fa-trash-alt"></i>
            </button>
          </div>
        </div>
      ),
    },
  ]);

  useEffect(() => {
    const payload = {
      // page:assignPageTableState.page,
      // perPage:assignPageTableState.perPage,
      // search:assignPageSearch,
      // key:assignPageTableState.sortKey,
      // sort:assignPageTableState.sortOrder,
      exportStatus: "true",
      // setSearch:setAssignPageSearch,
      // viaBrand:pageBrand.arr,
      // viaStatus:pageStatus.arr,
    };

    GetPagesList(payload)
      .then((res) => {
        if (res.status == 200) {
          setAllPageListData(res.data?.Data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const exportAssignPageData = (fileType, fileName) => {
    let exportData = [...assignedArticulationOptionList];
    const header = ["Name", "Status", "brand templates"];

    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });

    const payload = {
      page: assignPageTableState.page,
      perPage: assignPageTableState.perPage,
      search: assignPageSearch,
      key: assignPageTableState.sortKey,
      sort: assignPageTableState.sortOrder,
      exportStatus: true,
      viaBrand: pageBrand.arr,
      viaStatus: pageStatus.arr,
    };
    GetPagesList(payload)
      .then((res) => {
        exportData = res.data.Data;
        exportData = exportData?.map((row) => ({
          ...row,
          Name: row?.PageTitle ? row?.PageTitle : "-",
          Status: row?.status ? row.status : "-",
          "brand templates":
            row?.brandTemplete?.length > 0
              ? row.brandTemplete.map((item) => item?.templateName).join(", ")
              : "-",
        }));

        if (fileType === "csv") {
          const csvString = Papa.unparse({ fields: header, exportData });
          const blob = new Blob([csvString], {
            type: "text/csv;charset=utf-8,",
          });

          const blobURL = window.URL.createObjectURL(blob);

          // Create new tag for download file
          const anchor = document.createElement("a");
          anchor.download = fileName;
          anchor.href = blobURL;
          anchor.dataset.downloadurl = [
            "text/csv",
            anchor.download,
            anchor.href,
          ].join(":");
          anchor.click();

          // Remove URL.createObjectURL. The browser should not save the reference to the file.
          setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            URL.revokeObjectURL(blobURL);
          }, 1000);
          Swal.close();
        } else if (fileType === "xlsx") {
          const compatibleData = exportData.map((row) => {
            const obj = {};
            header.forEach((col, index) => {
              obj[col] = row[col];
            });
            return obj;
          });

          let wb = XLSX.utils.book_new();
          let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
            header,
          });
          XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
          XLSX.writeFile(wb, `${fileName}.xlsx`);
          Swal.close();
          // Returning false as downloading of file is already taken care of
          return false;
        }
        if (fileType === "pdf") {
          const compatibleData = exportData.map((row) => {
            return [row["Name"], row["Status"], row["brand templates"]];
          });
          const doc = new JsPDF();
          doc.autoTable({
            head: [header],
            body: compatibleData,
            styles: {
              minCellHeight: 10,
              minCellWidth: 5,
              halign: "left",
              // valign: "center",
              fontSize: 8,
            },
          });
          doc.save(`${fileName}.pdf`);
          Swal.close();
          return false;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const editorArr = [
    {
      name: "Overview",
      id: "oneW",
      component: (
        <>
          {pmc_id ? (<>
            <div
              className="d-flex my-20"
              title="Include content from PMC on the website."
            >
              <label className="mb-0">
                Overwrite PMC Content
                <i className="fal fa-info-circle grade-icon ml-2"></i>
                {/* <a href={formik.values.external_link} target="_blank"><i className="fal fa-external-link color-sidebar ml-2"></i></a> */}
              </label>
              <div className="toggle-switch ml-2">
                <label class="switch">
                  <input
                    type="checkbox"
                    checked={formik.values.toggle_overview === true}
                    onChange={(e) => {
                      formik.setFieldValue(
                        "toggle_overview",
                        e.target.checked
                      );
                    }}
                  />
                  <span class="slider slider-round"></span>
                </label>
              </div>
            </div>
          </>) : ""}
          <HtmlInputEditor
            editorState={subjectContent}
            setEditorState={setSubjectContent}
            hideSign={true}
            belowNoMargin={true}
            isCKEditor={true}
          />
        </>
      ),
      isInvalid: formik.errors.Description && formik.touched.Description,
    },
    {
      name: "Features & Benefits",
      id: "twoW",
      component: (
        <>
          {pmc_id ? (<>
            <div
              className="d-flex my-20"
              title="Include content from PMC on the website."
            >
              <label className="mb-0">
                Overwrite PMC Content
                <i className="fal fa-info-circle grade-icon ml-2"></i>
                {/* <a href={formik.values.external_link} target="_blank"><i className="fal fa-external-link color-sidebar ml-2"></i></a> */}
              </label>
              <div className="toggle-switch ml-2">
                <label class="switch">
                  <input
                    type="checkbox"
                    checked={formik.values.toggle_features_and_benefits === true}
                    onChange={(e) => {
                      formik.setFieldValue(
                        "toggle_features_and_benefits",
                        e.target.checked
                      );
                    }}
                  />
                  <span class="slider slider-round"></span>
                </label>
              </div>
            </div>
          </>) : ""}
          <HtmlInputEditor
            editorState={featuresBenefits}
            setEditorState={setfeaturesBenefits}
            hideSign={true}
            belowNoMargin={true}
            isCKEditor={true}
          />
        </>
      ),
      isInvalid: formik.errors.Description && formik.touched.Description,
    },
    {
      name: "Prerequisites",
      id: "twoPre",
      component: (
        <>
          {pmc_id ? (<>
            <div
              className="d-flex my-20"
              title="Include content from PMC on the website."
            >
              <label className="mb-0">
                Overwrite PMC Content
                <i className="fal fa-info-circle grade-icon ml-2"></i>
                {/* <a href={formik.values.external_link} target="_blank"><i className="fal fa-external-link color-sidebar ml-2"></i></a> */}
              </label>
              <div className="toggle-switch ml-2">
                <label class="switch">
                  <input
                    type="checkbox"
                    checked={formik.values.toggle_prerequisites === true}
                    onChange={(e) => {
                      formik.setFieldValue(
                        "toggle_prerequisites",
                        e.target.checked
                      );
                    }}
                  />
                  <span class="slider slider-round"></span>
                </label>
              </div>
            </div>
          </>) : ""}
          <HtmlInputEditor
            editorState={prerequisites}
            setEditorState={setPrerequisites}
            hideSign={true}
            belowNoMargin={true}
            isCKEditor={true}
          />
        </>
      ),
      isInvalid: formik.errors.Description && formik.touched.Description,
    },
    {
      name: "Course Outline",
      id: "towCourse",
      component: (
        <>
          {/* {pmc_id ? (<>
            <div
              className="d-flex my-20"
              title="Overwrite PMC Content"
            >
              <label className="mb-0">
                Overwrite PMC Content
                <i className="fal fa-info-circle grade-icon"></i>
                <i className="fal fa-external-link color-sidebar ml-2"></i>
              </label>
              <div className="toggle-switch ml-2">
                <label class="switch">
                  <input
                    type="checkbox"
                    checked={formik.values.toggle_programme_outline === true}
                    onChange={(e) => {
                      formik.setFieldValue(
                        "toggle_programme_outline",
                        e.target.checked
                      );
                    }}
                  />
                  <span class="slider slider-round"></span>
                </label>
              </div>
            </div>
          </>) : ""} */}
          <HtmlInputEditor
            editorState={programmeOutline}
            setEditorState={setProgrammeOutline}
            hideSign={true}
            belowNoMargin={true}
            isCKEditor={true}
          />
        </>
      ),
      isInvalid: formik.errors.Description && formik.touched.Description,
    },
    {
      name: "Outcomes",
      id: "twoOut",
      component: (
        <>
          {/* {pmc_id ? (<>
            <div
              className="d-flex my-20"
              title="Overwrite PMC Content"
            >
              <label className="mb-0">
                Overwrite PMC Content
                <i className="fal fa-info-circle grade-icon"></i>
                <i className="fal fa-external-link color-sidebar ml-2"></i>
              </label>
              <div className="toggle-switch ml-2">
                <label class="switch">
                  <input
                    type="checkbox"
                    checked={formik.values.toggle_outcomes === true}
                    onChange={(e) => {
                      formik.setFieldValue(
                        "toggle_outcomes",
                        e.target.checked
                      );
                    }}
                  />
                  <span class="slider slider-round"></span>
                </label>
              </div>
            </div>
          </>) : ""} */}
          <HtmlInputEditor
            editorState={outcomes}
            setEditorState={setOutcomes}
            hideSign={true}
            belowNoMargin={true}
            isCKEditor={true}
          />
        </>
      ),
      isInvalid: formik.errors.Description && formik.touched.Description,
    },
    //   {
    //     name: "Admission Requirements",
    //     id: "threeW",
    //     component: (
    //       <>
    //       <div
    //       className="d-flex my-20"
    //       title="Overwrite PMC Content"
    //       >
    //       <label className="mb-0">
    //       Overwrite PMC Content
    //       <i className="fal fa-info-circle grade-icon"></i>
    //       <i className="fal fa-external-link color-sidebar ml-2"></i>
    //       </label>
    //       <div className="toggle-switch ml-2">
    //       <label class="switch">
    //       <input
    //       type="checkbox"
    //       checked={formik.values.toggle_admission_req === true}
    //       onChange={(e) => {
    //         formik.setFieldValue(
    //           "toggle_admission_req",
    //           e.target.checked
    //         );
    //       }}
    //       />
    //       <span class="slider slider-round"></span>
    //       </label>
    //       </div>
    //       </div>
    //       <HtmlInputEditor
    //       editorState={admissionReq}
    //       setEditorState={setAdmissionReq}
    //       hideSign={true}
    //       belowNoMargin={true}
    //       isCKEditor={true}
    //       />
    //       </>
    //     ),
    //     isInvalid: formik.errors.admission_req && formik.touched.admission_req,
    //   },
    //   {
    //     name: "Modules",
    //     id: "fourW",
    //     component: (
    //       <>
    //       <div
    //       className="d-flex my-20"
    //       title="Overwrite PMC Content"
    //       >
    //       <label className="mb-0">
    //       Overwrite PMC Content
    //       <i className="fal fa-info-circle grade-icon"></i>
    //       <i className="fal fa-external-link color-sidebar ml-2"></i>
    //       </label>
    //       <div className="toggle-switch ml-2">
    //       <label class="switch">
    //       <input
    //       type="checkbox"
    //       checked={formik.values.toggle_programme_outline === true}
    //       onChange={(e) => {
    //         formik.setFieldValue(
    //           "toggle_programme_outline",
    //           e.target.checked
    //         );
    //       }}
    //       />
    //       <span class="slider slider-round"></span>
    //       </label>
    //       </div>
    //       </div>
    //       <HtmlInputEditor
    //       editorState={programmeOutline}
    //       setEditorState={setProgrammeOutline}
    //       hideSign={true}
    //       belowNoMargin={true}
    //       isCKEditor={true}
    //       />
    //       </>
    //     ),
    //     isInvalid: formik.errors.Description && formik.touched.Description,
    //   },
    //   // {
    //   //   name: "Outcomes",
    //   //   id: "fiveW",
    //   //   component: (

    //   //     <HtmlInputEditor
    //   //     editorState={outcomes}
    //   //     setEditorState={setOutcomes}
    //   //     hideSign={true}
    //   //     belowNoMargin={true}
    //   //     isCKEditor={true}
    //   //     />
    //   //   ),
    //   //   isInvalid: formik.errors.Description && formik.touched.Description,
    //   // },
    {
      name: "Accreditation",
      id: "sixW",
      component: (
        <>
          {pmc_id ? (<>
            <div
              className="d-flex my-20"
              title="Include content from PMC on the website."
            >
              <label className="mb-0">
                Overwrite PMC Content
                <i className="fal fa-info-circle grade-icon ml-2"></i>
                {/* <a href={formik.values.external_link} target="_blank"><i className="fal fa-external-link color-sidebar ml-2"></i></a> */}
              </label>
              <div className="toggle-switch ml-2">
                <label class="switch">
                  <input
                    type="checkbox"
                    checked={formik.values.toggle_accreditation === true}
                    onChange={(e) => {
                      formik.setFieldValue(
                        "toggle_accreditation",
                        e.target.checked
                      );
                    }}
                  />
                  <span class="slider slider-round"></span>
                </label>
              </div>
            </div>
          </>) : ""}
          <HtmlInputEditor
            editorState={accreditation}
            setEditorState={setAccreditation}
            hideSign={true}
            belowNoMargin={true}
            isCKEditor={true}
          />
        </>
      ),
      isInvalid: formik.errors.accreditation && formik.touched.accreditation,
    },
    {
      name: "Articulation Option",
      id: "sevenW",
      component: (
        <>
          <div className="mb-3">
            {pmc_id ? (<>
              <div
                className="d-flex my-20"
                title="Overwrite PMC Content"
              >
                <label className="mb-0">
                  Overwrite PMC Content
                  <i className="fal fa-info-circle grade-icon ml-2"></i>
                  {/* <a href={formik.values.external_link} target="_blank"><i className="fal fa-external-link color-sidebar ml-2"></i></a> */}
                  </label>
                <div className="toggle-switch ml-2">
                  <label class="switch">
                    <input
                      type="checkbox"
                      checked={formik.values.toggle_articultion_option === true}
                      onChange={(e) => {
                        formik.setFieldValue(
                          "toggle_articultion_option",
                          e.target.checked
                        );
                      }}
                    />
                    <span class="slider slider-round"></span>
                  </label>
                </div>
              </div>
            </>) : ""}
            <div>
              <>
                <div className="edit-icon new-card-header">
                  <h4 className="card-header" title="Programmes">Programmes</h4>
                </div>
                <DataTableComponentFrontPagination
                  data={dataToRender()}
                  columns={ArticulationAssigncolumns}
                  loading={assignedArticulationOptionLoading}
                  search={assignedArticulationSearch}
                  setSearch={setAssignedArticulationSearch}
                  exportData={exportDataProAssignedArticulationOption}
                  exportFileName={"Programme_assign_Articulation_list"}
                  // defaultSort={{ defaultSortColumn: "start", defaultSortAsc: false }}
                  filters={[
                    {
                      filterName: " Status",
                      optionArr: programArticulationFilterList.status,
                      state: assignProgStatus,
                      setState: setAssignProgStatus,
                      renderLabelFunction: RenderPMCStatus,
                      uniqueId: "assignedStatus",
                    },
                    {
                      filterName: "Linked PMC Programme",
                      optionArr: programArticulationFilterList.linkPMCPrograms,
                      state: assignLinkPMCProg,
                      setState: setAssignLinkPMCProg,
                      // renderLabelFunction : RenderLinkedProgrammeName
                    },
                    {
                      filterName: " Programme Type",
                      optionArr:
                        programArticulationFilterList.programmeType,
                      state: assignProgType,
                      setState: setAssignProgType,
                      uniqueId: "assignProgrammeType",
                      // renderLabelFunction: RenderLearningMethod,
                      renderLabelFunction: RenderProgrammeType,
                    },
                  ]}
                  tableButton={[
                    <button
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#assignArticulationPopup"
                      title="Add Programmes"
                      type="button"
                    >
                      <i className="fal fa-plus"></i>Add Programmes
                    </button>,
                  ]}
                />
              </>

              <>
                <div className="edit-icon new-card-header">
                  <h4 className="card-header" title="Short Courses">Short Courses</h4>
                </div>
                <DataTableComponentFrontPagination
                  data={renderShortArticulation()}
                  columns={assignShortCourseArticulationColumns}
                  loading={assignedArticulationOptionLoading}
                  search={shortCourseArticulationAssignSearch}
                  setSearch={setShortCourseArticulationAssignSearch}
                  exportData={exportDataShortAssignedArticulationOption}
                  exportFileName={"ShortCourse_assign_Articulation_list"}
                  // defaultSort={{ defaultSortColumn: "start", defaultSortAsc: false }}
                  filters={[
                    {
                      filterName: "Status",
                      optionArr: shortArtAssignFilterData.status,
                      state: AssignShortStatus,
                      setState: setAssignShortStatus,
                      renderLabelFunction: RenderPMCStatus,
                      uniqueId: "assignedStatus",
                    },
                    {
                      filterName: "Linked PMC Short Course",
                      optionArr: shortArtAssignFilterData.linkPMCShortCourse,
                      state: AssignLinkPMCShort,
                      setState: setAssignLinkPMCShort,
                      // renderLabelFunction: RenderPMCStatus,
                      uniqueId: "assignShortCourseLink",
                    },
                    // {
                    //   filterName: "Linked PMC Short Course",
                    //   optionArr: shortArtAssignFilterData.linkPMCShortCourse,
                    //   state: AssignLinkPMCShort,
                    //   setState: setAssignLinkPMCShort,
                    //   // renderLabelFunction : RenderLinkedProgrammeName
                    // }
                  ]}
                  tableButton={[
                    <button
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#assignShortCourseOptionList"
                      title="Add Short Course"
                      type="button"
                    >
                      <i className="fal fa-plus"></i>Add Short Course
                    </button>,
                  ]}
                />
              </>
            </div>
          </div>
          <label htmlFor="art_additional_info">Additional Information</label>
          <HtmlInputEditor
            editorState={articulationOption}
            setEditorState={setArticulationOption}
            hideSign={true}
            belowNoMargin={true}
            isCKEditor={true}
          />
        </>
      ),
      isInvalid:
        formik.errors.articultion_option && formik.touched.articultion_option,
    },
    {
      name: "Career Opportunities",
      id: "eightW",
      component: (
        <>
          {pmc_id ? (<>
            <div
              className="d-flex my-20"
              title="Include content from PMC on the website."
            >
              <label className="mb-0">
                Overwrite PMC Content
                <i className="fal fa-info-circle grade-icon ml-2"></i>
                {/* <a href={formik.values.external_link} target="_blank"><i className="fal fa-external-link color-sidebar ml-2"></i></a> */}
              </label>
              <div className="toggle-switch ml-2">
                <label class="switch">
                  <input
                    type="checkbox"
                    checked={formik.values.toggle_career_opportunities === true}
                    onChange={(e) => {
                      formik.setFieldValue(
                        "toggle_career_opportunities",
                        e.target.checked
                      );
                    }}
                  />
                  <span class="slider slider-round"></span>
                </label>
              </div>
            </div>
          </>) : ""}
          <HtmlInputEditor
            editorState={careerOpportunity}
            setEditorState={setCareerOpportunity}
            hideSign={true}
            belowNoMargin={true}
            isCKEditor={true}
          />
        </>
      ),
      isInvalid: false,
    },
    {
      name: "Career Video",
      id: "nineW",
      component: (
        <>
          <div
            className="d-flex col-md-4 my-20 p-0 flex-column"
            title="Career Video"
          >
            <label className="mb-0">
              Career Video URL
              <i className="fal fa-info-circle grade-icon ml-2"></i>
            </label>
            <div>

              <input
                className={
                  "form-control mt-1" +
                  (formik.errors.career_video &&
                    formik.touched.career_video
                    ? " is-invalid"
                    : "")
                }
                placeholder="Enter Career Video Link"
                name="career_video"
                id="career_video"
                title="Career Video Link"
                value={formik.values.career_video}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </div>

          </div>
          <HtmlInputEditor
            editorState={careerVideoDec}
            setEditorState={setCareerVideoDec}
            hideSign={true}
            belowNoMargin={true}
            isCKEditor={true}
          />
        </>
      ),
      isInvalid: false,
    },
    {
      name: "Study Kit",
      id: "tenW",
      component: (
        <>
          {pmc_id ? (<>
            <div
              className="d-flex my-20"
               title="Include content from PMC on the website."
            >
              <label className="mb-0">
                Overwrite PMC Content
                <i className="fal fa-info-circle grade-icon ml-2"></i>
                {/* <a href={formik.values.external_link} target="_blank"><i className="fal fa-external-link color-sidebar ml-2"></i></a> */}
              </label>
              <div className="toggle-switch ml-2">
                <label class="switch">
                  <input
                    type="checkbox"
                    checked={formik.values.toggle_study_kit === true}
                    onChange={(e) => {
                      formik.setFieldValue(
                        "toggle_study_kit",
                        e.target.checked
                      );
                    }}
                  />
                  <span class="slider slider-round"></span>
                </label>
              </div>
            </div>
          </>) : ""}
          <HtmlInputEditor
            editorState={studyKit}
            setEditorState={setStudyKit}
            hideSign={true}
            belowNoMargin={true}
            isCKEditor={true}
          />
        </>
      ),
      isInvalid: false,
    },
    {
      name: "Hardware Requirements",
      id: "elevenW",
      component: (
        <>
          {pmc_id ? (<>
            <div
              className="d-flex my-20 align-items-center"
               title="Include content from PMC on the website."
            >
              <label className="mb-0">
                Overwrite PMC Content
                <i className="fal fa-info-circle grade-icon ml-2"></i>
                {/* <a href={formik.values.external_link} target="_blank"><i className="fal fa-external-link color-sidebar ml-2"></i></a> */}
              </label>
              <div className="toggle-switch ml-2">
                <label class="switch">
                  <input
                    type="checkbox"
                    checked={formik.values.toggle_hardware_requirement === true}
                    onChange={(e) => {
                      formik.setFieldValue(
                        "toggle_hardware_requirement",
                        e.target.checked
                      );
                    }}
                  />
                  <span class="slider slider-round"></span>
                </label>
              </div>
            </div>
          </>) : ""}
          <div className="row">
            <div className="col-auto">
              <div className="form-group-blk mb-3 my-20">
                <label htmlFor="early_bird_discount">Laptop Options</label>
                <div className="d-flex">
                  <div className="custom-control custom-checkbox text-left">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="core_spec"
                      name="core_spec"
                      checked={formik.values.core_spec == 1}
                      onChange={() => {
                        if (formik.values.core_spec == 1) {
                          formik.setFieldValue("core_spec", 0);
                        } else {
                          formik.setFieldValue("core_spec", 1);
                        }
                      }}
                    />
                    <label className="custom-control-label" htmlFor="core_spec">
                      Core Spec
                    </label>
                  </div>
                  <div className="custom-control custom-checkbox text-left ml-2">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="med_spec"
                      name="med_spec"
                      checked={formik.values.med_spec == 1}
                      onChange={() => {
                        if (formik.values.med_spec == 1) {
                          formik.setFieldValue("med_spec", 0);
                        } else {
                          formik.setFieldValue("med_spec", 1);
                        }
                      }}
                    />
                    <label className="custom-control-label" htmlFor="med_spec">
                      Medium Spec
                    </label>
                  </div>
                  <div className="custom-control custom-checkbox text-left ml-2">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="high_spec"
                      name="high_spec"
                      checked={formik.values.high_spec == 1}
                      onChange={() => {
                        if (formik.values.high_spec == 1) {
                          formik.setFieldValue("high_spec", 0);
                        } else {
                          formik.setFieldValue("high_spec", 1);
                        }
                      }}
                    />
                    <label className="custom-control-label" htmlFor="high_spec">
                      High Spec
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <HtmlInputEditor
            editorState={hardwareReq}
            setEditorState={setHardwareReq}
            hideSign={true}
            belowNoMargin={true}
            isCKEditor={true}
          />
        </>
      ),
      isInvalid: false,
    },
    //   {
    //     name: "Financing Options",
    //     id: "onFin",
    //     component: (
    //       <>
    //       <div
    //       className="d-flex my-20"
    //       title="Overwrite PMC Content"
    //       >
    //       <label className="mb-0">
    //       Overwrite PMC Content
    //       <i className="fal fa-info-circle grade-icon"></i>
    //       <i className="fal fa-external-link color-sidebar ml-2"></i>
    //       </label>
    //       <div className="toggle-switch ml-2">
    //       <label class="switch">
    //       <input
    //       type="checkbox"
    //       checked={formik.values.toggle_overview === true}
    //       onChange={(e) => {
    //         formik.setFieldValue(
    //           "toggle_overview",
    //           e.target.checked
    //         );
    //       }}
    //       />
    //       <span class="slider slider-round"></span>
    //       </label>
    //       </div>
    //       </div>
    //       <HtmlInputEditor
    //       editorState={subjectContent}
    //       setEditorState={setSubjectContent}
    //       hideSign={true}
    //       belowNoMargin={true}
    //       isCKEditor={true}
    //       />
    //       </>
    //     ),
    //     isInvalid: formik.errors.Description && formik.touched.Description,
    //   },
    {
      name: "Financing Options",
      id: "twelveW",
      component: (
        <>
          {/* {pmc_id ? (<>
            <div
              className="d-flex my-20"
              title="Overwrite PMC Content"
            >
              <label className="mb-0">
                Overwrite PMC Content
                <i className="fal fa-info-circle grade-icon"></i>
                <i className="fal fa-external-link color-sidebar ml-2"></i>
              </label>
              <div className="toggle-switch ml-2">
                <label class="switch">
                  <input
                    type="checkbox"
                    checked={formik.values.toggle_financing_options === true}
                    onChange={(e) => {
                      formik.setFieldValue(
                        "toggle_financing_options",
                        e.target.checked
                      );
                    }}
                  />
                  <span class="slider slider-round"></span>
                </label>
              </div>
            </div>
          </>) : null} */}
          <HtmlInputEditor
            editorState={financingOptions}
            setEditorState={setFinancingOptions}
            hideSign={true}
            belowNoMargin={true}
            isCKEditor={true}
          />
        </>
      ),
      isInvalid: false,
    },
    {
      name: "Duration",
      id: "thirtennW",
      component: (
        <>
          {pmc_id ? (<>
            <div
              className="d-flex my-20"
               title="Include content from PMC on the website."
            >
              <label className="mb-0">
                Overwrite PMC Content
                <i className="fal fa-info-circle grade-icon ml-2"></i>
                {/* <a href={formik.values.external_link} target="_blank"><i className="fal fa-external-link color-sidebar ml-2"></i></a> */}
              </label>
              <div className="toggle-switch ml-2">
                <label class="switch">
                  <input
                    type="checkbox"
                    checked={formik.values.toggle_duration_dec === true}
                    onChange={(e) => {
                      formik.setFieldValue(
                        "toggle_duration_dec",
                        e.target.checked
                      );
                    }}
                  />
                  <span class="slider slider-round"></span>
                </label>
              </div>
            </div>
          </>) : null}
          <HtmlInputEditor
            editorState={duration}
            setEditorState={setDuration}
            hideSign={true}
            belowNoMargin={true}
            isCKEditor={true}
          />
        </>
      ),
      isInvalid: false,
    },
    {
      name: "Regulatory Information",
      id: "fourteenW",
      component: (
        <>
          {/* {pmc_id ? (<>
            <div
              className="d-flex my-20"
              title="Overwrite PMC Content"
            >
              <label className="mb-0">
                Overwrite PMC Content
                <i className="fal fa-info-circle grade-icon"></i>
                <i className="fal fa-external-link color-sidebar ml-2"></i>
              </label>
              <div className="toggle-switch ml-2">
                <label class="switch">
                  <input
                    type="checkbox"
                    checked={formik.values.toggle_regulatory_inform === true}
                    onChange={(e) => {
                      formik.setFieldValue(
                        "toggle_regulatory_inform",
                        e.target.checked
                      );
                    }}
                  />
                  <span class="slider slider-round"></span>
                </label>
              </div>
            </div>
          </>) : null} */}
          <HtmlInputEditor
            editorState={regulatoryInfo}
            setEditorState={setRegulatoryInfo}
            hideSign={true}
            belowNoMargin={true}
            isCKEditor={true}
          />
        </>
      ),
      isInvalid: false,
    },
    {
      name: "Important Notes",
      id: "fifteenW",
      component: (
        <>
          {pmc_id ? (<>
            <div
              className="d-flex my-20"
              title="Include content from PMC on the website."
            >
              <label className="mb-0">
                Overwrite PMC Content
                <i className="fal fa-info-circle grade-icon"></i>
                {/* <a href={formik.values.external_link} target="_blank"><i className="fal fa-external-link color-sidebar ml-2"></i></a> */}
              </label>
              <div className="toggle-switch ml-2">
                <label class="switch">
                  <input
                    type="checkbox"
                    checked={formik.values.toggle_important_notes === true}
                    onChange={(e) => {
                      formik.setFieldValue(
                        "toggle_important_notes",
                        e.target.checked
                      );
                    }}
                  />
                  <span class="slider slider-round"></span>
                </label>
              </div>
            </div>
          </>) : null}
          <HtmlInputEditor
            editorState={impNotes}
            setEditorState={setImpNotes}
            hideSign={true}
            belowNoMargin={true}
            isCKEditor={true}
          />
        </>
      ),
      isInvalid: false,
    },
  ];

  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      let background = RenderPMCStatus(data.value).bgColor;
      let color = RenderPMCStatus(data.value).colorCode;

      return {
        ...styles,
        backgroundColor: background,
        color: color,
        cursor: isDisabled ? "not-allowed" : "default",

        // ':active': {
        //   ...styles[':active'],
        //   backgroundColor: !isDisabled
        //     ? isSelected
        //       ? data.color
        //       : color.alpha(0.3).css()
        //     : undefined,
        // },
      };
    },
  };

  return (
    <>
      <div className="card card-profile-info-card filter-table-bg">
        <div className="new-card-header">
          <div className="card-header pt-0">Short Course Content {disabled ? <i className="fas fa-cog fa-spin ml-2"></i> : ""}</div>

          <div className="card-body-inr card-body-info">
            <form onSubmit={formik.handleSubmit}>
              <div className="row mt-3">
                <div className="col-md-6 col-lg-3 d-flex align-items-center">
                  <div
                    className="d-flex"
                    title="Link the short course to an external short course or programme"
                  >
                    <label className="mb-0">
                      Redirect to External Link  {formik.values.toggle_redirect == 1 ? "*" : ""}<i className="fal fa-info-circle grade-icon ml-2"></i>
                    </label>
                    <div className="toggle-switch ml-2">
                      <label class="switch">
                        <input
                          type="checkbox"
                          checked={formik.values.toggle_redirect === true}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "toggle_redirect",
                              e.target.checked
                            );
                          }}
                        />
                        <span class="slider slider-round"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-2 mb-4 px-15">
                <input
                  className={
                    "form-control" +
                    (formik.errors.external_link &&
                      formik.touched.external_link
                      ? " is-invalid"
                      : "")
                  }
                  placeholder="Enter Redirect Link"
                  name="external_link"
                  id="external_link"
                  title="Link the short course to an external short course or programme"
                  value={formik.values.external_link}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
              <div
                className="admin-home-acc accordion ac_over_none"
                id="accordionExample"
              >
                {editorArr.map((item, index) => {
                  return (
                    <div className="card-main" key={index}>
                      <div className="card-top">
                        <div className="card-header1" id={`heading${item.id}`}>
                          <h2 className="mb-0">
                            <button
                              className="btn-link collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target={`#collapse${item.id}`}
                              aria-expanded="false"
                              aria-controls={`collapse${item.id}`}
                            >
                              <div
                                className={`title-border-box ${item.isInvalid ? "editor-block-invalid" : ""
                                  } `}
                              >
                                {item.name}
                              </div>

                              <div className="header-status">
                                <div className="status-gol card-blue-1">
                                  <i className="fal fa-chevron-down rotate-icon"></i>
                                </div>
                              </div>
                            </button>
                          </h2>
                        </div>
                        <div
                          id={`collapse${item.id}`}
                          className="card-body1 mt-2 collapse"
                          aria-labelledby={`heading${item.id}`}
                          data-parent="#accordionExample"
                        >
                          <div className="card-body inner-tab-card">
                            {(editorToShow || type == "add") && item.component}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
              {/* <hr /> */}

              <div className="form-group form-group-save-cancel mt-4">
                <PermissionsGate scopes={["pmcpedit"]} errorProps={{ disabled: true }}>
                  <button
                    className="btn btn-save btn-success"
                    type="submit"
                    title="Save"
                    disabled={disabled}
                    onClick={formik.handleSubmit}
                  >
                    {disabled ? (
                      <i className="fas fa-cog fa-spin"></i>
                    ) : (
                      <i className="fal fa-save"></i>
                    )}
                    Save
                  </button>
                </PermissionsGate>
                <button
                  className="btn btn-close btn-danger"
                  type="button"
                  title="Cancel"
                  onClick={() => {
                    formik.resetForm()
                    setUpdateData(Date.now())
                  }
                  }
                >
                  <i className="fal fa-times"></i>Cancel
                </button>
              </div>
              {/** map function to render all errors  */}
              {Object.keys(formik.values).map((key) => {
                if (formik.touched[key] && formik.errors[key]) {
                  return (
                    <div className="invalid-feedback d-block" key={key}>
                      {formik.errors[key]}
                    </div>
                  );
                }
              })}
            </form>
          </div>
        </div>
      </div>
      <div
        className="topic-add-modal modal able_modal01 fade"
        id="assignPagePopup"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered available-assessment-modal"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-custom">
              <h5 className="modal-title">
                <i className="fal fa-plus"></i> Assign Page
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <DataTableComponent
                data={assignPageList}
                loading={assignPageLoading}
                state={assignPageTableState}
                setState={setAssignPageTableState}
                setSearch={setAssignPageSearch}
                totalRows={assignPageTotalRows}
                columns={assignPageColumn}
                exportFunction={exportAssignPageData}
                exportFileName={"Assign_Page"}
                isInsidePopUp={true}
                filters={[
                  {
                    filterName: "Status",
                    optionArr: PageFilterData.status,
                    state: pageStatus,
                    setState: setPageStatus,
                    renderLabelFunction: RenderPMCPageStatus,
                  },

                  {
                    filterName: "Brand Templates",
                    optionArr: PageFilterData.BrandList,
                    state: pageBrand,
                    isOptionReversed: false,
                    stopOptionSorting: true,
                    setState: setPageBrand,
                    // renderLabelFunction: RenderPageBrands,
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className="topic-add-modal modal able_modal01 fade"
        id="assignArticulationPopup"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        >

        <div
          className="modal-dialog modal-dialog-centered available-assessment-modal"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-custom">
              <h5 className="modal-title">
                <i className="fal fa-plus"></i> Add Programmes
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <DataTableComponent
                data={articulationOptionList}
                columns={articulationOptionColumn}
                loading={articulationOptionLoading}
                search={articulationOptionSearch}
                setSearch={setArticulationOptionSearch}
                state={articulationOptionTableState}
                setState={setArticulationOptionTableState}
                totalRows={articulationOptionTotalRow}
                exportFunction={exportDataProgArticulationOption}
                exportFileName={"Programme_Articulation_Option"}
                isInsidePopUp={true}
                keyField="ref_id"
                filters={[
                  {
                    filterName: "Status",
                    optionArr: articulationOptionFilterData.status,
                    state: artStatus,
                    setState: setArtStatus,
                    renderLabelFunction: RenderPMCStatus,
                  },
                  {
                    filterName: "Linked PMC Programme",
                    optionArr: articulationOptionFilterData.linkedPmcProgramme,
                    state: linkedPmcProgramme,
                    setState: setLinkedPmcProgramme,
                    // renderLabelFunction : RenderLinkedProgrammeName
                  },
                  {
                    filterName: "Programme Type",
                    optionArr: articulationOptionFilterData.programmeType,
                    state: artProgType,
                    setState: setArtProgType,
                    // uniqueId: "assignStudyType",
                    renderLabelFunction: RenderProgrammeType,
                  },
                  // {
                  //   filterName: "Type",
                  //   optionArr: articulationOptionFilterData.type,
                  //   state: artType,
                  //   setState: setArtType,
                  //   // uniqueId: "assignStudyType",
                  //   renderLabelFunction: RenderProgrammeOrCourseType,
                  // },
                ]}
              />
            </div>
          </div>
        </div>
      </div>

      {/* short course articulation option list */}
      <div
        className="topic-add-modal modal able_modal01 fade"
        id="assignShortCourseOptionList"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="assignShortCourseOptionList"
        aria-hidden="true"
        >
        <div
          className="modal-dialog modal-dialog-centered available-assessment-modal"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-custom">
              <h5 className="modal-title">
                <i className="fal fa-plus"></i> Add Short Course
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <DataTableComponent
                data={shortArticulationOptionList}
                columns={shortOptionArtColumn}
                loading={articulationOptionLoading}
                search={shortCourseArticulationSearch}
                setSearch={setShortCourseArticulationSearch}
                state={shortCourseArticulationTableState}
                setState={SetShortCourseArticulationTableState}
                totalRows={shortArticulationOptionTotalRows}
                exportFunction={exportDataShortArticulationOption}
                exportFileName={"ShortCourse_Articulation_Option"}
                isInsidePopUp={true}
                keyField="ref_id"
                filters={[
                  {
                    filterName: " Status",
                    optionArr: shortArtOptionFilterData.status,
                    state: optionShortStatus,
                    setState: setOptionShortStatus,
                    renderLabelFunction: RenderPMCStatus,
                  },
                  {
                    filterName: " Linked PMC Short Course",
                    optionArr: shortArtOptionFilterData.linkedPmcProgramme,
                    state: optionLinkPMCProg,
                    setState: setOptionLinkPMCProg,
                    // renderLabelFunction : RenderLinkedProgrammeName
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShortCourseContent;
