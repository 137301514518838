import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import TreeTheme from "react-sortable-tree-theme-minimal";
import SortableTree from 'react-sortable-tree';
import 'react-sortable-tree/style.css';
import axios from "axios";
import { AddMenus, getCategoryLandingColorList, getMenuDropdown, GetUpdateMenus, UpdateMenus } from "../../../services/Menusserver";
import HtmlParser from "react-html-parser";
import AddMenuItems from "./AddMenuItems";
import Placeholder from "../../courses/ContentAssembliesBlock/AssemblyListComponent/PalaceHolder";
import PermissionsGate from "../../../utils/permissionGate";
import { FileUploadField, FormField } from "../../common/FormFields";
import { TrimText } from "../../common/TrimText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { IMAGE_URL } from "../../../utils/Constants";

const LayoutForm = ({ setHeaderName }) => {
  let history = useHistory();
  const { type, subId } = useParams();
  const [MenuDetails, setMenuDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sitesFilters, setSitesFilters] = useState([]);
  const [menuStructure, setMenuStructure] = useState([]);
  const [menuColor, setMenuColor] = useState([]);
  const [updateData, setUpdateData] = useState([]);
  const [menuStructureData, setMenuStructureData] = useState([]);

  const getAccentColor = async () => {
    try {
      let res = await getCategoryLandingColorList();
      setMenuColor(res?.data?.result || []);
    } catch (error) {
      console.error('Error during API call:', error);
    }
  };

  // get data for update

  useEffect(() => {
    const getMenuUpdateItemList = async () => {
      try {
        const res = await GetUpdateMenus(subId);
        res.data.data.brandTemplete = res.data.data?.brandTemplete?.map(
          (el) => el.BrandID
        );
        // getAccentColor([...res.data.data.brandTemplete])
        setUpdateData(res?.data?.data)
        setHeaderName(res.data?.data?.MenuName);
        localStorage.setItem("headerName", res.data?.data?.MenuName);
        let data_items = res?.data?.data?.menusActiveItems?.map((item) => {
          return {
            id: item.MenuItemID,
            title: (
              <>
                {/* {item?.MenuIcon && <img src={IMAGE_URL + item.MenuIcon} width={10} height={10} />} */}
                {TrimText(item.MenuItemAlias, 35)}
              </>
            ),
            data: { ...item },
            className: "parent-node",
          }
        });
        setMenuStructure(data_items);
      } catch (error) {
        console.error(error);
      }
    }


    if (subId) {
      getMenuUpdateItemList();
    }
  }, [subId]);
  // get dropdown menu list

  useEffect(() => {
    const getData = async () => {
      try {
        const data = await getMenuDropdown()
        if (data.status === 200) {
          setMenuDetails(data && data?.data)
          setSitesFilters(data.data.sitesFilters.map(item => {
            return {
              value: item.value,
              label: HtmlParser(`<span className="status-color-select" style="color:#000000;" title=${item?.label}>${item?.label}</span>`),
              stringLabel: item.label
            }
          }))
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.log("error :", error)
        }
      }
    }

    getData()
  }, []);

  const handleRemoveItem = (node) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, unlink it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const updatedData = menuStructure.filter(item => item.id !== node.id);
        setMenuStructure(updatedData)
        setMenuStructureData([...menuStructureData, node.data])
      }
    });
  }
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      MenuID: updateData.MenuID || "",
      MenuName: updateData?.MenuName || '',
      MenuType: updateData?.MenuType || '',
      MenuStatus: updateData ? updateData?.MenuStatus : 0,
      Brand: updateData?.brandTemplete || [],
      MenuColor: updateData.MenuColor || "",
      MenuOverrideColor: updateData.MenuOverrideColor || 0,
      MenuLogo: updateData?.MenuLogo || '',
      MenuItems: updateData?.MenuItems || []
    },
    validationSchema: Yup.object().shape({
      MenuName: Yup.string().required("Menu Name is required"),
      MenuType: Yup.string().required("Menu type is required"),
      Brand: Yup.string().required("Assigned Brand Templates is required"),
      // Brand: Yup.string().when('MenuType', {
      //   is: '1',
      //   then: Yup.string().required("Assigned Brand Templates is required"),
      //   otherwise: Yup.string(),
      // }),
      MenuColor: Yup.string().required("Menu colour is required"),
    }),
    onSubmit: (values, action) => {
      console.log(values);

      const formData = new FormData();
      // Trim and clean up values
      const cleanedValues = Object.fromEntries(
        Object.entries(values).map(([key, value]) => [
          key,
          value === undefined || value === null
            ? ''
            : typeof value === 'string'
              ? value.trim().replace(/ +/g, ' ')
              : value,
        ])
      );

      // Append cleaned values to FormData
      Object.keys(cleanedValues).forEach((key) => {
        if (Array.isArray(cleanedValues[key])) {
          cleanedValues[key].forEach((val, idx) => {
            formData.append(`${key}[${idx}]`, val === undefined || val === null ? '' : val);
          });
        } else {
          formData.append(key, cleanedValues[key]);
        }
      });

      if (type == 'create') {
        setLoading(true);
        AddMenus(formData)
          .then((response) => {
            Swal.fire('Success!', 'Menus created successfully!', 'success');
            action.resetForm();
            history.push('/websiteManagement/layout_navigation/menus/table');
          })
          .catch((error) => {
            console.error('Error creating menus:', error);
            Swal.fire('Error!', 'Failed to create menus', 'error');
          })
          .finally(() => setLoading(false))
      } else if (type == 'open') {
        setLoading(true);
        UpdateMenus(formData, subId)
          .then((response) => {
            Swal.fire('Success!', 'Menus updated successfully!', 'success');
          })
          .catch((error) => {
            console.error('Error updating Menus:', error);
            Swal.fire('Error!', 'Failed to update menus', 'error');
          })
          .finally(() => setLoading(false))
      }
    },
  });
  useEffect(() => {
    const menuItemIDs = menuStructure.map(item => item.data.MenuItemID);
    formik.setFieldValue("MenuItems", menuItemIDs);
  }, [menuStructure]);

  useEffect(() => {
   
      getAccentColor(formik.values.Brand);
  }, [])
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-lg-9 col-12">
            <div className="card card-profile-info-card course-details-tab-sec">
              <div className="course-info-sec">
                <div className="edit-icon new-card-header">
                  <div className="card-header" title="Menu Information">
                    Menu Information
                  </div>
                </div>
                <div className="card-body-inr card-body-info">
                  <div className="row">
                    <div className="col-md-6 col-lg-4">
                      <div className="form-icon-group mb-4" title="Menu Name">
                        <label>Menu Name *</label>
                        <input
                          type="text"
                          className={`form-control ${formik.errors.MenuName && formik.touched.MenuName && "is-invalid"}`}
                          name="MenuName"
                          placeholder="Menu Name"
                          value={formik.values.MenuName}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.errors.MenuName && formik.touched.MenuName && (
                          <span className="exclamation">
                            <i className="fal fa-exclamation-circle"></i>
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-icon-group mb-4" title="Menu Type">
                        <label>Menu Type *</label>
                        <Select
                          className={"form-control custom-select-box" + (formik.errors.MenuType && formik.touched.MenuType ? " is-invalid" : "")}
                          name="MenuType"
                          value={MenuDetails?.menusTypesFilters?.find(item => item.value === formik.values.MenuType)}
                          onChange={(value) => {
                            if (value) {
                              formik.setFieldValue("MenuType", value.value);
                            } else {
                              formik.setFieldValue("MenuType", "");
                            }
                          }}
                          placeholder={"Select..."}
                          onBlur={formik.handleBlur}
                          options={MenuDetails.menusTypesFilters}
                          maxMenuHeight={175}
                          isClearable
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4">
                      <div className="form-group-blk mb-4" title="Status">
                        <label>Status <i className="fal fa-info-circle"></i></label>
                        <Select
                          className={"form-control custom-select-box" + (formik.errors.MenuStatus && formik.touched.MenuStatus ? " is-invalid" : "")}
                          name="MenuStatus"
                          value={MenuDetails?.menusStatusFilters?.find(item => item.value === formik.values.MenuStatus)}
                          onChange={(value) => {
                            if (value) {
                              formik.setFieldValue("MenuStatus", value.value);
                            } else {
                              formik.setFieldValue("MenuStatus", "");
                            }
                          }}
                          placeholder={"Select..."}
                          onBlur={formik.handleBlur}
                          options={MenuDetails.menusStatusFilters}
                          maxMenuHeight={175}
                          isClearable
                        />

                        {formik.errors.MenuStatus &&
                          formik.touched.MenuStatus && (
                            <span className="exclamation">
                              <i className="fal fa-exclamation-circle"></i>
                            </span>
                          )}
                      </div>
                    </div>
                    {/* {formik.values.MenuType === 1 && ( */}
                      <div className="col-md-6 col-lg-4">
                      <div className="form-icon-group mb-4" title="Brand Templates">
                        <label>Assigned Brand Templates *</label>
                        <Select
                          isMulti
                          name="Brand"
                          value={sitesFilters?.filter(option =>
                            formik.values.Brand?.includes(option.value)
                          )}
                          options={sitesFilters}
                          onChange={(selectedOptions) =>
                            formik.setFieldValue(
                              "Brand",
                              selectedOptions ? selectedOptions.map(option => option.value) : []
                            )
                          }
                          onBlur={formik.handleBlur}
                          className={`form-control color-drop custom-select-box is-multi basic-multi-select ${formik.errors.Brand && formik.touched.Brand && "is-invalid"}`}
                          placeholder="Select Brands"
                          isClearable
                        />
                        {formik.errors.Brand && formik.touched.Brand && (
                          <span className="exclamation">
                            <i className="fal fa-exclamation-circle"></i>
                          </span>
                        )}
                      </div>
                    </div>
                    {/* )} */}
                  </div>
                  <hr />

                  <div className="course-sec-gap course-resource-sec">
                    <div className="edit-icon new-card-header" title="Menu Settings">
                      <div className="card-header">
                        <FontAwesomeIcon icon={faGear} color="var(--topbar-color)" />&nbsp;
                        Menu Settings
                      </div>
                    </div>

                    <div className="card-body-inr">
                      <div className="row">
                        {/* <div className="col-md-6 col-lg-4">
                          <div className="form-icon-group mb-4" title="Menu Colour">
                            <label>Menu Colour *</label>
                            <Select
                              className={`form-control custom-select-box ${formik.errors.MenuColor && formik.touched.MenuColor && "is-invalid"}`}
                              name="MenuColor"
                              value={menuColor.filter(val => formik.values.MenuColor.includes(val.value.toString()))}
                              onChange={(value) => {
                                if (value) {
                                  formik.setFieldValue("MenuColor", value.value);
                                } else {
                                  formik.setFieldValue("MenuColor", "");
                                }
                              }}
                              isClearable
                              onBlur={formik.handleBlur}
                              options={menuColor}
                              maxMenuHeight={175}
                              placeholder={"Select..."}
                            />
                            {formik.errors.programSupport &&
                              formik.touched.programSupport ? (
                              <span className="exclamation">
                                <i className="fal fa-exclamation-circle"></i>{" "}
                              </span>
                            ) : null}
                          </div>
                        </div> */}
                        {!formik.values.MenuOverrideColor ?
                          <div className="col-md-6 col-lg-2">
                            <div className="form-icon-group mb-4" title="MenuItemColour">
                              <label>Menu Item Colour *</label>
                              <Select
                                className={`form-control custom-select-box ${formik.errors.MenuColor && formik.touched.MenuColor && "is-invalid"}`}
                                name="MenuColor"
                                value={menuColor.filter(val => formik.values.MenuColor.includes(val.value.toString()))}
                                onChange={(value) => {
                                  if (value) {
                                    formik.setFieldValue("MenuColor", value.value);
                                  } else {
                                    formik.setFieldValue("MenuColor", "");
                                  }
                                }}
                                isClearable
                                onBlur={formik.handleBlur}
                                options={menuColor}
                                maxMenuHeight={175}
                                placeholder={"Select..."}
                              />
                            </div>
                          </div>
                          :
                          <>


                            {[
                              { label: "Menu Item Colour", name: "MenuColor", color: true, required: true, type: "color", },
                            ].map((field, index) => (
                              <FormField key={index} field={field} formik={formik} col_md={6} col_lg={2} />
                            ))}

                            {formik.errors.MenuColor &&
                              formik.touched.MenuColor ? (
                              <span className="exclamation">
                                <i className="fal fa-exclamation-circle"></i>{" "}
                              </span>
                            ) : null}

                          </>
                        }

                        <div className="col-md-6 col-lg-3 d-flex" style={{ marginTop: 37 }} title="Override Colour Variable">
                          <label className="mb-0">
                            Override Colour Variable <i className="fal fa-info-circle"></i>
                          </label>
                          <div className="toggle-switch ml-2">
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={formik.values?.MenuOverrideColor === 1}
                                onChange={(e) => {
                                  formik.setFieldValue(
                                    "MenuOverrideColor",
                                    e.target.checked ? 1 : 0
                                  )
                                  if (e.target.checked) {
                                    formik.setFieldValue("MenuColor", "#000");
                                  } else {
                                    formik.setFieldValue("MenuColor", "");
                                  }
                                }
                                }
                              />
                              <span className="slider slider-round"></span>
                            </label>
                          </div>
                        </div>

                        <FileUploadField
                          name="MenuLogo"
                          label="Menu Icon"
                          formik={formik}
                          accept=".png,.jpg,.jpeg"
                          col_md={6}
                          col_lg={3}
                        />
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="course-sec-gap course-setting-sec d-block d-lg-none mb-4">
                    <div className="edit-icon new-card-header" title="Menu Structure">
                      <div className="card-header">Menu Structure</div>
                    </div>
                    <div className="card-body-inr correct-sort">
                      {menuStructure.length > 0 ?
                        <SortableTree
                          theme={TreeTheme}
                          className="my-class"
                          isVirtualized={false}
                          treeData={menuStructure}
                          onChange={(treeData) => setMenuStructure(treeData)}
                          maxDepth={3}
                          // onDragStateChanged={(dragData) => handleDragNode(dragData)}
                          style={{
                            padding: "0px",
                            width: "100%",
                          }}
                          // onVisibilityToggle={(data) => nodeToggle(data)}
                          generateNodeProps={(rowInfo) => {
                            const { node } = rowInfo;
                            return {
                              class: (node.type === 1) ? "main-category-node" : node.type === 3 ? "sub-category-node" : "item-node",
                              buttons: [
                                <div className='drag-option-container'>
                                  {/* <PermissionsGate errorProps={{ disabled: true }} scopes={["cadelete"]}> */}

                                  <button
                                    className='drag-option btn btn-danger rounded-circle'
                                    type="button"
                                    label="Delete"
                                    title="Delete"
                                    onClick={() => handleRemoveItem(node)}
                                  >
                                    <i className="fal fa-trash-alt"></i>
                                  </button>
                                  {/* </PermissionsGate> */}
                                </div>
                              ],
                              style: { borderRadius: "10px", width: "100%" }
                            }
                          }}
                          placeholderRenderer={Placeholder}
                        />
                        : <p className="menuStructure">No Menu Items Added</p>}

                      <div className="form-group form-group-save-cancel mt-3">
                        <button
                          className="btn btn-save btn-success"
                          type="button"
                          title="Add Items"
                          disabled={loading ? true : false}
                          data-toggle="modal"
                          data-target="#menuitem"
                        >
                          {loading === false ? (
                            <i className="fal fa-plus"></i>

                          ) : (
                            <i class="fas fa-cog fa-spin"></i>
                          )}
                          Add Items
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group form-group-save-cancel">
                <PermissionsGate scopes={[type == "create" ? 'wbslnmenusadd' : 'wbslnmenusedit']} RenderError={() => (
                  <button
                    className="btn btn-save btn-success"
                    title="Save"
                    disabled
                  >
                    <i className="fal fa-save"></i> Save
                  </button>
                )}>

                  <button
                    className="btn btn-save btn-success"
                    type="submit"
                    title="Save"
                    disabled={loading}
                  >
                    {loading == false ? (
                      <i className="fal fa-save"></i>
                    ) : (
                      <i className="fas fa-cog fa-spin"></i>
                    )}
                    Save
                  </button>
                </PermissionsGate>
                <button
                  className="btn btn-close btn-danger"
                  type="button"
                  title="Cancel"
                  onClick={() => {
                    type == 'open' ? formik.resetForm() : history.goBack();
                  }}
                >
                  <i className="fal fa-times"></i>
                  Cancel
                </button>

                {Object.keys(formik.errors).map((key) => {
                  if (formik.touched[key]) {
                    return (
                      <div className="invalid-feedback d-block" key={key}>
                        {formik.errors[key]}
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
            </div>
          </div>
          <div className="col-lg-3 d-none d-lg-block">
            <div className="card card-profile-info-card course-details-tab-sec p-3">
              <div className="course-sec-gap course-setting-sec">
                <div className="edit-icon new-card-header" title="Menu Structure">
                  <div className="card-header">Menu Structure</div>
                </div>
                <div className="card-body-inr correct-sort wbs-sort-card">
                  {menuStructure.length > 0 ? (
                    <SortableTree
                      theme={TreeTheme}
                      className="my-class"
                      isVirtualized={false}
                      treeData={menuStructure}
                      onChange={(treeData) => setMenuStructure(treeData)}
                      maxDepth={1}
                      generateNodeProps={(rowInfo) => {
                        const { node } = rowInfo;

                        let marginRight = "0";
                        let iconClass = "";
                        let color = "";

                        color = "";
                        iconClass = ""; 
                        marginRight = "10px"; 
                        
                        if (node.data.MenuItemType == "Category Sub-Menu") {
                          color = "#D946EF";
                          iconClass = "fal fa-tag mr-2 cat-purpleLight";
                        } else if (node.data.MenuItemType == "Sub-Menu") {
                          color = "#22c55e";
                          iconClass = "fal fa-indent mr-2 cat-outgoing";
                        } else if (node.data.MenuItemType == "Custom Link") {
                          color = "#FB923C";
                          iconClass = "fal fa-globe mr-2 cat-orangeDark";
                        } else if (node.data.MenuItemType == "Static Heading") {
                          color = "#818CF8";
                          iconClass = "fal fa-heading mr-2 cat-blueWhite";
                        } else if (node.data.MenuItemType == "Standard Link Item") {
                          color = "#007BFF";
                          iconClass = "fal fa-link mr-2 cat-blueHead";
                        } else if (node.data.MenuItemType == "Landing Page") {
                          color = "#FB7185";
                          iconClass = "fal fa-bookmark mr-2 cat-page";
                        } else if (node.data.MenuItemType == "Column Divider") {
                          color = "#14B8A6";
                          iconClass = "fal fa-columns mr-2 cat-teal";
                        } else {
                          iconClass = "";
                        }      

                        return {
                          class: node.type === 1 ? "main-category-node" : node.type === 3 ? "sub-category-node" : "item-node",
                          buttons: [
                            <div className='drag-option-container m-0'>
                              <button
                                className='drag-option btn btn-danger rounded-circle m-0'
                                type="button"
                                label="Delete"
                                title="Delete"
                                onClick={() => handleRemoveItem(node)}
                              >
                                <i className="fal fa-trash-alt"></i>
                              </button>
                            </div>,
                          ],
                          style: {
                            borderRadius: "5px",
                            marginRight: marginRight, 
                            borderLeft: `4px ${color} solid`,
                            width: "100%",
                            height: "50px",
                            minWidth:"150px"
                          },
                          title: (
                            <span>
                              <i className={iconClass}></i>
                              
                              {node.title}
                            </span>
                          ),
                        };
                      }}
                      placeholderRenderer={Placeholder}
                    />
                  ) : (
                    <p className="menuStructure">No Menu Items Added</p>
                  )}

                  <div className="form-group form-group-save-cancel mt-3">
                    <button
                      className="btn btn-save btn-success"
                      type="button"
                      title="Add Items"
                      disabled={loading ? true : false}
                      data-toggle="modal"
                      data-target="#menuitem"
                    >
                      {loading === false ? (
                        <i className="fal fa-plus"></i>
                      ) : (
                        <i className="fas fa-cog fa-spin"></i>
                      )}
                      Add Items
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <div
        className="topic-add-modal modal fade"
        id="menuitem"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content modal-border-update">
            <div className="modal-body p-0">
              <div className="modal-header modal-header-custom">
                <h5 className="modal-title">
                  <i className="fas fa-plus"></i> Add Menu Items
                </h5>
                <button type="button" data-dismiss="modal" className="close" title="Close"><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
              </div>
              <div className="p-4">
                <AddMenuItems id={subId} menuStructure={menuStructure} setMenuStructure={setMenuStructure} menuStructureData={menuStructureData} setMenuStructureData={setMenuStructureData} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LayoutForm;
