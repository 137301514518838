import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { ProfileMatch, updateSectionToSave, setBackToStudyKits, setBackToCW, setToCancelled, setToTransferred, setToAccounts } from "../../../../services/RegistrationService";
import $ from "jquery";
import PermissionsGate from "../../../../utils/permissionGate";

function SubmitButton(props) {
  
  const formik = useFormikContext();
  
  const dropdownValues = useSelector((state) => state.registerDropdownValues)
  
  const [studentData, setStudentData] = useState();
  const [profileData, setProfileData] = useState([]);
  const history = useHistory();
  const [status, setStatus] = useState("");
  const [data, setData] = useState({});
  const [disableSave, setDisableSave] = useState(false);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const studentId = params.get("studentId");
  const [setSavenSend, setSavenSendDisabled] = useState(false);
  const [studentFindMatch, setStudentFindMatch] = useState();
  const [loading, setLoading] = useState("")
  
  function getValue(arrName, label) {
    let labelName = "";
    for (let item of dropdownValues[arrName]) {
      if (item.value.toString() == label.toString()) {
        labelName = item.label;
        break;
      }
    }
    
    if (labelName === "" && arrName === "re_registration_status") return label;
    return labelName;
  }
  
  useEffect(() => {
    setStudentData(props.studentData);
    // setProfileData(props.profileMatchData);
    if (props.studentData.re_reg_data && props.studentData.re_reg_data.status) {
      setStatus(getValue("re_registration_status", props.studentData.re_reg_data.status));
    }
    setStudentFindMatch(props.studentData.student_find_match)
    setData(props.studentData && props.studentData.re_reg_data ? props.studentData.re_reg_data : {});
  }, [props]);
  
  // function getValue(arrName, label) {
  //   let labelName = "";
  //   for (let item of dropdownValues[arrName]) {
  //     if (item.value.toString() == label.toString()) {
  //       labelName = item.label;
  //       break;
  //     }
  //   }
  //   return labelName;
  // }
  
  const onRejectSubmit = () => {    
    $("#subDataModal").modal("hide");
    setSavenSendDisabled(true);
    setDisableSave(true);
    formik.setFieldValue("btnval", "Rejected")
    const formData = new FormData();
    formData.append("btnval", "Rejected");
    formData.append("reject_message", formik.values.RejectReason);
    let reg_status = 'Rejected'
    if (data && data['status'] == "Re-Registration") { reg_status = "Re-Registration Rejected" }
    if (data && data['status'] == "Re-Registration-Accounts") { reg_status = "Re-Registration-Accounts Rejected" }
    if (data && data['status'] == "Re-Registration-Sales-Rejected") { reg_status = "Re-Registration-Sales-Rejected" }
    if (data && data['status'] == "Preliminary Re-Registration") { reg_status = "Re-Registration Rejected" }
    if (data && data['status'] == "Review") { reg_status = "Re-Registration Rejected" }
    
    
    formData.append("student_reg_status", reg_status);
    formData.append("student_id", studentId);
    formData.append("brand_reg", formik.values.brand_id);
    formData.append("section_to_save", "re_registration_reject_status_section");
    updateSectionToSave(formData)
    .then((res) => {
      Swal.fire({ icon: "success", title: "Success", text: `${res?.data?.message || "Rejected Successfully"}` });
      setSavenSendDisabled(false);
      props.setrefresh(!props.refresh);
      setDisableSave(false);
    })
    .catch((err) => {
      Swal.fire({ icon: "error", title: "Error", text: `${err?.response?.data?.message}`, });
      setSavenSendDisabled(false);
      setDisableSave(false);
    });
  };
  
  const onRejectSaleSubmit = () => {
    const formData = new FormData();
    
    formData.append("reason", formik.values.RejectSaleReason);
    formData.append("sales_person_id", studentData?.re_reg_data?.sale_person_id);
    formData.append("contact_id", studentData?.re_reg_data?.NScontactID);
    formData.append("booking_id", studentData?.re_reg_data?.bookingId);
    formData.append("student_reg_status", "Re-Registration-Sales-Rejected");
    formData.append("student_reg_id", studentId);
    formData.append("section_to_save", "re_registration_reject_sales_status_section");
    
    updateSectionToSave(formData)
    .then((res) => {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: `${res?.data?.message || "Updated Successfully"}`,
      });
      $("#subDataModal").modal("hide");
      $("#rejectSale").modal("hide");
      props.setrefresh(!props.refresh);
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `${err?.response?.data?.message}`,
      });
    });
    $("#subDataModal").modal("hide");
    $("#rejectSale").modal("hide");
  };
  
  const onSetToNewSubmit = () => {
    const formData = new FormData();
    
    formData.append("student_reg_status", "new-status");
    formData.append("student_id", studentId);
    formData.append("section_to_save", "set_new_status");
    formData.append("contact_id", studentData?.re_reg_data?.NScontactID);
    formData.append("booking_id", studentData?.re_reg_data?.bookingId);
    updateSectionToSave(formData)
    .then((res) => {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: `${res?.data?.message || "Updated Successfully"}`,
      });
      $("#subDataModal").modal("hide");
      setLoading("")
      props.setrefresh(!props.refresh);
    })
    .catch((err) => {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `${err?.response?.data?.message}`,
      });
    });
  };
  
  const onNetsuiteSubmit = () => {
    $("#subDataModal").modal("hide");
    formik.submitForm();
  };
  
  const onClickReject = () => {
    // if (!formik.values.brand_id) {
    // formik.setFieldError("brand", "Brand Is Required")
    // formik.setErrors({...formik.errors, brand:"Brand is Required"});
    // formik.setTouched({...formik.touched, brand : true});
    // return
    // }
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Reject it!",
    }).then((result) => {      
      if (result.isConfirmed) {
        $("#subDataModal").modal("show");
        props.setSelectedBtn("Reject");
      }
    });
  };
  
  const onClickSetBackToCW = () => {
    $("#subDataModal").modal("hide");
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Set it!",
    }).then((result) => {
      setLoading(true);
      if (result.isConfirmed) {
        setBackToCW({"registration_id": studentId})
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: `${res?.data?.message || "Updated Successfully"}`,
          });
          setLoading(false);
          props.setrefresh(!props.refresh);
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${err?.response?.data?.message}`,
          });
        });
      }else{
        setLoading(false);
      }
    });
  };
  const onClickSetToCancelled = () => {
    $("#subDataModal").modal("hide");
    
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Set it!",
    }).then((result) => {
      setLoading(true);
      if (result.isConfirmed) {
        setToCancelled({"registration_id": studentId})
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: `${res?.data?.message || "Updated Successfully"}`,
          });
          setLoading(false);
          props.setrefresh(!props.refresh);
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${err?.response?.data?.message}`,
          });
        });
      }else{
        setLoading(false);
      }
    });
  };
  const onClickSetToAccounts = () => {
    $("#subDataModal").modal("hide");
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Set it!",
    }).then((result) => {
      setLoading(true);
      if (result.isConfirmed) {
        setToAccounts({"registration_id": studentId})
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: `${res?.data?.message || "Updated Successfully"}`,
          });
          setLoading(false);
          props.setrefresh(!props.refresh);
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${err?.response?.data?.message}`,
          });
        });
      }else{
        setLoading(false);
      }
    });
  }

  const onClickSetToTransferred = () => {
    $("#subDataModal").modal("hide");
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Set it!",
    }).then((result) => {
      setLoading(true);
      if (result.isConfirmed) {
        setToTransferred({"registration_id": studentId})
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: `${res?.data?.message || "Updated Successfully"}`,
          });
          setLoading(false);
          props.setrefresh(!props.refresh);
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${err?.response?.data?.message}`,
          });
        });
      }else{
        setLoading(false);
      }
    });
  }
  const onClickSetBackToStudyKits = () => {
    $("#subDataModal").modal("hide");
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Set it!",
    }).then((result) => {
      setLoading(true);
      if (result.isConfirmed) {
        setBackToStudyKits({"registration_id": studentId})
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: `${res?.data?.message || "Updated Successfully"}`,
          });
          setLoading(false);
          props.setrefresh(!props.refresh);
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `${err?.response?.data?.message}`,
          });
        });
      }else{
        setLoading(false);
      }
    });
  };
  const onClickRejectSale = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Reject it!",
    }).then((result) => {
      $("#rejectSale").modal("show");
    });
  };
  
  const onClickInactive = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Inactive it!",
    }).then((result) => {
      if (result.isConfirmed) {
        formik.setFieldValue("btnval", "Inactive");
        formik.submitForm();
      }
    });
  };
  
  const onProfileSubmit = async () => {
    const formData = new FormData();
    formData.append(
      "student_crm_id",
      (data && data?.student_crm_id) || ""
    );
    formData.append(
      "netsuite_id",
      data && data?.CustomerID
    );
    formData.append(
      "email",
      data && data?.email
    );
    formData.append(
      "id_number",
      data && data?.id_number
    );
    formData.append("contact_id", studentData?.re_reg_data?.NScontactID);
    formData.append("booking_id", studentData?.re_reg_data?.bookingId);
    ProfileMatch(formData)
    .then((res) => {
      setLoading("")
      setProfileData(res?.data?.res)
    })
    .catch((err) => console.error("error :", err));
    
    props.setIsSubmitionAllowed(false);
    formik.handleSubmit()
  };
  
  
  return (
    <div className="my-tickets-info-list Tickets-main-wrap">
    <div className="form-group form-group-save-cancel">
    {status &&
      (status == "Re-Registration" ||
        status == "Re-Registration-Sales-Rejected" ||
        status == "Re-Posted" ||
        status == "Re-Reg Re-Posted" ||
        status == "Re-Registration-Sales Admin" ||
        status == "Re-Registration-Study Kit Pending" ||
        status == "Re-Registration-RCO" ||
        status == "Re-Registration-Final" ||
        status == "Review" ||
        status == "Closed-Ordered" ||
        status == "Final-Status" ||
        status == "new-status" ||
        status == "New" ||
        status == "Transferred" ||
        status == "Re-Reg Transferred"||
        data['status'] == "Re-Registration-Accounts" ||
        data['status'] == "Re-Registration-Accounts Rejected" ||
        data['status'] == "Accounts" ||
        status == "Rejected") && (
          <>
          {data['status'] == "Re-Registration-Accounts" ? (
          <PermissionsGate errorProps={{disabled:true}} scopes={['regposttonetsuite']}>
          <button
          className="btn btn-save btn-success"
          type="button"
          title="Save"
          id="save"
          disabled={props.btnDissable === "save" || props.disabled || disableSave}
          onClick={async () => {
            props.setSelectedBtn("save");
            formik.setFieldValue("btnval", "");
            if (status == "Re-Registration") {
              await props.setIsValidating({ isChecking: false, skippingField: [] });
            } else if (status == "Re-Registration-Sales Admin" ||
              status == "Re-Posted" ||
              status == "Re-Reg Re-Posted" ||
              status == "Re-Registration-Sales Invoiced" ||
              status == "Re-Registration-Sales-Rejected" ||
              status == "Re-Registration-Study Kit Pending") {
                await props.setIsValidating({ isChecking: false, skippingField: [] });
              } else if (status == "Re-Registration-Accounts Rejected" || status == "Re-Registration-Accounts" || status == "Accounts") {
                await props.setIsValidating({ isChecking: false, skippingField: [] });
              } else {
                await props.setIsValidating({ isChecking: false, skippingField: ["laptop"] });
              }
              await formik.submitForm();
            }}
            >
            {props.btnDissable === "save" ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
            Save
            </button>
          </PermissionsGate>
          ) :
          <button
          className="btn btn-save btn-success"
          type="button"
          title="Save"
          id="save"
          disabled={props.btnDissable === "save" || props.disabled || disableSave}
          onClick={async () => {
            props.setSelectedBtn("save");
            formik.setFieldValue("btnval", "");
            if (status == "Re-Registration") {
              await props.setIsValidating({ isChecking: false, skippingField: [] });
            } else if (status == "Re-Registration-Sales Admin" ||
              status == "Re-Posted" ||
              status == "Re-Reg Re-Posted" ||
              status == "Re-Registration-Sales Invoiced" ||
              status == "Re-Registration-Sales-Rejected" ||
              status == "Re-Registration-Study Kit Pending") {
                await props.setIsValidating({ isChecking: false, skippingField: [] });
              } else if (status == "Re-Registration-Accounts Rejected" || status == "Re-Registration-Accounts" || status == "Accounts") {
                await props.setIsValidating({ isChecking: false, skippingField: [] });
              } else {
                await props.setIsValidating({ isChecking: false, skippingField: ["laptop"] });
              }
              await formik.submitForm();
            }}
            >
            {props.btnDissable === "save" ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
            Save
            </button>
            }
            {status == "Re-Registration-Sales-Rejected" && data["crm_checked"] == 1 && data["xero_checked"] == 1 && data["portal_checked"] == 1 && (
              <button
              className="btn btn-save btn-success"
              onClick={async () => {
                formik.setFieldValue("btnval", "SalesAdmin");
                await props.setIsValidating({ isChecking: true, skippingField: [] });
                formik.submitForm();
              }}
              >
              Set To Sales Admin
              </button>
            )}
            </>
          )}
          
          {status && (( status == "Re-Posted" && studentFindMatch < 1) || (((status == "New" && studentFindMatch < 1) || ((status == "new-status") && studentFindMatch < 1)) && data["status_merge"] != 1 && data["accept"] == 0)) && (
            <PermissionsGate errorProps={{disabled:true}} scopes={['regaccept']}>            
              
            <button
            className="btn btn-save btn-success"
            type="button"
            title="Save & Accept"
            disabled={props.btnDissable == "SaveAccept" || props.disabled || disableSave || ((studentData.matched_program.length == 0 || !studentData.matched_program.length ) && !props.isAcademicDepartment && formik.values.course_type != "shortcourse")}
            onClick={async () => {
              if (!formik.isValid) {
                await props.setIsValidating({ isChecking: true, skippingField: [] });
                await props.setIsSubmitionAllowed("SaveAccept")
                props.setSelectedBtn("SaveAccept");
                formik.setFieldValue("btnval", "Acceptsave");
                formik.handleSubmit()
                return;
              } else {
                Swal.fire({
                  title: "Are you sure you want to Save & Accept this record?",
                  text: "You won't be able to revert this!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, Save & Accept it!",
                }).then(async (result) => {
                  if (result.isConfirmed) {
                    await props.setIsValidating({ isChecking: true, skippingField: [] });
                    await props.setIsSubmitionAllowed(true)
                    props.setSelectedBtn("SaveAccept");
                    if (status == "Re-Registration-Sales Admin" || status == "Re-Posted") {
                      formik.setFieldValue("btnval", "Acceptsave");
                    }
                    if (status == "new-status" || status == "New") {
                      formik.setFieldValue("btnval", "Acceptsave");
                    }
                    formik.submitForm();
                  }
                })
              }
            }}
            >
            {props.btnDissable == "SaveAccept" ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
            Save & Accept
            </button></PermissionsGate>
          )}
          
          {data && ((status == "Re-Posted" && studentFindMatch > 0) || (data.status == "New" || data.status == "new-status") && data["status_merge"] != 1 && data["accept"] == 0 && studentFindMatch > 0) && (
            <PermissionsGate errorProps={{disabled:true}} scopes={['regaccept']}><button
            className="btn btn-save btn-success"
            type="button"
            title="Find Profile Match"
            disabled={props.btnDissable == "FindProfileMatch" || props.disabled || props.courseItemNonMatch}
            onClick={async () => {
              await setLoading("findProfileMatch")
              await props.setIsValidating({ isChecking: true, skippingField: [] });
              // props.setSelectedBtn("FindProfileMatch")
              onProfileSubmit();
            }}
            >
            {loading === "findProfileMatch" ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
            Find Profile Match
            </button>
            </PermissionsGate>
          )}
          
          
          {status && (status == "Re-Registration" || status == "Preliminary Re-Registration") && (
            <button
            className="btn btn-save btn-success"
            type="button"
            title="Set To Accounts"
            disabled={props.btnDissable == "SetToAccounts" || props.disabled}
            onClick={async () => {
              await formik.setErrors({});
              if (status == "Re-Registration") {
                await props.setIsValidating({ isChecking: true, skippingField: ["salesPerson", "laptop"] });
              } else {
                await props.setIsValidating({ isChecking: true, skippingField: [] });
              }
              props.setSelectedBtn("SetToAccounts");
              formik.setFieldValue("btnval", "Accounts");
              formik.submitForm();
            }}
            >
            {props.btnDissable == "SetToAccounts" ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
            Set To Accounts
            </button>
          )}
          
          {status && status == "Re-Registration Cancelled" && (
            <button
            className="btn btn-save btn-success"
            type="submit"
            title="Re-Registration Cancelled"
            disabled={props.btnDissable == "Re-RegistrationCancelled" || props.disabled}
            onClick={async () => {
              await props.setIsValidating({ isChecking: true, skippingField: [] });
              props.setSelectedBtn("Re-RegistrationCancelled");
              formik.submitForm();
            }}
            >
            {props.btnDissable == "Re-RegistrationCancelled" ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
            Re-Registration Cancelled
            </button>
          )}
          
          {((data &&
            (data['status'] == "Re-Registration" ||
              data['status'] == "Preliminary Re-Registration" ||
              // data['status'] == "Re-Registration-RCO" ||
              data['status'] == "New" ||
              data['status'] == "new-status" ||
              data['status'] == "Re-Registration-Accounts" ||
              data['status'] == "Accounts") &&
              data["status_merge"] != 1) || data?.status == "Re-Posted") && ( 
                <>
                {data['status'] == "Re-Registration-Accounts" ? (
                  <PermissionsGate errorProps={{disabled:true}} scopes={['regposttonetsuite']}>
                <button
                className="btn btn-save btn-success"
                type="button"
                title="Reject"
                disabled={props.btnDissable == "Reject" || props.disabled}
                onClick={() => {
                  props.setSelectedBtn("Reject");
                  if (formik.isValid) {
                    if (status === "Accounts") {
                      formik.setFieldValue("btnval", "RejAccount");
                    }
                    if (status == "New" || status == "new-status") {
                      formik.setFieldValue("btnval", "Rejected");
                    }
                  }
                  onClickReject();
                }}
                >
                {props.btnDissable == "Reject" || disableSave || props.btnDissable === "save"  ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                Reject
                </button>
                </PermissionsGate>
              ) : (
                <button
                className="btn btn-save btn-success"
                type="button"
                title="Reject"
                disabled={props.btnDissable == "Reject" || props.disabled}
                onClick={() => {
                  props.setSelectedBtn("Reject");
                  if (formik.isValid) {
                    if (status === "Accounts") {
                      formik.setFieldValue("btnval", "RejAccount");
                    }
                    if (status == "New" || status == "new-status") {
                      formik.setFieldValue("btnval", "Rejected");
                    }
                  }
                  onClickReject();
                }}
                >
                {props.btnDissable == "Reject" || disableSave || props.btnDissable === "save"  ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                Reject
                </button>
              )}
              </>
            )}
              {status && status == "Re-Registration" && data && data['academy'] == "engineering" && (
                <button
                className="btn btn-save btn-success"
                type="button"
                title="Set As Preliminary"
                disabled={props.btnDissable == "SetAsPreliminary" || props.disabled}
                onClick={async () => {
                  props.setSelectedBtn("SetAsPreliminary");
                  formik.setFieldValue("btnval", "Prelim");
                  await props.setIsValidating({ isChecking: false, skippingField: [] });
                  await formik.submitForm();
                }}
                >
                {props.btnDissable == "SetAsPreliminary" ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                Set As Preliminary
                </button>
              )}
              
              {status && status == "Re-Registration Rejected" && (
                <button
                className="btn btn-save btn-success"
                type="button"
                title="Set To Re-Registration"
                disabled={props.btnDissable == "SetToRe-Registration" || props.disabled}
                onClick={async () => {
                  await props.setIsValidating({ isChecking: true, skippingField: [] });
                  props.setSelectedBtn("SetToRe-Registration");
                  formik.submitForm();
                }}
                >
                {props.btnDissable == "SetToRe-Registration" ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                Set To Re-Registration
                </button>
              )}
              
              {status && (status == "Re-Registration-Accounts Rejected" || status == "Re-Registration-Accounts" || status == "Accounts") && (
                <PermissionsGate errorProps={{disabled:true}} scopes={['regposttonetsuite']}>
                <button
                className="btn btn-save btn-success"
                type="button"
                title="Post to Netsuite"
                disabled={props.btnDissable == "PosttoNetsuite" || props.disabled || disableSave || data?.post_to_netsuite === 1}
                onClick={async () => {
                  await props.setIsValidating({ isChecking: true, skippingField: ["laptop"] });
                  props.setSelectedBtn("PosttoNetsuite");
                  formik.setFieldValue("btnval", "Postcrm");
                  onNetsuiteSubmit();
                }}
                >
                {props.btnDissable == "PosttoNetsuite" ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                Post to Netsuite
                </button>
                </PermissionsGate>
              )}
              
              {status && status == "Re-Registration-Accounts Rejected" && (
                <button
                className="btn btn-save btn-success"
                type="button"
                title="Set as Inactive"
                disabled={props.btnDissable == "SetasInactive" || props.disabled}
                onClick={() => {
                  props.setSelectedBtn("SetasInactive");
                  onClickInactive();
                }}
                >
                {props.btnDissable == "SetasInactive" ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                Set as Inactive
                </button>
              )}
              {((status == "Re-Registration-Sales Admin" && data["status_test"] == 0) || status == "Re-Reg Re-Posted") && (
                <PermissionsGate errorProps={{disabled:true}} scopes={['regaccept']}>
                <button
                className="btn btn-save btn-success"
                type="button"
                title="Save & Accept"
                disabled={props.btnDissable == "SaveAccept" || props.disabled || props.courseItemNonMatch}
                onClick={async () => {
                  if (!formik.isValid) {
                    await props.setIsValidating({ isChecking: true, skippingField: [] });
                    await props.setIsSubmitionAllowed("SaveAccept")
                    props.setSelectedBtn("SaveAccept");
                    formik.setFieldValue("btnval", "SetAcceptReReg");
                    formik.handleSubmit()
                    return;
                  } else {
                    Swal.fire({
                      title: "Are you sure you want to Save & Accept this record?",
                      text: "You won't be able to revert this!",
                      icon: "warning",
                      showCancelButton: true,
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Yes, Save & Accept it!",
                    }).then(async (result) => {
                      if (result.isConfirmed) {
                        await props.setIsValidating({ isChecking: true, skippingField: [] });
                        await props.setIsSubmitionAllowed(true)
                        props.setSelectedBtn("SaveAccept");
                        formik.setFieldValue("btnval", "SetAcceptReReg");
                        formik.submitForm();
                      }
                    })
                  }
                }}
                >
                {props.btnDissable == "SaveAccept" ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                Save & Accept
                </button>
                </PermissionsGate>
              )}
              
              {data && (data.status == "New" || data.status == "new-status") && data["status_merge"] != 1 && data["accept"] == 1 && studentFindMatch > 0 && (
                <PermissionsGate errorProps={{disabled:true}} scopes={['regaccept']}>
                <button
                className="btn btn-save btn-success"
                type="button"
                title="Find Profile Match"
                disabled={props.btnDissable == "FindProfileMatch" || props.disabled || props.courseItemNonMatch}
                onClick={async () => {
                  await props.setIsValidating({ isChecking: true, skippingField: [] });
                  // props.setSelectedBtn("FindProfileMatch")
                  onProfileSubmit();
                }}
                >
                {props.btnDissable == "FindProfileMatch" ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                Find Profile Match
                </button>
                </PermissionsGate>
              )}
              
              {status && (status == "Re-Registration-Sales Admin" || status == "Re-Reg Re-Posted") && data["status_test"] == 0 && (
                <button
                className="btn btn-save btn-success"
                type="button"
                title="Reject Sale"
                disabled={props.btnDissable == "RejectSale" || props.disabled}
                onClick={() => {
                  props.setSelectedBtn("RejectSale")
                  onClickRejectSale();
                }}
                >
                {props.btnDissable == "RejectSale" ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                Reject Sale
                </button>
              )}
              
              {status && (((status == "Re-Registration-Study Kit Pending" && !(data.to_be_reviewed == 1 && data.academy == 'engineering')) || status == "Closed-Ordered" || status == "Study Kit Pending") || (status == "Review" && data.exam_results == 'passed')) && (
                <PermissionsGate errorProps={{disabled:true}} scopes={['regsetfinal']}>
                <button
                className="btn btn-save btn-success"
                type="button"
                title="Set to Final"
                disabled={props.btnDissable == "SettoFinal" || props.disabled}
                onClick={async () => {
                  await props.setIsValidating({ isChecking: true, isSetToFinal: true, skippingField: [] });
                  await props.setSelectedBtn("SettoFinal")
                  formik.setFieldValue("btnval", "Final");
                  formik.submitForm();
                }}
                >
                {props.btnDissable == "SettoFinal" ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                Set to Final
                </button>
                </PermissionsGate>
              )}
              
              {status && (status == "Review" && data.exam_results == 'failed') && (
                <button
                className="btn btn-save btn-success"
                type="button"
                title="Set To RCO"
                disabled={props.btnDissable == "SettoRCO" || props.disabled}
                onClick={async () => {
                  await props.setIsValidating({ isChecking: true, skippingField: [] });
                  props.setSelectedBtn("SettoRCO")
                  formik.setFieldValue("btnval", "RCO");
                  formik.submitForm();
                }}
                >
                {props.btnDissable == "SettoRCO" ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                Set To RCO
                </button>
              )}
              {status && (status == "Re-Registration-Study Kit Pending" && data && data['to_be_reviewed'] === 1 && data['academy'] == 'engineering') && (
                <button
                className="btn btn-save btn-success"
                type="button"
                title="Set To Review"
                disabled={props.btnDissable == "SettoReview" || props.disabled}
                onClick={async () => {
                  await props.setIsValidating({ isChecking: true, skippingField: [] });
                  props.setSelectedBtn("SettoReview")
                  formik.setFieldValue("btnval", "Review");
                  formik.submitForm();
                }}
                >
                {props.btnDissable == "SettoReview" ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                Set To Review
                </button>
              )}
              {status && status == "Rejected" && ""
                // (
                // <button
                //   className="btn btn-save btn-success"
                //   type="button"
                //   title="Set to New"
                //   disabled={props.btnDissable == "SettoNew" || props.disabled}
                //   onClick={async () => {
                  //     // await props.setSelectedBtn("SettoNew")
                //     setLoading("SettoNew")
                //     onSetToNewSubmit();
                //   }}
                // >
                //   {loading === "SettoNew" ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                //   Set to New
                // </button>
                // )
              }
              
              {status && data["accept"] == 1 && data["status_merge"] == "1" && (status == "new-status" || status == "New") && (
                <button
                className="btn btn-close btn-primary"
                type="button"
                title="Set Qualification"
                disabled={props.btnDissable == "SetQualification"}
                onClick={async () => {
                  await props.setIsValidating({ isChecking: true, skippingField: [] });
                  props.setSelectedBtn("SetQualification")
                  formik.setFieldValue("btnval", "SetQual");
                  formik.submitForm();
                }}>
                {props.btnDissable == "SetQualification" ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                Set Qualification
                </button>
              )}
              
              {status && (status == "Re-Registration-Sales Admin") && data["status_test"] == 1 && (
                <button
                className="btn btn-close btn-primary"
                type="button"
                title="Set Qualification"
                onClick={async () => {
                  await props.setIsValidating({ isChecking: true, skippingField: [] });
                  props.setSelectedBtn("SetQualification");
                  formik.setFieldValue("btnval", "SetQual");
                  formik.submitForm();
                }}
                disabled={props.btnDissable == "SetQualification"}
                >
                {props.btnDissable == "SetQualification" ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                Set Qualification
                </button>
              )}
              {data && (data.status != "Registration Withdrawn" && data.status != "Re-Reg Withdrawn" && data.status != "Transferred" && data.status != "Re-Reg Transferred")  && (
                <PermissionsGate scopes={['regsettotransferred']}>
                <button
                className="btn btn-save btn-success"
                type="button"
                title="This will set the registrations status to Transferred"
                disabled={loading}
                onClick={async () => {
                  onClickSetToTransferred();
                }}
                >
                {loading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                Set to Transferred
                </button>
                </PermissionsGate>
              )}
              {data && (data.status == "Re-Registration-Accounts Rejected")  && (
                <PermissionsGate scopes={['regsettoaccounts']}>
                <button
                className="btn btn-save btn-success"
                type="button"
                title="This will set the registrations status to Accounts"
                disabled={loading}
                onClick={async () => {
                  onClickSetToAccounts();
                }}
                >
                {loading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                Set to Accounts
                </button>
                </PermissionsGate>
              )}
              {data && (data.status != "Registration Withdrawn" && data.status != "Re-Reg Withdrawn" && data.status != "Transferred" && data.status != "Re-Reg Transferred" )  && (
                <PermissionsGate scopes={['regsettocancelled']}>
                <button
                className="btn btn-save btn-success"
                type="button"
                title="This will set the registrations status to Cancelled"
                disabled={loading}
                onClick={async () => {
                  onClickSetToCancelled();
                }}
                >
                {loading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                Set to Cancelled
                </button>
                </PermissionsGate>
              )}
              {data && (data.status == "Closed-Ordered" || data.status == "Re-Registration-Study Kit Pending")  && (
                <PermissionsGate scopes={['regbacktocw']}>
                <button
                className="btn btn-save btn-success"
                type="button"
                title="This will set the registration status back to Closed-Won"
                disabled={loading}
                onClick={async () => {
                  onClickSetBackToCW();
                }}
                >
                {loading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                Set Back To CW
                </button>
                </PermissionsGate>
              )}              
              {data && (data.status == "Final-Status" || data.status == "Re-Registration-Final")  && (
                <PermissionsGate  scopes={['regbacktostudykit']}>
                <button
                className="btn btn-save btn-success"
                type="button"
                title="This will set the registration status back to Study Kit Pending"
                disabled={loading}
                onClick={async () => {
                  onClickSetBackToStudyKits();
                }}
                >
                {loading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                Set Back To Study Kits
                </button>
                </PermissionsGate>
              )}
              {/* this function will map all validation error message in formik */}
              {Object.keys(formik.values).map(key => {
                if (formik.touched[key] && formik.errors[key]) {
                  return (
                    <div className="invalid-feedback d-block" key={key}>
                    {formik.errors[key]}
                    </div>
                  )
                }
              })}
              {((studentData?.matched_program?.length == 0 
                || !studentData?.matched_program?.length) &&
                !props.isAcademicDepartment &&
                status &&
                ( status == "Re-Posted" ||
                  status == "Re-Reg Re-Posted" ||
                  status == "Re-Registration-Sales Admin" ||
                  status == "new-status" ||
                  status == "New" )) && (
                    <div className="invalid-feedback d-block"><strong>
                    {formik.values.course_type != "shortcourse" ? "Save & Accept not allowed without a matched Course/Programme" : "NOTE: No matching course found"}
                    </strong>
                    </div>
                  )}
                  {(props.courseItemNonMatch &&
                    status &&
                    ( status == "Re-Posted" ||
                      status == "Re-Reg Re-Posted" ||
                      status == "Re-Registration-Sales Admin" ||
                      status == "new-status" ||
                      status == "New" )) && (
                        <div className="invalid-feedback d-block"><strong>
                        {formik.values.course_type != "shortcourse" ? "Save & Accept not allowed if a matching course is not found for ALL courses" : "NOTE: No matching course found"}
                        </strong>
                        </div>
                      )}


                  </div>
                  <div className="topic-add-modal modal fade" id="subDataModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                  <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                  <div className="modal-content">
                  <div className="modal-header modal-header-custom"><h5 className="modal-title"><i className="fal fa-save"></i> Reason for re-registration rejected</h5><button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button></div>
                  <div className="modal-body">
                  <div>
                  <div className="topic-add-modal-content">
                  <div className="topic-add-modal-content">
                  <div className="row center-label">
                  <div className="col-md-12">
                  <div className="form-group-blk mb-3">
                  <label>Reason *</label>
                  <textarea
                  rows={10}
                  type="text"
                  id="RejectReason"
                  className={"form-control" + (formik.errors.RejectReason && formik.touched.RejectReason ? " is-invalid" : "")}
                  name="RejectReason"
                  placeholder="Reason"
                  value={formik.values.RejectReason}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  title="Reason"
                  />
                  </div>
                  </div>
                  </div>
                  </div>
                  </div>
                  
                  <div className="form-group form-group-save-cancel mt-4">
                  <button
                  disabled={setSavenSend}
                  className="btn btn-save btn-success"
                  type="button"
                  title="Save & Send"
                  onClick={() => {
                    setSavenSendDisabled(true);
                    onRejectSubmit();
                  }}
                  >
                  {setSavenSend ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}Save & Send
                  </button>
                  
                  <button className="btn btn-close btn-danger" type="button" title="Cancel" data-dismiss="modal">
                  <i className="fal fa-times"></i>Cancel
                  </button>
                  </div>
                  </div>
                  </div>
                  </div>
                  </div>
                  </div>
                  
                  <div className="topic-add-modal modal fade" id="rejectSale" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                  <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                  <div className="modal-content">
                  <div className="modal-body">
                  <div className="topic-add-modal-content text-center font-weight-bold">Add Comments</div>
                  <hr />
                  <div>
                  <div className="topic-add-modal-content">
                  <div className="row">
                  <div className="col-2 font-weight-bold">Comments</div>
                  <div className="col-10">
                  <div className="form-group-blk ">
                  <textarea
                  rows={10}
                  type="text"
                  id="RejectSaleReason"
                  className={"form-control" + (formik.errors.RejectSaleReason && formik.touched.RejectSaleReason ? " is-invalid" : "")}
                  name="RejectSaleReason"
                  placeholder="Comments"
                  value={formik.values.RejectSaleReason}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  title="Comments"
                  />
                  </div>
                  </div>
                  </div>
                  </div>
                  
                  <div className="form-group form-group-save-cancel mt-4">
                  <button
                  disabled={setSavenSend}
                  className="btn btn-save btn-success"
                  type="button"
                  title="Save & Send"
                  onClick={() => {
                    setSavenSendDisabled(true);
                    onRejectSaleSubmit();
                  }}
                  >
                  {setSavenSend ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}Save & Send
                  </button>
                  
                  <button className="btn btn-close btn-danger" type="button" title="Cancel" data-dismiss="modal">
                  <i className="fal fa-times"></i>Cancel
                  </button>
                  </div>
                  </div>
                  </div>
                  </div>
                  </div>
                  </div>
                  
                  {/* Profile MAtch modal */}
                  <div className="topic-add-modal modal fade" id="ProfileMatchModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                  <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                  <div className="modal-content">
                  <div className="modal-body">
                  <div className="topic-add-modal-content text-center font-weight-bold">Compare registration with existing profile</div>
                  <hr />
                  <div>
                  <div className="topic-add-modal-content">
                  <div className="pb-2">
                  <div className="font-weight-bold">Please inform management regarding this existing student profile and new registration</div>
                  </div>
                  <div className="row">
                  <div className="col-6">
                  <div className="form-group-blk ">
                  <div className="font-weight-bold py-2">New Registration details:</div>
                  <div className="pb-2"></div>
                  <div className="row">
                  <div className="col-6 font-weight-bold">Full Name:</div>
                  <div className="col-6 ">{formik.values.FirstName + " " + formik.values.LastName}</div>
                  </div>
                  <div className="pb-2"></div>
                  <div className="row">
                  <div className="col-6 font-weight-bold">Email:</div>
                  <div className="col-6 ">{formik.values.Email}</div>
                  </div>
                  <div className="pb-2"></div>
                  <div className="row">
                  <div className="col-6 font-weight-bold">Student Number:</div>
                  <div className="col-6 ">{formik.values.StudentNumber}</div>
                  </div>
                  <div className="pb-2"></div>
                  <div className="row">
                  <div className="col-6 font-weight-bold">RSA ID or Passport Number:</div>
                  <div className="col-6 ">{formik.values.RSAID}</div>
                  </div>
                  </div>
                  </div>
                  
                  <div className="col-6">
                  <div className="form-group-blk ">
                  <div className="font-weight-bold py-2">Existing Profile details:</div>
                  {profileData &&
                    profileData.map((data) => {
                      return (
                        <div key={data.id}>
                        <div className="py-2">
                        <div className="pb-2">
                        <div className="row">
                        <div className="col-6 font-weight-bold">Full Name:</div>
                        <div className="col-6 ">{data.first_name + " " + data.last_name}</div>
                        </div>
                        </div>
                        <div className="pb-2">
                        <div className="row">
                        <div className="col-6 font-weight-bold">Email:</div>
                        <div className="col-6 ">{data.email}</div>
                        </div>
                        </div>
                        <div className="pb-2">
                        <div className="row">
                        <div className="col-6 font-weight-bold">Student Number:</div>
                        <div className="col-6 ">{data.student_crm_id}</div>
                        </div>
                        </div>
                        <div className="pb-2">
                        <div className="row">
                        <div className="col-6 font-weight-bold">RSA ID or Passport Number:</div>
                        <div className="col-6 ">{data.number}</div>
                        </div>
                        </div>
                        <div className="pb-2">
                        <div className="row">
                        <div className="col-6 font-weight-bold">Profile Status:</div>
                        <div className="col-6 ">{data.status}</div>
                        </div>
                        </div>
                        </div>
                        <div className="form-group form-group-save-cancel mt-2">
                        <button
                        className="btn btn-save btn-success"
                        type="button"
                        title="View Profile"
                        onClick={() => {
                          history.push(`/studentAdministration/students/open/${data?.id}/general`);
                        }}
                        data-dismiss="modal"
                        >
                        View Profile
                        </button>
                        
                        <button
                        className="btn btn-close btn-danger"
                        type="button"
                        title="Merge to Profile"
                        disabled={props.btnDissable == "MergeProfileMatch"}
                        onClick={() => {
                          props.setIsSubmitionAllowed(true);
                          props.setSelectedBtn("MergeProfileMatch")
                          formik.setFieldValue("btnval", "SetQualMerge");
                          formik.setFieldValue("update_id", data.id);
                          formik.submitForm();
                        }}
                        >
                        {props.btnDissable == "MergeProfileMatch" ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                        Merge to Profile
                        </button>
                        </div>
                        </div>
                      );
                    })}
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    {/* Profile MAtch modal */}
                    </div>
                  );
                }
                
                export default SubmitButton;
                