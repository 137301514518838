/**
 * the Student Component is responsible for the certificate Generation and also with the view Certificate
 * the Certificate Generation uses three to four main Concepts while generating the certificate
 * the Certificate is generated based on the Certificate Template that we select from the Certificates Tab
 * this tab will only be visible when admin enable the certificate generation by Clicking on the enable Certificate Checkbox
 * this checkbox is available in the Details Tab
 * the flow to check the certificate generation is as follows
 * 1. Select the course administration tab from the side bar.
 * 2. Select the Courses Image Tab this will open the list of all the courses that are available in the system
 * 3. Select the Course that you want to generate the certificate for
 * 4. Check the enable Certificate checkbox and then click on the save button
 * 5. Now the Certificate Tab will be visible in the course administration tab
 * 6. Select the Certificate Tab and then select the certificate template that you want to use for the certificate generation
 * 7. Now this certificate template will be mapped with this certificate template id and the course id
 * 8. You only need to do this once
 * 9. Now when you go the students tab you will see the list of students that are enrolled in the course
 * 10. Now you can select the students and then click on the generate certificate button
 * 11. Once the certificate is generated you can view the certificate by clicking on the view certificate button
 */
import React, { useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import JsPDF from 'jspdf';
import 'jspdf-autotable';
import {
  ColorCode,
  ColorRender,
} from '../../systemadministration/usermanagement/CheckRole';
import SkeletonTicketList from '../../../loaders/SkeletonTicketList';
import {
  GetStudentList, deleteGenerateCertificate,
} from '../../../services/CourseService';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  IMAGE_URL,
  TABLE_ROWS_PER_PAGE,
  TABLE_TIME_FORMAT,
} from '../../../utils/Constants';
import { InitialRender } from '../../common/Helper';
import PermissionsGate from '../../../utils/permissionGate';
import Hover from '../../common/Hover';
import Tablefilter from '../../common/Tablefilter';
import {
  RenderCourseSStatus,
  RenderProgrammeStudentStatus,
  RenderQuizAttemptResultStatus,
  RenderCertificateStatus,
} from '../../../utils/CommonStatusItems';
import BadgeIcon from './../../../assets/images/badge-with-a-star-s.png';
import InProgress from './../../../assets/images/In-Progress.png';
import PendingReview from './../../../assets/images/Pending-Review.png';
import NotStarted from './../../../assets/images/Not-Started.png';
import { exportFile } from '../../../utils/ExportData';
import {
  RenderAttendanceHealth,
  RenderLearningMethodGradebook,
} from '../../../utils/CommonGroupingItem';
import $, { parseHTML } from 'jquery';
import Swal from 'sweetalert2';
import { InsertSignOff } from '../../../services/DashBoardServices';
import moment from 'moment';
import {
  getCourseGradebook,
    getStudentBrand,
  } from '../../../services/GradeSettingService';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import LetterheadIntakePDF from '../../../LetterheadIntakePDF';
import { pdf } from '@react-pdf/renderer';
import { handleTableScroll } from '../../../utils/commonFunction';
import {
  GenerateCertificate,
  GetStudentCertificateById,
  GetCertificateStatus,
} from '../../../services/CertificateServices';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { formatDate } from '../../common/Date';
import { updateStudentProgress } from '../../../services/StudentService';
import { StudentCertificatNotification } from '../../../services/TickectService';
import { SetToInProgressDialog } from '../../DashBoardComp/CommonDialog';
import html2canvas from 'html2canvas';
import { useSelector } from 'react-redux';
import { GetBrandSettingDetail } from '../../../services/BrandServices';

const Students = (props) => {
  const state_ = localStorage.getItem('state');

  const [userData, setUserData] = useState([]);

  const brandVisuals = JSON.parse(state_).brandVisuals.config;
  const history = useHistory();
  const { id } = useParams();
  const [search, setSearch] = useState('');
  const [status, setStatus] = useState({ arr: [], checkObj: {} });
  const [disclaimer,setDisclaimer] = useState("")
  const [attendanceHealth, setAttendanceHealth] = useState({
    arr: [],
    checkObj: {},
  });
  const [coursestatus, setCourseStatus] = useState({ arr: [], checkObj: {} });
  const [studentList, setStudentList] = useState([]);
  const [isStudents, setIsStudents] = useState(false);
  const [filterData, setFilterData] = useState({
    status: [],
    attendance: [],
    cStatus: [],
  });

  const [dialogOpen, setDialogOpen] = useState(false);
  const [enrollmentData, setEnrollmentData] = useState({
    studentId: 0,
    intakeId: 0,
    studentFirstName: "",
    studentLastName: ""
  });

  const [filterCheck, setFilterCheck] = useState('true');
  const [signOffData, setSignOffData] = useState({});
  const [validation, setValidation] = useState(false);
  const notificationInitialState = {
    class_id: '',
    subject: '',
    message: '',
    attachment: {},
    class_name: '',
  };
  const [notificationFields, setNotificationFields] = useState(
    notificationInitialState
  );
  const [isChecked, setIsChecked] = useState(false);
  const [isShowFinal, setIsShowFinal] = useState(false);
  const [isExcludeGrade, setIsExcludeGrade] = useState(false);
  const [pdfDownloading, setPdfDownloading] = useState(false);
  const [bulkPdfDownloading, setBulkPdfDownloading] = useState(false);
  const [studentID, setStudentID] = useState('');
  const [bulkDownload, setBulkDownload] = useState(false);
  const [allStudents, setAllStudents] = useState([]);
  const [courseDetail, setCourseDetail] = useState({course_type: 'shortcourse',qualification: 899});
  const [pdfsData, setPDFsData] = useState([]);
  const [studentCertificateStatusList, setStudentCertificateStatusList] =
    useState([]);

  const [trackStudentId, setTrackStudentID] = useState(0);
  const [updateCert, setUpdateCert] = useState(false)
  const [studentProgressId, setStudentProgressId] = useState(0);
  const [brand_override, setBrandOverride] = useState("")
  const [isBrandOverride, setIsBrandOverride] = useState(false)
  
  const course_type = useSelector(state => state.course_type_reducer.course_type)

  useEffect(() => {
    handleTableScroll();
  }, [isStudents]);

  useEffect(() => {
    getSutdentData();
  }, [studentProgressId]);

  // this useEffect is used to get the certificate status of all the students
  //we need to pass the array of studentIds and courseId
  useEffect(() => {
    if (allStudents.length > 0) {
      const GetAllCertificateStatus = async (allStudents, courseId) => {
        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
        await delay(1000);
        const response = await GetCertificateStatus(allStudents, courseId);
        setStudentCertificateStatusList(response.data.results);
      };
      GetAllCertificateStatus(allStudents, id);
    }
  }, [trackStudentId, allStudents, updateCert]);

  const getSutdentData = () => {
    let data = filterCheck;
    GetStudentList(props.userId, data)
      .then((data) => {
        let allStdIds = [];
        let stdIds = data.data.studentList.map((std) => {
          allStdIds.push(std?.id);
        });
        setAllStudents(allStdIds);
        setStudentList([]);
        setStudentList(
          data && data.data && data.data.studentList
            ? data.data.studentList
            : []
        );
        if (filterCheck == 'true') {
          setFilterData({
            ...data.data,
            status: data.data.statusTypeFilter,
            attendance: data.data.attendanceTypeFilter,
            cStatus: data.data.courseTypeFilter,
          });
          setFilterCheck('false');
        }
        setIsStudents(true);
      })
      .catch((error) => {
        console.log('<>><>< ', error);
        setIsStudents(true);
      });
  };

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const resetFilter = () => {
    setSearch('');
    setStatus({ arr: [], checkObj: {} });
    setAttendanceHealth({ arr: [], checkObj: {} });
    setCourseStatus({ arr: [], checkObj: {} });
  };


  const getStudentFullName = (id) => {
    const student = studentList.find((std) => std.id === id);
    if (student) {
      const { first_name, last_name } = student;
      const studentFullName = first_name + ' ' + last_name;

      return studentFullName;
    }
  };

  const handleCert_Generation = async (stu_id) => {
    const studentFullName = getStudentFullName(stu_id);
    const certificate_pdf_Data = {
      studentId: stu_id,
      studentName: studentFullName,
      courseId: id,
    };
    setTrackStudentID(stu_id);

    // here we are calling the api to generate the certificate
    try {
      await GenerateCertificate(certificate_pdf_Data);
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'Certificate Generated Successfully',
      });
      setUpdateCert(!updateCert)
    } catch (error) {
      console.log('error', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.response.data.message,
      });
    }
  };

  const handleViewCertificate = async (certId) => {
    try {
      const response = await GetStudentCertificateById(certId, id);
      const certificateData = response.data;
      const blob = new Blob([certificateData], {
        type: 'application/pdf',
      });
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    } catch (error) {
      console.log('error', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Error in generating certificate',
      });
    }
  };

  const handleNotifyStudent = async (stdId) => {
    const params = { student_id: stdId };
      StudentCertificatNotification(params)
        .then((res) => {
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Send successfully',
          });
          setUpdateCert(!updateCert)
        })
        .catch((err) => console.log('err', err));
  };

  const handleDeleteCertificate = async (id, intake, certId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    })
      .then((result) => {
        if (result.isConfirmed) {
          const data = {
            student_id: id,
            intake_id: intake,
            cert_template_id: certId
          };
          deleteGenerateCertificate(data).then(res => {
            Swal.fire("Success", res.data.message, "success")
            setUpdateCert(!updateCert)
          }).catch(err => {
            console.error("error :", err)
            Swal.fire("Error", err.response.data.message, "error")
          })
        }
      })
  };
  // this function is going to update the status of the student to In-Progress
  // it takes only the studentId and the intakeId as the parameters which is passed on button click
  const handleUpdateProgressStatus = async (values) => {

    const { reasonDesc, sendEmail } = values;
    try {
      if (enrollmentData.studentId !== 0 && enrollmentData.intakeId !== 0) {

        const progress_status = await updateStudentProgress(reasonDesc,sendEmail,enrollmentData);
        if (progress_status.status == 200) {
          setDialogOpen(false);
          setStudentProgressId(enrollmentData.studentId);
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: 'Status updated to In-Progress successfully.',
          });
        }
      }
    }
    catch (error) {
      console.log("error", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Error in setting status to In Progress. Please try again.',
      });
    }
  }

  const dataToRender = () => {
    // console.log("data to render ---> ", status);
    let updatedData = [];
    let allData = studentList;
    if (search.length) {
      let tempStudent = allData.filter((item) => {
        let includes =
          item.first_name &&
          item.first_name
            .toString()
            .toLowerCase()
            .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempEmail = allData.filter((item) => {
        let includes = item.email
          .toString()
          .toLowerCase()
          .includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let tempStudentNo = allData.filter((item) => {
        let includes = item.student_crm_id
          ?.toString()
          ?.toLowerCase()
          ?.includes(search.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });
      let data = [...tempStudent, ...tempEmail, ...tempStudentNo];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (status.arr.length) {
      let tempStatus = updatedData;
      let tempResult = tempStatus.filter((item) => {
        const startsWith = status.arr.find(function (post, index) {
          if (
            post.toString().toLowerCase() ===
              item.status.toString().toLowerCase()
              ? item.status.toString().toLowerCase()
              : ''
          )
            return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempResult;
    }

    if (attendanceHealth.arr.length) {
      let tempStatus = updatedData;
      let tempResult = tempStatus.filter((item) => {
        const startsWith = attendanceHealth.arr.find(function (post, index) {
          if (
            post.toString().toLowerCase() ==
            item.attendanceStatus.toString().toLowerCase() &&
            item.attendanceStatus.toString().toLowerCase()
          )
            return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempResult;
    }

    if (coursestatus.arr.length) {
      let tempStatus = updatedData;
      let tempResult = tempStatus.filter((item) => {
        const startsWith = coursestatus.arr.find(function (post, index) {
          if (
            post.toString().toLowerCase() ==
            item.subject_status.toString().toLowerCase() &&
            item.subject_status.toString().toLowerCase()
          )
            return item;
        });
        if (startsWith) {
          return startsWith;
        } else return null;
      });

      updatedData = tempResult;
    }

    if (
      search.length ||
      status.arr.length ||
      attendanceHealth.arr.length ||
      coursestatus.arr.length
    ) {
      return updatedData;
    } else {
      return studentList;
    }
  };

  useEffect(() => {
    console.log("================================",dataToRender());
  },[search,status,attendanceHealth,coursestatus])

  useEffect(() => {
    setBulkPdfDownloading(true);
    const data = dataToRender();
    var filteredIds = [];
    const filterArray = data?.map((std) => {
      filteredIds.push(std?.id);
    });
    const Values = {
      intake_id: id,
      exportStatus: 'true',
      is_student: filteredIds.length ? 1 : '',
      students: filteredIds?.length ? filteredIds : '',
    };
    getCourseGradebook(Values).then((res) => {
      setDisclaimer(res?.data?.getCourse?.st_disclaimer)
      const details = res?.data?.result;
      setBrandOverride(res?.data?.getCourse?.override_brand)
      setIsBrandOverride(res?.data?.getCourse?.override_sor_brand != 0)
      const courseData = res?.data?.getCourse;
      setCourseDetail(courseData);
      let assessments = details?.map((student) => ({
        ...student,
        assessments: student?.assessmentArr?.map((assessment) => ({
          id: student?.student_id,
          name: assessment?.assessment_name,
          weight: assessment?.grant_exemption
            ? 'Exempted'
            : assessment.ass_weighting,
          result: assessment?.grant_exemption
            ? 'Exempted'
            : assessment?.submited == '0' ||
              moment.unix(assessment?.close_date_time) > moment.unix(new Date())
              ? 'N/A'
              : assessment.pass_status == 'N/A'
                ? 'N/A'
                : assessment.pass_status
                  ? assessment.pass_status
                  : '-',
          grade: assessment?.grant_exemption
            ? 'Exempted'
            : assessment?.submited == '0' ||
              moment.unix(assessment?.close_date_time) > moment.unix(new Date())
              ? 'N/A'
              : assessment?.grading_type == 3
                ? `${assessment?.ass_mark}/${assessment?.ass_total_mark}`
                : assessment?.gradeText
                  ? assessment?.gradeText
                  : 'N/A',
          isExclude: assessment?.isExclude,
          final_status: student?.pass_status,
          final_grade:
            student?.grading_type == 0
              ? Math.round(student?.scoreRecieved) + '%'
              : student?.grading_type == 3
                ? `${student?.getAssessmentCalculate_sum_ass_mark}/${student?.getAssessmentCalculate_sum_ass_total_mark}`
                : student?.gradeText,
        })),
        studentDetails: {
          course_name: courseData?.name,
          Student_name: student.full_name,
          ID_number: student?.number,
          Student_number: student?.student_crm_id
            ? student?.student_crm_id
            : student?.student_num,
          start: courseData?.start
            ? courseData.start?.toString().length == 10
              ? moment.unix(courseData?.start).format('DD MMMM YYYY')
              : moment(courseData?.start).format('DD MMMM YYYY')
            : '',
          end: courseData?.end
            ? courseData.end?.toString().length == 10
              ? moment.unix(courseData?.end).format('DD MMMM YYYY')
              : moment(courseData?.end).format('DD MMMM YYYY')
            : '',
          intake:
            courseData?.number && courseData?.year
              ? `${courseData?.number}-${courseData?.year}`
              : '',
          learning_method: courseData?.type
            ? RenderLearningMethodGradebook(courseData?.type).text
            : '',
        },
      }));
      setPDFsData(assessments);
      setBulkPdfDownloading(false);
    });
  }, [status, attendanceHealth, coursestatus]);

  const GenerateCertificateDisabled = (row) => {
    var disable = true;
    var tooltip = "";
    if (row.type == "selfplaced") {
      if (row.subject_status == "FINISHED") {
        disable = false;
      } else {
        tooltip = "Course should be finished to generate certificate.";
      }
    } else {
      disable = false;
    }
    return { disable: disable, tooltip: tooltip }
  }

  const handlepdfData = (subTab) => {
    setPdfDownloading(true);
    const swalPro = Swal.mixin({
      title: 'Downloading PDF',
      text: 'Please wait...',
      icon: 'info',
      allowOutsideClick: false,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    swalPro.fire();
    let pdfDetails =
      pdfsData?.length &&
      pdfsData?.find((student) => student?.student_id == subTab);
    let brand = {};
    const data = {
      id: [JSON.stringify(subTab)],
    };
    if(brand_override && isBrandOverride){

      GetBrandSettingDetail(brand_override)
      .then((res) => {
        if (pdfDetails) {
          let modifiedSoR =
            pdfDetails.assessments?.length &&
            pdfDetails.assessments
              ?.map((item) => ({
                ...item,
                final_grade: !isShowFinal ? '' : item?.final_grade,
                final_status: !isShowFinal ? '' : item?.final_status,
              }))
              .filter((assessment) => !isExcludeGrade || !assessment.isExclude);
              const uniqueId = 'st_disclaimer_' + Math.random() * 100
              // let kjs = `<div id='${uniqueId}' style='height: 545px;'>
              let kjs = `<div id='${uniqueId}' style='max-width: fit-content;'>
              ${disclaimer}
              </div>`
              kjs = parseHTML(kjs)
              // document.body.appendChild(kjs[0])
              document.querySelector("body").appendChild(kjs[0])
              let divHeight = document.getElementById(uniqueId).clientHeight
              divHeight = divHeight < 670 ? divHeight : 670
            
              let divMinHeight = Math.floor(document.getElementById(uniqueId).innerText.toString().length/200)*18
              let divWidth = document.getElementById(uniqueId).clientWidth
              
              divWidth = document.getElementById(uniqueId).innerText.toString().length * 5;
              html2canvas(document.getElementById(uniqueId)).then(function(canvas) {
              var dataURL = canvas.toDataURL();
              return dataURL
            }).then(noteImage => {
                        pdf(
            <LetterheadIntakePDF
              detailData={modifiedSoR}
                            studentAndCourseDetails={pdfDetails?.studentDetails}
              brandData={res.data.brand_setting}
              programDetails={res.data?.program}
              noteImage={disclaimer ? noteImage : null}
              divMinHeight = {divMinHeight}
              divHeight={divHeight+300}
              divWidth={divWidth > 565 ? 565 : divWidth}
              divOrgHeight={divHeight}
              divTopPosition = {1197 - divHeight - 130}
            />
          )
            .toBlob()
            .then((response) => {
              const blobURL = window.URL.createObjectURL(response);
              const a = document.createElement('a');
              a.href = blobURL;
              a.download = `${pdfDetails?.studentDetails?.Student_number}_${pdfDetails?.studentDetails.Student_name
                }_${pdfDetails?.studentDetails?.course_name}_SOR_${brandVisuals?.brand_name
                }_${moment(new Date()).format('DD MM YY')}.pdf`; // Set the desired filename for the download
              a.click();

              URL.revokeObjectURL(blobURL);
              setStudentID('');
              setIsChecked(false);
              setIsShowFinal(false);
              setIsExcludeGrade(false);
              swalPro.close();
              $('#downloadSoRModal').modal('hide');
              setPdfDownloading(false);
              Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Download Successfully',
              });
            })
            .catch((err) => {
              setPdfDownloading(false);
              setStudentID('');
              setIsChecked(false);
              setIsShowFinal(false);
              setIsExcludeGrade(false);
              swalPro.close();
              $('#downloadSoRModal').modal('hide');
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: err,
              });
            });
            });
                  }
      }).catch((err) => console.log(err));
    }else{

      getStudentBrand(data)
        .then((res) => {
          brand = res?.data;
          if (pdfDetails) {
            let modifiedSoR =
              pdfDetails.assessments?.length &&
              pdfDetails.assessments
                ?.map((item) => ({
                  ...item,
                  final_grade: !isShowFinal ? '' : item?.final_grade,
                  final_status: !isShowFinal ? '' : item?.final_status,
                }))
                .filter((assessment) => !isExcludeGrade || !assessment.isExclude);
                const uniqueId = 'st_disclaimer_' + Math.random() * 100
                // let kjs = `<div id='${uniqueId}' style='height: 545px;'>
                let kjs = `<div id='${uniqueId}' style='max-width: fit-content;'>
                ${disclaimer}
                </div>`
                kjs = parseHTML(kjs)
                // document.body.appendChild(kjs[0])
                document.querySelector("body").appendChild(kjs[0])
                let divHeight = document.getElementById(uniqueId).clientHeight
                divHeight = divHeight < 670 ? divHeight : 670
              
                let divMinHeight = Math.floor(document.getElementById(uniqueId).innerText.toString().length/200)*18
                let divWidth = document.getElementById(uniqueId).clientWidth
                
                divWidth = document.getElementById(uniqueId).innerText.toString().length * 5;
                html2canvas(document.getElementById(uniqueId)).then(function(canvas) {
                var dataURL = canvas.toDataURL();
                return dataURL
              }).then(noteImage => {
                          pdf(
              <LetterheadIntakePDF
                detailData={modifiedSoR}
                              studentAndCourseDetails={pdfDetails?.studentDetails}
                brandData={res.data?.getBrandDetail}
                programDetails={res.data?.program}
                noteImage={disclaimer ? noteImage : null}
                divMinHeight = {divMinHeight}
                divHeight={divHeight+300}
                divWidth={divWidth > 565 ? 565 : divWidth}
                divOrgHeight={divHeight}
                divTopPosition = {1197 - divHeight - 130}
              />
            )
              .toBlob()
              .then((response) => {
                const blobURL = window.URL.createObjectURL(response);
                const a = document.createElement('a');
                a.href = blobURL;
                a.download = `${pdfDetails?.studentDetails?.Student_number}_${pdfDetails?.studentDetails.Student_name
                  }_${pdfDetails?.studentDetails?.course_name}_SOR_${brandVisuals?.brand_name
                  }_${moment(new Date()).format('DD MM YY')}.pdf`; // Set the desired filename for the download
                a.click();
  
                URL.revokeObjectURL(blobURL);
                setStudentID('');
                setIsChecked(false);
                setIsShowFinal(false);
                setIsExcludeGrade(false);
                swalPro.close();
                $('#downloadSoRModal').modal('hide');
                setPdfDownloading(false);
                Swal.fire({
                  icon: 'success',
                  title: 'Success',
                  text: 'Download Successfully',
                });
              })
              .catch((err) => {
                setPdfDownloading(false);
                setStudentID('');
                setIsChecked(false);
                setIsShowFinal(false);
                setIsExcludeGrade(false);
                swalPro.close();
                $('#downloadSoRModal').modal('hide');
                Swal.fire({
                  icon: 'error',
                  title: 'Error',
                  text: err,
                });
              });
              });
                    }
      })
        .catch((err) => console.log(err));
    }
              
      };


  const handleSetToInProgressDialog = (rowData) => {

    const { student_id, intake_real, first_name,last_name } = rowData && rowData;

    setEnrollmentData({
      studentId: student_id,
      intakeId: intake_real,
      studentFirstName: first_name,
      studentLastName: last_name
    });

    setDialogOpen(true);
  }

  const columns = useMemo(
    () => [
      {
        name: 'Student',
        selector: 'first_name',
        // selector: row => `${row.first_name} ${row.last_name}`,
        sortable: true,
        cell: (row, index) => (
          <div className="assigned-title-block-new">
            <div className="assigned-title-itm">
              <span
                className={
                  'assigned-title-blk  name-icon cat-dark-red ' +
                  ColorRender('student support')
                }
              >
                {row.photo ? (
                  <img
                    src={`${IMAGE_URL}/${row.photo
                      .replaceAll('/home/myaie/public_html/', '')
                      .replace('public/', '')}`}
                    alt="AIE"
                  />
                ) : (
                  InitialRender([row.first_name, row.last_name])
                )}
                <span
                  className={`profile-box-2-status ${row.activity_status
                      ? row.activity_status.toLowerCase() == 'online'
                        ? 'Online'
                        : row.activity_status.toLowerCase() == 'away'
                          ? 'Away'
                          : 'Offline'
                      : 'Offline'
                    }`}
                >
                  <i className="fas fa-circle"></i>
                </span>
                <Hover
                  firstName={row.first_name}
                  lastName={row.last_name}
                  photo={row.photo}
                  email={row.email}
                  mobile={row.mobile}
                  right={true}
                  role={'student support'}
                  status={row.status}
                  activity_status={row.activity_status}
                  showNumber={true}
                  number={row.student_crm_id}
                />
              </span>
              <p>
                <Link
                  className="as-text-blue curser feature-name"
                  key={index}
                  to={{
                    pathname: `/studentAdministration/students/open/${row.student_id}/general`,
                  }}
                >
                  <span
                    className="textLimit100"
                    title={`${row.first_name} ${row.last_name}`}
                  >
                    {' '}
                    {`${row.first_name} ${row.last_name}`}
                  </span>
                </Link>
              </p>
            </div>
          </div>
        ),
      },
      {
        name: 'myAIE Email',
        selector: 'email',
        // reorder: true,
        sortable: true,
        cell: (row) => (
          <span
            className="feature-name"
            title={
              row.microsoft_status == 1 && row.microsoft_email
                ? row.microsoft_email
                : '-'
            }
          >
            <span className="textLimit100">
              {row.microsoft_status == 1 && row.microsoft_email
                ? row.microsoft_email
                : '-'}
            </span>
          </span>
        ),
      },
      {
        name: 'Email',
        selector: 'email',
        // reorder: true,
        sortable: true,
        cell: (row) => (
          <span className="feature-name" title={row.email}>
            <span className="textLimit100">{row.email}</span>
          </span>
        ),
      },
      {
        name: 'Student Number',
        selector: 'student_crm_id',
        // reorder: true,
        sortable: true,
        cell: (row) => (
          <p>
            <span>{row?.student_crm_id ? row?.student_crm_id : '-'}</span>
          </p>
        ),
      },
      {
        name: 'Attendance Health',
        selector: 'attendanceStatus',
        // reorder: true,
        sortable: true,
        cell: (row) =>
          row.attendanceStatus
            ? RenderAttendanceHealth(row.attendanceStatus).html
            : '-',
      },
      {
        name: 'Status',
        selector: 'status',
        // reorder: true,
        sortable: true,
        cell: (row) =>
          row.status ? RenderProgrammeStudentStatus(row.status).html : '-',
      },
      props.gradingStandard != 2 && {
        name: "Grade",
        selector: "grade",
        // reorder: true,
        sortable: false,
        cell: (row) => {
          return row.calculateGradeOfStudentViaCourse?.pass_status == "N/A"
            ? "N/A"
            : row.grading_type == 0
              ? Math.round(row.calculateGradeOfStudentViaCourse.scoreRecieved) + "%"
              : row.calculateGradeOfStudentViaCourse && row.calculateGradeOfStudentViaCourse?.getAssessmentCalculate_sum_ass_mark && row.calculateGradeOfStudentViaCourse?.getAssessmentCalculate_sum_ass_total_mark && row.grading_type == 3
                ? `${row.calculateGradeOfStudentViaCourse?.getAssessmentCalculate_sum_ass_mark}/${row.calculateGradeOfStudentViaCourse?.getAssessmentCalculate_sum_ass_total_mark}`
                : row.calculateGradeOfStudentViaCourse && row.calculateGradeOfStudentViaCourse?.gradeText
                  ? row.calculateGradeOfStudentViaCourse?.gradeText
                  : "-"
        }
      },
      props.gradingStandard != 2 && {
        name: "Result",
        selector: "status",
        sortable: true,
        cell: (row) =>
          row.calculateGradeOfStudentViaCourse?.pass_status == 'N/A'
            ? 'N/A'
            : row.calculateGradeOfStudentViaCourse &&
              row.calculateGradeOfStudentViaCourse?.pass_status
              ? RenderQuizAttemptResultStatus(
                row.calculateGradeOfStudentViaCourse?.pass_status
              ).html
              : '-',
      },
      {
        name: 'Course Status',
        selector: 'subject_status',
        // reorder: true,
        sortable: true,
        cell: (row) => (
          // row.subject_status ? row.subject_status : "-"
          <>
            <span
              className={
                'mentored-subject-status '
                // (row.subject_status == "PENDING-SIGN OFF" ? "d-block" : "")
              }
            >
              <span className="sing_ard_badge">
                {row.subject_status == 'PENDING-SIGN OFF' ? (
                  <img
                    src={BadgeIcon}
                    alt="PENDING-SIGN OFF"
                    style={{ height: 24 }}
                  ></img>
                ) : row.subject_status.toUpperCase() == 'NOT STARTED' ? (
                  <img
                    src={NotStarted}
                    alt="NOT STARTED"
                    style={{ height: 24 }}
                  ></img>
                ) : row.subject_status == 'IN-PROGRESS' ? (
                  <img
                    src={InProgress}
                    alt="IN-PROGRESS"
                    style={{ height: 24 }}
                  ></img>
                ) : row.subject_status == 'FINISHED' ? (
                  <img
                    src={`https://www.myaie.ac/theme/img/icon-complete.png`}
                    alt="FINISHED"
                    style={{ height: 24 }}
                  ></img>
                ) : row.subject_status == 'Pending Review' ? (
                  <img
                    src={PendingReview}
                    alt="Pending Review"
                    style={{ height: 24 }}
                  ></img>
                ) : (
                  <></>
                )}
              </span>
              <span title={row.subject_status}>
                {row && row.subject_status
                  ? ColorCode(row.subject_status.toUpperCase())
                  : '-'}
              </span>
            </span>
            <p className="sing_ard_badge">
              {row.completed_date && (
                <>
                  {row.subject_status == 'Pending-sign off' ||
                    row.subject_status == 'Pending Review' ||
                    row.subject_status == 'PENDING-SIGN OFF' ||
                    row.subject_status == 'Pending-sigh off'
                    ? 'Requested on'
                    : row.subject_status == 'FINISHED'
                      ? 'Completed on'
                      : ''}
                  {` ${moment
                    .unix(Number(row.completed_date))
                    .format("DD MMM 'YY")}`}
                  <br /> at{' '}
                  {moment
                    .unix(Number(row.completed_date))
                    .format(TABLE_TIME_FORMAT)}
                </>
              )}
              {/* {console.log("row.subject_status", row.subject_status)} */}
              {row.subject_status &&
                (row.subject_status == 'Pending-sign off' ||
                  row.subject_status == 'Pending Review' ||
                  row.subject_status == 'PENDING-SIGN OFF' ||
                  row.subject_status == 'Pending-sigh off') && (
                <>
                  <button
                    className="btn btn-save btn-success ml-2"
                    type="button"
                    title="Sign-Off"
                    data-toggle="modal"
                    data-target="#sign-off"
                    onClick={() => {
                      setSignOffData(row);
                    }}
                  >
                    Sign-Off
                </button>
                <button
                className="btn btn-save btn-success ml-2"
                type="button"
                title="Set To in Progress"
                data-toggle="modal"
                data-target="#set-to-in-progress"
                onClick={() => handleSetToInProgressDialog(row)}
                  >
                    Set To in Progress
                  </button>
                  </>
            
                
                )}
            </p>
          </>
        ),
      },
      {
        name: "Progress",
        selector: "lecturer_name",
        // sortable: true,
        // sortField: "lecturer",
        cell: (row) => (
          <>
          <div className="d-flex">
          <span title="Progress of learning material" className="mr-2"><i className="fal fa-books as-text-blue mr-1"></i>{row?.progress?.learning_material_percentage}%</span>
          <span title="Progress of assessments"><i className="fal fa-ballot-check as-text-blue mr-1"></i>{row?.progress?.assessment_percentage}%</span>
          </div>
          </>
        ),
      },

      {
        name: 'Dates',
        selector: 'subject_Dates',
        // reorder: true,
        sortable: true,
        cell: (row) =>
          row.subject_Dates ? (
            <span className="feature-name">
              <span className="textLimit100">
                {row.subject_status.toUpperCase() === 'NOT STARTED' &&
                  row.type === 'selfplaced'
                  ? 'N/A'
                  : row.subject_Dates}
              </span>
            </span>
          ) : (
            '-'
          ),
      },
      props.courseDetails?.enable_certificate && {
        name: 'Certificate Status',
        selector: 'certificate_status',
        // reorder: true,
        sortable: false,
        cell: (row) => {
          const studentCertificateStatus = studentCertificateStatusList.find(
            (std) => std.student_id === row.id
          );
          return (
            <span className="feature-name">
              <span className="textLimit100">
                {studentCertificateStatus?.cert_status === 'generated'
                  ? RenderCertificateStatus(
                    studentCertificateStatus?.cert_status
                  ).html
                  : RenderCertificateStatus('pending').html}
              </span>
            </span>
          );
        },
      },
      // {
      //   name: "Course Status",s
      //   selector: "CourseStatus",
      //   // reorder: true,
      //   sortable: true,
      //   cell: (row) =>row.courseStatus ? RenderCourseStatus(row.courseStatus).html : "-"
      // },
      {
        name: 'Actions',
        selector: 'Action',
        // sortable: true,
        cell: (row) => (
          <div className="assessment-08 btn-dropdown-grp">
            <div className="as-buttons">
              <Link
                to={{
                  pathname: `/studentAdministration/students/open/${row.student_id}/general`,
                }}
              >
                <button className="btn btn-primary rounded-circle" title="Open">
                  <i className="fal fa-folder-open"></i>
                </button>
              </Link>
              <div className="dropdown btn-dropdown-item">
                <button
                  className="btn btn-primary rounded-circle dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fal fa-ellipsis-h-alt"></i>
                </button>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton"
                >
                  <PermissionsGate
                    scopes={['cedit']}
                    errorProps={{ disabled: true }}
                  >
                    <button
                      className="btn btn-primary rounded-circle"
                      title="Create Notification"
                      onClick={() =>
                        history.push(
                          `/courseAdministration/coursesdetails/${props.userId}/notifications/create/student/${row.student_id}`
                        )
                      }
                    >
                      <i className="fa fa-paper-plane"></i>
                    </button>
                  </PermissionsGate>
                  <button
                    className="btn btn-primary rounded-circle"
                    onClick={() =>
                      exportFile(
                        'csv',
                        'Student Statement',
                        row.student_id,
                        'courseStudent',
                        id
                      )
                    }
                    title="Statement"
                  >
                    <i className="fal fa-file-excel icon"></i>
                  </button>
                  <Link
                    to={{
                      pathname:
                        'https://student.myaie.ac/viewStudent?email=' +
                        row.email +
                        '&password=' +
                        row.password,
                    }}
                    target="_blank"
                  >
                    <button
                      className="btn btn-sm btn-primary rounded-circle"
                      title="View"
                      data-toggle="modal"
                      data-target="#notifications"
                    >
                      <i className="fal fa-eye"></i>
                    </button>
                  </Link>
                  {props.courseDetails.grading_standard != 2 &&
                    <Link
                      to={{
                        pathname: `/courseAdministration/coursesdetail/${id}/students/grades/${row.id
                          }?courseName=${props?.courseName}&stdName=${row?.first_name + ' ' + row?.last_name
                          }`,
                      }}
                    >
                      <button
                        className="btn btn-sm btn-primary rounded-circle"
                        title={
                          row?.grant_exemption == 1
                            ? 'Student Exempted from Course'
                            : 'Student Grade'
                        }
                        disabled={row?.grant_exemption == 1 ? true : false}
                      >
                        <i className="fal fa-bookmark"></i>
                      </button>
                    </Link>
                  }
                  {props.gradingStandard != 2 && courseDetail.subject_type == "shortcourse" || !courseDetail.qualification ?
                    <button
                      className="btn btn-primary rounded-circle"
                      data-toggle="modal"
                      data-target="#downloadSoRModal"
                      onClick={() => {
                        setIsShowFinal(courseDetail?.show_final_results)
                        setIsExcludeGrade(courseDetail?.show_empty_grades)
                        setStudentID(row?.id)
                      }}
                      disabled={bulkPdfDownloading}
                      title="Download SoR PDF"
                    >
                      {bulkPdfDownloading || pdfDownloading ? (
                        <i className="fas fa-cog fa-spin download-SoR-icon"></i>
                      ) : (
                        <i className="fal fa-download download-SoR-icon"></i>
                      )}
                    </button> : ""
                  }
                  {studentCertificateStatusList.find(
                    (std) => std.student_id === row.id
                  )?.cert_status === 'pending' ? (

                    props.courseDetails?.enable_certificate ? <PermissionsGate scopes={['cstudentcerteficateadd']} errorProps={{ disabled: true }}>
                      <button
                        className="btn btn-sm btn-primary rounded-circle"
                        title="Generate Certificate"
                        onClick={() => {
                          handleCert_Generation(row.id, row);
                        }}
                        disabled={GenerateCertificateDisabled(row).disable}
                        tooltip={GenerateCertificateDisabled(row).tooltip}
                      >
                        <i className="fal fa-certificate"></i>
                      </button>
                    </PermissionsGate> : ""

                  ) : (
                    studentCertificateStatusList.find(
                      (std) => std.student_id === row.id
                    )?.cert_status === 'generated' && (
                      props.courseDetails?.enable_certificate ? <>
                        <PermissionsGate scopes={['cstudentcerteficateview']} errorProps={{ disabled: true }}>
                          <button
                            className="btn btn-sm btn-primary rounded-circle"
                            title="View Certificate"
                            onClick={() => handleViewCertificate(row.id)}
                          >
                            <i className="fal fa-file-certificate"></i>
                          </button>
                        </PermissionsGate>
                        <button
                          className="btn btn-sm btn-primary rounded-circle"
                          title="Send Certificate Notification"
                          onClick={() => handleNotifyStudent(row.id)}
                        >
                          <i className="fal fa-bell"></i>
                        </button>
                        <PermissionsGate scopes={['cstudentcerteficatedelete']} errorProps={{ disabled: true }}>
                        <button
                          className="btn btn-sm btn-danger rounded-circle"
                          title="Delete Certificate"
                          onClick={() => handleDeleteCertificate(row.id, row.intake_real, row.cert_template_id)}
                        >
                          <i className="fal fa-trash-alt"></i>
                        </button>
                        </PermissionsGate>
                      </> : ""
                    )
                  )}

                  <Link
                    to={`/studentAdministration/students/open/${row.student_id}/notes/${id}`}
                  >
                    <button
                      className="btn btn-sm btn-primary rounded-circle"
                      title="Notes"
                    >
                      <i className="fas fa-bars"></i>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ),
      },
    ].filter(item => item),
    [bulkPdfDownloading, studentCertificateStatusList]
  );

  const generateSinglePDF = async (data, studentDetail,i) => {
    let brandDetail;
    let brand = [];
    const details = {
      id: allStudents,
    };
    if(brand_override && isBrandOverride){

      brandDetail = await GetBrandSettingDetail(brand_override)
      brand = brandDetail.data.brand_setting
    }else{
      brandDetail = await getStudentBrand(details)
      brand = brandDetail.data.getBrandDetail;
    }
    let matchingBrand = brand
    let modifiedSoR = data
      ?.map((item) => ({
        ...item,
        final_grade: !isShowFinal ? '' : item?.final_grade,
        final_status: !isShowFinal ? '' : item?.final_status,
      }))
      .filter((assessment) => !isExcludeGrade || !assessment.isExclude);
      const uniqueId = 'st_disclaimer_' + Math.random() * 100
              // let kjs = `<div id='${uniqueId}' style='height: 545px;'>
              let kjs = `<div id='${uniqueId}' style='max-width: fit-content;'>
              ${disclaimer}
              </div>`
              kjs = parseHTML(kjs)
              // document.body.appendChild(kjs[0])
              document.querySelector("body").appendChild(kjs[0])
              let divHeight = document.getElementById(uniqueId).clientHeight
              divHeight = divHeight < 670 ? divHeight : 670
            
              let divMinHeight = Math.floor(document.getElementById(uniqueId).innerText.toString().length/200)*18
              let divWidth = document.getElementById(uniqueId).clientWidth
              
              divWidth = document.getElementById(uniqueId).innerText.toString().length * 5;
              const noteImage = await html2canvas(document.getElementById(uniqueId)).then(function(canvas) {
              var dataURL = canvas.toDataURL();
              return dataURL
              })
              // .then(async noteImage => {

                var blob = await pdf(
                  <LetterheadIntakePDF
                  detailData={modifiedSoR}
                  studentAndCourseDetails={studentDetail}
                  brandData={brand_override && isBrandOverride ? matchingBrand : matchingBrand[i]}
                  noteImage={disclaimer ? noteImage : null}
              divMinHeight = {divMinHeight}
              divHeight={divHeight+300}
              divWidth={divWidth > 565 ? 565 : divWidth}
              divOrgHeight={divHeight}
              divTopPosition = {1197 - divHeight - 130}
                  />
                ).toBlob();
                return blob;
              // }).catch(error => console.log(error))

  };

  const generatePDFs = async () => {
    const zip = new JSZip();
    for (let i = 0; i < pdfsData.length; i++) {
      const pdfBlob = await generateSinglePDF(
        pdfsData[i].assessments,
        pdfsData[i]?.studentDetails,
        i
      );
      zip
        // .folder(`${brandVisuals?.brand_name}_${pdfsData[i]?.studentDetails?.program_name}_SOR_${moment(new Date()).format("DD MM YY")}`)
        .file(
          `${pdfsData[i]?.studentDetails?.Student_number}_${pdfsData[i]?.studentDetails?.Student_name
          }_${pdfsData[i]?.studentDetails?.course_name}_SOR_${brandVisuals?.brand_name
          }_${moment(new Date()).format('DD MM YY')}.pdf`,
          pdfBlob
        );
    }
    return zip.generateAsync({ type: 'blob' });
  };

  const handleDownload = async () => {
    // Display SweetAlert modal with progress bar
    const swalProgress = Swal.mixin({
      title: 'Creating ZIP',
      text: 'Please wait while we create the ZIP file...',
      icon: 'info',
      allowOutsideClick: false,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });

    swalProgress.fire();

    try {
      const zipBlob = await generatePDFs();
      const fileName = `${brandVisuals?.brand_name}_${pdfsData[0]?.studentDetails?.course_name
        }_SOR_${moment(new Date()).format('DD MM YY')}${status.arr?.length ? `_${status.arr}` : ''
        }${attendanceHealth.arr?.length ? `_${attendanceHealth.arr}` : ''}${coursestatus.arr?.length ? `_${coursestatus.arr}` : ''
        }.zip`;

      // Hide the progress bar and show success icon
      swalProgress.close();
      setBulkDownload(false);
      setStudentID('');
      setIsChecked(false);
      setIsShowFinal(false);
      setIsExcludeGrade(false);
      $('#downloadSoRModal').modal('hide');
      Swal.fire({
        title: 'ZIP Created',
        text: 'ZIP file has been created successfully!',
        icon: 'success',
        timer: 2000, // Automatically close after 2 seconds
        showCancelButton: false,
        showConfirmButton: false,
      });

      // Trigger download
      saveAs(zipBlob, fileName);
    } catch (error) {
      console.log(error);
      // Hide the progress bar and show error icon
      swalProgress.close();
      setBulkDownload(false);
      setStudentID('');
      setIsChecked(false);
      setIsShowFinal(false);
      setIsExcludeGrade(false);
      $('#downloadSoRModal').modal('hide');
      Swal.fire({
        title: 'Error',
        text: 'An error occurred while creating the ZIP file.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };

  const approveSend = () => {
    const dataParam = {
      table_id: signOffData?.table_id,
      id: signOffData?.student_id,
      intake_real: signOffData?.intake_real,
      feedback: notificationFields.subject,
      status: 3,
    };

    InsertSignOff(dataParam)
      .then((res) => {
        if (res.status == 200) {
          $('#sign-off').modal('hide');
          setNotificationFields(notificationInitialState);
          setValidation(false);
          Swal.fire('Success!', 'Successfully approved.', 'success').then(
            () => {
              getSutdentData();
            }
          );
        }
      })
      .catch((err) => console.error(err));
  };

  const declineSend = () => {
    const dataParam = {
      table_id: signOffData?.table_id,
      id: signOffData?.student_id,
      intake_real: signOffData?.intake_real,
      feedback: notificationFields.subject,
      status: 4,
    };

    InsertSignOff(dataParam)
      .then((res) => {
        if (res.status == 200) {
          $('#sign-off').modal('hide');
          setNotificationFields(notificationInitialState);
          setValidation(false);
          Swal.fire('Success!', 'Successfully declined.', 'success').then(
            () => {
              getSutdentData();
            }
          );
        }
      })
      .catch((err) => console.error(err));
  };

  const exportData = (fileType, fileName) => {
    let data = dataToRender();
    let header = []
    if (props.gradingStandard != 2) {
      header = [
        "Student",
        "myAIE Email",
        "Email",
        "Student Number",
        "Attendance Health",
        "Status",
        "Grade",
        "Result",
        "Course Status",
        "Dates",
      ];
    } else {
      header = [
        "Student",
        "myAIE Email",
        "Email",
        "Student Number",
        "Attendance Health",
        "Status",
        // "Grade",
        // "Result",
        "Course Status",
        "Dates",
      ];
    }
    if (props.courseDetails?.enable_certificate) {
      header.push("Certificate Status")
    }

    data = data?.map((row) => {
      const studentCertificateStatus = studentCertificateStatusList.find(
        (std) => std.student_id === row.id
      );
      return ({
      Student: row?.first_name + ' ' + row?.last_name,
      'myAIE Email':
        row.microsoft_status == 1 && row.microsoft_email
          ? row.microsoft_email
          : '-',
      Email: row.email ? row.email : 'NA',
      'Attendance Health': row.attendanceStatus
        ? RenderAttendanceHealth(row.attendanceStatus).text
        : '-',
      'Student Number': row.student_crm_id ? row.student_crm_id : '-',
      Status: row?.status
        ? RenderProgrammeStudentStatus(row?.status).text
        : '-',
      Grade:
        row.calculateGradeOfStudentViaCourse?.pass_status == "N/A"
          ? "N/A"
          : row.grading_type == 0
            ? Math.round(row.calculateGradeOfStudentViaCourse.scoreRecieved) + "%"
            : row.calculateGradeOfStudentViaCourse && row.calculateGradeOfStudentViaCourse?.getAssessmentCalculate_sum_ass_mark && row.calculateGradeOfStudentViaCourse?.getAssessmentCalculate_sum_ass_total_mark && row.grading_type == 3
              ? `${row.calculateGradeOfStudentViaCourse?.getAssessmentCalculate_sum_ass_mark}/${row.calculateGradeOfStudentViaCourse?.getAssessmentCalculate_sum_ass_total_mark}`
              : row.calculateGradeOfStudentViaCourse && row.calculateGradeOfStudentViaCourse?.gradeText
                ? (row.calculateGradeOfStudentViaCourse?.gradeText)
                : "-",
      Result:
        row.calculateGradeOfStudentViaCourse?.pass_status == "N/A"
          ? "N/A"
          : row.calculateGradeOfStudentViaCourse && row.calculateGradeOfStudentViaCourse?.pass_status
            ? RenderQuizAttemptResultStatus(row.calculateGradeOfStudentViaCourse?.pass_status).text
            : "-",
      "Course Status": row.subject_status ? row.subject_status : "-",
      Dates: row.subject_Dates
        ? row.subject_status.toUpperCase() === 'NOT STARTED' &&
          row.type === 'selfplaced'
          ? 'N/A'
          : row.subject_Dates
        : '-',
        'Certificate Status': studentCertificateStatus?.cert_status === 'generated'
        ? RenderCertificateStatus('generated').text
        : RenderCertificateStatus('pending').text
    })
  });

    if (fileType === 'csv') {
      const csvString = Papa.unparse({ fields: header, data });
      const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8,' });

      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement('a');
      anchor.download = fileName;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = [
        'text/csv',
        anchor.download,
        anchor.href,
      ].join(':');
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 1000);
    } else if (fileType === 'xlsx') {
      const compatibleData = data.map((row) => {
        const obj = {};
        // console.log("row ---> ", row);
        // return;
        header.map((col, index) => {
          obj[col] = row[col];
        });
        return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
        header,
      });
      XLSX.utils.book_append_sheet(wb, ws1, 'React Table Data');
      XLSX.writeFile(wb, `${fileName}.xlsx`);

      // Returning false as downloading of file is already taken care of
      return false;
    }
    if (fileType === 'pdf') {
      const compatibleData = data.map((row) => {
        if (props.gradingStandard != 2) {
          const arr = [
            row.Student,
            row["myAIE Email"],
            row.Email,
            row["Student Number"],
            row["Attendance Health"],
            row.Status,
            row["Grade"],
            row["Result"],
            row["Course Status"],
            row["Dates"],
          ];
          if (props.courseDetails?.enable_certificate) {
            arr.push(row["Certificate Status"])
          }
          return arr
        } else {
          const arr = [
            row.Student,
            row["myAIE Email"],
            row.Email,
            row["Student Number"],
            row["Attendance Health"],
            row.Status,
            // row["Grade"],
            // row["Result"],
            row["Course Status"],
            row["Dates"],
          ];
          if (props.courseDetails?.enable_certificate) {
            arr.push(row["Certificate Status"])
          }
          return arr
        }
      });
      const doc = new JsPDF();
      doc.autoTable({
        head: [header],
        body: compatibleData,
        styles: {
          minCellHeight: 10,
          minCellWidth: 5,
          halign: 'left',
          // valign: "center",
          fontSize: 8,
        },
      });
      doc.save(`${fileName}.pdf`);

      return false;
    }
  };
  const handleToggle = () => {
    setIsChecked(!isChecked);
  };

  const handleShowFinalToggle = () => {
    setIsShowFinal(!isShowFinal);
  };

  const handleExcludeFinal = () => {
    setIsExcludeGrade(!isExcludeGrade);
  };

  return (
    <>
      <div className="my-tickets-info-list Tickets-main-wrap">
        <div className="custom-table-div filter-search-icon card card-table-custom days-overdue-cell-width">
          <div className="search-filter-div">
            <div className="search-filter-div-left">
              <div className="system-administration-table table-responsive">
                <div className="table-responsive-div">
                  <div
                    id="assessment-table-main_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div
                      id="assessment-table-main_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        <input
                          type="search"
                          className=""
                          placeholder="Search"
                          aria-controls="assessment-table-main"
                          onChange={handleSearchFilter}
                          value={search}
                        />
                      </label>
                      <div className="filter-eff filter-data-btn">
                        <button className="filter-buttons">
                          <i className="fal fa-filter"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="filter-button-group">
                  <div className="filter-scroll">
                    <div className={`filter-scroll-inner  filter-custom-new`}>
                      <Tablefilter
                        filterName="Status"
                        optionArr={filterData.status || []}
                        state={status}
                        setState={setStatus}
                        renderLabelFunction={RenderProgrammeStudentStatus}
                      />
                      <Tablefilter
                        filterName="Attendance Health"
                        optionArr={filterData.attendance || []}
                        state={attendanceHealth}
                        setState={setAttendanceHealth}
                        renderLabelFunction={RenderAttendanceHealth}
                      />

                      <Tablefilter
                        filterName="Course Status"
                        optionArr={filterData.cStatus || []}
                        state={coursestatus}
                        setState={setCourseStatus}
                        renderLabelFunction={RenderCourseSStatus}
                      />
                    </div>
                  </div>

                  <div className="reset-btn-group">
                    <div className="button-reset dropdown-comman">
                      <button
                        className="btn btn-primary"
                        onClick={resetFilter}
                        title="Reset"
                      >
                        <i className="fal fa-redo"></i>Reset
                      </button>
                    </div>
                    {props.gradingStandard != 2 && courseDetail.subject_type == "shortcourse" || !courseDetail.qualification ?
                      <div className="button-reset dropdown-comman">
                        <button
                          className="btn btn-primary"
                          title="Download SoR's"
                          data-toggle="modal"
                          data-target="#downloadSoRModal"
                          onClick={() => {
                            setIsShowFinal(courseDetail?.show_final_results)
                            setIsExcludeGrade(courseDetail?.show_empty_grades)
                            setBulkDownload(true)
                          }}
                          disabled={bulkPdfDownloading}
                        >
                          {bulkPdfDownloading ? (
                            <i className="fas fa-cog fa-spin"></i>
                          ) : (
                            <i className="fal fa-download"></i>
                          )}
                          Download SoR's
                        </button>
                      </div> : ""
                    }
                    <div className="files-export-group">
                      <button
                        type="button"
                        className="btn btn-files"
                        title="Export spreadsheet"
                        onClick={() => {
                          exportData('xlsx', 'Students');
                        }}
                      >
                        <i className="fal fa-file-excel icon"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-files"
                        title="Export CSV"
                        onClick={() => {
                          exportData('csv', 'Students');
                        }}
                      >
                        <i className="fal fa-file-csv icon"></i>
                      </button>
                      <button
                        type="button"
                        className="btn btn-files"
                        title="Export PDF"
                        onClick={() => {
                          exportData('pdf', 'Students');
                        }}
                      >
                        <i className="fal fa-file-pdf icon"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!isStudents && <SkeletonTicketList />}
          {isStudents && (
            <DataTable
              data={dataToRender()}
              columns={columns}
              pagination={true}
              defaultSortField="first_name"
              defaultSortAsc={true}
              paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
            />
          )}

          {/* <div>
            <button
              className="btn btn-sm btn-primary rounded-circle"
              title="Generate Certificate"
              onClick={() => {
                // getSutdentData();

                handleCert_Generation(12);
              }}
            >
              <i className="fal fa-certificate"></i>
            </button>
          </div> */}
        </div>
      </div>
      <div
        className="topic-add-modal modal fade"
        id="sign-off"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xl"
          role="document"
        >
          <div className="modal-content modal-border-update">
            <div className="modal-body p-0">
              <div className="modal-header modal-header-custom">
                <h5 className="modal-title">
                  <i className="fal fa-bars"></i>{' '}
                  <label>Requires Sign-Off</label>
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="t p-30px">
                <div className="topic-add-modal-content">
                  <div className="row center-label">
                    <div className="col-md-12">
                      <div className="form-group-blk mb-3">
                        <label>Feedback *</label>
                        <textarea
                          type="text"
                          rows={10}
                          className="form-control form-control-aatch-file"
                          value={notificationFields.subject}
                          onChange={(e) => {
                            setNotificationFields({
                              ...notificationFields,
                              ['subject']: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group form-group-save-cancel mt-3">
                  <button
                    type="button"
                    className="btn btn-save btn-success"
                    onClick={() => {
                      approveSend();
                      setValidation(true);
                    }}
                  >
                    Approve
                  </button>

                  <button
                    type="button"
                    className="btn btn-close btn-danger"
                    onClick={() => {
                      declineSend();
                      setValidation(true);
                    }}
                  >
                    Decline
                  </button>
                  {!notificationFields.subject.length && validation && (
                    <div className="invalid-feedback d-block">
                      Feedback is required
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="topic-add-modal modal fade"
        id="downloadSoRModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        data-backdrop="static"
      >
        <div
          className="modal-600 modal-dialog modal-dialog-centered modal-m"
          role="document"
        >
          <div className="modal-content modal-border-update sor">
            <div className="modal-body p-0">
              <div className="modal-header modal-header-custom">
                <h5 className="modal-title">
                  <i className="fal fa-download"></i> Statement of Results
                  Download Options
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  data-toggle="modal"
                  data-target="#downloadSoRModal"
                  onClick={() => {
                    setStudentID('');
                    setIsChecked(false);
                    setIsShowFinal(false);
                    setIsExcludeGrade(false);
                    setBulkDownload(false);
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="t p-17px">
                <i className="fal fa-cog SoR-icon"></i>
                <span className="SoR-settings"> Settings</span>
                <div className="toogles-inline mt-3 d-flex align-items">
                  <div className="d-flex align-items mr-3">
                    <label className="mb-0">Show Final Results</label>
                    <div className="toggle-switch ml-2">
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={isShowFinal}
                          onChange={handleShowFinalToggle}
                        />
                        <span className="slider slider-round"></span>
                      </label>
                    </div>
                  </div>
                  <div className="d-flex align-items mr-3">
                    <label className="mb-0">Exclude Empty Grades</label>
                    <div className="toggle-switch ml-2">
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={isExcludeGrade}
                          onChange={handleExcludeFinal}
                        />
                        <span className="slider slider-round"></span>
                      </label>
                    </div>
                  </div>
                </div>

                <div className="form-group form-group-save-cancel mt-4">
                  <button
                    className="btn btn-save btn-success"
                    type="button"
                    title="Save"
                    onClick={() => {
                      if (bulkDownload) {
                        handleDownload();
                      } else {
                        handlepdfData(studentID);
                      }
                    }}
                  >
                    <i className="fal fa-download"></i> Download SoR
                  </button>
                  <button
                    className="btn btn-close btn-danger"
                    type="button"
                    title="Cancel"
                    data-dismiss="modal"
                    aria-label="Close"
                    data-toggle="modal"
                    data-target="#downloadSoRModal"
                    onClick={() => {
                      setStudentID('');
                      setIsChecked(false);
                      setIsShowFinal(false);
                      setIsExcludeGrade(false);
                      setBulkDownload(false);
                    }}
                  >
                    <i className="fal fa-times"></i>{' '}
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SetToInProgressDialog
          dialogOpen={dialogOpen}
          setDialogOpen={setDialogOpen}
          onSubmit={(values) => handleUpdateProgressStatus(values)}
        />
      
    </>
  );
};

export default Students;
