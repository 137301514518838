import React, { useEffect, useState } from "react";
import {
  PDFDownloadLink,
  Document,
  Page,
  Image,
  View,
  StyleSheet,
  Text,
  Font,
} from "@react-pdf/renderer";
import { IMAGE_URL } from "./utils/Constants";
import moment from "moment";
import footer from './assets/images/letterheadFooter_1706788148.png'
import sign from './assets/images/leonSign.png'
import header from './assets/images/letterheadHeader_1706788148.png'

const LetterheadPDF = ({
  programDetails = {},
  detailData = [],
  divMinHeight = 20,
  studentAndCourseDetails = {},
  brandData = [],
  noteImage,
  divOrgHeight = 20,
  divHeight = 100,
  divWidth = 100,
  divTopPosition = 460,
}) => {
const styles = StyleSheet.create({
  page: {
    backgroundColor: "white",
  },
  header: {
    width: "100%",
    height: 90,
  },
  footer: {
    width: "100%",
    height: 15,
    position: "absolute",
    bottom: 0,
    left: 0,
  },
  contentContainer: {
    padding: "0 40px",
    marginTop: 30,
    alignItems: "center",
  },
  heading: {
    fontSize: 30,
    lineHeight: "normal",
    marginBottom: 2,
    textAlign: "center",
  },
  subheading: {
    fontSize: 20,
    color: "#000",
    lineHeight: "normal",
    textAlign: "center",
  },
  detailLabel: {
    fontSize: 11,
  },
  detailValue: {
    fontSize: 11,
    color: "#000",
    paddingBottom: 5,
  },
  topmain: {
    marginTop: 10,
    padding: 15,
    display: "flex",
    flexDirection: "row",
    paddingBottom: 0,
    marginBottom: 15,
  },
  topleft: {
    width: "50%",
  },
  topright: {
    width: "50%",
  },
  tableBox: {
    paddingLeft: 15,
    paddingRight: 15,
    // paddingBottom: 15,
  },
  tableHead: {
    display: "flex",
    flexDirection: "row",
  },
  tablerowinner: {
    display: "flex",
    flexDirection: "row",
  },
  graderowinner: {
    marginTop: 15,
    display: "flex",
    flexDirection: "row",
  },
  tableFirst: {
    width: "300px",
    fontSize: 11,
    paddingTop: 15,
    paddingLeft: 10,
    paddingBottom: 10,
    paddingRight: 10,
    color: "#fff",
  },
  tableBodyFirst: {
    width: "300px",
    fontSize: 10,
    color: "#212529",
    padding: 10,
    borderLeft: "1px solid #DEE2E6",
    borderBottom: "1px solid #DEE2E6",
    borderRight: "1px solid #DEE2E6",
  },
  coursetableBodyFirst: {
    width: "300px",
    fontSize: 10,
    color: "#212529",
    padding: 10,
    borderLeft: "1px solid #DEE2E6",
    borderBottom: "1px solid #DEE2E6",
    borderRight: "1px solid #DEE2E6",
    backgroundColor: "#F8F9FA",
  },
  gradeBodyFirst: {
    width: "300px",
    fontSize: 10,
    color: "#212529",
    padding: 10,
    border: "1px solid #DEE2E6",
    backgroundColor: "#F8F9FA",
  },
  tableLabel: {
    flex: 1,
    fontSize: 11,
    paddingTop: 15,
    paddingLeft: 10,
    paddingBottom: 10,
    paddingRight: 10,
    color: "#fff",
  },
  tableBodyLabel: {
    flex: 1,
    fontSize: 10,
    color: "#212529",
    padding: 10,
    borderBottom: "1px solid #DEE2E6",
    borderRight: "1px solid #DEE2E6",
  },
  coursetableBodyLabel: {
    flex: 1,
    fontSize: 10,
    color: "#212529",
    padding: 10,
    borderBottom: "1px solid #DEE2E6",
    borderRight: "1px solid #DEE2E6",
    backgroundColor: "#F8F9FA",
  },
  gradeBodyLabel: {
    flex: 1,
    fontSize: 10,
    color: "#212529",
    padding: 10,
    borderTop: "1px solid #DEE2E6",
    borderBottom: "1px solid #DEE2E6",
    borderRight: "1px solid #DEE2E6",
  },
  signimg: {
    width: 100,
    marginBottom: 10,
    position: "absolute",
    bottom: divOrgHeight > 360 ? 385 : divOrgHeight + 20,
    left: 15,
  },
  tableSignboxlabel: {
    fontSize: 12,
    paddingBottom: 5,
    position: "absolute",
    bottom: 45,
    left: 15,
  },
  note: {
    width: divWidth,
    maxHeight : 360,
    minHeight : divMinHeight,
    position: "absolute",
    bottom: 20,
    left: 15,
  },
});
  const state = localStorage.getItem("state");
  const brandVisuals = JSON.parse(state).brandVisuals.config;
  const headerUrl = `${IMAGE_URL}/Library/brand/${
    brandData?.letterheadHeader ? brandData?.letterheadHeader : brandData[0]?.letterheadHeader ? brandData[0]?.letterheadHeader : brandVisuals?.letterheadHeader
  }`;
  const footerUrl = `${IMAGE_URL}/Library/brand/${
    brandData?.letterheadFooter ? brandData?.letterheadFooter : brandData[0]?.letterheadFooter ? brandData[0]?.letterheadFooter : brandVisuals?.letterheadFooter
  }`;
  const brandSignature = `${IMAGE_URL}/Library/brand/${
    brandData?.brandSignature ? brandData?.brandSignature : brandData[0]?.brandSignature ? brandData[0]?.brandSignature : brandVisuals?.brandSignature 
  }`;
  const programmeName = studentAndCourseDetails?.program_name;
  
  detailData.unshift({ isHeading: true });
  const highestPossibleRows = Math.floor((670 - divHeight) / 30)
  const maxRowsPerPage = highestPossibleRows < 13 ? highestPossibleRows > 0 ? highestPossibleRows : 1 : 13 // Maximum rows per page
  const pages = Math.ceil(detailData[1]?.assessments.length / (maxRowsPerPage)) || 1;
  console.log("Highest possible",maxRowsPerPage,pages,detailData);

  return (
    <Document>
      {[...Array(pages)].map((_, pageIndex) => (
      <Page size="A4" style={[styles.page, { paddingBottom: 85 }]}>
        <Image src={headerUrl} style={styles.header} fixed />

        <View style={styles.contentContainer} fixed>
          <Text
            style={[
              styles.heading,
              {
                color:
                  brandData?.length && brandData[0]?.topbar_color
                    ? brandData[0]?.topbar_color
                    : brandVisuals?.topbar_color,
                fontFamily: "Helvetica"
              },
            ]}
          >
            Statement of Results
          </Text>
          <Text
            style={[
              styles.subheading,
              { fontFamily: "Helvetica" },
            ]}
          >
            {programmeName}
          </Text>
        </View>

        <View style={styles.topmain} fixed>
          <View style={styles.topleft}>
            <Text style={styles.detailValue}>
              <Text
                style={[
                  styles.detailLabel,
                  {
                    color:
                      brandData?.length && brandData[0]?.topbar_color
                        ? brandData[0]?.topbar_color
                        : brandVisuals?.topbar_color,
                    fontFamily: "Helvetica-Bold"
                  },
                ]}
              >
                Student Name:
              </Text>{" "}
              <Text style={{ fontFamily: "Helvetica" }}>
                {studentAndCourseDetails?.Student_name}
              </Text>
            </Text>

            <Text style={styles.detailValue}>
              <Text
                style={[
                  styles.detailLabel,
                  {
                    color:
                      brandData?.length && brandData[0]?.topbar_color
                        ? brandData[0]?.topbar_color
                        : brandVisuals?.topbar_color,
                    fontFamily: "Helvetica-Bold"
                  },
                ]}
              >
                Student Number:{" "}
              </Text>{" "}
              <Text style={{ fontFamily: "Helvetica" }}>
                {studentAndCourseDetails?.Student_number}
              </Text>
            </Text>

            <Text style={styles.detailValue}>
              <Text
                style={[
                  styles.detailLabel,
                  {
                    color:
                      brandData?.length && brandData[0]?.topbar_color
                        ? brandData[0]?.topbar_color
                        : brandVisuals?.topbar_color,
                    fontFamily: "Helvetica-Bold"
                  },
                ]}
              >
                ID / Passport Number:
              </Text>{" "}
              <Text style={{ fontFamily: "Helvetica" }}>
                {studentAndCourseDetails?.ID_number}
              </Text>
            </Text>
          </View>

          <View style={styles.topright}>
            <Text style={styles.detailValue}>
              {" "}
              <Text
                style={[
                  styles.detailLabel,
                  {
                    color:
                      brandData?.length && brandData[0]?.topbar_color
                        ? brandData[0]?.topbar_color
                        : brandVisuals?.topbar_color,
                    fontFamily: "Helvetica-Bold"
                  },
                ]}
              >
                Course Duration:
              </Text>{" "}
              <Text style={{ fontFamily: "Helvetica" }}>
                {studentAndCourseDetails?.start && studentAndCourseDetails?.end
                  ? `${studentAndCourseDetails?.start} - ${studentAndCourseDetails?.end}`
                  : "N/A"}
              </Text>
            </Text>

            <Text style={styles.detailValue}>
              {" "}
              <Text
                style={[
                  styles.detailLabel,
                  {
                    color:
                      brandData?.length && brandData[0]?.topbar_color
                        ? brandData[0]?.topbar_color
                        : brandVisuals?.topbar_color,
                    fontFamily: "Helvetica-Bold"
                  },
                ]}
              >
                Intake:
              </Text>{" "}
              <Text
                style={{ fontFamily: "Helvetica" }}
              >{`${studentAndCourseDetails?.intake} ${studentAndCourseDetails?.learning_method}`}</Text>
            </Text>

            <Text style={styles.detailValue}>
              {" "}
              <Text
                style={[
                  styles.detailLabel,
                  {
                    color:
                      brandData?.length && brandData[0]?.topbar_color
                        ? brandData[0]?.topbar_color
                        : brandVisuals?.topbar_color,
                    fontFamily: "Helvetica-Bold"
                  },
                ]}
              >
                Date Issued:
              </Text>{" "}
              <Text style={{ fontFamily: "Helvetica" }}>
                {moment(new Date()).format("DD MMMM YYYY")}
              </Text>
            </Text>
          </View>
        </View>

        <View style={styles.tableBox}>
          <View style={styles.tableBody}>
                <View
                  style={[
                    styles.tableHead,
                    {
                      backgroundColor:
                        brandData?.length && brandData[0]?.topbar_color
                          ? brandData[0]?.topbar_color
                          : brandVisuals?.topbar_color,
                    },
                  ]}
                  fixed
                  // key={courseIndex}
                >
                  <Text
                    style={[
                      styles.tableFirst,
                      { fontFamily: "Helvetica-Bold" },
                    ]}
                  >
                    Name
                  </Text>
                  <Text
                    style={[
                      styles.tableLabel,
                      { fontFamily: "Helvetica-Bold" },
                    ]}
                  >
                    Grade
                  </Text>
                  <Text
                    style={[
                      styles.tableLabel,
                      { fontFamily: "Helvetica-Bold" },
                    ]}
                  >
                    Result
                  </Text>
                </View>
            {detailData.slice((pageIndex + 1) * maxRowsPerPage,(pageIndex + 2) * maxRowsPerPage).map((course, courseIndex) => {
              return (
                <View key={courseIndex}>
                  {/* Course row */}
                  <View style={styles.tablerowinner} wrap={false}>
                    <Text
                      style={[
                        styles.coursetableBodyFirst,
                        { fontFamily: "Helvetica-Bold" },
                      ]}
                    >
                      {course.course_name}
                    </Text>
                    <Text
                      style={[
                        styles.coursetableBodyLabel,
                        { fontFamily: "Helvetica" },
                      ]}
                    >
                      {course?.grade}
                    </Text>
                    <Text
                      style={[
                        styles.coursetableBodyLabel,
                        { fontFamily: "Helvetica" },
                      ]}
                    >
                      {course.result}
                    </Text>
                  </View>

                  {/* Assessments for the current course */}
                  {course?.assessments?.length &&
                    course.assessments
                    .slice(pageIndex * maxRowsPerPage, (pageIndex + 1) * maxRowsPerPage)
                    .map((assessment, assessmentIndex) => {
                      return (
                        <View
                          key={assessmentIndex}
                          style={styles.tablerowinner}
                          wrap={false}
                        >
                          <Text
                            style={[
                              styles.tableBodyFirst,
                              { fontFamily: "Helvetica" },
                            ]}
                          >
                            {assessment.name}
                          </Text>
                          <Text
                            style={[
                              styles.tableBodyLabel,
                              { fontFamily: "Helvetica" },
                            ]}
                          >
                            {assessment.grade}
                          </Text>
                          <Text
                            style={[
                              styles.tableBodyLabel,
                              { fontFamily: "Helvetica" },
                            ]}
                          >
                            {assessment.result}
                          </Text>
                        </View>
                      );
                    })}
                </View>
              );
            })}
          </View>
          {detailData[1]?.final_grade !== "" &&
            detailData[1]?.final_status !== "" &&
            pageIndex === pages - 1 && (
              <View style={styles.graderowinner} wrap={false}>
                <Text
                  style={[
                    styles.gradeBodyFirst,
                    { fontFamily: "Helvetica-Bold" },
                  ]}
                >
                  Final Course Grade
                </Text>
                <Text
                  style={[
                    styles.gradeBodyLabel,
                    { fontFamily: "Helvetica-Bold" },
                  ]}
                >
                  {detailData && detailData[1]?.final_grade}
                </Text>
                <Text
                  style={[
                    styles.gradeBodyLabel,
                    { fontFamily: "Helvetica-Bold" },
                  ]}
                >
                  {detailData && detailData[1]?.final_status}
                </Text>
              </View>
            )}
        </View>
        

        

        <Image src={brandSignature} style={styles.signimg} fixed />
        {noteImage && <Image src={noteImage} style={styles.note} fixed />}
       
        {/* <Text
          style={[
            styles.tableSignboxlabel,
            { color: brandVisuals?.topbar_color },
          ]}
          fixed
        >
          Leon Smalberger | CEO
        </Text>
        <Text
          style={[
            styles.tableSignboxsmall,
            { color: brandVisuals?.topbar_color },
          ]}
          fixed
        >
          Academic Institute of Excellence (AIE)
        </Text> */}
        <Image src={footerUrl} style={styles.footer} fixed />
      </Page>
      ))}
    </Document>
  );
};

export default LetterheadPDF;
