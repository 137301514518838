import React, { useState, useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router";
import { TermPeriods } from "../../systemadministration/DataReactSelect";
import { useFormik } from "formik";
import Select from "react-select";
import HtmlInputEditor from "../../common/HtmlInputEditor";
import * as Yup from "yup";
import Swal from "sweetalert2";
import Papa from "papaparse";
import {
  GetSchoolList,
  GetProgramDetailCancelToken,
  GetProgrammePageDetailCancelToken,
  GetCampusLocationDropdown,
  GetPagesList,
  GetCategoryListStatic,
  GetPopUpListStatic,
  getPagesListForPmcFilters,
  getBrandTemplateList,
  getAllPmcProgrammesDropdown,
  AddAndUpdateProgrammePageCancelToken,
  getAllPmcShortcourseDropdownCancelToken,
  GetShortCourseData,
  GetLevelsList,
  GenerateFactSheet,
} from "../../../services/ProgramService";
import hasPermission from "../../../utils/hasMultiplePermission";
import { useSelector } from "react-redux";
import {
  RenderProgrammeOrCourseType,
  RenderProgrammeType,
} from "../../../utils/CommonGroupingItem";
// import { RenderPMCStatus } from "../../../utils/CommonStatusItems";
import HtmlParser from "react-html-parser";
import axios from "axios";
import { GetNetsuiteProductListCancelToken } from "../../../services/ProgrammeServices";
import {
  checkIfImageExists,
  modifiyOptionsForSelect,
} from "../../../utils/commonFunction";
import DataTableComponent from "../../common/DataTableComponent";
import { TrimText } from "../../common/TrimText";
import { downloadURL, IMAGE_URL } from "../../../utils/Constants";
import $, { error } from "jquery";
import DataTableComponentFrontPagination from "../../common/DataTableComponentFrontPagination";
import PermissionsGate from "../../../utils/permissionGate";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import {
  RenderPMCPageStatus,
  RenderPMCStatus,
  RenderPMCStatusCol,
  RenderPageBrands,
} from "../../../utils/CommonStatusItems";
import { FileUploadField } from "../../common/FormFields";
import { download } from "../../../utils/FileDownload";

const statusArr = [
  {
    value: "Unpublished",
    label: HtmlParser(
      `<span className="red" title="Unpublished">Unpublished</span>`
    ),
  },
  {
    value: "Draft",
    label: HtmlParser(`<span className="blue" title="Draft">Draft  </span> `),
  },
  {
    value: "Published",
    label: HtmlParser(
      `<span className="green" title="Published">Published</span>`
    ),
  },
];

const PublishstatusArr = [
  {
    value: "Draft",
    label: HtmlParser(`<span className="red" title="Draft">Draft  </span> `),
  },
  {
    value: "Published",
    label: HtmlParser(
      `<span className="blue" title="Published">Published</span>`
    ),
  },
  {
    value: "Unpublished",
    label: HtmlParser(
      `<span className="blue" title="Unpublished">Unpublished</span>`
    ),
  },
];

const learningMethodOptionArr = [
  {
    // label: HtmlParser(`<span className="red" title="Draft">Draft  </span> `)
    label: "Full Time,Hybrid",
    value: "full",
  },
  {
    label: "Full Time,Online",
    value: "fulltimeonline",
  },
  {
    label: "Part Time,Online",
    value: "parttimeonline",
  },
  {
    label: "Part Time,Online",
    value: "parttimeonline",
  },
  // {
  //   value: "Draft",
  //   label: HtmlParser(`<span className="red" title="Draft">Draft  </span> `)
  // },
  // {
  //   value: "New",
  //   label: HtmlParser(`<span className="skyblue" title="New"> New </span>`)
  // },
  // {
  //   value: "Final",
  //   label: HtmlParser(` <span className="green" title="Final">Final</span>`)
  // },
  // {
  //   value: "Approved",
  //   label: HtmlParser(`<span className="yellowshade" title="Approved">Approved</span>`)
  // },
  // {
  //   value: "Inactive",
  //   label: HtmlParser(`<span className="blue" title="Inactive">Inactive</span>`)
  // },
  // {
  //   value: "Published",
  //   label: HtmlParser(`<span className="blue" title="Published">Published</span>`)
  // },
  // {
  //   value: "Unpublished",
  //   label: HtmlParser(`<span className="blue" title="Unpublished">Unpublished</span>`)
  // },
];

const NQF_LevelArr = [
  {
    value: "1",
    label: "1",
  },
  {
    value: "2",
    label: "2",
  },
  {
    value: "3",
    label: "3",
  },
  {
    value: "4",
    label: "4",
  },
  {
    value: "5",
    label: "5",
  },
  {
    value: "6",
    label: "6",
  },
  {
    value: "7",
    label: "7",
  },
  {
    value: "8",
    label: "8",
  },
  {
    value: "9",
    label: "9",
  },
  {
    value: "10",
    label: "10",
  },
];

const creditStatusList = [
  { value: "0", label: "No Credits" },
  { value: "1", label: "Credit Bearing" },
]

const title = {
  siteDomain: "This is the URL of the website",
  branch: "If the website is the production, development or staging branch. If this is set to production, all pages and qualifications will be filtered by where published status is set to published, if set to development it will show both published and unpublished items.",
  status: "This determines if the website is live or not. If set to archived the website will not load any data.",
  tagline: " The tagline is a short line of text that aims to communicate the site's main focus. Taglines are usually eight to 12 words long",
  siteIcon: "The site icon is a small, 16x16 pixel icon used on web browsers to represent a website or a web page. It is commonly displayed on tabs at the top of a web browser, but they're also found on the browser's bookmark bar, history and in search results, alongside the page url.",
  linkedBrandTemplate: "This is the brand template linked to the website and determains the style of the website as well as the content displayed."
}

const ShortCoursePageDetails = ({ pageData, pageLoading, setUpdateData }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(pageLoading)
  const { id, type } = useParams();
  const [programId, setProgramID] = useState(id);
  const [AssignData, setAssignData] = useState();
  const [editorState1, setEditorState1] = useState("");
  const [subjectContent, setSubjectContent] = useState("");
  const [admissionReq, setAdmissionReq] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false)
  const [accreditation, setAccreditation] = useState();
  const [articulationOption, setArticulationOption] = useState("");
  const [studyKit, setStudyKit] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [careerOpportunity, setCareerOpportunity] = useState("");
  const [hardwareReq, setHardwareReq] = useState("");
  const [duration, setDuration] = useState("");
  const [regulatoryInfo, setRegulatoryInfo] = useState("");
  const [impNotes, setImpNotes] = useState("");
  const [learningMethodArr, setLearningMethodArr] = useState([]);
  const [editorToShow, setEditorToShow] = useState(false);
  const [schoolList, setSchoolList] = useState([]);
  const [campusLocList, setCampusLocList] = useState([]);
  const [netsuiteProdList, setNetsuiteProdList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [popupList, setPopupList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [allpageListData, setAllPageListData] = useState([]);
  const [assignPageList, setAssignPageList] = useState([]);
  const [assignPageLoading, setAssignPageLoading] = useState(false);
  const [pageStatus, setPageStatus] = useState({ arr: [], checkObj: {} });
  const [pageBrand, setPageBrand] = useState({ arr: [], checkObj: {} });
  const [levelList, setLevelList] = useState([]);
  const [PageFilterData, setPageFilterdata] = useState({
    status: [],
    BrandList: [],
  });

  const [assignPageTableState, setAssignPageTableState] = useState({
    page: 1,
    perPage: 10,
    sortKey: "PageTitle",
    sortOrder: "asc",
  });

  const [assignPageSearch, setAssignPageSearch] = useState("");
  const [assignPageTotalRows, setAssignPageTotalRows] = useState(0);
  const [assignedPage, setAssignedPage] = useState({});
  const [articulationOptionList, setArticulationOptionList] = useState([]);
  const [articulationOptionLoading, setArticulationOptionLoading] = useState(false);
  const [articulationOptionSearch, setArticulationOptionSearch] = useState("");
  const [articulationOptionTotalRow, setArticulationOptionTotalRow] = useState("");
  const [articulationOptionTableState, setArticulationOptionTableState] =
    useState({
      page: 1,
      perPage: 10,
      sortKey: "name",
      sortOrder: "asc",
    });
  const [artStatus, setArtStatus] = useState({ arr: [], checkObj: {} });
  const [artProgType, setArtProgType] = useState({ arr: [], checkObj: {} });
  const [articulationOptionFilterData, setArticulationOptionFilterData] = useState({ status: [], programmeType: [], type: [] });
  const [artType, setArtType] = useState({ arr: [], checkObj: {} });
  const [articulationAssignedTableState, setArticulationAssignedTableState] =
    useState({
      page: 1,
      perPage: 10,
      sortKey: "name",
      sortOrder: "asc",
    });
  const [assignedDataList, setAssignedDataLis] = useState([])
  const [articulationAssignedTotalRow, setArticulationAssignednTotalRow] = useState("");
  const [assignedArticulationOptionList, setAssignedArticulationOptionList] = useState([]);
  const [assignedArticulationSearch, setAssignedArticulationSearch] = useState("");
  const [assignedArticulationOptionLoading, setAssignedArticulationOptionLoading,] = useState(false);
  const [assignedStatus, setAssignedStatus] = useState({ arr: [], checkObj: {}, });
  const [assignedProgType, setAssignedProgType] = useState({ arr: [], checkObj: {}, });
  const [assignedType, setAssignedType] = useState({ arr: [], checkObj: {} });
  const [assignedArticulationOptionFilterData, setAssignedArticulationOptionFilterData,] = useState({ AssignStatus: [], AssignProgrammeType: [], AssignType: [] });
  const dropDownValues = useSelector((state) => state.registerDropdownValues);
  const [factsheet,setFactsheet] = useState("")
  const [programmeList, setProgrammeList] = useState([]);
  const [next, setNext] = useState(false)
  const [detail, setDetail] = useState([])
  const [programmeDetails, setProgrammeDetails] = useState([])
  const [pmc_id, setPMC_id] = useState("")
  const [isValuesSet, setIsValuesSet] = useState(false);
  const [programName, setProgrammName] = useState("")
  const [netsuite_product, setNetsuite_product] = useState("")
  const [pSchool, setPSchool] = useState("")
  const [importData, setImportData] = useState(false)

  const helpText = {
    linkedPMC : 'The linked PMC short course links data from the PMC short course to the created short course page',
    status : 'The status determines the visibility of the short course. When set to draft the page will be viewable on the development websites.'
  }

  const helpText2 = {
    linkedPMC : 'The linked PMC Short Course links data from the PMC Short Course to the created Short Course Page',
    status : 'The status determines the visibility of the short course page. When set to draft the page will be viewable on the development websites.',
    assignedBrand : 'Linked brand template where the page will be assigned. The page will be assigned to all sites connected to the specified brand templates',
    duration : 'The short course duration in text e.g. 1 day, 4 weeks, 1 year, 2-4 years.',
    banner_image : 'The image show on the qualification card and on the course banner. The optimal resolution is 1440px x 510px',
    overrideInformation : 'Override PMC Data on Website. This does not change data on the linked PMC Short Course'
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      duplicateId:"",
      name: pageData?.name ? pageData?.name : "",
      programme_name: pageData?.programme_name ? pageData?.programme_name : "",
      pmc_id: pageData?.pmc_id ? pageData?.pmc_id : "",
      status: pageData?.status ? pageData?.status : "",
      publish_status: pageData?.publish_status ? pageData?.publish_status : "",
      Duration: pageData?.Duration ? pageData?.Duration : "",
      campus_location: pageData?.campus_ids ? pageData?.campus_ids : [],
      brandID: pageData?.brand_ids ? pageData?.brand_ids : "",
      categories: pageData?.categoryIds ? pageData?.categoryIds : [],
      banner_image: pageData?.banner_image ? pageData?.banner_image : "",
      netsuite_product: pageData?.netsuite_product ? pageData?.netsuite_product : "",
      School: pageData?.School ? pageData?.School : "",
      // qualification_type: pageData?.qualification_type ? pageData?.qualification_type : "",
      Code: pageData?.Code ? pageData?.Code : "",
      SAQA_Name: pageData?.SAQA_Name ? pageData?.SAQA_Name : "",
      level: pageData?.level ? pageData?.level : "",
      credit_status: pageData?.credit_status ? pageData?.credit_status : 0,
      Credits: pageData?.Credits != null ? pageData?.Credits : "",
      Term_Period: pageData?.Term_Period ? pageData?.Term_Period : "",
      SAQA_Link: pageData?.SAQA_Link ? pageData?.SAQA_Link : "",
      // Delivery_Methods: pageData?.learning_methods ? pageData?.learning_methods : [],
      Delivery_Methods: pageData?.learning_methods
      ? pageData?.learning_methods : [],
      full_time_online_fee: pageData?.full_time_online_fee ? pageData?.full_time_online_fee : 0,
      part_time_online_fee: pageData?.part_time_online_fee ? pageData?.part_time_online_fee : 0,
      full_time_hybrid_fee: pageData?.full_time_hybrid_fee ? pageData?.full_time_hybrid_fee : 0,
      self_paced_fee: pageData?.self_paced_fee ? pageData?.self_paced_fee : 0,
      registration_fee: pageData?.registration_fee ? pageData?.registration_fee : 0,
      installments: pageData?.installments ? pageData?.installments : "",
      earlyBirds: ((type === "open" || type === "duplicate") && pageData?.earlyBirds !== "") ? pageData?.earlyBirds : 2,
      cost_terms: pageData?.cost_terms ? pageData?.cost_terms : "",
      options_factsheet: (pageData?.options_factsheet != null) ? pageData.options_factsheet : 1,
      override_default_tc: pageData?.override_default_tc ? pageData?.override_default_tc : 0,
      terms_conditions_page: pageData?.terms_conditions_page ? pageData?.terms_conditions_page : "",
      netsuite_department_id: pageData?.netsuite_department_id ? pageData?.netsuite_department_id : "",
      toggle_override_info: pageData?.toggle_override_info ? pageData?.toggle_override_info : 0,
      course_delivery: pageData?.course_delivery ? pageData.course_delivery : [], 
      page_type :"1",
      factsheet_pdf : pageData?.factsheet_pdf || "",
    },

    validationSchema: Yup.object().shape({
      name: Yup.string()
      .required("Marketing Short Course Name is required")
      .max(255, "Marketing Short Course Name should not be greater than 255 Character")
      .trim(),
      terms_conditions_page: Yup.string().when("override_default_tc", {
        is: "1",
        then: Yup.string().required("Assigned Terms & Conditions Page is required"),
      }),
      publish_status: Yup.string().required("Publish status is required"),
      // brandID: Yup.string().required("Assigned Brand is required"),
      full_time_hybrid_fee: Yup.string().when("Delivery_Methods", {
        is: (methods) => Array.isArray(methods) && methods.includes('full'),
        then: Yup.string().required("Full Time, Hybrid Fee is required"),
        otherwise: Yup.string(),
      }),
      full_time_online_fee: Yup.string().when("Delivery_Methods", {
        is: (methods) => Array.isArray(methods) && methods.includes('fulltimeonline'),
        then: Yup.string().required("Full Time, Online Fee is required"),
        otherwise: Yup.string(),
      }),
      part_time_online_fee: Yup.string().when("Delivery_Methods", {
        is: (methods) => Array.isArray(methods) && methods.includes('parttimeonline'),
        then: Yup.string().required("Part Time, Online Fee is required"),
        otherwise: Yup.string(),
      }),  
      self_paced_fee: Yup.string().when("Delivery_Methods", {
        is: (methods) => Array.isArray(methods) && methods.includes('selfplaced'),
        then: Yup.string().required("Self-Paced Fee is required"),
        otherwise: Yup.string(),
      }),
      // factsheet_pdf: Yup.string().when("options_factsheet", {
      //   is: 2,
      //   then: Yup.string().required("Fact Sheet PDF is required"),
      //   otherwise: Yup.string(),
      // }),
      // factsheet_pdf : factsheet == 2 ? Yup.mixed().required("Fact Sheet PDF is required") : ""
    }),

    onSubmit: (values, action) => {
      setLoading(true);
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("programme_name", values.programme_name);
      formData.append("pmc_id", values.pmc_id);
      formData.append("status", values.status);
      formData.append("publish_status", values.publish_status);
      formData.append("Duration", values.Duration);
      values.campus_location.forEach((val, idx) => {
        formData.append(`${"campus_location"}[${idx}]`, val);
      });
      values.campus_location.forEach((val, idx) => {
        formData.append(`${"campus_location"}[${idx}]`, val);
      });
      values.brandID.forEach((val, idx) => {
        formData.append(`${"brandID"}[${idx}]`, val);
      });
      values.categories.forEach((val, idx) => {
        formData.append(`${"categories"}[${idx}]`, val);
      });
      values.Delivery_Methods.forEach((val, idx) => {
        formData.append(`${"Delivery_Methods"}[${idx}]`, val);
      });
      values.course_delivery.forEach((val, idx) => {
        formData.append(`${"course_delivery"}[${idx}]`, val);
      });
      formData.append("banner_image", values.banner_image);
      formData.append("netsuite_product", values.netsuite_product);
      formData.append("netsuite_department_id", values.netsuite_department_id);
      formData.append("School", values.School);
      // formData.append("qualification_type", values.qualification_type);
      formData.append("Code", values.Code);
      formData.append("SAQA_Name", values.SAQA_Name);
      formData.append("SAQA_Link", values.SAQA_Link);
      formData.append("level", values.level);
      formData.append("credit_status", values.credit_status);
      formData.append("Credits", values.Credits | "");
      formData.append("Term_Period", values.Term_Period);
      formData.append("full_time_online_fee", values.full_time_online_fee);
      formData.append("part_time_online_fee", values.part_time_online_fee);
      formData.append("full_time_hybrid_fee", values.full_time_hybrid_fee);
      formData.append("self_paced_fee", values.self_paced_fee);
      
      formData.append("registration_fee", values.registration_fee);
      formData.append("installments", values.installments);
      formData.append('earlyBirds', values.earlyBirds === "" || values.earlyBirds == null ? 2 : values.earlyBirds);
      formData.append("cost_terms", values.cost_terms);
      formData.append("options_factsheet", values.options_factsheet);
      formData.append(
        "override_default_tc",
        values.override_default_tc == true ? 1 : 0
      );
      formData.append("toggle_override_info", values.toggle_override_info);
      formData.append("page_type", 1);
      formData.append("factsheet_pdf", values.factsheet_pdf)
      formData.append("terms_conditions_page", values.terms_conditions_page);

      if (type == "create") {
       
        AddAndUpdateProgrammePageCancelToken(formData)
          .then(() => {
            Swal.fire("Success!", "Short course has been created successfully!", "success");
            action.resetForm();
            setLoading(false);
            history.push("/websiteManagement/pages/programmes_and_short_courses/short_course_page/table");
          })
          .catch((error) => {
            Swal.fire("Error!", "Failed to create Short course", "error");
            setLoading(false);
          });
      }
      else if (type === "update" || type == "open"|| type == "duplicate") {
        if (type == "duplicate") {
          formData.append("duplicateId", id);
        } else {
          formData.append("id", id);
        }
        AddAndUpdateProgrammePageCancelToken(formData).then(() => {
          Swal.fire(
            "Success!",
            "Short Course Updated Successfully!",
            "success"
          );
          action.resetForm();
          setLoading(false);
          setUpdateData(Date.now())
          if(type == "duplicate"){
            history.push("/websiteManagement/pages/programmes_and_short_courses/short_course_page/table");
          }
        }).catch((error) => {
          Swal.fire("Error!", "Failed to Update Short course", "error");
          setLoading(false);
        });
      }
      action.setSubmitting(false);
      // setLoading(false);
    },
  });

  useEffect(()=>{
    if (formik.values.options_factsheet == 0 || formik.values.options_factsheet == 1) {
      formik.setFieldValue("factsheet_pdf", "")
    } else {
      formik.setFieldValue("factsheet_pdf", formik.values.factsheet_pdf)
    }

    if (formik.values.override_default_tc == 0) {
      formik.setFieldValue("terms_conditions_page", "")
    } else {
      formik.setFieldValue("terms_conditions_page", formik.values.terms_conditions_page);
    }
  },[formik.values])

  useEffect(()=>{
    if(formik.values.toggle_override_info == 0 || pmc_id == ""){
      setImportData(false)
    }
  }, [formik.values.toggle_override_info, pmc_id])

  useEffect(() => {
    setPMC_id(formik.values.pmc_id)
  }, [formik.values.pmc_id])


  useEffect(() => {
    const cancelTokenSources = [];
    setDetail([])
    const programmeDetails = async () => {
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });

      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);

      try {
        const res = await GetShortCourseData({ id: pmc_id }, source.token);
        if (res.status === 200) {
          setProgrammeDetails(res.data.result)
          setUpdateData(Date.now())
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };
    if (formik.values.pmc_id !== "") {
      programmeDetails()
    }
  }, [pmc_id])

  const handleDownload = async () => {
    setButtonLoading(true);
    try {
      const success = await GenerateFactSheet({ page_id: id });
  
      let url = IMAGE_URL + (success.data?.fileName).replace("public", "");
      setButtonLoading(false);
  
      window.open(url, "_blank");
  
    } catch (error) {
      setButtonLoading(false);
      console.error("Error generating fact sheet:", error);
    }
  };
  
  

  useEffect(() => {
    const cancelTokenSources = [];
    const cancelDropdownSource1 = [];
    const cancelDropdownSource2 = [];
    const cancelDropdownSource3 = [];
    const cancelDropdownSource4 = [];
    const cancelDropdownSource5 = [];
    const cancelDropdownSource6 = [];
    const cancelDropdownSource7 = [];
    const getSchool = async () => {
      cancelTokenSources.forEach((source) => {
        source.cancel("New request made");
      });

      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);

      try {
        const res = await GetSchoolList();
        if (res.status == 200) {
          setSchoolList(res.data.data);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };

    const getCampusLocationData = async () => {
      cancelDropdownSource1.forEach((source) => {
        source.cancel("New request made");
      });
      const source1 = axios.CancelToken.source();
      cancelDropdownSource1.push(source1);

      try {
        const res = await GetCampusLocationDropdown(source1.token);
        if (res.status == 200) {
          setCampusLocList(res.data.campusLocationFilters);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };


    const getNetsuiteProductData = async () => {
      cancelDropdownSource2.forEach((source) => {
        source.cancel("New request made");
      });
      const source2 = axios.CancelToken.source();
      cancelDropdownSource2.push(source2);

      try {
        const res = await GetNetsuiteProductListCancelToken("", source2.token);
        if (res.status == 200) {
          const respData =
            res.data.data && res.data.data.items ? res.data.data.items : [];
          const modifyResponse = modifiyOptionsForSelect(respData, "netsuite");
          setNetsuiteProdList(modifyResponse);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };

    const getPmcShortcourseDropdown = async () => {
      cancelDropdownSource2.forEach((source) => {
        source.cancel("New request made");
      });
      const source2 = axios.CancelToken.source();
      cancelDropdownSource2.push(source2);

      try {
        const res = await getAllPmcShortcourseDropdownCancelToken("", source2.token);
        if (res.status == 200) {
          const respData =
            res.data.data && res.data.data.items ? res.data.data.items : [];
          const modifyResponse = modifiyOptionsForSelect(respData, "netsuite");
          setNetsuiteProdList(modifyResponse);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };

    const getBrandData = async () => {
      cancelDropdownSource3.forEach((source) => {
        source.cancel("New request made");
      });
      const source3 = axios.CancelToken.source();
      cancelDropdownSource3.push(source3);

      try {
        const res = await getBrandTemplateList(source3.token);
        if (res.status == 200) {
          setBrandList(res.data?.brandTypesFilters);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };

    const getPopupData = async () => {
      cancelDropdownSource4.forEach((source) => {
        source.cancel("New request made");
      });
      const source4 = axios.CancelToken.source();
      cancelDropdownSource4.push(source4);

      try {
        const res = await GetPopUpListStatic(source4.token);
        if (res.status == 200) {
          setPopupList(res.data.Data);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };

    const getCategoryData = async () => {
      cancelDropdownSource5.forEach((source) => {
        source.cancel("New request made");
      });
      const source5 = axios.CancelToken.source();
      cancelDropdownSource5.push(source5);

      try {
        const res = await GetCategoryListStatic(source5.token);
        if (res.status == 200) {
          setCategoryList(res.data.Data);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };

    const getAllPmcProgrammes = async () => {
      // cancelDropdownSource7.forEach((source) => {
      //   source.cancel("New request made");
      // });
      // const source5 = axios.CancelToken.source();
      // cancelDropdownSource7.push(source5);

      try {
        const res = await getAllPmcShortcourseDropdownCancelToken();
        if (res.status == 200) {
          setProgrammeList(res.data?.data)
        }
      } catch (error) {
        // if (!axios.isCancel(error)) {
        //   console.error(error);
        // }
      }
    };
    const getLevelData = async () => {
      cancelDropdownSource7.forEach(source => {
        source.cancel('New request made');
      });
      const source7 = axios.CancelToken.source();
      cancelDropdownSource7.push(source7);

      try {
        const res = await GetLevelsList(source7.token);
        if (res.status == 200) {
          setLevelList(res.data.levels);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    }


    const getPageFilters = async () => {
      cancelDropdownSource6.forEach((source) => {
        source.cancel("New request made");
      });
      const source3 = axios.CancelToken.source();
      cancelDropdownSource6.push(source3);

      try {
        const res = await getPagesListForPmcFilters(source3.token);
        if (res.status == 200) {
          setPageFilterdata({
            BrandList: res.data?.getBrandtemplate,
            status: res.data?.getStatus,
          });
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };

    getSchool();
    getCampusLocationData();
    getNetsuiteProductData();
    getBrandData();
    getPopupData();
    getCategoryData();
    getPageFilters();
    getLevelData()
    getAllPmcProgrammes()

    return () => {
      cancelTokenSources.forEach((source) => {
        source.cancel("Component unmounted");
      });
      cancelDropdownSource1.forEach((source) => {
        source.cancel("Component unmounted");
      });
      cancelDropdownSource2.forEach((source) => {
        source.cancel("Component unmounted");
      });
      cancelDropdownSource3.forEach((source) => {
        source.cancel("Component unmounted");
      });
      cancelDropdownSource4.forEach((source) => {
        source.cancel("Component unmounted");
      });
      cancelDropdownSource5.forEach((source) => {
        source.cancel("Component unmounted");
      });
    };
  }, [type]);

  useEffect(() => {
    setLearningMethodArr(learningMethodOptionArr);
  }, []);



  useEffect(() => {
    const cancelTokenSources = [];

    const getAssignPageListData = async () => {
      setAssignPageLoading(true);
      cancelTokenSources.forEach((source) => {
        source.cancel("New request made");
      });

      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);

      try {
        const payload = {
          page: assignPageTableState.page,
          perPage: assignPageTableState.perPage,
          search: assignPageSearch,
          key: assignPageTableState.sortKey,
          sort: assignPageTableState.sortOrder,
          exportStatus: false,
          setSearch: setAssignPageSearch,
          viaBrand: pageBrand.arr,
          viaStatus: pageStatus.arr,
        };
        const res = await GetPagesList(payload, source.token);
        if (res.status == 200) {
          setAssignPageTotalRows(res.data?.Data?.total);
          setAssignPageList(res.data?.Data?.data);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      } finally {
        setAssignPageLoading(false);
      }
    };

    getAssignPageListData();

    return () => {
      cancelTokenSources.forEach((source) => {
        source.cancel("Component unmounted");
      });
    };
  }, [assignPageTableState, assignPageSearch, pageStatus, pageBrand]);

  const givenPermsisions = useSelector((state) => state.givenPermission);

  useEffect(() => {
    let response = hasPermission({
      scopes: ["pmcpedit"],
      permissions: givenPermsisions,
    });
    if (!response) {
      history.push("/noaccess");
    }
  }, []);

  useEffect(() => {
    const assignPage = assignPageList.filter((page) => {
      return page.id === formik.values.terms_conditions_page;
    });
    setAssignedPage(assignPage);
  }, [assignPageList]);

  useEffect(() => {
    const data = netsuiteProdList.filter((item) => item.value == formik.values?.netsuite_product);
    const netsuit_dId = data[0]?.departmentId;

    if (netsuit_dId) {
      formik.setFieldValue("netsuite_department_id", netsuit_dId);
    }
  }, [netsuiteProdList, formik.values?.netsuite_product]);

  const handleChangeInstallments = (value) => {
    const prevValue = formik.values.installments
      ?.split(",")
      ?.filter((item) => item != "");
    if (prevValue.includes(value)) {
      const ind = prevValue.findIndex((item) => item == value);
      prevValue.splice(ind, 1);
    } else {
      prevValue.push(value);
    }
    formik.setFieldValue("installments", prevValue.join(","));
  };

  const handlereset = async () => {
    history.push(`/websiteManagement/pages/programmes_and_short_courses/short_course_page/table`);
    formik.resetForm();
  };

  const assignPageColumn = useMemo(() => [
    {
      name: "Name",
      selector: "name",
      sortable: true,
      sortField: "PageTitle",
      // wrap: true,
      cell: (row) => (
        <Link to={`/website_management/pages/staticpages/table/update/${row?.PageID}`}  onClick={() => {
            $("#assignPagePopup").modal("hide");
          }}>
        <span
          className="as-text-blue curser feature-name"
          title={row.title}
        >
          <span className="textLimit100">
            {row.PageTitle ? row.PageTitle : "-"}
          </span>
        </span>
        </Link>
      ),
    },
    {
      name: "Status",
      selector: "Status",
      sortField: "status",
      sortable: true,
      cell: (row) => (row.status ? RenderPMCStatusCol(row.status).html : "-"),
    },
    {
      name: "Brand Templates",
      selector: "Brand Templates",
      sortable: false,
      cell: (row) => (
        <div className="d-flex flex-wrap">
          {row?.brandTemplete?.length > 0
            ? row?.brandTemplete.map(
              (brand, index) =>
                brand?.templateName && (
                  <div className="brandList-pmc" title={brand?.templateName}>
                    {brand?.templateName}
                  </div>
                )
            )
            : "-"}
        </div>
      ),
    },
    {
      name: "Action",
      selector: "",
      maxWidth: "50px",
      cell: (row) => (
        <div className="assessment-08">
          <div className="as-buttons">
            {formik.values.terms_conditions_page != row?.PageID ? (
              <button
                type="button"
                className="btn btn-primary rounded-circle"
                onClick={() => {
                  formik.setFieldValue("terms_conditions_page", row?.PageID);
                  setAssignedPage({ ...row });
                  formik.setFieldValue(
                    "terms_conditions_page_type",
                    row?.PageTitle
                  );
                  $("#assignPagePopup").modal("hide");
                }}
                title="Assign Page"
              >
                <i className="fal fa-plus"></i>
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-danger rounded-circle"
                onClick={() => {
                  formik.setFieldValue("terms_conditions_page", "");
                  formik.setFieldValue("terms_conditions_page_type", "");
                  setAssignedPage({});
                }}
                title="Assign Page"
              >
                <i className="fal fa-minus"></i>
              </button>
            )}
          </div>
        </div>
      ),
    },
  ]);


  useEffect(() => {
    const payload = {
      // page:assignPageTableState.page,
      // perPage:assignPageTableState.perPage,
      // search:assignPageSearch,
      // key:assignPageTableState.sortKey,
      // sort:assignPageTableState.sortOrder,
      exportStatus: "true",
      // setSearch:setAssignPageSearch,
      // viaBrand:pageBrand.arr,
      // viaStatus:pageStatus.arr,
    };

    GetPagesList(payload)
      .then((res) => {
        if (res.status == 200) {
          setAllPageListData(res.data?.Data);
        }
      })
      .catch((err) => console.log(err));
  }, []);



  const handleNext = () => {
    if (!formik.errors.name && formik.values.name) {
      setNext(true);
    } else {
      formik.setTouched({ ...formik.touched, name: true });
    }
  };

  const exportAssignPageData = (fileType, fileName) => {
    let exportData = [...assignedArticulationOptionList];
    const header = ["Name", "Status", "brand templates"];

    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });

    const payload = {
      page: assignPageTableState.page,
      perPage: assignPageTableState.perPage,
      search: assignPageSearch,
      key: assignPageTableState.sortKey,
      sort: assignPageTableState.sortOrder,
      exportStatus: true,
      viaBrand: pageBrand.arr,
      viaStatus: pageStatus.arr,
    };
    GetPagesList(payload)
      .then((res) => {
        exportData = res.data.Data;
        exportData = exportData?.map((row) => ({
          ...row,
          Name: row?.PageTitle ? row?.PageTitle : "-",
          Status: row?.status ? row.status : "-",
          "brand templates":
            row?.brandTemplete?.length > 0
              ? row.brandTemplete.map((item) => item?.templateName).join(", ")
              : "-",
        }));

        if (fileType === "csv") {
          const csvString = Papa.unparse({ fields: header, exportData });
          const blob = new Blob([csvString], {
            type: "text/csv;charset=utf-8,",
          });

          const blobURL = window.URL.createObjectURL(blob);

          // Create new tag for download file
          const anchor = document.createElement("a");
          anchor.download = fileName;
          anchor.href = blobURL;
          anchor.dataset.downloadurl = [
            "text/csv",
            anchor.download,
            anchor.href,
          ].join(":");
          anchor.click();

          // Remove URL.createObjectURL. The browser should not save the reference to the file.
          setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            URL.revokeObjectURL(blobURL);
          }, 1000);
          Swal.close();
        } else if (fileType === "xlsx") {
          const compatibleData = exportData.map((row) => {
            const obj = {};
            header.forEach((col, index) => {
              obj[col] = row[col];
            });
            return obj;
          });

          let wb = XLSX.utils.book_new();
          let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
            header,
          });
          XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
          XLSX.writeFile(wb, `${fileName}.xlsx`);
          Swal.close();
          // Returning false as downloading of file is already taken care of
          return false;
        }
        if (fileType === "pdf") {
          const compatibleData = exportData.map((row) => {
            return [row["Name"], row["Status"], row["brand templates"]];
          });
          const doc = new JsPDF();
          doc.autoTable({
            head: [header],
            body: compatibleData,
            styles: {
              minCellHeight: 10,
              minCellWidth: 5,
              halign: "left",
              // valign: "center",
              fontSize: 8,
            },
          });
          doc.save(`${fileName}.pdf`);
          Swal.close();
          return false;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(()=>{
    if(formik.values.toggle_override_info == 0){
      setImportData(false)
    }
  },[formik.values.toggle_override_info])
  
  useEffect(() => {
    setFactsheet(formik.values.options_factsheet)
  },[formik.values.options_factsheet])
  useEffect(() => {
    if (formik.values.toggle_override_info == 0) {
      setImportData(false)
      formik.setFieldValue("School", "");
      formik.setFieldValue("Delivery_Methods", []);
      formik.setFieldValue("netsuite_product", "");
      formik.setFieldValue("installments", "");
      formik.setFieldValue("programme_name","");
      formik.setFieldValue("SAQA_Name", "");
      formik.setFieldValue("SAQA_Link", "");
      formik.setFieldValue("level", "");
      formik.setFieldValue("Duration", "");
      formik.setFieldValue("Code", "");
      formik.setFieldValue("credit_status", 0);
      formik.setFieldValue("Credits","");
      formik.setFieldValue("Term_Period", "");
      formik.setFieldValue("full_time_hybrid_fee",  0);
      formik.setFieldValue("full_time_online_fee",  0);
      formik.setFieldValue("part_time_online_fee",  0);
      formik.setFieldValue("part_time_online_fee",  0);
      formik.setFieldValue("registration_fee",  0);
      formik.setFieldValue("cost_terms", "");
      formik.setFieldValue("status", "");
      formik.setFieldValue("course_delivery", []);
      formik.setFieldValue("earlyBirds", programmeDetails.earlyBirds === "" || programmeDetails.earlyBirds == null ? 2  : programmeDetails.earlyBirds);
    }
  }, [formik.values.toggle_override_info])
  
  const setPMCData = () => {
      // setLoading(true)
      setImportData(true)
      
      let deliveryMethodData = programmeDetails?.learning_method?.map((el) => el?.learning_method)
      let courseDeliveryData = programmeDetails?.course_delivery ? programmeDetails.course_delivery?.map((item) => item?.delivery_method)
        : []
      formik.setFieldValue("School", programmeDetails?.School);
      formik.setFieldValue("Delivery_Methods", deliveryMethodData ? deliveryMethodData : []);
      // formik.setFieldValue("qualification_type", programmeDetails?.qualification_type );
      formik.setFieldValue("netsuite_product", programmeDetails?.netsuite_product || "");
      formik.setFieldValue("installments", programmeDetails?.installments || "");
      formik.setFieldValue("programme_name", programmeDetails?.name);
      formik.setFieldValue("SAQA_Name", programmeDetails?.SAQA_Name || "");
      formik.setFieldValue("SAQA_Link", programmeDetails?.SAQA_Link || "");
      formik.setFieldValue("level", programmeDetails?.level || "");
      formik.setFieldValue("Duration", programmeDetails?.Duration || "");
      formik.setFieldValue("Code", programmeDetails?.Code || "");
      formik.setFieldValue("credit_status", programmeDetails?.credit_status || 0);
      formik.setFieldValue("Credits", programmeDetails?.Credits || "");
      formik.setFieldValue("Term_Period", programmeDetails?.Term_Period || "");
      formik.setFieldValue("full_time_hybrid_fee", programmeDetails?.full_time_hybrid_fee || 0);
      formik.setFieldValue("self_paced_fee", programmeDetails?.self_paced_fee || 0);
      formik.setFieldValue("full_time_online_fee", programmeDetails?.full_time_online_fee || 0);
      formik.setFieldValue("part_time_online_fee", programmeDetails?.part_time_online_fee || 0);
      formik.setFieldValue("registration_fee", programmeDetails?.registration_fee || 0);
      formik.setFieldValue("cost_terms", programmeDetails?.cost_terms || "");
      formik.setFieldValue("status", programmeDetails?.status || "");
      formik.setFieldValue("course_delivery", courseDeliveryData || []);
      formik.setFieldValue("earlyBirds",programmeDetails?.earlyBirds || 2);
      setLoading(false)
  }

  return (
    <>

      <div className="card card-profile-info-card filter-table-bg">
        {!next && type == "create" && <>
          <div className="row">
            <div className="col-md-12">
              <div className="edit-icon new-card-header">
                <h4 className="card-header" title="Short Course Page Details">Short Course Page Details</h4>
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <label htmlFor="site_Name" title="Marketing Short Course Name">Marketing Short Course Name *</label>
              <div className="form-icon-group mb-4" title="Marketing Short Course Name">
                <input
                  type="text"
                  className={`form-control ${formik.touched.name && formik.errors.name && "is-invalid"
                    }`}
                  id="name"
                  title="Marketing Short Course Name"
                  placeholder="Marketing Short Course Name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </div>
            </div>

            <div className="col-md-6 col-lg-4">
              <div className="form-group-blk mb-3" title={helpText.linkedPMC}>
                <label>
                  Linked PMC Short Course
                  <i className="fal fa-info-circle grade-icon ml-2"></i>
                </label>
                <Select
                  // styles={colourStyles}
                  className={
                    "form-control custom-select-box" +
                    (formik.errors.pmc_id &&
                      formik.touched.pmc_id
                      ? " is-invalid"
                      : "")
                  }
                  name="pmc_id"
                  value={programmeList.filter(
                    (item) => item.value == formik.values.pmc_id
                  )}
                  onChange={(value) => {
                    if (value) {
                      formik.setFieldValue("pmc_id", value.value);
                    } else {
                      formik.setFieldValue("pmc_id", "");
                    }
                  }}
                  // menuIsOpen={true}
                  onBlur={formik.handleBlur}
                  options={programmeList}
                  maxMenuHeight={175}
                  placeholder={
                    formik.values.pmc_id
                      ? formik.values.pmc_id
                      : "Select Linked PMC Short Course"
                  }
                  isClearable
                />
              </div>
            </div>

            <div className="col-md-6 col-lg-3">
              <label htmlFor="Status" title={helpText.status}>Status <i className="fal fa-info-circle"></i></label>
              <div className="form-icon-group mb-4" title={helpText.status}>
                <Select
                  className={`form-control custom-select-box ${formik.touched.publish_status && formik.errors.publish_status && "is-invalid"
                    }`}
                  id="publish_status"
                  name="publish_status"
                  // value={statusArr.find((item) => item.value === formik.values.status)}
                  onChange={(option) => formik.setFieldValue("publish_status", option.value)}
                  onBlur={formik.handleBlur}
                  options={statusArr}
                  maxMenuHeight={175}
                  placeholder="Select Status"
                  isClearable
                />
              </div>
            </div>
          </div>

          <div className="form-group form-group-save-cancel">
            <button className="btn btn-save btn-success" type="submit" title="Next" disabled={loading} onClick={handleNext}>
              Next
              {loading ? <i className="fas fa-cog fa-spin ml-2"></i> : <i className="fal fa-arrow-right ml-2"></i>}
            </button>


            <button className="btn btn-close btn-danger" type="button" title="Cancel" onClick={handlereset}>
              <i className="fal fa-times"></i>
              Cancel
            </button>
            {Object.keys(formik.values).map((key) => {
              if (formik.touched[key] && formik.errors[key]) {
                return (
                  <div className="invalid-feedback d-block" key={key}>
                    {formik.errors[key]}
                  </div>
                );
              }
            })}
          </div>
        </>}

        {((next && type == "create") || type == "open" || type == "duplicate") &&
          <div className="new-card-header">
            <div className="card-header pt-0">Short Course Details {loading ? <i className="fas fa-cog fa-spin"></i> : ""}</div>

            <div className="card-body-inr card-body-info">
              <form onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="col-md-6 col-lg-4">
                    <div className="form-group-blk mb-3">
                      <label htmlFor="name">Marketing Short Course Name *</label>
                      <input
                        type="text"
                        className={
                          "form-control" +
                          (formik.errors["name"] && formik.touched["name"]
                            ? " is-invalid"
                            : "")
                        }
                        placeholder="Enter Marketing Programme Name"
                        name="name"
                        id="name"
                        title="Marketing Short Course Name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-4">
                    <div className="form-group-blk mb-3" title={helpText2.linkedPMC}>
                      <label>
                      Linked PMC Short Course 
                        <i className="fal fa-info-circle grade-icon ml-2"></i>
                      </label>
                      <Select
                        // styles={colourStyles}
                        className={
                          "form-control custom-select-box" +
                          (formik.errors.pmc_id &&
                            formik.touched.pmc_id
                            ? " is-invalid"
                            : "")
                        }
                        name="pmc_id"
                        value={programmeList?.filter(
                          (item) => item.value == formik.values?.pmc_id
                        )}
                        onChange={(value) => {
                          if (value) {
                            formik.setFieldValue("pmc_id", value.value);
                          } else {
                            formik.setFieldValue("pmc_id", "");
                            formik.setFieldValue("toggle_override_info",0)
                            setPMC_id("")
                          }
                        }}
                        // menuIsOpen={true}
                        onBlur={formik.handleBlur}
                        options={programmeList}
                        maxMenuHeight={175}
                        placeholder={
                          formik.values.pmc_id
                            ? formik.values.pmc_id
                            : "Select Linked PMC Short Course"
                        }
                        isClearable
                      />
                    </div>
                  </div>

                  {/* <div className="col-md-6 col-lg-4">
                    <div className="form-group-blk mb-3" title="Linked PMC Short Course">
                      <label htmlFor="Linked PMC Programme">Linked PMC Short Course  <i className="fal fa-info-circle grade-icon"></i></label>
                      <Select
                        className={
                          "form-control custom-select-box " +
                          (formik.errors.pmc_id && formik.touched.pmc_id
                            ? " is-invalid"
                            : "")
                        }
                        name="Linked PMC Short Course"
                        value={programmeList.filter(
                          (item) => item.value == formik.values.pmc_id
                        )}
                        onChange={(value) => {
                          if (value) {
                            formik.setFieldValue("pmc_id", value.value);
                          } else {
                            formik.setFieldValue("pmc_id", "");
                          }
                        }}
                        onBlur={formik.handleBlur}
                        options={programmeList}
                        maxMenuHeight={175}
                        placeholder={"Select Linked PMC Short Course"}
                      />
                    </div>
                  </div> */}

                  <div className="col-md-6 col-lg-4">
                    <div className="form-group-blk mb-3" title={helpText2.status}>
                      <label>
                        Publish Status *{" "}
                        <i className="fal fa-info-circle grade-icon"></i>
                      </label>
                      <Select
                        // styles={colourStyles}
                        className={
                          "form-control custom-select-box" +
                          (formik.errors.publish_status &&
                            formik.touched.publish_status
                            ? " is-invalid"
                            : "")
                        }
                        name="publish_status"
                        value={PublishstatusArr.filter(
                          (val) => val?.value === formik.values.publish_status
                        )}
                        onChange={(value) => {
                          if (value) {
                            formik.setFieldValue("publish_status", value.value);
                          } else {
                            formik.setFieldValue("publish_status", "");
                          }
                        }}
                        // menuIsOpen={true}
                        onBlur={formik.handleBlur}
                        options={PublishstatusArr}
                        maxMenuHeight={175}
                        placeholder={
                          formik.values.publish_status
                            ? formik.values.publish_status
                            : "Select Publish Status  "
                        }
                        isClearable
                      />
                    </div>
                  </div>


                  {/* <div className="col-md-6 col-lg-4">
                  <div className="form-group-blk mb-3">
                  <label htmlFor="Duration">Duration  <i className="fal fa-info-circle grade-icon"></i></label>
                  <input
                  type="number"
                  className={
                  "form-control" +
                  (formik.errors.Duration && formik.touched.Duration
                  ? " is-invalid"
                  : "")
                  }
                  placeholder="Enter Duration"
                  name="Duration"
                  id="Duration"
                  title="Duration"
                  value={formik.values.Duration}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  />
                  </div>
                  </div> */}

                  <div className="col-md-6 col-lg-4" title="Campus Locations">
                    <label>Campus Locations </label>
                    <div className="form-icon-group mb-4">
                      <Select
                        className={
                          "form-control custom-select-box" +
                          (formik.errors.campus_location &&
                            formik.touched.campus_location
                            ? " is-invalid"
                            : "")
                        }
                        name="campus_location"
                        value={campusLocList.filter((val) =>
                          formik.values?.campus_location?.includes(val.value)
                        )}
                        onChange={(value) => {
                          formik.setFieldValue(
                            "campus_location",
                            value ? value.map((v) => v.value) : []
                          );
                        }}
                        onBlur={formik.handleBlur}
                        options={campusLocList}
                        maxMenuHeight={175}
                        placeholder={"Select Campus Location"}
                        isClearable
                        isMulti
                      />
                    </div>
                  </div>

                  <div
                    className="col-md-6 col-lg-4"
                    title={helpText2.assignedBrand}
                  >
                    <label>
                      Assigned Brand Templates *{" "}
                      <i className="fal fa-info-circle grade-icon"></i>
                    </label>
                    <div className="form-icon-group mb-4">
                      <Select
                        className={
                          "form-control custom-select-box" +
                          (formik.errors.brandID && formik.touched.brandID
                            ? " is-invalid"
                            : "")
                        }
                        name="brandID"
                        value={brandList.filter((val) =>
                          formik.values?.brandID?.includes(val.value)
                        )}
                        onChange={(value) => {
                          formik.setFieldValue(
                            "brandID",
                            value ? value.map((v) => v.value) : []
                          );
                        }}
                        onBlur={formik.handleBlur}
                        options={brandList}
                        maxMenuHeight={175}
                        placeholder={"Select Assigned Brands"}
                        isClearable
                        isMulti
                      />
                    </div>
                  </div>
                  <FileUploadField name="banner_image" title={helpText2.banner_image} label="Banner Image" formik={formik} accept=".png,.jpg,.jpeg,.svg" col_md={6} col_lg={3} />

                  {/* <div className="col-md-6 col-lg-3">
                        <div className="form-group-blk mb-3" title="Banner Image">
                        <label>Banner Image * <i className="fal fa-info-circle grade-icon"></i></label>
                        <div className="form-group atttach-file m-0">
                        <label
                        className={
                        formik.errors.banner_image &&
                        formik.touched.banner_image
                        ? "file-req is-invalid"
                        : ""
                        }
                        >
                        <i className="fal fa-upload"></i>
                        <span>Upload</span>
                        <input
                        type="file"
                        title="Upload"
                        accept="image/*"
                        className="form-control  form-control-aatch-file"
                        onChange={(e) => {
                          if (e.target.files.length) {
                        formik.setFieldValue("banner_image",e.target.files[0]);
                        formik.setFieldValue("banner_image_url",URL.createObjectURL(e.target.files[0]));                              
                        }
                        e.target.value=""
                        }}
                        />
                        </label>
                        </div>
                        {(formik.values.banner_image && formik.values.banner_image_url) && (
                        <>
                        <div className="frm-group">
                        <ul className="list-unstyled attached-file-ul m-0">
                        <li>
                        <a
                        title={formik.values.banner_image_url}
                        href={
                        formik.values.banner_image_url.includes("blob")
                        ? formik.values.banner_image_url
                        : `${downloadURL}/${IMAGE_URL}/${formik.values.banner_image_url}`
                        .replaceAll("/public/", "")
                        .replaceAll(
                        "home/myaie/public_html/",
                        ""
                        )
                        }
                        target="_blank"
                        >
                        <i className="fal fa-file"></i>
                        &nbsp;
                        {formik.values.banner_image?.name
                        ? TrimText(formik.values.banner_image?.name, 40)
                        : TrimText(
                        formik.values.banner_image_url.split(
                        "/"
                        ).reverse()[0],
                        40
                        )}
                        </a>
                        <button
                        className="btn btn-danger rounded-circle btn-dropdown-item"
                        title="Delete"
                        onClick={() => {
                          formik.setFieldValue("banner_image", "");
                        formik.setFieldValue("banner_image_url", "");
                        }}
                        >
                        <i className="fal fa-trash-alt"></i>
                        </button>
                        </li>
                        </ul>
                        </div>
                        </>
                        )}
                        </div>
                        </div> */}
                </div>
                <hr />
                {formik.values.pmc_id !== "" &&
                  <>
                    <div className="row mb-3">
                      <div className="col-12 d-flex align-items-center">
                        <div className="d-flex align-items-center">
                          <div
                            className="d-flex"
                            title={helpText2.overrideInformation}
                          >
                            <label className="mb-0">
                              Override Information <i className="fal fa-info-circle grade-icon"></i>
                            </label>
                            <div className="toggle-switch ml-2">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  checked={formik.values.toggle_override_info == "1"}
                                  onChange={(e) => {
                                    if (formik.values.toggle_override_info == "1") {
                                      formik.setFieldValue(
                                        "toggle_override_info", "0"
                                      );
                                    } else {
                                      formik.setFieldValue(
                                        "toggle_override_info", "1"
                                      );
                                    }
                                  }}
                                />
                                <span className="slider slider-round"></span>
                              </label>
                            </div>
                          </div>
                        </div>

                        {(formik.values.toggle_override_info == 1 && pmc_id) &&
                          <button
                            className="btn btn-save btn-success ml-3"
                            type="button"
                            title="Import PMC short Course"
                            onClick={() => setPMCData()}
                          >
                            {loading && (
                              <i className="fas fa-cog fa-spin"></i>
                            )}
                            Import PMC Short Course
                          </button>
                        }
                      </div>
                    </div>
                  </>}
                {(!(formik?.values?.pmc_id) || formik?.values?.toggle_override_info == "1") && (
                  <>
                    <div className="row mb-3">
                      <div className="col-md-6 col-lg-3">
                        <div className="form-group-blk mb-3">
                          <label htmlFor="name">Programme Name</label>
                          <div className="d-flex flex-column">
                            <input
                              type="text"
                              className={
                                "form-control" +
                                (formik.errors["programme_name"] && formik.touched["programme_name"]
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder="Enter Programme Name"
                              name="programme_name"
                              id="programme_name"
                              title="Programme Name"
                              value={formik.values["programme_name"]}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {importData && programmeDetails?.name !== formik.values.programme_name && (
                              <div className="mt-1">
                                <span className="reset-button" onClick={() => {
                                  formik.setFieldValue("programme_name", programmeDetails.name);
                                }}>
                                  <i className="fal fa-undo"></i> Reset Changes
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 col-lg-3">
                        <div className="form-group-blk mb-3" title="Netsuite Product">
                          <label>
                            Netsuite Product {" "}
                            <i className="fal fa-info-circle grade-icon"></i>
                          </label>
                          <div className="d-flex flex-column">
                            <Select
                              className={
                                "mb-1 form-control custom-select-box" +
                                (formik.errors.netsuite_product &&
                                  formik.touched.netsuite_product
                                  ? " is-invalid"
                                  : "")
                              }
                              name="netsuite_product"
                              value={netsuiteProdList.filter(
                                (val) => val?.value == formik.values.netsuite_product
                              )}
                              onChange={(value) => {
                                if (value) {

                                  formik.setFieldValue("netsuite_product", value.value);
                                  formik.setFieldValue("netsuite_department_id", value.departmentId);
                                } else {
                                  formik.setFieldValue("netsuite_product", "");
                                  formik.setFieldValue("netsuite_department_id", "");
                                }
                              }}
                              onBlur={formik.handleBlur}
                              options={netsuiteProdList}
                              maxMenuHeight={175}
                              placeholder={"Select Netsuite Product"}
                              isClearable
                            />
                            {importData && (programmeDetails?.netsuite_product !== null ? programmeDetails?.netsuite_product : "") !== formik.values.netsuite_product && (
                              <div>
                                <span className="reset-button" onClick={() => {
                                  formik.setFieldValue("netsuite_product", programmeDetails.netsuite_product);
                                }}>
                                  <i className="fal fa-undo"></i> Reset Changes
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 col-lg-3">
                        <div className="form-group-blk mb-3" title="School">
                          <label htmlFor="school">School </label>
                          <div className="d-flex flex-column">
                            <Select
                              className={
                                "mb-1 form-control custom-select-box " +
                                (formik.errors.School && formik.touched.School
                                  ? " is-invalid"
                                  : "")
                              }
                              name="School"
                              value={schoolList.filter(
                                (item) => item.value == formik.values.School
                              )}
                              onChange={(value) => {
                                if (value) {
                                  formik.setFieldValue("School", value.value);
                                } else {
                                  formik.setFieldValue("School", "");
                                }
                              }}
                              onBlur={formik.handleBlur}
                              options={schoolList}
                              maxMenuHeight={175}
                              placeholder={"Select School"}
                              isClearable
                            />
                            {importData && ((programmeDetails?.School ? programmeDetails?.School : "") !== formik.values.School) && (
                              <div>
                                <span className="reset-button" onClick={() => {
                                  formik.setFieldValue("School", programmeDetails.School);
                                }}>
                                  <i className="fal fa-undo"></i> Reset Changes
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 col-lg-3">
                        <div className="form-group-blk mb-3" title="School">
                          <label htmlFor="school">Credit Bearing <i className="fal fa-info-circle grade-icon"></i></label>
                          <div className="d-flex flex-column">
                            <Select
                              className={
                                "mb-1 form-control custom-select-box" +
                                (formik.errors.credit_status &&
                                  formik.touched.credit_status
                                  ? " is-invalid"
                                  : "")
                              }
                              name="credit_status"
                              value={creditStatusList && creditStatusList.filter(val => val?.value == formik.values.credit_status)}
                              onChange={(value) => {
                                if (value) {
                                  formik.setFieldValue("credit_status", value.value)
                                } else {
                                  formik.setFieldValue("credit_status", "")
                                }
                              }}
                              onBlur={formik.handleBlur}
                              options={creditStatusList}
                              maxMenuHeight={175}
                              placeholder={
                                formik.values.credit_status
                                  ? formik.values.credit_status
                                  : "Select Credits  "
                              }
                              isClearable
                            />
                            {importData && ((programmeDetails?.credit_status ? programmeDetails?.credit_status : 0) !== formik.values.credit_status) && (
                              <div>
                                <span className="reset-button" onClick={() => {
                                  formik.setFieldValue("credit_status", programmeDetails.credit_status);
                                }}>
                                  <i className="fal fa-undo"></i> Reset Changes
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                                {/* {console.log(">>>data",(importData && formik.values?.credit_status !== "") && programmeDetails?.credit_status !== formik.values?.credit_status)} */}

                      {/* <div className="col-md-6 col-lg-3">
                                        <div
                                        className="form-group-blk mb-3"
                                        title="Qualification Type"
                                        >
                                        <label htmlFor="qualification_type">
                                        Qualification Type
                                        </label>
                                        <div className="d-flex flex-column">
                                        <Select
                                        className={
                                        "form-control custom-select-box " +
                                        (formik.errors.qualification_type &&
                                        formik.touched.qualification_type
                                        ? " is-invalid"
                                        : "")
                                        }
                                        name="qualification_type"
                                        value={dropDownValues?.programs.filter((item) => {
                                          return item.value == formik.values.qualification_type;
                                        })}
                                        onChange={(value) => {
                                          if (value) {
                                        formik.setFieldValue(
                                        "qualification_type",
                                        value.value
                                        );
                                        } else {
                                        formik.setFieldValue("qualification_type", "");
                                        }
                                        }}
                                        onBlur={formik.handleBlur}
                                        options={dropDownValues?.programs}
                                        maxMenuHeight={175}
                                        placeholder={"Select Qualification Type"}
                                        />
                                        {programmeDetails?.qualification_type !== formik.values.Schoqualification_typeol && (
                                        <div>
                                        <span className="reset-button" onClick={() => {
                                          formik.setFieldValue("qualification_type", programmeDetails.qualification_type);
                                        }}>
                                        <i className="fal fa-undo"></i> Reset Changes
                                        </span>
                                        </div>
                                        )}   
                                        </div>
                                        </div>
                                        </div> */}

                      <div className="col-md-6 col-lg-3">
                        <div className="form-group-blk mb-3">
                          <label htmlFor="code">Code 
                          {/* {importData && <i className="fal fa-ban cat-ban ml-2"></i>} */}
                          </label>
                          <input
                            type="text"
                            className={
                              "block-input form-control" +
                              (formik.errors.Code && formik.touched.Code
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="Enter code"
                            name="Code"
                            id="Code"
                            disabled={importData}
                            title="Code"
                            value={formik.values.Code}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                      </div>

                      <div className="col-md-6 col-lg-3">
                        <div className="form-group-blk mb-3">
                          <label htmlFor="name">SAQA Registered Name </label>
                          <div className="d-flex flex-column">
                            <input
                              type="text"
                              className={
                                "mb-1 form-control" +
                                (formik.errors["SAQA_Name"] &&
                                  formik.touched["SAQA_Name"]
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder="Enter SAQA Name"
                              name="SAQA_Name"
                              id="SAQA_Name"
                              title="SAQA Registered Name"
                              value={formik.values["SAQA_Name"]}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {importData && formik.values.SAQA_Name !== "" && (programmeDetails?.SAQA_Name == null ? "" : programmeDetails?.SAQA_Name) !== formik.values.SAQA_Name && (
                              <div>
                                <span className="reset-button" onClick={() => {
                                  formik.setFieldValue("SAQA_Name", programmeDetails.SAQA_Name || "");
                                }}>
                                  <i className="fal fa-undo"></i> Reset Changes
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 col-lg-3">
                        <div className="form-group-blk mb-3">
                          <label htmlFor="SAQA_Link">
                            SAQA Link{" "}
                            <i className="fal fa-info-circle grade-icon"></i>
                          </label>
                          <div className="d-flex flex-column">
                            <input
                              type="text"
                              className={
                                "mb-1 form-control" +
                                (formik.errors["SAQA_Link"] &&
                                  formik.touched["SAQA_Link"]
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder="Enter SAQA Link"
                              name="SAQA_Link"
                              id="SAQA_Link"
                              title="SAQA Link"
                              value={formik.values["SAQA_Link"]}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {importData && formik.values.SAQA_Link !== "" && (programmeDetails?.SAQA_Link == null ? "" : programmeDetails?.SAQA_Link) !== formik.values.SAQA_Link && (
                              <div>
                                <span className="reset-button" onClick={() => {
                                  formik.setFieldValue("SAQA_Link", programmeDetails.SAQA_Link || "");
                                }}>
                                  <i className="fal fa-undo"></i> Reset Changes
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 col-lg-3">
                        <div className="form-group-blk mb-3" title={helpText2.duration}>
                          <label htmlFor="Duration">Duration  <i className="fal fa-info-circle grade-icon"></i></label>
                          <input
                            type="number"
                            className={
                              "mb-1 form-control" +
                              (formik.errors.Duration && formik.touched.Duration
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="Enter Duration"
                            name="Duration"
                            id="Duration"
                            title="Duration"
                            value={formik.values.Duration}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {importData && (programmeDetails?.Duration || "") !== formik.values.Duration && (
                            <div>
                              <span className="reset-button" onClick={() => {
                                formik.setFieldValue("Duration", programmeDetails.Duration);
                              }}>
                                <i className="fal fa-undo"></i> Reset Changes
                              </span>
                            </div>
                          )}
                        </div>
                      </div>


                      <div className="col-md-6 col-lg-3">
                        <div className="form-group-blk mb-3" title="Level">
                          <label htmlFor="NQF_Level">Level </label>
                          <div className="d-flex flex-column">
                            <Select
                              className={
                                "mb-1 form-control custom-select-box" +
                                (formik.errors.level &&
                                  formik.touched.level
                                  ? " is-invalid"
                                  : "")
                              }
                              name="level"
                              value={levelList.filter((obj) => {
                                return obj.value == formik.values.level
                              })}
                              onChange={(value) => {
                                if (value) {
                                  formik.setFieldValue("level", value.value)
                                } else {
                                  formik.setFieldValue("level", "")
                                }
                              }}
                              onBlur={formik.handleBlur}
                              options={levelList}
                              maxMenuHeight={175}
                              placeholder={
                                formik.values.level
                                  ? formik.values.level
                                  : "Select Level  "
                              }
                              isClearable
                            />
                            {importData &&  (programmeDetails?.level !== formik.values.level) && (
                              <div>
                                <span className="reset-button" onClick={() => {
                                  formik.setFieldValue("level", programmeDetails.level);
                                }}>
                                  <i className="fal fa-undo"></i> Reset Changes
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 col-lg-3">
                        <div className="form-group-blk mb-3">
                          <label htmlFor="credit">
                            Credits 
                            {/* {importData &&<i className="fal fa-ban cat-ban ml-2"></i>} */}
                          </label>
                          <input
                            type="number"
                            className={
                              "block-input form-control" +
                              (formik.errors.Credits && formik.touched.Credits
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="Enter Credits"
                            name="Credits"
                            id="Credits"
                            disabled={importData}
                            title="Credits"
                            value={formik.values.Credits}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                      </div>

                      {formik.values.toggle_override_info == "12545" && <div className="col-md-6 col-lg-3">
                        <div className="form-group-blk mb-3" title="School">
                          <label htmlFor="school">Credit Bearing <i className="fal fa-info-circle grade-icon"></i></label>
                          <div className="d-flex flex-column">
                            <Select
                              className={
                                "mb-1 form-control custom-select-box" +
                                (formik.errors.credit_status &&
                                  formik.touched.credit_status
                                  ? " is-invalid"
                                  : "")
                              }
                              name="credit_status"
                              value={creditStatusList && creditStatusList.filter(val => val?.value == formik.values.credit_status)}
                              onChange={(value) => {
                                if (value) {
                                  formik.setFieldValue("credit_status", value.value)
                                } else {
                                  formik.setFieldValue("credit_status", "")
                                }
                              }}
                              onBlur={formik.handleBlur}
                              options={creditStatusList}
                              maxMenuHeight={175}
                              placeholder={
                                formik.values.credit_status
                                  ? formik.values.credit_status
                                  : "Select Credits  "
                              }
                              isClearable
                            />
                            {importData && (programmeDetails?.credit_status || "") !== formik.values.credit_status && (
                              <div>
                                <span className="reset-button" onClick={() => {
                                  formik.setFieldValue("credit_status", programmeDetails.credit_status);
                                }}>
                                  <i className="fal fa-undo"></i> Reset Changes
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>}


                      <div className="col-md-6 col-lg-6" title="Learning Methods">
                        <label>Learning Methods </label>
                        <div className="form-icon-group mb-1">
                          <Select
                            className={
                              "form-control custom-select-box" +
                              (formik.errors.Delivery_Methods &&
                                formik.touched.Delivery_Methods
                                ? " is-invalid"
                                : "")
                            }
                            name="Delivery_Methods"
                            value={dropDownValues?.study_type?.filter((data) =>
                              formik.values.Delivery_Methods?.includes(data.value)
                            )}
                            onChange={(value) =>
                              formik.setFieldValue(
                                "Delivery_Methods",
                                value ? value.map((v) => v.value) : []
                              )
                            }
                            onBlur={formik.handleBlur}
                            options={dropDownValues?.study_type}
                            maxMenuHeight={175}
                            placeholder={"Select Learning Method"}
                            isClearable
                            isMulti
                          />
                        </div>

                        {importData &&
                          programmeDetails?.learning_method &&
                          JSON.stringify(
                            formik.values.Delivery_Methods.sort() 
                          ) !== JSON.stringify(
                            programmeDetails.learning_method.map(el => el.learning_method).sort() 
                          ) && (
                            <div>
                              <span className="reset-button" onClick={() => {
                                formik.setFieldValue("Delivery_Methods", programmeDetails.learning_method.map(el => el.learning_method));
                              }}>
                                <i className="fal fa-undo"></i> Reset Changes
                              </span>
                            </div>
                          )}
                      </div>

                      <div className="col-md-6 col-lg-auto">
                        <div className="form-group-blk mb-3">
                          <div className="d-flex">
                            <label htmlFor="course_delivery">Course Delivery</label>
                            {importData &&
                              programmeDetails?.course_delivery &&
                              JSON.stringify([...formik.values.course_delivery].sort()) !==
                              JSON.stringify([...programmeDetails.course_delivery.map(item => item.delivery_method)].sort()) && (
                                <div>
                                  <span
                                    className="reset-button ml-3"
                                    onClick={() => {
                                      formik.setFieldValue(
                                        "course_delivery",
                                        programmeDetails.course_delivery.map(item => item.delivery_method)
                                      );
                                    }}
                                  >
                                    <i className="fal fa-undo"></i> Reset Changes
                                  </span>
                                </div>
                              )}
                          </div>
                          <div className="d-flex mt-3">
                            <div className="custom-control custom-checkbox text-left">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="scheduled"
                                name="scheduled"
                                checked={formik.values.course_delivery.includes("scheduled")}
                                onChange={() => {
                                  const prevVal = [...formik.values.course_delivery];
                                  if (prevVal.includes("scheduled")) {
                                    // Remove the item
                                    const newValue = prevVal.filter(item => item !== "scheduled");
                                    formik.setFieldValue("course_delivery", newValue);
                                  } else {
                                    // Add the item
                                    prevVal.push("scheduled");
                                    formik.setFieldValue("course_delivery", prevVal);
                                  }
                                }}
                              />
                              <label className="custom-control-label" htmlFor="scheduled">
                                Instructor-Led Classes (Scheduled)
                              </label>
                            </div>
                            <div className="custom-control custom-checkbox text-left ml-3">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="mentored"
                                name="mentored"
                                checked={formik.values.course_delivery.includes("mentored")}
                                onChange={() => {
                                  const prevVal = [...formik.values.course_delivery];
                                  if (prevVal.includes("mentored")) {
                                    // Remove the item
                                    const newValue = prevVal.filter(item => item !== "mentored");
                                    formik.setFieldValue("course_delivery", newValue);
                                  } else {
                                    // Add the item
                                    prevVal.push("mentored");
                                    formik.setFieldValue("course_delivery", prevVal);
                                  }
                                }}
                              />
                              <label className="custom-control-label" htmlFor="mentored">
                                Mentored
                              </label>
                            </div>
                            <div className="custom-control custom-checkbox text-left ml-3">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="selfpaced"
                                name="selfpaced"
                                checked={formik.values.course_delivery.includes("selfpaced")}
                                onChange={() => {
                                  const prevVal = [...formik.values.course_delivery];
                                  if (prevVal.includes("selfpaced")) {
                                    // Remove the item
                                    const newValue = prevVal.filter(item => item !== "selfpaced");
                                    formik.setFieldValue("course_delivery", newValue);
                                  } else {
                                    // Add the item
                                    prevVal.push("selfpaced");
                                    formik.setFieldValue("course_delivery", prevVal);
                                  }
                                }}
                              />
                              <label className="custom-control-label" htmlFor="selfpaced">
                                Self-Paced
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12 flex">
                        <div className="card-header pt-0 d-flex align-items-center">
                          <i
                            className="fal fa-usd-circle mr-2"
                            style={{ color: "var(--topbar-color)" }}
                          ></i> Pricing
                          {importData && (
                            [
                              { key: 'full_time_hybrid_fee', defaultValue: 0 },
                              { key: 'full_time_online_fee', defaultValue: 0 },
                              { key: 'self_paced_fee', defaultValue: 0 },
                              { key: 'part_time_online_fee', defaultValue: 0 },
                              { key: 'registration_fee', defaultValue: 0 },
                              { key: 'cost_terms', defaultValue: '' },
                              { key: 'installments', defaultValue: '' },
                              { key: 'earlyBirds', defaultValue: 2 } // Keep it consistent in both places
                            ].some(({ key, defaultValue }) =>
                              (programmeDetails?.[key] ?? defaultValue) !== formik.values[key]
                            ) && (
                              <div>
                                <span
                                  className="reset-button"
                                  onClick={() => {
                                    [
                                      { key: 'full_time_hybrid_fee', defaultValue: 0 },
                                      { key: 'full_time_online_fee', defaultValue: 0 },
                                      { key: 'self_paced_fee', defaultValue: 0 },
                                      { key: 'part_time_online_fee', defaultValue: 0 },
                                      { key: 'registration_fee', defaultValue: 0 },
                                      { key: 'cost_terms', defaultValue: '' },
                                      { key: 'installments', defaultValue: '' },
                                      { key: 'earlyBirds', defaultValue: 2 } // Keep it consistent in both places
                                    ].forEach(({ key, defaultValue }) => {
                                      formik.setFieldValue(key, programmeDetails?.[key] ?? defaultValue);
                                    });
                                  }}
                                >
                                  <i className="fal fa-undo ml-2"></i> Reset Changes
                                </span>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                      <div className="col-md-8 col-lg-3">
                        <div className="form-group-blk mb-3">
                          <label htmlFor="full_time_hybrid_fee">
                            Full Time, Hybrid Fee {formik.values.Delivery_Methods.includes("full") && "*"}
                          </label>
                          <div>
                            <span className="pricing-before-text">R</span>
                            <input
                              type="number"
                              className={
                                "input-price form-control" +
                                (formik.errors.full_time_hybrid_fee &&
                                  formik.touched.full_time_hybrid_fee
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder="Enter Full Time, Hybrid Fee"
                              name="full_time_hybrid_fee"
                              id="full_time_hybrid_fee"
                              title="Full Time, Hybrid Fee"
                              value={formik.values.full_time_hybrid_fee}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-8 col-lg-3">
                        <div className="form-group-blk mb-3">
                          <label htmlFor="full_time_online_fee">
                            Full Time, Online Fee {formik.values.Delivery_Methods.includes("fulltimeonline") && "*"}
                          </label>
                          <div>
                            <span className="pricing-before-text">R</span>
                            <input
                              type="number"
                              className={
                                "input-price form-control" +
                                (formik.errors.full_time_online_fee &&
                                  formik.touched.full_time_online_fee
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder="Enter Full Time, Online Fee"
                              name="full_time_online_fee"
                              id="full_time_online_fee"
                              title="Full Time, Online Fee"
                              value={formik.values.full_time_online_fee}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-8 col-lg-3">
                        <div className="form-group-blk mb-3">
                          <label htmlFor="part_time_online_fee">
                            Part Time, Online Fee {formik.values.Delivery_Methods.includes("parttimeonline") && "*"}
                          </label>
                          <div>
                            <span className="pricing-before-text">R</span>
                            <input
                              type="number"
                              className={
                                "input-price form-control" +
                                (formik.errors.part_time_online_fee &&
                                  formik.touched.part_time_online_fee
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder="Enter Part Time, Online Fee"
                              name="part_time_online_fee"
                              id="part_time_online_fee"
                              title="Part Time, Online Fee"
                              value={formik.values.part_time_online_fee}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-8 col-lg-3">
                        <div className="form-group-blk mb-3">
                          <label htmlFor="self_paced_fee">
                            Self-Paced Fee {formik.values.Delivery_Methods.includes("selfplaced") && "*"}
                          </label>
                          <div>
                            <span className="pricing-before-text">R</span>
                            <input
                              type="number"
                              className={
                                "input-price form-control" +
                                (formik.errors.self_paced_fee &&
                                  formik.touched.self_paced_fee
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder="Enter Self-Paced Fee"
                              name="self_paced_fee"
                              id="self_paced_fee"
                              title="Self-Paced Fee"
                              value={formik.values.self_paced_fee}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                        </div>
                      </div>
                      
                      <div className="col-md-8 col-lg-3">
                        <div className="form-group-blk mb-3">
                          <label htmlFor="registration_fee">Registration Fee </label>
                          <div>
                            <span className="pricing-before-text">R</span>
                            <input
                              type="number"
                              className={
                                "input-price form-control" +
                                (formik.errors.registration_fee &&
                                  formik.touched.registration_fee
                                  ? " is-invalid"
                                  : "")
                              }
                              placeholder="Enter Registration Fee"
                              name="registration_fee"
                              id="registration_fee"
                              title="Registration Fee"
                              value={formik.values.registration_fee}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-9">
                        <div className="form-group-blk mb-3">
                          <label htmlFor="cost_terms">Cost Terms</label>
                          <input
                            className={
                              "form-control" +
                              (formik.errors.cost_terms && formik.touched.cost_terms
                                ? " is-invalid"
                                : "")
                            }
                            placeholder="Enter Cost Terms"
                            name="cost_terms"
                            id="cost_terms"
                            title="Cost Terms"
                            value={formik.values.cost_terms}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <div className="row">
                          <div className="col-auto">
                            <div className="form-group-blk mb-3">
                              <label htmlFor="installments">Instalments</label>
                              <div className="d-flex">
                                {["2M", "3M", "6M", "10M", "12M", "18M", "24M"].map(
                                  (option) => (
                                    <div
                                      className="custom-control custom-checkbox text-left ml-2"
                                      key={option}
                                    >
                                      <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id={option}
                                        name={option}
                                        checked={formik.values.installments?.split(",")?.includes(option)}
                                        onChange={() => handleChangeInstallments(option)}
                                      />
                                      <label
                                        className="custom-control-label label-instalments"
                                        htmlFor={option}
                                      >
                                        {option}
                                      </label>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-lg-4">
                            <div className="form-group-blk mb-3">
                              <label htmlFor="early_bird_discount">
                                Early Bird Discount
                              </label>
                              <div className="d-flex">
                                <div className="custom-control custom-checkbox text-left">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="5_percent"
                                    name="5_percent"
                                    checked={formik.values.earlyBirds == 1}
                                    onChange={() => {
                                      if (formik.values.earlyBirds == 1) {
                                        formik.setFieldValue("earlyBirds", "2");
                                      } else {
                                        formik.setFieldValue("earlyBirds", "1");
                                      }
                                    }}                                   />
                                  <label
                                    className="custom-control-label label-instalments"
                                    htmlFor="5_percent"
                                  >
                                    5%
                                  </label>
                                </div>
                                <div className="custom-control custom-checkbox text-left">
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="10_percent"
                                    name="10_percent"
                                    checked={formik.values.earlyBirds == 0}
                                    onChange={() => {
                                      if (formik.values.earlyBirds == 0) {
                                        formik.setFieldValue("earlyBirds", "2");
                                      } else {
                                        formik.setFieldValue("earlyBirds", "0");
                                      }
                                    }}                                   />
                                  <label
                                    className="custom-control-label label-instalments"
                                    htmlFor="10_percent"
                                  >
                                    10%
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* <div className="col-12">
                                                                            <div className="form-group-blk mb-3">
                                                                            <label htmlFor="cost_terms">Cost Terms</label>
                                                                            <input
                                                                            className={
                                                                            "form-control" +
                                                                            (formik.errors.cost_terms && formik.touched.cost_terms
                                                                            ? " is-invalid"
                                                                            : "")
                                                                            }
                                                                            placeholder="Enter Cost Terms"
                                                                            name="cost_terms"
                                                                            id="cost_terms"
                                                                            title="Cost Terms"
                                                                            value={formik.values.cost_terms}
                                                                            onChange={formik.handleChange}
                                                                            onBlur={formik.handleBlur}
                                                                            />
                                                                            </div>
                                                                            </div> */}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <hr />
                <div className="row">
                  <div className="col-12 ">
                    <div className="card-header pt-0">
                      <i
                        className="fal fa-cog"
                        style={{ color: "var(--topbar-color)" }}
                      ></i> Programme Settings
                    </div>
                  </div>

                  <div className={`${formik.values.options_factsheet === 2 ? "col-md-12" : "col-md-6"} col-lg-auto`}>
                    <div className="form-group-blk mb-3" title="This allows you to select how the factsheet should be managed. When none is selected, there will be no fact sheet on the page, when auto generate is selected, the factsheet will be generated from PMC data. When upload is selected, you will be able to upload a pdf file that will be used as the fact sheet">
                      <label htmlFor="early_bird_discount">Factsheet Options <i className="fal fa-info-circle"></i></label>
                      <div className="d-flex form-group-blk">
                        <div className="custom-control custom-radio text-left mr-3">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="options_factsheet-none"
                            name="options_factsheet"
                            checked={formik.values.options_factsheet == 0}
                            onChange={() => formik.setFieldValue("options_factsheet", 0)}
                          />
                          <label
                            className="custom-control-label label-instalments"
                            htmlFor="options_factsheet-none"
                          >
                            None
                          </label>
                        </div>

                        <div className="custom-control custom-radio text-left mr-3">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="options_factsheet-auto"
                            name="options_factsheet"
                            checked={formik.values.options_factsheet === 1}
                            onChange={() => formik.setFieldValue("options_factsheet", 1)}
                          />
                          <label
                            className="custom-control-label label-instalments text-nowrap"
                            htmlFor="options_factsheet-auto"
                          >
                            Auto Generate
                          </label>
                        </div>

                        <div className="custom-control custom-radio text-left mr-3">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="options_factsheet-upload"
                            name="options_factsheet"
                            checked={formik.values.options_factsheet === 2}
                            onChange={() => formik.setFieldValue("options_factsheet", 2)}
                          />
                          <label
                            className="custom-control-label label-instalments"
                            htmlFor="options_factsheet-upload"
                          >
                            Upload
                          </label>
                        </div>
                        {formik.values.options_factsheet !== "10" && formik.values.options_factsheet === 2 && (
                          <FileUploadField label={"Factsheet PDF"} required name={"factsheet_pdf"} formik={formik} col_lg={6} mbTrue={false}/>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 col-lg-auto">
                    <div className="form-group-blk mb-3" title="This allows you to select the terms and conditions page for the short course. When default is selected, the terms and conditions assigned to the brand template will be used. When override terms and conditions is selected you will be able to assign a static page that contains custom terms & conditions, the available selection is restricted to pages with the same brand templates assigned.">
                      <label htmlFor="early_bird_discount">
                        Terms & Conditions Options <i className="fal fa-info-circle"></i>
                      </label>
                      <div className="d-flex form-group-blk">
                        <div className="custom-control custom-radio text-left">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="override_term_default"
                            name="5_percent"
                            checked={formik.values.override_default_tc == 0}
                            onChange={() => formik.setFieldValue("override_default_tc", 0)}
                          />
                          <label
                            className="custom-control-label label-instalments"
                            htmlFor="override_term_default"
                          >
                            Default
                          </label>
                        </div>

                        <div className="custom-control custom-radio text-left">
                          <input
                            type="radio"
                            className="custom-control-input"
                            id="override_term"
                            name="override_default_tc"
                            checked={formik.values.override_default_tc == 1}
                            onChange={() => formik.setFieldValue("override_default_tc", "1")}
                          />
                          <label
                            className="custom-control-label label-instalments"
                            htmlFor="override_term"
                          >
                            Override Terms & Conditions
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {formik.values.override_default_tc == 1 ? (
                    <div className="col-md-6 col-lg-auto">
                      <div className="d-flex align-items-start flex-column">
                        <label>
                          Assigned Terms & Conditions Page {formik.values.override_default_tc == 1 && "*"}
                          <i className="fal fa-info-circle grade-icon ml-2"></i>
                        </label>
                        <button
                          className={
                            "btn btn-save btn-success" +
                            (formik.errors.terms_conditions_page &&
                              formik.touched.terms_conditions_page
                              ? " file-req is-invalid"
                              : "")
                          }
                          type="button"
                          title="Assign Page"
                          data-toggle="modal"
                          data-target="#assignPagePopup"
                        >
                          <i className="fal fa-plus"></i> Assign Page
                        </button>
                        {formik.values.terms_conditions_page
                          ? allpageListData
                            ?.filter(
                              (page) =>
                                page.PageID ===
                                formik.values.terms_conditions_page
                            )
                            .map((assignedPage) => (
                              <div
                                className="frm-group"
                                key={assignedPage.PageID}
                              >
                                <ul className="list-unstyled attached-file-ul">
                                  <li>
                                    <a
                                      target="_blank"
                                      href={`/website_management/pages/staticpages/table/update/${assignedPage.PageID}`}
                                    >
                                      <span
                                        className="textLimit100 as-text-blue mr-3"
                                        title={assignedPage.PageTitle}
                                      >
                                        {TrimText(assignedPage.PageTitle, 30)}
                                      </span>
                                    </a>
                                    <button
                                      className="btn btn-danger rounded-circle btn-dropdown-item"
                                      title="Delete"
                                      onClick={() => {
                                        formik.setFieldValue(
                                          "terms_conditions_page",
                                          ""
                                        );
                                      }}
                                    >
                                      <i className="fal fa-trash-alt"></i>
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            ))
                          : null}
                      </div>
                    </div>
                  ) : ""}
                  <div
                    className="topic-add-modal modal able_modal01 fade"
                    id="assignPagePopup"
                    tabIndex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalCenterTitle"
                    aria-hidden="true"
                  >
                    <div
                      className="modal-dialog modal-dialog-centered available-assessment-modal"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header modal-header-custom">
                          <h5 className="modal-title">
                            <i className="fal fa-plus"></i> Assign Page
                          </h5>
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">×</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <DataTableComponent
                            data={assignPageList}
                            loading={assignPageLoading}
                            state={assignPageTableState}
                            setState={setAssignPageTableState}
                            setSearch={setAssignPageSearch}
                            totalRows={assignPageTotalRows}
                            columns={assignPageColumn}
                            exportFunction={exportAssignPageData}
                            exportFileName={"Assign_Page"}
                            isInsidePopUp={true}
                            filters={[
                              {
                                filterName: "Status",
                                optionArr: PageFilterData.status,
                                state: pageStatus,
                                setState: setPageStatus,
                                renderLabelFunction: RenderPMCPageStatus,
                              },

                              {
                                filterName: "Brand Templates",
                                optionArr: PageFilterData.BrandList,
                                state: pageBrand,
                                isOptionReversed: false,
                                stopOptionSorting: true,
                                setState: setPageBrand,
                                // renderLabelFunction: RenderPageBrands,
                              },
                            ]}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group form-group-save-cancel mt-4">
                  <button
                    className="btn btn-save btn-success"
                    type="submit"
                    title="Save"
                    disabled={loading || (type == "open" && !formik.dirty)}
                    onClick={formik.handleSubmit}
                  >
                    {loading ? (
                      <i className="fas fa-cog fa-spin"></i>
                    ) : (
                      <i className="fal fa-save"></i>
                    )}
                    Save
                  </button>
                  {
                    formik.values.options_factsheet == 1 && (
                      <button className="btn btn-primary" onClick={handleDownload} type="button" disabled={buttonLoading}>
                        {buttonLoading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-file-alt"></i>}
                        Preview
                      </button>
                    )
                  }

                  <button
                    className="btn btn-close btn-danger"
                    type="button"
                    title="Cancel"
                    onClick={() => {
                      if (type == "open" || type == "update") {
                        formik.resetForm()
                        setUpdateData(Date.now())
                      }
                      else {
                        history.push("/websiteManagement/pages/programmes_and_short_courses/programme_pages/table")
                      }
                    }
                    }
                  >
                    <i className="fal fa-times"></i>Cancel
                  </button>
                </div>
                {/** map function to render all errors  */}
                {Object.keys(formik.values).map((key) => {
                  if (formik.touched[key] && formik.errors[key]) {
                    return (
                      <div className="invalid-feedback d-block" key={key}>
                        {formik.errors[key]}
                      </div>
                    );
                  }
                })}
              </form>
            </div>
          </div>}
      </div>
      <div
        className="topic-add-modal modal able_modal01 fade"
        id="assignPagePopup"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered available-assessment-modal"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header modal-header-custom">
              <h5 className="modal-title">
                <i className="fal fa-plus"></i> Assign Page
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <DataTableComponent
                data={assignPageList}
                loading={assignPageLoading}
                state={assignPageTableState}
                setState={setAssignPageTableState}
                setSearch={setAssignPageSearch}
                totalRows={assignPageTotalRows}
                columns={assignPageColumn}
                exportFunction={exportAssignPageData}
                exportFileName={"Assign_Page"}
                isInsidePopUp={true}
                filters={[
                  {
                    filterName: "Status",
                    optionArr: PageFilterData.status,
                    state: pageStatus,
                    setState: setPageStatus,
                    renderLabelFunction: RenderPMCPageStatus,
                  },

                  {
                    filterName: "Brand Templates",
                    optionArr: PageFilterData.BrandList,
                    state: pageBrand,
                    isOptionReversed: false,
                    stopOptionSorting: true,
                    setState: setPageBrand,
                    // renderLabelFunction: RenderPageBrands,
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShortCoursePageDetails;
