import React, { useState, useMemo, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Link, useHistory, useParams } from "react-router-dom";
import {
  TABLE_DATE_FORMAT,
  TABLE_ROWS_PER_PAGE,
} from "../../../utils/Constants";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import PermissionsGate from "../../../utils/permissionGate";
import { RenderQuizAttemptResultStatus } from "../../../utils/CommonStatusItems";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import {
  assessmentExemptionToggle,
  exemptionToggle,
  getProgrameGradeMixGradebook,
  getProgrameGradeMixGradebookCancelToken,
  getStudentBrand,
  getStudentBrandCancelToken,
  getStudentProgrammeGradeDetails,
  getStudentProgrammeGradeDetailsCancelToken,
  updateProgameScore,
  updateProgrammeScore,
} from "../../../services/GradeSettingService";
import moment from "moment";
import hasPermission from "../../../utils/hasMultiplePermission";
import { useSelector } from "react-redux";
import GradeBookSaveBar from "../../common/GradeBookSaveBar";
import { PDFDownloadLink, pdf } from "@react-pdf/renderer";
import LetterheadPDF from "../../../LetterheadPDF";
import { RenderLearningMethodGradebook } from "../../../utils/CommonGroupingItem";
import $, { parseHTML } from 'jquery'
import { handleTableScroll } from "../../../utils/commonFunction";
import axios from "axios";
import LetterHeadPDF2 from "../../../LetterHeadPDF2";
import { GetProgrammeStudentCancelToken } from "../../../services/ProgrammeServices";
import LetterHeadPDF3 from "../../../LetterHeadPDF3";
import html2canvas from "html2canvas";
import { GetBrandSettingDetail } from "../../../services/BrandServices";

const ProgrammeGrademixGradeBookTable = ({ studentDetails, programmeDetails }) => {
  const state = localStorage.getItem("state");
  const brandVisuals = JSON.parse(state).brandVisuals.config;
  const { id, subTab } = useParams();
  const history = useHistory();
  const [expandedRows, setExpandedRows] = useState([]);
  const [studentGradeBookData, setStudentGradeBookData] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [programmeTotal, setProgrammeTotal] = useState({});
  const [aggregationMethodId, setAggregationMethodId] = useState();
  const [gradingType, setGradingType] = useState();
  const [percentageView, setPercentageView] = useState(false);
  const [saveGradesLoading, setSaveGradesLoading] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const [pdfData, setPDFData] = useState([]);
  const [pdfDownloading, setPdfDownloading] = useState(false);
  const [isProgrammeEdited, setIsProgrammeEdited] = useState(0);
  const givenPermsisions = useSelector((state) => state.givenPermission);
  const [isChecked, setIsChecked] = useState(false);
  const [isShowFinal, setIsShowFinal] = useState(false);
  const [isExcludeGrade, setIsExcludeGrade] = useState(false);
  const [brandData, setBrandData] = useState({});
  const [updateValueArr, setUpdateValueArr] = useState([]);
  const [programDetails,setProgramDetails] = useState([])
  const [userData, setUserData] = useState([]);
  const [isBrandOverride, setIsBrandOverride] = useState(false)
  const [brand_override, setBrandOverride] = useState("")
  const [studentID, setStudentID] = useState("");
  const [allStudents, setAllStudents] = useState([]);
  const [filterData, setFilterData] = useState({
    status: [],
    campus: [],
    learning: [],
  });

  useEffect(() => {
    handleTableScroll()
  }, [loading])

  useEffect(() => {
    let response = hasPermission({
      scopes: ["capgview"],
      permissions: givenPermsisions,
    });
    if (!response) {
      history.push("/noaccess");
    }
  }, [givenPermsisions, history]);

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
  
    const fetchData = async () => {
      const data = {
        id: [subTab]
      }
      try {
        const res = await getStudentBrandCancelToken(data, dropdownSource.token);
        setBrandData(res?.data);
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };
  
    fetchData();
  
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, []);

  const handleRowToggle = (rowId, e, iconClick) => {
    if (
      e &&
      e.target &&
      e.target.parentElement &&
      e.target.parentElement.parentElement
    ) {
      var parentElements = e.target.parentElement.parentElement.parentElement;
    }
    const isRowExpanded = expandedRows.includes(rowId);
    const rows = document.getElementsByClassName("expanded-course-row");
    for (let i = 0; i < rows.length; i++) {
      rows[i].classList.remove("expanded-course-row");
    }
    let tempExpendedRows = expandedRows;
    if (isRowExpanded) {
      setExpandedRows(expandedRows.filter((id) => id !== rowId));
      tempExpendedRows = expandedRows.filter((id) => id !== rowId);
    } else {
      if (parentElements && iconClick) {
        parentElements.classList.add("expanded-course-row");
      } else {
        e.currentTarget.classList.add("expanded-course-row");
      }
      setExpandedRows(() => [rowId]);
      tempExpendedRows = [rowId];
    }
    let tempArr = studentGradeBookData.map((row) => {
      return {
        ...row,
        isExpanded: tempExpendedRows.includes(row.id),
      };
    });
    setStudentGradeBookData(tempArr);
  };

  const isRowExpanded = (rowId) => expandedRows.includes(rowId);

  useEffect(() => {
    const cancelTokenSources = [];

    const getProgramsList = async () => {
      setLoading(true);
  
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);

      const values = {
        id: id,
        search: "",
        limit: 10,
        page: 1,
        exportStatus: "true",
        forPdf: 'false',
        student_id: subTab
      }
      try {
        const res = await getProgrameGradeMixGradebookCancelToken(values, source.token);
        if (res.status === 200) {
          setProgramDetails(res?.data?.program);
          setIsProgrammeEdited(
            res?.data?.program?.programCalculate?.allUpdateProgram
          );
          let details = res?.data?.courseWithAssessment;
          details = details.map((row) => ({
            ...row,
            isExpanded: expandedRows.includes(row.id),
          }));
          setStudentGradeBookData(details);
          setProgrammeTotal(res?.data?.program?.programCalculate);
          setBrandOverride(res.data.program?.override_brand)
        setIsBrandOverride(res.data.program?.override_sor_brand != 0)
          setAggregationMethodId(res?.data?.program?.aggregation_method_id);
          setGradingType(res?.data?.program?.grading_type);
          setLoading(false);
          const pdfDetails = details?.map((course) => ({
            course_name: course?.name,
            weight: course?.grant_exemption
              ? "Exempted"
              : !course?.assessments.length
              ? "N/A"
              : course?.weight,
            grade: course?.grant_exemption
              ? "Exempted"
              : !course?.assessments.length
              ? "N/A"
              : percentageView || course?.grading_type == 0
              ? Math.round(course?.scoreRecieved) + "%"
              : course.gradeText
              ? course.gradeText
              : "N/A",
            result: course?.grant_exemption
              ? "Exempted"
              : !course?.assessments.length
              ? "N/A"
              : course?.pass_status
              ? course?.pass_status
              : "N/A",
            isExclude: course?.isExclude,  
            assessments: course?.assessments?.map((assessment) => ({
              name: assessment?.assessment_name,
              weight:
                assessment?.grant_exemption || course?.grant_exemption
                  ? "Exempted"
                  : assessment.ass_weighting,
              result:
                assessment?.grant_exemption || course?.grant_exemption
                  ? "Exempted"
                  : assessment?.submited == "0" ||
                    moment.unix(assessment?.close_date_time) >
                      moment.unix(new Date())
                  ? "N/A"
                  : assessment.pass_status == "N/A"
                  ? "N/A"
                  : assessment.pass_status
                  ? assessment.pass_status
                  : "-",
              grade:
                assessment?.grant_exemption || course?.grant_exemption
                  ? "Exempted"
                  : percentageView
                  ? Math.round(assessment.ass_percentage) + "%"
                  : assessment?.submited == "0" ||
                    moment.unix(assessment?.close_date_time) >
                      moment.unix(new Date())
                  ? "N/A"
                  : assessment?.grading_type == 3
                  ? `${assessment?.ass_mark}/${assessment?.ass_total_mark}`
                  : assessment?.gradeText
                  ? assessment?.gradeText
                  : "N/A",
              isExclude: assessment?.isExclude    
            })),
            final_status: res?.data?.program?.programCalculate?.pass_status,
            final_grade:
              percentageView ||
              res?.data?.program?.programCalculate?.grading_type == 0
                ? Math.round(
                    res?.data?.program?.programCalculate?.scoreRecieved
                  ) + "%"
                : res?.data?.program?.programCalculate?.grading_type == 3
                ? `${res?.data?.program?.programCalculate?.programeAssMark}/${res?.data?.program?.programCalculate?.programeAssTotalMark}`
                : res?.data?.program?.programCalculate?.gradeText,
          }));
          setPDFData(pdfDetails);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading(false);
        }
      }
    };
  
    getProgramsList();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [updateData]);

  useEffect(() => {
    const cancelTokenSources = [];

    const getStudList = async () => {
  
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
  
      try {
        const res = await GetProgrammeStudentCancelToken(id, source.token);
        if (res.status === 200) {
          let allStdIds = [];
        res.data.programs.map((std) => {
          allStdIds.push(std?.id);
        });
        setAllStudents(allStdIds);
        setUserData(res.data.programs);
        setFilterData({
          ...res.data,
          status: res.data.studentStatusFilter,
          campus: res.data.campusStatusFilter,
          learning: res.data.deliveryMethodFilter,
        });
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading(false);
        }
      }
    };
  
    getStudList();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, []);

  const handlepdfData = () => {
    setPdfDownloading(true);
    const swalPro = Swal.mixin({
      title: "Downloading PDF",
      text: "Please wait...",
      icon: "info",
      allowOutsideClick: false,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    swalPro.fire();
    let studentDetails = userData?.find((student) => student?.id == subTab);
    let brand = {};
    const data = {
      id: [subTab],
    };
    if(brand_override && isBrandOverride){

      GetBrandSettingDetail(brand_override)
      .then((res) => {
        brand = res.data.brand_setting
      }).catch((err) => console.log(err));
    }else{
      console.log("Please wait...",brand_override,isBrandOverride,data,subTab);
      getStudentBrand(data)
        .then((res) => {
          brand = res.data;
      })
        .catch((err) => console.log(err));
    }
        getProgrameGradeMixGradebook({
          id,
          search: "",
          limit: "",
          page: "",
          exportStatus: "true",
          forPdf: 'false',
          student_id: subTab
        })
        .then((res) => {
          let details = res?.data?.courseWithAssessment;
          const pdfDetails = details?.map((course) => ({
            course_name: course?.name,
            weight: course?.grant_exemption
              ? "Exempted"
              : !course?.assessments.length
              ? "N/A"
              : course?.weight,
            grade: course?.grant_exemption
              ? "Exempted"
              : !course?.assessments.length
              ? "N/A"
              : course?.grading_type == 0
              ? Math.round(course?.scoreRecieved) + "%"
              : course.gradeText
              ? course.gradeText
              : "N/A",
            result: course?.grant_exemption
              ? "Exempted"
              : !course?.assessments.length
              ? "N/A"
              : course?.pass_status
              ? course?.pass_status
              : "N/A",
            isExclude: course?.isExclude,
            assessments: course?.assessments?.map((assessment) => ({
              name: assessment?.assessment_name,
              weight:
                assessment?.grant_exemption || course?.grant_exemption
                  ? "Exempted"
                  : assessment.ass_weighting,
              result:
                assessment?.grant_exemption || course?.grant_exemption
                  ? "Exempted"
                  : assessment?.submited == "0" ||
                    moment.unix(assessment?.close_date_time) >
                      moment.unix(new Date())
                  ? "N/A"
                  : assessment.pass_status == "N/A"
                  ? "N/A"
                  : assessment.pass_status
                  ? assessment.pass_status
                  : "-",
              grade:
                assessment?.grant_exemption || course?.grant_exemption
                  ? "Exempted"
                  : assessment?.submited == "0" ||
                    moment.unix(assessment?.close_date_time) >
                      moment.unix(new Date())
                  ? "N/A"
                  : assessment?.grading_type == 3
                  ? `${assessment?.ass_mark}/${assessment?.ass_total_mark}`
                  : assessment?.gradeText
                  ? assessment?.gradeText
                  : "N/A",
              isExclude: assessment?.isExclude,
              id: assessment?.assessment_id,
            })),
            final_status: res?.data?.program?.programCalculate?.pass_status,
            final_grade:
              res?.data?.program?.programCalculate?.grading_type == 0
                ? Math.round(
                    res?.data?.program?.programCalculate?.scoreRecieved
                  ) + "%"
                : res?.data?.program?.programCalculate?.grading_type == 3
                ? `${res?.data?.program?.programCalculate?.programeAssMark}/${res?.data?.program?.programCalculate?.programeAssTotalMark}`
                : res?.data?.program?.programCalculate?.gradeText,
          }));
          // pdfDetails.unshift({isHeading: true})
          if (pdfDetails) {
            let modifiedSoR = pdfDetails
              ?.map((item) => ({
                ...item,
                assessments: !isChecked
                  ? []
                  : item?.assessments.filter(
                      (assessment) => !isExcludeGrade || !assessment.isExclude
                    ),
                final_grade: !isShowFinal ? "" : item?.final_grade,
                final_status: !isShowFinal ? "" : item?.final_status,
              }))
              .filter((course) => !isExcludeGrade || !course.isExclude);
              const uniqueId = 'st_disclaimer_' + Math.random() * 100
              // let kjs = `<div id='${uniqueId}' style='height: 545px;'>
              let kjs = `<div id='${uniqueId}' style='max-width: fit-content;'>
              ${res.data.program.st_disclaimer}
              </div>`
              kjs = parseHTML(kjs)
              // document.body.appendChild(kjs[0])
              document.querySelector("body").appendChild(kjs[0])
              let divHeight = document.getElementById(uniqueId).clientHeight
              divHeight = divHeight < 670 ? divHeight : 670
            
              let divMinHeight = Math.floor(document.getElementById(uniqueId).innerText.toString().length/200)*18
              let divWidth = document.getElementById(uniqueId).clientWidth
              
              divWidth = document.getElementById(uniqueId).innerText.toString().length * 5;
              html2canvas(document.getElementById(uniqueId)).then(function(canvas) {
              var dataURL = canvas.toDataURL();
              return dataURL
            }).then(noteImage => {
              pdf(
                <LetterHeadPDF3
                  detailData={modifiedSoR}
                  divMinHeight = {divMinHeight}
              divHeight={divHeight+300}
              divWidth={divWidth > 565 ? 565 : divWidth}
              divOrgHeight={divHeight}
              divTopPosition = {1197 - divHeight - 130}
                  noteImage={res.data.program.st_disclaimer ? noteImage : null}
                  studentAndCourseDetails={{
                    program_name: studentDetails?.course,
                    Student_name:
                      studentDetails.first_name + " " + studentDetails.last_name,
                    ID_number: studentDetails?.number,
                    Student_number: studentDetails?.student_crm_id
                      ? studentDetails?.student_crm_id
                      : studentDetails?.student_num,
                    start: programmeDetails?.start
                      ? programmeDetails.start?.toString().length == 10
                        ? moment
                            .unix(programmeDetails?.start)
                            .format("DD MMMM YYYY")
                        : moment(programmeDetails?.start).format("DD MMMM YYYY")
                      : "",
                    end: programmeDetails?.end
                      ? programmeDetails.end?.toString().length == 10
                        ? moment.unix(programmeDetails?.end).format("DD MMMM YYYY")
                        : moment(programmeDetails?.end).format("DD MMMM YYYY")
                      : "",
                    intake:
                      programmeDetails?.number && programmeDetails?.year
                        ? `${programmeDetails?.number}-${programmeDetails?.year}`
                        : "",
                    learning_method: programmeDetails?.type
                      ? RenderLearningMethodGradebook(programmeDetails?.type).text
                      : "",
                  }}
                  brandData={brand_override && isBrandOverride ? brand : brand.getBrandDetail}
                />
              )
                .toBlob()
                .then((response) => {
                  // const blobURL = "";
                  const blobURL = window.URL.createObjectURL(response);
                  const a = document.createElement("a");
                  a.href = blobURL;
                  a.download = `${
                    studentDetails?.student_crm_id
                      ? studentDetails?.student_crm_id
                      : studentDetails?.student_num
                  }_${studentDetails.first_name + " " + studentDetails.last_name}_${
                    studentDetails?.course
                  }_SOR_${brandVisuals?.brand_name}_${moment(new Date()).format(
                    "DD MM YY"
                  )}.pdf`; // Set the desired filename for the download
                  a.click();
  
                  URL.revokeObjectURL(blobURL);
                  setStudentID("");
                  setIsChecked(false);
                  setIsShowFinal(false);
                  setIsExcludeGrade(false);
                  swalPro.close();
                  $("#availableprogrammeinRereg").modal("hide");
                  setPdfDownloading(false);
                  Swal.fire({
                    icon: "success",
                    title: "Success",
                    text: "Download Successfully",
                  });
                })
                .catch((err) => {
                  console.log(err);
                  setPdfDownloading(false);
                  setStudentID("");
                  setIsChecked(false);
                  setIsShowFinal(false);
                  setIsExcludeGrade(false);
                  swalPro.close();
                  $("#availableprogrammeinRereg").modal("hide");
                  Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: err,
                  });
                });
            })
            
          }
        })
        .catch((err) => console.error("error :", err));
      
  };


  const sortingName = (rowA, rowB) => {
    const name1 = rowA.name ? rowA.name.toString() : "";
    const name2 = rowB.name ? rowB.name.toString() : "";
    return name1.localeCompare(name2);
  };

  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "course_name",
      sortable: true,
      sortFunction: sortingName,
      cell: (row) => {
        return (
          <>
            <div className="d-flex align-items-center">
              <div
                className="expand-toggle-button mr-2"
                onClick={(e) => handleRowToggle(row.id, e, "iconClick")}
              >
                {row.isExpanded ? (
                  <span style={{ pointerEvents: "none", fontSize: "14px" }}>
                    <i className="fas fa-minus-circle"></i>
                  </span>
                ) : (
                  <span style={{ pointerEvents: "none", fontSize: "14px" }}>
                    <i className="fas fa-plus-circle"></i>
                  </span>
                )}
              </div>
              <span title={row.name} className="">
                {row.name ? (
                  <Link
                    className="as-text-topbar-gradebook curser"
                    to={`/courseAdministration/gradeMix/${id}/details/${row.id}/open`}
                  >
                    <span className="">{row.name}</span>
                  </Link>
                ) : (
                  "-"
                )}
              </span>
            </div>
          </>
        );
      },
    },
    aggregationMethodId == 6 && {
      name: "Weight",
      selector: "weight",
      //   sortable: true,
      cell: (row) =>
        row.grant_exemption ? (
          <span className="exempted-text">
            Exempted <i className="fal fa-info-circle"></i>
          </span>
        ) : !row?.assessments.length ? (
          "N/A"
        ) : (
          row.weight
        ),
    },
    {
      name: "Score Recieved",
      selector: "getAssessmentCalculate_sum_ass_mark",
      //   sortable: true,
      cell: (row) =>
        row.grant_exemption ? (
          <span className="exempted-text">
            Exempted <i className="fal fa-info-circle"></i>
          </span>
        ) : !row?.assessments.length ? (
          "N/A"
        ) : (
          <>
            {row.allAss_ass_mark &&
            row.allAss_total_mark ? (
              <div className="resultstatus_flex arrowhide">
                {parseFloat(row.allAss_ass_mark).toFixed(2)} /{" "}
                {row.allAss_total_mark}
              </div>
            ) : (
              "N/A"
            )}
          </>
        ),
    },
    {
      name: "Range",
      selector: "getAssessmentCalculate_sum_ass_total_mark",
      //   sortable: true,
      cell: (row) =>
        row.grant_exemption ? (
          <span className="exempted-text">
            Exempted <i className="fal fa-info-circle"></i>
          </span>
        ) : !row?.assessments.length ? (
          "N/A"
        ) : (
          <p>
            {row.allAss_total_mark ? (
              <span>0 - {row.allAss_total_mark}</span>
            ) : (
              "N/A"
            )}
          </p>
        ),
    },
    {
      name: "Grade",
      selector: "gradeText",
      //   sortable: true,
      cell: (row) => {
        return row.grant_exemption ? (
          <span className="exempted-text">
            Exempted <i className="fal fa-info-circle"></i>
          </span>
        ) : !row?.assessments.length ? (
          "N/A"
        ) : percentageView || row?.grading_type == 0 ? (
          parseFloat(row?.scoreRecieved).toFixed(2) + "%"
        ) : row?.grading_type == 3 ? (parseFloat(row.gradeText).toFixed(2)) : (row.gradeText ? (
          row.gradeText
        ) : (
          "N/A"
        ));
      },
    },
    // ((gradingType == 0 || gradingType == 3) || percentageView) && {
    //   name: "Contribution to Final Score ",
    //   selector: "contributionMark",
    //   //   sortable: true,
    //   cell: (row) => {
    //     return row.grant_exemption ? <span className="exempted-text">Exempted <i className="fal fa-info-circle"></i></span> : !row?.assessments.length ? "N/A" : row?.submited == "0" || moment.unix(row?.close_date_time) > moment.unix(new Date()) ? "N/A" : row?.contributionMark?.includes("%") ? Math.round(parseFloat(row?.contributionMark?.replace("%", ""))).toString() + "%" : row.contributionMark
    //   }
    // },
    {
      name: "Result",
      selector: "pass_status",
      sortable: true,
      cell: (row) =>
        row.grant_exemption ? (
          <span className="exempted-text">
            Exempted <i className="fal fa-info-circle"></i>
          </span>
        ) : !row?.assessments.length ? (
          "N/A"
        ) : row.pass_status == "N/A" ? (
          "N/A"
        ) : row.pass_status ? (
          RenderQuizAttemptResultStatus(row.pass_status).html
        ) : (
          "N/A"
        ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons">
            {row?.grant_exemption ? (
              <PermissionsGate
                scopes={["programgge"]}
                errorProps={{ disabled: true }}
              >
                <button
                  className="btn btn-success rounded-circle"
                  title="Remove from Exemption"
                  onClick={() => handleUpdateExemption(0, 0, 1, row.id)}
                >
                  <i className="fal fa-user-plus"></i>
                </button>
              </PermissionsGate>
            ) : (
              <PermissionsGate
                scopes={["programgge"]}
                errorProps={{ disabled: true }}
              >
                <button
                  className="btn btn-danger rounded-circle"
                  title="Grant Exemption"
                  onClick={() => handleUpdateExemption(0, 1, 1, row.id)}
                >
                  <i className="fal fa-user-times"></i>
                </button>
              </PermissionsGate>
            )}
            {/* <PermissionsGate
                scopes={["capsvedit"]}
                errorProps={{ disabled: true }}
              >
                <button
                  className="btn btn-primary rounded-circle"
                  title="Save Grades"
                  onClick={() => handleUpdateExemption(row.assessment_id, 0)}
                >
                  <i className="fal fa-save"></i>
                </button>
              </PermissionsGate> */}
          </div>
        </div>
      ),
    },
  ]);

  const handleSaveGrades = () => {
    setSaveGradesLoading(true);
    const values = {
      id: [id],
      student_id: subTab,
      grademix: updateValueArr
    };
    updateProgrammeScore(values)
      .then((res) => {
        setSaveGradesLoading(false);
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Updated Successfully",
        }).then((res) => setUpdateData(!updateData));
        setUpdateValueArr([])
      })
      .catch((err) => {
        setSaveGradesLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `${err?.response?.data?.data}`,
        });
      });
  };

  const handleUpdateExemption = (assId, val, type, gradeId) => {
    if (type) {
      const updatedCourseList = studentGradeBookData.map(course => {
        if (course.id === gradeId) {
          const arr = updateValueArr
          let ifCourseExists = false
          arr.forEach((item, index) => {
            if (item.id === gradeId) {
              ifCourseExists = index
            }
          })
          if (ifCourseExists === false) {
            arr.push({
              student_id: +subTab,
              qid: +id,
              id: gradeId,
              grant_exemption: val,
              assessments: []
            })
          } else {
            arr[ifCourseExists] = { ...arr[ifCourseExists], grant_exemption: val }
          }
          setUpdateValueArr(() => [...arr])
          setIsProgrammeEdited(true)
          return {
            ...course,
            grant_exemption: val
          };
        }
        return course;
      });
      setStudentGradeBookData(() => [...updatedCourseList]);
    } else {
      const updatedCourseList = studentGradeBookData.map(course => {
        if (course.id === gradeId) {
          return {
            ...course,
            assessments: course.assessments.map(ass => {
              if (ass.assessment_id === assId && course.grant_exemption === 0) {
                const arr = updateValueArr;
                let ifCourseExists = false;
                let ifAssessmentExists = false;
                arr.forEach((item, index) => {
                  if (item.id === gradeId) {
                    ifCourseExists = index
                    item.assessments.map((ass, ind) => {
                      if (ass.assessment_id === assId) {
                        ifAssessmentExists = ind
                      }
                    })
                  }
                })
                if (ifCourseExists === false) {
                  arr.push({
                    student_id: +subTab,
                    qid: +id,
                    id: gradeId,
                    grant_exemption: 0,
                    assessments: [
                      {
                        student_id: +subTab,
                        assessment_id: assId,
                        grant_exemption: val
                      }
                    ]
                  })
                } else if (ifAssessmentExists === false) {
                  arr[ifCourseExists].assessments.push({
                    student_id: +subTab,
                    assessment_id: assId,
                    grant_exemption: val
                  })
                } else {
                  arr[ifCourseExists].assessments[ifAssessmentExists] = { ...arr[ifCourseExists].assessments[ifAssessmentExists], grant_exemption: val }
                }
                setUpdateValueArr(() => [...arr])
                setIsProgrammeEdited(true)
                return {
                  ...ass,
                  grant_exemption: val
                };
              }
              return ass;
            })
          };
        }
        return course;
      });
      setStudentGradeBookData(() => [...updatedCourseList])
    }
    // let data = {
    //   id: assId,
    //   grade_id: grade_id,
    //   student_id: +subTab,
    //   grant_exemption: val,
    // };
    // if (type) {
    //   data = { ...data, type, qid: +id };
    // }
    // exemptionToggle(data).then((res) => {
    //   setUpdateData(!updateData);
    //   Swal.fire("Updated!", res.data.message, "success");
    // });
  };
  const ExpandedRowComponent = ({ data }) => {
    let isExempted = studentGradeBookData?.filter(
      (item) => item.id == data.rowId
    )?.length
      ? studentGradeBookData.find((item) => item.id == data.rowId)
          .grant_exemption
      : "";
    let isSupplementary = data?.isSupplementary  
    const Assessmentcolumns = useMemo(() => [
      {
        name: "Name",
        selector: "name",
        // sortable: true,
        // sortFunction: sortingName,
        cell: (row) => {
          return (
            <div className="d-flex align-items-center">
              {row?.assessment_name ?
              <span title={row?.assessment_name} className="">
                <Link
                  className={`as-text-blue curser feature-name ${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}
                  to={`/courseAdministration/coursesdetails/${row?.intake}/assessments/open/assigned/Details/${row.assessment_id}`}
                  target="_blank"
                >
                  <span className="">{row?.assessment_name}</span>
                </Link>
                {/* <span className={`as-text-topbar-gradebook ${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}>
                  <span className="">{row?.assessment_name ? row?.assessment_name : "-"}</span>
                </span> */}
              </span>
               : "-"
               }
            </div>
          );
        },
      },
      aggregationMethodId == 6 && {
        name: "Weight",
        selector: "ass_weighting",
        //   sortable: true,
        cell: (row) =>
          row?.grant_exemption || isExempted ? (
            <span className={`exempted-text ${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}>
              Exempted <i className="fal fa-info-circle"></i>
            </span>
          ) : (row?.ass_type == 9 && !row.isAssignedSickStudent) ? (
            <span className={`${(isSupplementary && !row.isSupplementaryForThisStudent) ? "supplementry-opacity" : ""}`}>N/A</span>
          ) : (
            <span className={`${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}>{row.ass_weighting}</span>
          ),
      },
      {
        name: "Score Recieved",
        selector: "ass_mark",
        //   sortable: true,
        cell: (row) =>
          row?.grant_exemption || isExempted ? (
            <span className={`exempted-text ${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}>
              Exempted <i className="fal fa-info-circle"></i>
            </span>
          ) : (row?.ass_type == 9 && !row.isAssignedSickStudent) ? (
            <span className={`${(isSupplementary && !row.isSupplementaryForThisStudent) ? "supplementry-opacity" : ""}`}>N/A</span>
          ) : (
            <div className={`d-flex ${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}>
              {row?.ass_mark + "/" + row?.ass_total_mark}
              <div className="d-flex">
                {row?.assessmentMarkingStatus?.toString() === "notsubmitted" ||
                row?.assessmentMarkingStatus?.toString() === "NOT SUBMITTED" ? (
                  <div
                    className="grade-info-ic grade-info-red ml-2"
                    title="Not Submitted"
                  >
                    <i className="fal fa-file-times"></i>
                  </div>
                ) : (
                  ""
                )}
                {!row?.mark_edited || row?.mark_edited == "0" ? (
                  ""
                ) : (
                  <div
                    className="grade-info-ic grade-info-orange ml-2"
                    title="Mark Edited"
                  >
                    <i className="fal fa-edit"></i>
                  </div>
                )}
                {row?.assessmentStatus?.toString() === "OPEN" ? (
                  <div
                    className="grade-info-ic grade-info-green ml-2"
                    title="Open/Extension"
                  >
                    <i className="fal fa-calendar-day"></i>
                  </div>
                ) : (
                  ""
                )}
                {row?.assessmentStatus?.toString() === "PENDING" ? (
                  <div
                    className="grade-info-ic grade-info-blue ml-2"
                    title="Upcoming Assessment"
                  >
                    <i className="fal fa-calendar-alt"></i>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          ),
      },
      {
        name: "Range",
        selector: "ass_total_mark",
        sortable: false,
        cell: (row) =>
          row.grant_exemption || isExempted ? (
            <span className={`exempted-text ${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}>
              Exempted <i className="fal fa-info-circle"></i>
            </span>
          ) : (row?.ass_type == 9 && !row.isAssignedSickStudent) ? (
            <span className={`${(isSupplementary && !row.isSupplementaryForThisStudent) ? "supplementry-opacity" : ""}`}>N/A</span>
          ) : (
            <p>
              {row.ass_total_mark ? (
                <span className={`${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}>0 - {row.ass_total_mark}</span>
              ) : (
                <span className={`${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}>N/A</span>
              )}
            </p>
          ),
      },
      {
        name: "Grade",
        selector: "grade",
        //   sortable: true,
        cell: (row) => {
          let options = [
            { label: "Red", value: "0" },
            { label: "Yellow", value: "49" },
            { label: "Green", value: "100" },
          ];
          return row.grant_exemption || isExempted ? (
            <span className={`exempted-text ${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}>
              Exempted <i className="fal fa-info-circle"></i>
            </span>
          ) : (row?.ass_type == 9 && !row.isAssignedSickStudent) ? (
            <span className={`${(isSupplementary && !row.isSupplementaryForThisStudent) ? "supplementry-opacity" : ""}`}>N/A</span>
          ) : percentageView ? (
            <span className={`${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}>{Math.round(row.ass_percentage) + "%"}</span>
          ) : row?.submited == "0" ||
            moment.unix(row?.close_date_time) > moment.unix(new Date()) ? (
              <span className={`${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}>N/A</span>
          ) : row?.grading_type == 3 ? (
            <span className={`${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}>{`${row?.ass_mark}/${row?.ass_total_mark}`}</span>
          ) : row?.gradeText ? (
            <span className={`${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}>{row?.gradeText}</span>
          ) : (
            <span className={`${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}>N/A</span>
          );
        },
      },
      // ((gradingType == 0 || gradingType == 3) || percentageView) && {
      //   name: "Contribution to Final Score",
      //   selector: "contributionMark",
      //   //   sortable: true,
      //   cell: (row) => {
      //     return row.grant_exemption || isExempted ? <span className="exempted-text">Exempted <i className="fal fa-info-circle"></i></span> : row?.submited == "0" || moment.unix(row?.close_date_time) > moment.unix(new Date()) ? "N/A" : row.contributionMark.includes("%") ? Math.round(parseFloat(row.contributionMark.replace("%", ""))).toString() + "%" : row.contributionMark
      //   }
      // },
      {
        name: "Result",
        selector: "pass_status",
        sortable: true,
        cell: (row) =>
          row.grant_exemption || isExempted ? (
            <span className={`exempted-text ${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}>
              Exempted <i className="fal fa-info-circle"></i>
            </span>
          ) : (row?.ass_type == 9 && !row.isAssignedSickStudent) ? (
            <span className={`${(isSupplementary && !row.isSupplementaryForThisStudent) ? "supplementry-opacity" : ""}`}>N/A</span>
          ) : row?.submited == "0" ||
          moment.unix(row?.close_date_time) > moment.unix(new Date()) ? (
          <span className={`${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}>N/A</span>
        ) : row.pass_status == "N/A" ? (
          <span className={`${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}>N/A</span>
        ) : row.pass_status ? (
          <span className={`${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}>{RenderQuizAttemptResultStatus(row.pass_status).html}</span>
        ) : (
          <span className={`${(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ? "supplementry-opacity" : ""}`}>-</span>
        ),
      },
      {
        name: "Actions",
        cell: (row) => (
          <div className="assessment-08 btn-dropdown-grp">
            <div className="as-buttons">
              {row?.grant_exemption || isExempted ? (
                <PermissionsGate
                  scopes={["programgge"]}
                  errorProps={{ disabled: true }}
                >
                  <button
                    className="btn btn-success rounded-circle"
                    title="Remove from Exemption"
                    disabled={(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ||  (row?.ass_type == 9 && !row.isAssignedSickStudent)}
                    onClick={() => handleUpdateExemption(row.assessment_id, 0, 0, data.rowId)}
                  >
                    <i className="fal fa-user-plus"></i>
                  </button>
                </PermissionsGate>
              ) : (
                <PermissionsGate
                  scopes={["programgge"]}
                  errorProps={{ disabled: true }}
                >
                  <button
                    className="btn btn-danger rounded-circle"
                    title="Grant Exemption"
                    disabled={(isSupplementary && !row.isSupplementaryForThisStudent) || row.isSickForThisStudent ||  (row?.ass_type == 9 && !row.isAssignedSickStudent)}
                    onClick={() => handleUpdateExemption(row.assessment_id, 1, 0, data.rowId)}
                  >
                    <i className="fal fa-user-times"></i>
                  </button>
                </PermissionsGate>
              )}
              {/* <PermissionsGate
                scopes={["capsvedit"]}
                errorProps={{ disabled: true }}
              >
                <button
                  className="btn btn-primary rounded-circle"
                  title="Save Grades"
                  onClick={() => handleUpdateExemption(row.assessment_id, 0)}
                >
                  <i className="fal fa-save"></i>
                </button>
              </PermissionsGate> */}
            </div>
          </div>
        ),
      },
    ]);

    return (
      <DataTable
        noHeader
        noTableHead
        data={data.data}
        columns={Assessmentcolumns.filter((item) => item)}
        pagination={false}
        paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
        noDataComponent={
          <span className="my-10px">
            There are no assessments linked to this course
          </span>
        }
      />
    );
  };

  const ExpandedRowWrapper = ({ data }) => {
    return (
      <ExpandedRowComponent
        key={data.id}
        data={{ data: data?.assessments, rowId: data.id }}
      />
    );
  };

  const handleToggle = () => {
    setIsChecked(!isChecked);
  };

  const handleShowFinalToggle = () => {
    setIsShowFinal(!isShowFinal);
  };

  const handleExcludeFinal = () => {
    setIsExcludeGrade(!isExcludeGrade);
  };

  return (
    <>
      <div className="my-tickets-info-list Tickets-main-wrap tickets-new-custom">
        <div className="l-o-c-t filter-search-icon card card-table-custom ">
          <div className="search-filter-div">
            <div className="search-filter-div-left">
              <div className="system-administration-table table-responsive">
                <div className="table-responsive-div">
                  <div
                    id="assessment-table-main_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div
                      id="assessment-table-main_wrapper"
                      className="dataTables_wrapper no-footer"
                    >
                      <div
                        id="assessment-table-main_filter"
                        className="dataTables_filter"
                      >
                        {/* <label>
                        <input
                          type="search"
                          className=""
                          placeholder="Search"
                          aria-controls="assessment-table-main"
                          onChange={handleSearchFilter}
                          value={search}
                        />
                      </label> */}
                        <div className="filter-eff filter-data-btn">
                          {/* <button className="filter-buttons">
                          <i className="fal fa-filter"></i>
                        </button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="filter-button-group"></div>
              </div>
            </div>
            <div className="search-filter-div-right">
              <div className="filter-search-bar-blk">
                <div className="add-ticket-blk button-reset dropdown-comman">
                  <button
                    className="btn btn-primary"
                    title={percentageView ? "Standard View" : "Percentage View"}
                    onClick={() => {
                      setPercentageView(!percentageView);
                    }}
                  >
                    <i className="fal fa-eye"></i>{" "}
                    {percentageView ? "Standard View" : "Percentage View"}
                  </button>
                </div>
              </div>
            </div>
          </div>
          {loading ? (
            <SkeletonTicketList />
          ) : (
            <DataTable
              data={studentGradeBookData}
              columns={columns.filter((item) => item)}
              pagination={true}
              defaultSortField="name"
              defaultSortAsc={false}
              paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
              expandableRows
              expandOnRowClicked
              expandableRowsComponent={<ExpandedRowWrapper />}
              onRowClicked={(row, e) => handleRowToggle(row?.id, e)}
              expandableIcon={
                <FontAwesomeIcon
                  icon={faPlus}
                  className="custom-collapse-icon"
                />
              } // Customize the expand icon
              collapseIcon={
                <FontAwesomeIcon
                  icon={faMinus}
                  className="custom-collapse-icon"
                />
              }
              expandableRowExpanded={(row) => isRowExpanded(row.id)} // Check if row is expanded
              className="gradebook-expand-table programme-grade-table-expand"
            />
          )}
        </div>
      </div>
      <div className="my-tickets-info-list Tickets-main-wrap tickets-new-custom mt-4">
        <div className="l-o-c-t custom-table-div filter-search-icon card card-table-custom">
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <i className="fal fa-chart-bar mr-2 font-weight-bold"></i>
              <span className="curser feature-name font-weight-bold">
                Final Course Grade
              </span>
            </div>
            {programmeTotal?.pass_status == "N/A" ? (
              <div>
                <span className="mr-3">N/A</span>
                <button
                  className="btn btn-primary rounded-circle"
                  title="Download SoR PDF"
                  data-toggle="modal"
                  data-target="#availableprogrammeinRereg"
                  onClick={() => {
                    setIsChecked(programDetails.show_assessments)
                          setIsShowFinal(programDetails.show_final_results);
                          setIsExcludeGrade(programDetails.show_empty_grades);
                  }}
                  // onClick={() => handlepdfData()}
                >
                  {pdfDownloading ? (
                    <i className="fas fa-cog fa-spin download-SoR-icon"></i>
                  ) : (
                    <i class="fal fa-download download-SoR-icon"></i>
                  )}
                </button>
              </div>
            ) : (
              <div className="resultstatus_flex arrowhide">
                {percentageView || programmeTotal?.grading_type == 0 ? (
                  Math.round(programmeTotal?.scoreRecieved) + "%"
                ) : programmeTotal?.grading_type == 3 ? (
                  <>
                    <PermissionsGate
                      scopes={["cassgradeassedit"]}
                      errorProps={{ disabled: true }}
                    >
                      <input
                        className={
                          "form-control" + (false ? " is-invalid" : "")
                        }
                        type="number"
                        defaultValue={programmeTotal?.programeAssMark}
                        disabled
                        // onBlur={(e) => handlegradeChange(e, row.id, "inputbox", row.ass_total_mark, row)}
                      />
                    </PermissionsGate>
                    <span>/{programmeTotal?.programeAssTotalMark}</span>
                  </>
                ) : (
                  programmeTotal?.gradeText
                )}{" "}
                &nbsp;
                <p className="m-0 mr-2">
                  {programmeTotal?.pass_status
                    ? RenderQuizAttemptResultStatus(programmeTotal?.pass_status)
                        .html
                    : ""}
                </p>
                <button
                  className="btn btn-primary rounded-circle"
                  title="Download SoR PDF"
                  data-toggle="modal"
                  data-target="#availableprogrammeinRereg"
                  // onClick={() => handlepdfData()}
                  onClick={() => {
                    setIsChecked(programDetails.show_assessments)
                          setIsShowFinal(programDetails.show_final_results);
                          setIsExcludeGrade(programDetails.show_empty_grades);
                  }}
                >
                  {pdfDownloading ? (
                    <i className="fas fa-cog fa-spin download-SoR-icon"></i>
                  ) : (
                    <i class="fal fa-download download-SoR-icon"></i>
                  )}
                </button>
              </div>
            )}
          </div>
        </div>
        {studentGradeBookData?.length && isProgrammeEdited ? (
          <GradeBookSaveBar
            handleClick={() => handleSaveGrades()}
            saveGradesLoading={saveGradesLoading}
          />
        ) : (
          <></>
        )}
        <div
          className="topic-add-modal modal fade"
          id="availableprogrammeinRereg"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
          data-backdrop="static"
        >
          <div
            className="modal-600 modal-dialog modal-dialog-centered modal-m"
            role="document"
          >
            <div className="modal-content modal-border-update sor">
              <div className="modal-body p-0">
                <div className="modal-header modal-header-custom">
                  <h5 className="modal-title">
                    <i className="fal fa-download"></i> Statement of Results
                    Download Options
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    data-toggle="modal"
                    data-target="#availableprogrammeinRereg"
                    onClick={()=>{
                      setIsChecked(false);
                      setIsShowFinal(false);
                      setIsExcludeGrade(false);
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="t p-17px">
                  <i class="fal fa-cog SoR-icon"></i>
                  <span className="SoR-settings"> Settings</span>
                  <div className="toogles-inline mt-3 d-flex align-items">
                    <div className="d-flex align-items mr-3">
                      <label className="mb-0">Show Assessments</label>
                      <div className="toggle-switch ml-2">
                        <label class="switch">
                          <input
                            type="checkbox"
                            checked={isChecked}
                            onChange={handleToggle}
                          />
                          <span class="slider slider-round"></span>
                        </label>
                      </div>
                    </div>
                    <div className="d-flex align-items mr-3">
                      <label className="mb-0">Show Final Results</label>
                      <div className="toggle-switch ml-2">
                        <label class="switch">
                          <input
                            type="checkbox"
                            checked={isShowFinal}
                            onChange={handleShowFinalToggle}
                          />
                          <span class="slider slider-round"></span>
                        </label>
                      </div>
                    </div>
                    <div className="d-flex align-items mr-3">
                      <label className="mb-0">Exclude Empty Grades</label>
                      <div className="toggle-switch ml-2">
                        <label class="switch">
                          <input
                            type="checkbox"
                            checked={isExcludeGrade}
                            onChange={handleExcludeFinal}
                          />
                          <span class="slider slider-round"></span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="form-group form-group-save-cancel mt-4">
                    <button
                      class="btn btn-save btn-success"
                      type="submit"
                      title="Save"
                      onClick={() => handlepdfData()}
                    >
                      <i class="fal fa-download"></i> Download SoR
                    </button>
                    <button
                      class="btn btn-close btn-danger"
                      type="button"
                      title="Cancel"
                    >
                      <i
                        class="fal fa-times"
                        onClick={() => {
                          setIsChecked(false);
                          setIsShowFinal(false);
                          setIsExcludeGrade(false);
                          $("#availableprogrammeinRereg").modal("hide");
                        }}
                      ></i>{" "}
                      Cancel
                    </button>
                  </div>

                  {/* <label
                    className={`custom-toggle-switch ${
                      isChecked ? "checked" : ""
                    }`}
                  >
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleToggle}
                    />
                    <span className="slider">
                      <span className={`icon ${isChecked ? "checked" : ""}`}>
                        {isChecked ? "U+2713" : "X"}
                      </span>
                    </span>
                  </label> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProgrammeGrademixGradeBookTable;
