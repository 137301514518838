import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useHistory, useParams } from "react-router";
import { useSelector } from "react-redux";
import hasPermission from "../../../../utils/hasMultiplePermission";
import HtmlInputEditor from "../../../common/HtmlInputEditor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { AddTestimonial, getTestimonialColorList, getTestimonialDropdownlist, GetUpdateTestimonial, UpdateTestimonial } from "../../../../services/TestimonialsServer";
import ReactStars from "react-rating-stars-component";
import { FileUploadField, FormField } from "../../../common/FormFields";
import PermissionsGate from "../../../../utils/permissionGate";
import axios from "axios";


const LayoutTestimonialForm = ({ setHeaderName }) => {
    const history = useHistory();
    const { tab, type, subId } = useParams();

    const [updateData, setUpdateData] = useState(null);
    const [testimonialColour, setTestimonialColour] = useState([]);
    const [description, setDescription] = useState("")
    const [brandValue, setbrandValue] = useState([]);
    const [templateName, setTemplateName] = useState([]);
    const [dropdownlist, setDropdownlist] = useState([]);

    const givenPermissions = useSelector((state) => state.givenPermission);

    let brandID = updateData?.brandTemplete.map((data) => data.brand_id);

    useEffect(() => {
        const checkPermissions = () => {
            const hasAccess = hasPermission({ scopes: ["pmcmedit"], permissions: givenPermissions });
            if (!hasAccess) {
                history.push("/noaccess");
            }
        };
        checkPermissions();
    }, [givenPermissions, history]);

    useEffect(() => {
        const fetchData = async () => {
            getTestimonialDropdownlist()
                .then(res => setDropdownlist(res?.data))
        };

        fetchData();
    }, []);

    useEffect(() => {
        const cancelTokenSources = [];
        const getPageDetails = async () => {
          cancelTokenSources.forEach(source => {
            source.cancel('New request made');
          });
    
          const source = axios.CancelToken.source();
          cancelTokenSources.push(source);
    
          try {
            const res = await GetUpdateTestimonial(subId, source.token);
            if (res.status === 200) {
                setUpdateData(res?.data?.data)
                setHeaderName(res?.data?.data?.title)
            }
          } catch (error) {
            if (!axios.isCancel(error)) {
                if (error.response.data?.message !== "") {
                    Swal.fire({
                      icon: "Info",
                      title: "Info",
                      text: "No record found with this id",
                    })
                    history.push(`/websiteManagement/layout_navigation/categories/table`)
                  }
            }
          }
        };
        if (formik.values.subId !== "" && type == "open") {
            getPageDetails()
        }
      }, [subId])

    const formik = useFormik({
        enableReinitialize: type === "open" ? true : false,
        initialValues: {
            id: updateData?.id || "",
            title: updateData?.title || "",
            category: updateData?.category || "",
            status: updateData ? updateData?.status : "",
            testimonial_type: updateData?.testimonial_type || "",
            Brand: brandID || [],
            image: updateData?.image || "",
            content: updateData?.content || "",
            is_video_show: updateData?.is_video_show || 0,
            video_url: updateData?.video_url || "",
            testimonial_color: updateData?.testimonial_color || "",
            is_override_color_variable: updateData?.is_override_color_variable || 0,
            video_preview_image: updateData?.video_preview_image || "",
            rating: updateData?.rating || '',

        },
        validationSchema: Yup.object({
            title: Yup.string().required("Title is required"),
            category: Yup.string().required("Related School is required"),
            testimonial_type: Yup.string().required("Type is required"),
            Brand: Yup.array().required("Brand Templates is required"),
            content: Yup.string().required("Testimonial Content Templates is required"),
            status: Yup.string().required("Status is required"),
            video_url: Yup.string().when('is_video_show', {
                is: 1,
                then: Yup.string().required("Video URL is required"),
                otherwise: Yup.string().when("testimonial_type", {
                    is: (value) => value == 2,
                    then: Yup.string().required("Video URL is required").url("Must be a valid video URL"),
                    otherwise: Yup.string().nullable()
                })
            }),
            rating: Yup.string().when('testimonial_type', {
                is: (val) => val == 1,
                then: Yup.string().required("Rating  is required"),
                otherwise: Yup.string()

            }),
            testimonial_color: Yup.string().required("color is required"),
            video_preview_image: Yup.string().when("testimonial_type", {
                is: (value) => value == 2,
                then: Yup.string().required("Video Preview Image is required"),
                otherwise: Yup.string().nullable()
            }),

        }),

        onSubmit: async (values, actions) => {

            const formData = new FormData();

            // Prepare values
            const cleanedValues = Object.fromEntries(
                Object.entries(values).map(([key, value]) => [
                    key,
                    value === undefined || value === null ? "" : value
                ])
            );

            // Append other fields to FormData
            Object.keys(cleanedValues).forEach((key) => {
                if (key !== 'assignedPage') {
                    const value = cleanedValues[key];
                    if (Array.isArray(value)) {
                        value.forEach((val, idx) => {
                            formData.append(`${key}[${idx}]`, val !== undefined && val !== null ? val : "");
                        });
                    } else {
                        formData.append(key, value);
                    }
                }
            });

            if (type == "open") {
                UpdateTestimonial(formData)
                    .then(res => {
                        Swal.fire({
                            icon: "success",
                            title: "Success",
                            text: "Updated Successfully",
                        });
                    })
                    .catch(err => console.error(err))
            } else {
                try {
                    await AddTestimonial(formData);
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Added Successfully",
                    });
                    actions.resetForm();
                    history.push("/websiteManagement/layout_navigation/testimonials/table");
                } catch (error) {
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: error.response?.data?.message || "An error occurred",
                    });
                }
            }
        }
    });
    console.log("redner")
    console.log(formik.values)

    useEffect(() => {
        let colorValue = {
            brandID: templateName
        }

        getTestimonialColorList(colorValue)
            .then(res => setTestimonialColour(res.data.result))
            .catch(err => console.error(err))
    }, [templateName])

    useEffect(() => {
        setbrandValue(dropdownlist?.brandTemplateList?.filter((data) => formik.values.Brand.includes(data.value)))
    }, [formik.values.Brand])

    useEffect(() => {
        setTemplateName(brandValue?.map(data => data.value))
    }, [brandValue])

    // useEffect(() => {
    //     setDescription(updateData?.content)
    // }, [updateData])

    return (
        <div className="card card-profile-info-card">
            <div>
                <div className="new-card-header">
                    <div className="card-header pt-0">Testimonial Information</div>
                </div>
                <div className="card-body-inr card-body-info">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-md-6 col-lg-3">
                                <div className="form-group-blk mb-3">
                                    <label>Title *</label>
                                    <input
                                        type="text"
                                        className={
                                            "form-control" +
                                            (formik.errors.title && formik.touched.title
                                                ? " is-invalid"
                                                : "")
                                        }
                                        placeholder="Enter Title"
                                        name="title"
                                        id="title"
                                        title="Title"
                                        value={formik.values.title}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div className="form-group-blk mb-3" title="Related School">
                                    <label>Related School*</label>
                                    <Select
                                        className={
                                            "form-control custom-select-box" +
                                            (formik.errors.category && formik.touched.category
                                                ? " is-invalid"
                                                : "")
                                        }
                                        name="category"
                                        value={dropdownlist?.schoolList?.find(val => val.value === formik.values.category)}
                                        onChange={(option) => {
                                            formik.setFieldValue("category", option ? option.value : "");
                                        }}
                                        onBlur={formik.handleBlur}
                                        options={dropdownlist?.schoolList}
                                        maxMenuHeight={175}
                                        placeholder={"Select..."}
                                        isClearable
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div className="form-group-blk mb-3" title="Status">
                                    <label>Status * <i className="fal fa-info-circle"></i></label>
                                    <Select
                                        className={
                                            "form-control custom-select-box" +
                                            (formik.errors.status && formik.touched.status
                                                ? " is-invalid"
                                                : "")
                                        }
                                        name="status"
                                        value={dropdownlist?.statusList?.find(val => val.value === formik.values.status)}
                                        onChange={(option) => {
                                            formik.setFieldValue("status", option ? option.value : "");
                                        }}
                                        onBlur={formik.handleBlur}
                                        options={dropdownlist?.statusList}
                                        maxMenuHeight={175}
                                        placeholder={"Select..."}
                                        isClearable
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div className="form-group-blk mb-3" title="Type">
                                    <label>Type*</label>
                                    <Select
                                        className={
                                            "form-control custom-select-box" +
                                            (formik.errors.testimonial_type && formik.touched.testimonial_type
                                                ? " is-invalid"
                                                : "")
                                        }
                                        name="testimonial_type"
                                        value={dropdownlist?.testimonialType?.find(val => val.value === formik.values.testimonial_type)}
                                        onChange={(option) => {
                                            formik.setFieldValue("testimonial_type", option ? option.value : "");
                                        }}
                                        onBlur={formik.handleBlur}
                                        options={dropdownlist?.testimonialType}
                                        maxMenuHeight={175}
                                        placeholder={"Select..."}
                                        isClearable
                                    />
                                </div>
                            </div>
                            <div className={`col-md-6 ${formik.values.testimonial_type !== 2 ? "col-lg-6" : "col-lg-3"}`}>
                                <div className="form-group-blk mb-3" title="Assigned Brand Templates">
                                    <label>Assigned Brand Templates* <i className="fal fa-info-circle"></i></label>
                                    <Select
                                        className={
                                            "form-control custom-select-box is-multi" + (formik.errors.Brand && formik.touched.Brand
                                                ? " is-invalid"
                                                : "")
                                        }
                                        name="Brand"
                                        value={brandValue}
                                        onChange={(value) => formik.setFieldValue("Brand", value ? value.map((v) => v.value) : [])}
                                        onBlur={formik.handleBlur}
                                        options={dropdownlist?.brandTemplateList}
                                        maxMenuHeight={175}
                                        isMulti
                                        placeholder={"Select..."}
                                    />
                                </div>
                            </div>
                            {
                                formik.values.testimonial_type === 2 &&
                                <div className="col-md-6 col-lg-3">
                                    <div className="form-group-blk mb-3">
                                        <label>
                                            Video URL* <i className="fal fa-info-circle"></i>
                                        </label>
                                        <input
                                            type="text"
                                            className={
                                                "form-control" +
                                                (formik.errors.video_url && formik.touched.video_url
                                                    ? " is-invalid"
                                                    : "")
                                            }
                                            placeholder="Enter Video URL"
                                            name="video_url"
                                            id="video_url"
                                            title="Video URL"
                                            value={formik.values.video_url}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                        />
                                    </div>
                                </div>
                            }

                            {
                                formik.values.testimonial_type === 1 &&
                                <div className="col-md-6 col-lg-3">
                                    <div className="form-group-blk mb-3" title="Rating">
                                        <label>Rating*</label>
                                        <div className="mt-md-1" title={`Rating: ${formik.values.rating}`}>
                                            <ReactStars
                                                value={formik.values.rating}
                                                onChange={(newRating) => formik.setFieldValue('rating', newRating)}
                                                size={24}
                                                activeColor="#00A9FF"
                                            />
                                        </div>
                                    </div>
                                </div>

                            }

                            {
                                formik.values.testimonial_type === 2 &&
                                <FileUploadField
                                    name="video_preview_image"
                                    label="Video Preview Image"
                                    formik={formik}
                                    accept=".png,.jpg,.jpeg"
                                    col_md={6}
                                    col_lg={3}
                                    required={true}
                                />
                            }
                            {
                                (formik.values.testimonial_type === 2 || formik.values.testimonial_type === 3) &&
                                <FileUploadField
                                    name="image"
                                    label="Image"
                                    formik={formik}
                                    accept=".png,.jpg,.jpeg"
                                    col_md={6}
                                    col_lg={3}
                                />
                            }


                            <div className="col-md-12">
                                <div className="form-group-blk mb-3">
                                    <label>Testimonial Content*</label>
                                    <HtmlInputEditor
                                        editorState={formik.values.content}
                                        setEditorState={(editorState) => {
                                            // setDescription(editorState);
                                            formik.setFieldValue('content', editorState);
                                        }}
                                        hideSign
                                        isCKEditor
                                    />
                                </div>
                            </div>
                            {formik.values.testimonial_type === 1 &&
                                <>
                                    <div className="col-md-2 d-flex align-items-center">
                                        <label className="mb-0">
                                            Show Video <i className="fal fa-info-circle"></i>
                                        </label>
                                        <div className="toggle-switch">
                                            <label className="switch">
                                                <input
                                                    type="checkbox"
                                                    checked={formik.values.is_video_show}
                                                    onChange={(e) => formik.setFieldValue(
                                                        "is_video_show",
                                                        e.target.checked ? 1 : 0
                                                    )}
                                                />
                                                <span className="slider slider-round"></span>
                                            </label>
                                        </div>
                                    </div>

                                    {formik.values.is_video_show == 1 ? <div className="col-md-10">
                                        <div className="form-group-blk mb-3">
                                            <label>
                                                Video URL* <i className="fal fa-info-circle"></i>
                                            </label>
                                            <input
                                                type="text"
                                                className={
                                                    "form-control" +
                                                    (formik.errors.video_url && formik.touched.video_url
                                                        ? " is-invalid"
                                                        : "")
                                                }
                                                placeholder="Enter Video URL"
                                                name="video_url"
                                                id="video_url"
                                                title="Video URL"
                                                value={formik.values.video_url}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>
                                    </div>
                                        : null}
                                </>
                            }
                            <div className="col-md-12">
                                <div className="edit-icon new-card-header mb-4 mt-1">
                                    <h4 className="card-header">
                                        <FontAwesomeIcon icon={faGear} className="top-bar-color" /> Style Settings
                                    </h4>
                                </div>
                            </div>
                            {formik.values.is_override_color_variable === 1 ? <>
                                {[
                                    { label: "Testimonial Colour", name: "testimonial_color", color: true, required: true, type: "color", },
                                ].map((field, index) => (
                                    <FormField key={index} field={field} formik={formik} />
                                ))}
                            </> : <div className="col-md-6 col-lg-3" title="Testimonial Colour">
                                <div className="form-group-blk mb-4">
                                    <label>Testimonial Colour *</label>
                                    <Select
                                        className={`form-control custom-select-box ${formik.errors.testimonial_color && formik.touched.testimonial_color && "is-invalid"}`}
                                        name="campusColor"
                                        value={testimonialColour.filter(val => formik.values.testimonial_color.includes(val.value.toString()))}
                                        onChange={(value) => {
                                            if (value) {
                                                formik.setFieldValue("testimonial_color", value.value);
                                            } else {
                                                formik.setFieldValue("testimonial_color", "");
                                            }
                                        }}
                                        isClearable
                                        onBlur={formik.handleBlur}
                                        options={testimonialColour}
                                        maxMenuHeight={175}
                                        placeholder={"Select..."}
                                    />
                                    {formik.errors.testimonial_color &&
                                        formik.touched.testimonial_color ? (
                                        <span className="exclamation">
                                            <i className="fal fa-exclamation-circle"></i>
                                        </span>
                                    ) : null}
                                </div>
                            </div>}
                            <div className="col-md-6 col-lg-3 d-flex align-items-center mb-3" title="Override Colour Variable">
                                <label className="mb-0">
                                    Override Colour Variable <i className="fal fa-info-circle"></i>
                                </label>
                                <div className="toggle-switch ml-2">
                                    <label className="switch">
                                        <input
                                            type="checkbox"
                                            checked={formik.values?.is_override_color_variable === 1}
                                            onChange={(e) => {
                                                formik.setFieldValue(
                                                    "is_override_color_variable",
                                                    e.target.checked ? 1 : 0
                                                )
                                                formik.setFieldValue(
                                                    "testimonial_color",
                                                    e.target.checked ? "#000000" : ""
                                                )
                                            }
                                            }
                                        />
                                        <span className="slider slider-round"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="form-group form-group-save-cancel mt-4">
                            <PermissionsGate scopes={[tab == "create" ? 'wbslntestimonialsadd' : 'wbslntestimonialsedit']}>

                                <button
                                    className="btn btn-save btn-success"
                                    type="submit"
                                    title="Save"
                                >
                                    <i className="fal fa-save"></i>
                                    Save
                                </button>
                            </PermissionsGate>
                        </div>
                        {Object.keys(formik.errors).map((key) => {
                            if (formik.touched[key]) {
                                return (
                                    <div className="invalid-feedback d-block" key={key}>
                                        {formik.errors[key]}
                                    </div>
                                );
                            }
                            return null;
                        })}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default LayoutTestimonialForm;
