import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { RenderLearningMethod } from '../../../../utils/CommonGroupingItem';
import DataTableComponentFrontPagination from '../../../common/DataTableComponentFrontPagination';
import axios from 'axios';
import { RenderCourseAssignedlistStatus } from '../../../../utils/CommonStatusItems';
import CourseAvailableProgrames from './CourseAvailableProgrames'
import SkeletonTicketList from '../../../../loaders/SkeletonTicketList';
import { getassignedProgrammeFilters, GetCoursePageAssignedProgrammeList } from '../../../../services/CourseLandingPageServer';

function CourseAssignedProgrammes({ id, assignedRec, setAssignedRec, assignloading, setAssignLoading, type,refresh }) {
  const [availableLoading, setAvailableLoading] = useState(false);
  const [addedRec, setAddedRec] = useState([]);
  const [availableRec, setAvailableRec] = useState([]);
  const [searchAssigned, setSearchAssigned] = useState("");
  const [assingIntake, setAssingIntake] = useState({ arr: [], checkObj: {} })
  const [assingStudyType, setAssingStudyType] = useState({ arr: [], checkObj: {} })
  const [filterData, setFilterData] = useState({ intake: [], learning: [] })
  const [assignType,setAssignType] = useState({ arr: [], checkObj: {} })
  const [AvailablefilterData, setAvailableFilterData] = useState({ intake: [], learning: [] })
  // const uniquekey = assignedRec?.map((id) => id.ref_id)

  useEffect(() => {
    const getProgramsList = async () => {
      setAssignLoading(true);
      try {
        let res = await getassignedProgrammeFilters(id, 1);

        if (res.status === 200) {
          setFilterData({ ...res.data, intake: res?.data?.statusListFilter, learning: res?.data?.type })
          setAssignLoading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.log("error:", error)
          setAssignLoading(false)
        }
      }
    }

    const getsingleProgramFilter = () => {
      setAvailableLoading(true)
      getassignedProgrammeFilters(id, 0)
        .then((res) => {
          setAvailableFilterData({ intake: res?.data?.statusListFilter, learning: res?.data?.type })
        })
        .catch((error) => {
          console.log("error:", error)
        })
        .finally(() => setAvailableLoading(false))
    }

    getProgramsList()
    getsingleProgramFilter()
  }, [refresh])

  useEffect(() => {
    const getProgramsList = async () => {
      setAvailableLoading(true);
      const values = {
        TrainingCourseLandingPageID: type === 'open' && id,
        isAssigned: "0",
        exportStatus: "true"
      }

      try {
        let res = await GetCoursePageAssignedProgrammeList(values);
        if (res.status === 200) {
          setAvailableRec(res?.data?.listProgramme);
          setAvailableLoading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.log("error:", error)
          setAvailableLoading(false)
        }
      }

    }
    getProgramsList()
  }, [refresh])

  const handleAdd = (rec, type) => {
    if (type === 'add') {
      Swal.fire({
        title: "Are you sure?",
        icon: "confirm",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Add it!",
      }).then((result) => {
        if (result.isConfirmed) {
          availableRec.map((elem, ind) => {
            if (elem.id == rec.id) {
              availableRec.splice(ind, 1);
              setAssignedRec([...assignedRec, rec]);
              if (addedRec.length) {
                addedRec.map((e, i) => {
                  if (e == rec.id) {
                    addedRec.splice(i, 1);
                    setAddedRec([...addedRec]);
                  } else {
                    setAddedRec([...addedRec, rec.id]);
                  }
                });
              } else {
                setAddedRec([...addedRec, rec.id]);
              }
            }
          });
          Swal.fire("Success!", "Programe Assigned Successfully.", "success");
        }
      });
    } else if (type === "Delete") {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          assignedRec.map((elem, ind) => {
            if (elem.id == rec.id) {
              assignedRec.splice(ind, 1);
              setAvailableRec([...availableRec, rec]);
              if (addedRec.length) {
                addedRec.map((e, i) => {
                  if (e == rec.id) {
                    addedRec.splice(i, 1);
                    setAddedRec([...addedRec]);
                  } else {
                    setAddedRec([...addedRec, rec.id]);
                  }
                });
              } else {
                setAddedRec([...addedRec, rec.id]);
              }
            }
          });
        }
      });
    }
  };

  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "name",
      sortField: "name",
      sortable: true,
      cell: (row) => (
        <Link
          className="as-text-blue curser feature-name"
          to={`/websiteManagement/pages/programmes_and_short_courses/${row.page_type == 0 ? 'programme_pages' : 'short_course_page'}/details/open/${row.id}`}
          title={row.name}
        >
          <span className="textLimit100">{row.name}</span>
        </Link>
      ),
    },
    {
      name: "Status",
      selector: "status",
      sortField: "status",
      sortable: true,
      cell: (row) => (row.status !== undefined || null ? RenderCourseAssignedlistStatus(row.status).html : "-"),
    },
    {
      name: "Type",
      selector: "qualification_type_name",
      sortable: true,
      cell: (row) => row?.qualification_type_name != null ? RenderLearningMethod(row.qualification_type_name).html : "-"
    },
    {
      name: "Learning Method",
      selector: "study_type",
      sortable: true,
      sortField: "type",
      cell: (row) => (
       <div>
         {row?.getLearningMethods.length > 0 ? row.getLearningMethods.map((item)=>item.learning_method ? RenderLearningMethod(item.learning_method).html : "-") : "-"}
       </div>
          
      ),
  },
    // {
    //   name: "Learning Method",
    //   selector: "learning_method",
    //   sortable: true,
    //   cell: (row) => {

    //     const learningMethods = row?.getLearningMethods;
    
    //     if (learningMethods && learningMethods.length > 0) {
    //       return learningMethods.map(item => 
    //         item.learning_method ? RenderLearningMethod(item.learning_method).html : "-"
    //       ).join(", ");
    //     }        
    //     return "-";
    //   }
    // },    
    {
      name: "Actions",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons d-flex">
            <Link
              className="btn btn-primary rounded-circle"
              to={`/websiteManagement/pages/programmes_and_short_courses/${row.page_type == 0 ? 'programme_pages' : 'short_course_page'}/details/open/${row.id}`}
              title="Open"
            >
              <i className="fal fa-folder-open"></i>
            </Link>

            <button
              type="button"
              className="btn btn-danger rounded-circle"
              onClick={() => handleAdd(row, "Delete")}
              title='Delete'
            >
              <i className="fal fa-minus"></i>
            </button>
          </div>
        </div>
      ),
    },
  ]);

  const dataToRender = () => {
    let updatedData = [];
    let allData = assignedRec;

    if (searchAssigned.length) {
      let name = allData?.filter((item) => {
        let includes = item?.name?.toString()
          .toLowerCase()
          .includes(searchAssigned.toLowerCase());
        if (includes) {
          return includes;
        } else return null;
      });

      let data = [...name];
      let unique = [...new Set(data)];

      updatedData = unique;
    } else {
      updatedData = allData;
    }

    if (assignType.arr.length) {
      let tempAssignStudy = updatedData;
      let tempIntakeHash = tempAssignStudy.filter((item) => {

        const startsMarking = assignType.arr.find((it) => it == item.qualification_type)
        if (startsMarking) {
          return startsMarking;
        } else return null;
      });
      updatedData = tempIntakeHash;
    } else if (assingIntake.arr.length) {
      let tempIntake = updatedData;
      let tempIntakeHash = tempIntake.filter((item) => {
        const startsMarking = assingIntake.arr.find((it) => it == item.status)
        if (startsMarking) {
          return startsMarking;
        } else return null;
      });

      updatedData = tempIntakeHash;
    }

    if (
      searchAssigned.length ||
      assignType.arr.length ||
      assingIntake.arr.length
    ) {
      return updatedData;
    } else {
      return assignedRec;
    }
  };

  const exportData = () => {
    let data = dataToRender();
    const header = [
      "Name",
      "Status",
      "Type",
      "Learning Methods"
    ];
    data = data.map((row) => ({
      "Name": row.name ? row.name : "-",
      "Status": row.status,
      "Type": row?.qualification_type_name != null ? RenderLearningMethod(row.qualification_type_name).text : "-",
      "Learning Methods": row?.getLearningMethods.length > 0 ? row.getLearningMethods.map((item)=>item.learning_method ? RenderLearningMethod(item.learning_method).text : "-").join(",") : "-",
    }));
    const pdfData = data.map((row) => {
      return [
        row["Name"],
        row["Status"],
        row["Type"],
        row["Learning Methods"]
      ];
    });
    return {
      header,
      data,
      pdfData,
      columnStyles: {}
    }
  };

  return (
    <>
      <DataTableComponentFrontPagination
        data={dataToRender()}
        columns={columns}
        loading={assignloading}
        search={searchAssigned}
        setSearch={setSearchAssigned}
        exportData={exportData}
        exportFileName={"Assigned Programmes"}
        progressComponent={<SkeletonTicketList />}
        defaultSort={{ defaultSortColumn: "name", defaultSortAsc: false }}
        custumcss="border-0 p-0"
        // keyField={uniquekey[1]}
        filters={[
          {
            filterName: "Status",
            optionArr: filterData.intake,
            state: assingIntake,
            setState: setAssingIntake,
            uniqueId: "assignstatus",
            isOptionReversed: true,
            renderLabelFunction: RenderCourseAssignedlistStatus,
          },
          {
            filterName: "Type",
            optionArr: filterData.type,
            state: assignType,
            setState: setAssignType,
            uniqueId: "assigntype",
            isOptionReversed: true,
            renderLabelFunction: RenderLearningMethod,
          },
        ]}
        tableButton={
          [
            <div className='d-flex'>
              <button className="btn btn-primary mr-1" data-toggle="modal" data-target="#availableProgramme" title="Assign Programme" type="button">
                <i className="fal fa-laptop"></i>Assign Programme
              </button>
            </div>
          ]
        }
      />

      <div
        className="topic-add-modal modal able_modal01 fade"
        id="availableProgramme"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered available-assessment-modal"
          role="document"
        >
          <CourseAvailableProgrames
            AvailablefilterData={AvailablefilterData}
            availableRec={availableRec}
            assignedRec={assignedRec}
            setAssignedRec={setAssignedRec}
            availableLoading={availableLoading}
            handleAdd={handleAdd}
          />
        </div>
      </div>
    </>
  )
}

export default CourseAssignedProgrammes