import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import {
    AddWebsite,
    GetWebsiteUpdateList,
    updateWebsite,
    WebsiteBranch,
    WebsiteStatus,
    WebsiteTemplates,
} from "../../../services/WebsiteListServer";
import { FileUploadField } from "../../common/FormFields";
import PermissionsGate from "../../../utils/permissionGate";

const WebsiteInformation = () => {
    const { id, type } = useParams();
    const history = useHistory();

    // DropDown Arrays Start
    const [branch, setBranch] = useState([]);
    const [brandTemplate, setBrandTemplate] = useState([]);
    const [status, setStatus] = useState([]);
    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState(false);
    const [updateData, setUpdateData] = useState({})
    const title = {
        siteDomain : "This is the URL of the website", 
        branch: "If the website is the production, development or staging branch. If this is set to production, all pages and qualifications will be filtered by where published status is set to published, if set to development it will show both published and unpublished items.", 
        status : "This determines if the website is live or not. If set to archived the website will not load any data.",
        tagline:" The tagline is a short line of text that aims to communicate the site's main focus. Taglines are usually eight to 12 words long",
        siteIcon : "The site icon is a small, 16x16 pixel icon used on web browsers to represent a website or a web page. It is commonly displayed on tabs at the top of a web browser, but they're also found on the browser's bookmark bar, history and in search results, alongside the page url.", 
        linkedBrandTemplate: "This is the brand template linked to the website and determains the style of the website as well as the content displayed."
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            siteName: updateData?.siteName || "",
            siteDomain: updateData?.siteDomain || "",
            branch: updateData?.branch || "",
            status: updateData?.status || "",
            siteTitle: updateData?.siteTitle || "",
            brandTemplate: updateData?.brandTemplate || "",
            tagLine: updateData?.tagLine || "",
            siteIcon: updateData?.siteIcon || "",
        },

        validationSchema: Yup.object().shape({
            siteName: Yup.string().required("Site Name is required"),
            siteDomain: Yup.string().required("Domain is required"),
            branch: Yup.string().required("Branch is required"),
            siteTitle: Yup.string().required("Site Title is required"),
            brandTemplate: Yup.string().required("Brand Templates is required"),
            tagLine: Yup.string().required("Tagline is required"),
            siteIcon: Yup.string().required("Site Icon is required"),
        }),

        onSubmit: (values, action) => {
            setLoading(true);
            const formData = new FormData();
            const trimmedValues = Object.fromEntries(
                Object.entries(values).map(([key, value]) => [
                    key,
                    typeof value === "string" ? value.trim().replace(/ +/g, " ") : value,
                ])
            );
            Object.keys(trimmedValues).forEach((key) => {
                if (Array.isArray(trimmedValues[key])) {
                    trimmedValues[key].forEach((val, idx) => {
                        formData.append(`${key}[${idx}]`, val);
                    });
                } else {
                    formData.append(key, trimmedValues[key]);
                }
            });
            if (type === "create") {
                AddWebsite(formData)
                    .then(() => {
                        Swal.fire("Success!", "Website created successfully!", "success");
                        action.resetForm();
                        setLoading(false);
                        history.push("/websiteManagement/siteSetup/table");
                    })
                    .catch((error) => {
                        console.error("Error creating Website:", error);
                        Swal.fire("Error!", "Failed to create programme", "error");
                        setLoading(false);
                    });
            } else if (type === "update" || type === "open") {
                updateWebsite(formData, id)
                    .then(() => {
                        Swal.fire(
                            "Success!",
                            "Website updated successfully!",
                            "success"
                        );
                        setLoading(false);
                    })
                    .catch((error) => {
                        console.error("Error updating Website:", error);
                        Swal.fire("Error!", "Failed to update Website", "error");
                        setLoading(false);
                    });
            }
            action.setSubmitting(false);
        },
    });


    const websiteBranch = () => {
        setLoading(true);
        WebsiteBranch()
        .then((data) => {
            let respData = data.data.branchFilters.map((item) => ({
                label: item.label,
                value: item.value,
            }));
            setBranch([...respData]);
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    };

    const websiteStatus = () => {
        setLoading(true);
        WebsiteStatus()
        .then((data) => {
            let respData = data.data.statusFilters.map((item) => ({
                label: item.label,
                value: item.value,
            }));
            setStatus([...respData]);
        })
        .catch((res) => console.log(res))
        .finally(() => setLoading(false))
    };

    const websiteTemplates = () => {
        WebsiteTemplates().then((data) => {
            let respData = data.data.brandTypesFilters.map((item) => ({
                label: item.label,
                value: item.value,
            }));
            setBrandTemplate([...respData]);
        });
    };

    const handleUpdate = (id) => {
        setLoading(true);
        GetWebsiteUpdateList(id)
            .then(res => {
                setEdit(true)
                setUpdateData(res?.data?.Data)
                setLoading(false);
            })
            .catch(err => {
                console.log(err)
                setLoading(false);
            })
    }

    useEffect(() => {
        websiteBranch();
        websiteStatus();
        websiteTemplates();
    }, []);

    useEffect(() => {
        handleUpdate(id);
    }, [id])

    return (
        <div className="card card-body-inr">
            <form onSubmit={formik.handleSubmit}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="edit-icon new-card-header">
                            <h4 className="card-header" title="Website Information">Website Information</h4>
                        </div>
                    </div>

                    <div className="col-md-6 col-lg-3">
                        <label htmlFor="site_Name" title="Site Name" >Site Name *</label>
                        <div className="form-icon-group mb-4" title="site_Name">
                            <input
                                type="text"
                                className={`form-control ${formik.touched.siteName && formik.errors.siteName && "is-invalid"
                                    }`}
                                name="siteName"
                                id="site_Name"
                                title="Site Name"
                                placeholder="Site Name"
                                value={formik.values.siteName}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <label htmlFor="site_Domain" title={title?.siteDomain}>Site Domain *</label>
                        <div className="form-icon-group mb-4" title={title?.siteDomain}>
                            <input
                                type="text"
                                className={`form-control ${formik.touched.siteDomain && formik.errors.siteDomain && "is-invalid"
                                    }`}
                                name="siteDomain"
                                id="site_Domain"
                                title={title?.siteDomain}
                                placeholder="Site Domain"
                                value={formik.values.siteDomain}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-3">
                        <label htmlFor="Branch" title={title?.branch} >Branch *</label>
                        <div className="form-icon-group mb-4" title={title?.branch}>
                            <Select
                                className={`form-control custom-select-box ${formik.touched.branch && formik.errors.branch && "is-invalid"
                                    }`}
                                name="branch"
                                value={branch.find((item) => item.value === formik.values.branch)}
                                onChange={(option) => formik.setFieldValue("branch", option ? option.value : "")}
                                onBlur={formik.handleBlur}
                                options={branch}
                                maxMenuHeight={175}
                                placeholder="Select Branch"
                                isClearable
                            />
                        </div>
                    </div>

                    <div className="col-md-6 col-lg-3">
                        <label htmlFor="Status" title={title?.status} >Status <i className="fal fa-info-circle"></i></label>
                        <div className="form-icon-group mb-4" title={title?.status}>
                            <Select
                                className={`form-control custom-select-box ${formik.touched.status && formik.errors.status && "is-invalid"
                                    }`}
                                name="status"
                                value={status.find((item) => item.value === formik.values.status)}
                                onChange={(option) => formik.setFieldValue("status", option ? option.value : "")}
                                onBlur={formik.handleBlur}
                                options={status}
                                maxMenuHeight={175}
                                placeholder="Select Status"
                                isClearable
                            />
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="edit-icon new-card-header" title="Site Settings">
                            <h4 className="card-header">
                                <FontAwesomeIcon icon={faGear} color="var(--topbar-color)" /> Site Settings
                            </h4>
                        </div>
                    </div>

                    <div className="col-md-3">
                        <label htmlFor="Site_Title" title="Site Title" >Site Title *</label>
                        <div className="form-icon-group mb-4" title="Site Title">
                            <input
                                type="text"
                                className={`form-control ${formik.touched.siteTitle && formik.errors.siteTitle && "is-invalid"
                                    }`}
                                name="siteTitle"
                                id="Site_Title"
                                title="Site Title"
                                placeholder="Site Title"
                                value={formik.values.siteTitle}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="Tagline" title={title?.tagline} >Tagline *</label>
                        <div className="form-icon-group mb-4" title={title?.tagline} >
                            <input
                                type="text"
                                className={`form-control ${formik.touched.tagLine && formik.errors.tagLine && "is-invalid"
                                    }`}
                                name="tagLine"
                                id="Tagline"
                                title={title?.tagline}
                                placeholder="Tagline"
                                value={formik.values.tagLine}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </div>
                    </div>
                    <div className="col-md-3">
                        <label htmlFor="brandTemplate" title={title?.linkedBrandTemplate}>Linked Brand Template *</label>
                        <div className="form-icon-group mb-4" title={title?.linkedBrandTemplate} >
                            <Select
                                className={`form-control custom-select-box ${formik.touched.brandTemplate && formik.errors.brandTemplate && "is-invalid"
                                    }`}
                                name="brandTemplate"
                                value={brandTemplate.find((item) => item.value === formik.values.brandTemplate)}
                                onChange={(option) => formik.setFieldValue("brandTemplate", option ? option.value : "")}
                                onBlur={formik.handleBlur}
                                options={brandTemplate}
                                maxMenuHeight={175}
                                placeholder="Select Brand Template"
                                isClearable
                            />
                        </div>
                    </div>

                    <FileUploadField
                        name={"siteIcon"}
                        title={title?.siteIcon}
                        label={"Site Icon"}
                        formik={formik}
                        accept=".png,.jpg,.jpeg"
                        col_md={6}
                        col_lg={3}
                        required={true}
                    />
                </div>
                <div className="form-group form-group-save-cancel">
                    <PermissionsGate RenderError={() => (
                        <button className="btn btn-save btn-success" disabled>
                            <i className="fal fa-save"></i> Save
                        </button>
                    )} scopes={[type == "create" ? "wbssitewebsiteadd" : "wbssitewebsiteedit"]}>

                    <button className="btn btn-save btn-success" type="submit" title="Save" disabled={loading === true}>
                        {loading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-save"></i>}
                        Save
                    </button>
                    </PermissionsGate>

                    { type === "create" &&
                        <button className="btn btn-close btn-danger" type="button" title="Cancel" onClick={() => formik.resetForm()}>
                            <i className="fal fa-times"></i>
                            Cancel
                        </button>
                    }

                    {edit && (
                        <a
                            href={updateData?.siteDomain?.startsWith('http') ? updateData.siteDomain : `https://${updateData.siteDomain}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn btn-save btn-success"
                            title="View Site"
                        >
                            <i className="fal fa-eye"></i>
                            View Site
                        </a>
                    )}
                </div>

                {Object.keys(formik.errors).map((key) => {
                    if (formik.touched[key]) {
                        return (
                            <div className="invalid-feedback d-block" key={key}>
                                {formik.errors[key]}
                            </div>
                        );
                    }
                    return null;
                })}
            </form>
        </div>
    );
};

export default WebsiteInformation;