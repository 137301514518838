import React, { useState, useMemo, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Link, useHistory, useParams } from "react-router-dom";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import PermissionsGate from "../../../utils/permissionGate";
import {
  RenderAssessmentType,
  RenderCourseType,
} from "../../../utils/CommonGroupingItem";
import Swal from "sweetalert2";
import Select from "react-select";
import {
  getProgrameGradeDetails,
  GradeLetterAggregationMethods,
  GradeLetterTemplateDetail,
  GradeLetterTypes,
  updateProgrameGradeDetails,
  getProgrameGradeMixDetails,
  updateProgrammeGradeMixDetails,
  getGradeMixDropdown,
  lockGradeProgrammeGrademixToggle,
  GetStatemantTmpList,
} from "../../../services/GradeSettingService";
import ListOfTemplateModal from "../CourseGradeSettings/ListOfTemplateModal";
import Str from "../../common/Str";
import hasPermission from "../../../utils/hasMultiplePermission";
import { useDispatch, useSelector } from "react-redux";
import { handleTableScroll } from "../../../utils/commonFunction";
import $ from "jquery";
import Tablefilter from "../../common/Tablefilter";
import { GetListOfBrandSettingCancelToken } from "../../../services/BrandServices";
import axios from "axios";
import { setBrandOverride2 } from "../../../store/actions";

const ProgrameGradeSetting = ({ gradingStandard }) => {
  const history = useHistory();
  const { id } = useParams();
  const [search, setSearch] = useState("");
  const [assessmentList, setAssessmentList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [gradingMethodValue, setGradingMethodValue] = useState("1");
  const [aggregationMethodValue, setAggregationMethodValue] = useState();
  const [gradeTypeValue, setGradeTypeValue] = useState();
  const [dropoutValue, setDropoutValue] = useState("");
  const [passMark, setPassMark] = useState("");
  const [passMarkValue, setPassMarkValue] = useState("");
  const [formulaValue, setFormulaValue] = useState("");
  const [gradeTypeArr, setGradeTypeArr] = useState([]);
  const [aggregationTypeArr, setAggregationTypeArr] = useState([]);
  const [excludeEmptyGrade, setExcludeEmptyGrade] = useState(0);
  const [isDisabled, setIsDisabled] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [sortOrder, setSortOrder] = useState("ASC");
  const [sortKey, setSortKey] = useState("name");
  const [totalRows, setTotalRows] = useState(0);
  const [isGradeLocked, setIsGradeLocked] = useState(0);
  const [updateData, setUpdateData] = useState(true);
  const [errorMessage, setErrorMessage] = useState({});
  const [editedRows, setEditedRows] = useState([]);
  const givenPermsisions = useSelector((state) => state.givenPermission);
  const tooltipObj = {
    aggregationMethodTooltip:
      "Median of grades - If this is selected, the programme grade must be determined by is the middle value (or the mean of the two middle values) when percentages are arranged in order of value for the courses.\nLowest Grade – If this is selected, the programme grade will be the lowest grade achieved for any of its courses.\nHighest Grade - If this is selected, the programme grade will be the highest grade achieved for any of its courses.\nMode of Grades - If this is selected, the programme grade will be the grade that occurs the most frequently.\nNatural - If this is selected, the programme grade will be the sum of all course grades, scaled by their relative weights.",
    dropoutTootltip:
      "The number entered will be used to exclude X number of courses which have the lowest final grade from the final calculation, this will only consider courses which have a weight if natural is selected",
  };
  const [linkedAssessmentDropdown, setLinkedAssessmentDropdown] = useState({
    linkedAssessment: [],
  });
  const [assessmentIds, setAssessmentIds] = useState({ arr: [], checkObj: [] });
  const [brandOverride, setBrandOverride] = useState();
  const [brandList, setBrandList] = useState([]);
  const [sorTemplatevalue, setsorTemplatevalue] = useState();
  const [sorTemplateList, setSorTemplateList] = useState([]);
  const [switchState, setSwitchState] = useState({
    show_assessments: false,
    show_final_results: false,
    show_empty_grades: false,
    allow_student_portal_gradebook_view: false,
    allow_student_portal_sor_to_download: false,
    override_sor_brand: false,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    handleTableScroll();
  }, [loading]);

  useEffect(() => {
    $(document).ready(function () {
      $(".dropdown-toggle").click(function () {
        $(".rdt_TableCell").css("z-index", 0);
        $(this).parents(".rdt_TableCell").css("z-index", 22);
      });
    });
  });

  useEffect(() => {
    let response = hasPermission({
      scopes: ["capgseview"],
      permissions: givenPermsisions,
    });
    if (!response) {
      history.push("/noaccess");
    }
  }, []);

  let templatePassMarkArr = [];
  let templateArray =
    selectedTemplate?.gradeOptions?.length &&
    selectedTemplate?.gradeOptions.map((item) => {
      templatePassMarkArr.push({
        value: item?.id,
        label: item?.text,
      });
    });

  let options = [
    { label: "Red", value: "0" },
    { label: "Yellow", value: "49" },
    { label: "Green", value: "100" },
  ];

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();

    const fetchData = async () => {
      try {
        const response = await GetListOfBrandSettingCancelToken(dropdownSource.token);
        if (response?.status == 200) {
          const brands = response.data.brand_setting_list.map(brand => ({
            label: brand.brand_name,
            value: brand.id
          }))
          const sortedBrands = brands.sort((a, b) => {
            if (a.label?.toLowerCase() < b.label?.toLowerCase()) {
              return -1
            } else if (a.label?.toLowerCase() > b.label?.toLowerCase()) {
              return 1
            }
            return 0
          })
          setBrandList(sortedBrands)
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };

    fetchData();

    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, []);

  useEffect(() => {
    const cancelTokenSources = [];

    const getData = async () => {
      setLoading(true);

      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });

      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);

      const data = {
        page: 1,
        limit: 10,
        search: '',
        key: 'name',
        sort: 'asc',
        exportStatus: "true",
        viaKeywords: [],
        viaTopics: [],
      };

      try {
        const res = await GetStatemantTmpList(data, source.token);
        if (res.status === 200) {
          const sorTemplates = res.data?.result.map(template => ({
            label: template.name,
            value: template.id
          }))
          const sortedTemplates = sorTemplates.sort((a, b) => {
            if (a.label.toLowerCase() > b.label.toLowerCase()) {
              return -1
            } else if (a.label.toLowerCase() > b.label.toLowerCase()) {
              return 1
            }
            return 0
          })
          setSorTemplateList(sortedTemplates);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    }

    getData();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    }
  }, []);
  
  useEffect(() => {
    setLoading(true);
    let data = {
      id,
      page: 1,
      limit: 10,
      key: sortKey,
      sort: sortOrder,
      exportStatus: "true",
      search,
      // viaAssessmentType: assessmentIds.arr
    };
    if (gradingStandard) {
      if (gradingStandard == 2) {
        data.viaAssessment = assessmentIds.arr;
        getProgrameGradeMixDetails(data)
          .then((res) => {
            let details = res.data?.details;
            let assessment_data = res?.data?.gradeMixList;
            setsorTemplatevalue(details?.st_template)
            setBrandOverride(details?.override_brand)

            setSwitchState({
              show_assessments: details.show_assessments,
              show_final_results: details.show_final_results,
              show_empty_grades: details.show_empty_grades,
              allow_student_portal_gradebook_view: details.allow_student_portal_gradebook_view,
              allow_student_portal_sor_to_download: details.allow_student_portal_sor_to_download,
              override_sor_brand: details.override_sor_brand
            })
            setAssessmentList(assessment_data ? assessment_data : []);
            setAggregationMethodValue(details?.aggregation_method_id);
            setExcludeEmptyGrade(details?.exclude_empty_grades);
            setGradeTypeValue(details?.grading_type);
            setFormulaValue(details?.formula);
            if (details?.grading_type == "1" || details?.grading_type == "2") {
              setPassMarkValue(details?.pass_mark);
            } else {
              setPassMark(details?.pass_mark);
            }
            setDropoutValue(
              details?.drop_lowest_amount == 0
                ? ""
                : details?.drop_lowest_amount
            );
            setTotalRows(res.data?.gradeMixList?.length);
            setIsGradeLocked(details?.lock_grades);
            if (details?.grading_type == "2") {
              GradeLetterTemplateDetail({ id: details?.grading_template_id })
                .then((res) => {
                  setSelectedTemplate(res?.data?.list_data[0]);
                  setEditedRows([]);
                  setLoading(false);
                })
                .catch((err) => {
                  setLoading(false);
                  console.error(err);
                });
            }
            setLoading(false);
          })
          .catch((err) => {
            console.error("error :", err);
            setLoading(false);
          });
      } else {
        getProgrameGradeDetails(data)
          .then((res) => {
            dispatch(setBrandOverride2(res.data?.data.override_brand))
            let details = res.data?.data;
            let assessment_data = res?.data?.courseAndAssessment;
            setsorTemplatevalue(details?.st_template)
            setBrandOverride(details?.override_brand)
            setSwitchState({
              show_assessments: details.show_assessments,
              show_final_results: details.show_final_results,
              show_empty_grades: details.show_empty_grades,
              allow_student_portal_gradebook_view: details.allow_student_portal_gradebook_view,
              allow_student_portal_sor_to_download: details.allow_student_portal_sor_to_download,
              override_sor_brand: details.override_sor_brand
            })
            setAssessmentList(assessment_data ? assessment_data : []);
            setAggregationMethodValue(details?.aggregation_method_id);
            setExcludeEmptyGrade(details?.exclude_empty_grades);
            setGradeTypeValue(details?.grading_type);
            setFormulaValue(details?.formula);
            if (details?.grading_type == "1" || details?.grading_type == "2") {
              setPassMarkValue(details?.pass_mark);
            } else {
              setPassMark(details?.pass_mark);
            }
            setDropoutValue(
              details?.drop_lowest_amount == 0
                ? ""
                : details?.drop_lowest_amount
            );
            setTotalRows(res.data?.courseAndAssessment?.length);
            setIsGradeLocked(res.data?.data?.lock_grades);
            if (details?.grading_type == "2") {
              GradeLetterTemplateDetail({ id: details?.grading_template_id })
                .then((res) => {
                  setSelectedTemplate(res?.data?.list_data[0]);
                  setEditedRows([]);
                  setLoading(false);
                })
                .catch((err) => {
                  setLoading(false);
                  console.error(err);
                });
            }
            setLoading(false);
          })
          .catch((err) => {
            console.error("error :", err);
            setLoading(false);
          });
      }
    }
  }, [
    sortKey,
    sortOrder,
    search,
    id,
    updateData,
    gradingStandard,
    assessmentIds,
  ]);

  useEffect(() => {
    if (gradingStandard && gradingStandard == 2) {
      let data = {
        gradeMixIds: id,
        type: "programme",
      };
      getGradeMixDropdown(data)
        .then((res) => {
          setLinkedAssessmentDropdown({
            ...res.data,
            linkedAssessment: res?.data?.assessment
              ? res?.data?.assessment
              : [],
          });
        })
        .catch((err) => console.log(err));
    }
  }, [gradingStandard]);

  useEffect(() => {
    GradeLetterTypes()
      .then((res) => {
        setGradeTypeArr(res?.data);
      })
      .catch((err) => console.error(err));
    GradeLetterAggregationMethods()
      .then((res) => {
        setAggregationTypeArr(res?.data);
      })
      .catch((err) => console.error(err));
  }, []);

  const handleWeightChange = async (e, assId) => {
    const assList = assessmentList;
    let value = e.target.value;
    if (!value) {
      value = 0;
    } else {
      value = +value;
    }
    let filteredObj = assList.find((item) => item.id === assId);
    if (!filteredObj) {
      return;
    }
    let alreadyAdded = editedRows.find((item) => filteredObj.id === item.id);
    if (gradingStandard == 2) {
      if (alreadyAdded) {
        alreadyAdded.weight = value;
        await editedRows.splice(
          editedRows.findIndex((item) => item.id == assId),
          1
        );
        editedRows.push(alreadyAdded);
      } else {
        filteredObj.weight = value;
        editedRows.push(filteredObj);
      }
    } else {
      if (alreadyAdded) {
        alreadyAdded.ass_weighting = value;
        await editedRows.splice(
          editedRows.findIndex((item) => item.id == assId),
          1
        );
        editedRows.push(alreadyAdded);
      } else {
        filteredObj.ass_weighting = value;
        editedRows.push(filteredObj);
      }
    }
  };

  const handlePassRequiredChange = async (checked, assId, key = "") => {
    const assList = assessmentList;
    let filteredObj = assList.find((item) => item.id === assId);
    if (!filteredObj) {
      return;
    }
    let alreadyAdded = editedRows.find((item) => filteredObj.id === item.id);
    let editedState = editedRows;
    if (alreadyAdded) {
      alreadyAdded[key] = checked ? 1 : 0;
      await editedState.splice(
        editedState.findIndex((item) => item.id == assId),
        1
      );
      editedState.push(alreadyAdded);
    } else {
      filteredObj[key] = checked ? 1 : 0;
      await editedState.push(filteredObj);
    }
    setEditedRows([...editedState]);
  };

  const columns = useMemo(() => [
    {
      name: "Item",
      selector: "name",
      sortField: "name",
      sortable: true,
      cell: (row) => {
        return (
          <span
            title={row?.name ? row.name : "-"}
            className="overflow-ellipsis2 "
          >
            {row.name ? (
              <Link
                className="as-text-blue curser feature-name"
                to={
                  row?.isAssessment == 0
                    ? `/courseAdministration/coursesdetails/${row?.id}/gradeSettings/show`
                    : `/courseAdministration/coursesdetails/${row.intakeID}/assessments/open/assigned/Details/${row.id}`
                }
              >
                <span className="textLimit100">
                  {row?.name ? row.name : "-"}
                </span>
              </Link>
            ) : (
              "-"
            )}
          </span>
        );
      },
    },
    {
      name: "ID",
      selector: "id",
      sortField: "id",
      sortable: true,
      cell: (row) => <p>{row.id ? row.id : "-"}</p>,
    },
    gradingMethodValue == 1 &&
      aggregationMethodValue == 6 && {
        name: "Weight",
        selector: "ass_weighting",
        sortable: false,
        cell: (row) => (
          <div
            className="input_wt02 resultstatus_flex arrowhide"
            title="Weight"
          >
            <PermissionsGate
              scopes={["capgseedit"]}
              errorProps={{ disabled: true }}
            >
              <input
                className={
                  "form-control custom-select-box" +
                  (false ? " is-invalid" : "")
                }
                type="number"
                defaultValue={row.ass_weighting ? row?.ass_weighting : ""} // Set the initial value from the data
                // onBlur={(e) => {
                // handleWeightingChange(row.id, e.target.value, row?.pass_required);
                // handleWeightBlur(row, e?.target?.value);
                // }} // Define a function to handle value changes
                onChange={(e) => handleWeightChange(e, row.id)}
                disabled={isGradeLocked}
              />
            </PermissionsGate>
          </div>
        ),
      },
    {
      name: "Maximum Grade",
      selector: "maximumGrade",
      sortable: false,
      sortField: "maximumGrade",
      cell: (row) => (row?.maximumGrade ? row?.maximumGrade : "-"),
    },
    {
      name: "Pass Required",
      selector: "pass_required",
      sortable: false,
      cell: (row) => (
        <div
          className="custom-control custom-checkbox text-left table-curser"
          title="Pass Required"
        >
          <PermissionsGate
            scopes={["capgseedit"]}
            errorProps={{ disabled: true }}
          >
            <input
              type="checkbox"
              className="custom-control-input"
              id={`pass_required${row.id}`}
              name="pass_required"
              checked={row.pass_required} // Set the initial value from the data
              onChange={(e) => {
                // handlePassRequiredBlur(row, e.target?.checked);
                handlePassRequiredChange(
                  e.target?.checked,
                  row.id,
                  "pass_required"
                );
              }} // Define a function to handle value changes
              disabled={isGradeLocked}
            />
          </PermissionsGate>
          <label className="custom-control-label" htmlFor={row.id}></label>
        </div>
      ),
    },
    aggregationMethodValue != 6 && {
      name: "Exclude from calculation",
      selector: "exclude_from_calculation",
      sortable: false,
      cell: (row) => (
        <div
          className="custom-control custom-checkbox text-left table-curser"
          title="Pass Required"
        >
          <PermissionsGate
            scopes={["capgseedit"]}
            errorProps={{ disabled: true }}
          >
            <input
              type="checkbox"
              className="custom-control-input"
              id={`exclude_from_calculation${row.id}`} // change id
              name="exclude_from_calculation"
              defaultChecked={row.exclude_from_calculation} // Set the initial value from the data
              onChange={(e) => {
                handlePassRequiredChange(
                  e.target?.checked,
                  row.id,
                  "exclude_from_calculation"
                );
              }} // Define a function to handle value changes
              disabled={isGradeLocked}
            />
          </PermissionsGate>
          <label className="custom-control-label" htmlFor={row.id}></label>
        </div>
      ),
    },
    {
      name: "Course Type",
      selector: "assessment_type",
      sortField: "assessment_type",
      sortable: true,
      cell: (row) =>
        row?.assessment_type
          ? row.isAssessment
            ? RenderAssessmentType(row?.assessment_type).html
            : RenderCourseType(row?.assessment_type).html
          : "-",
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons">
            <PermissionsGate
              scopes={["cagsview"]}
              RenderError={() => (
                <button className="btn btn-primary rounded-circle" title="Open">
                  <i className="fal fa-folder-open"></i>
                </button>
              )}
            >
              <Link
                className="mr-1"
                to={`/courseAdministration/coursesdetails/${row?.id}/gradeSettings/show`}
              >
                <button className="btn btn-primary rounded-circle" title="Open">
                  <i className="fal fa-folder-open"></i>
                </button>
              </Link>
            </PermissionsGate>
          </div>
        </div>
      ),
    },
  ]);

  const LinkedAssessmentCell = ({ row }) => {
    const [showAll, setShowAll] = useState(false);
    const maxItemsToShow = 2;

    const linkedAssessment = row?.getAssessmentlist || [];
    const visibleIds = showAll
      ? linkedAssessment
      : linkedAssessment.slice(0, maxItemsToShow);

    const toggleShowAll = () => {
      setShowAll(!showAll);
    };

    return (
      <div>
        {visibleIds.map((item, index) => (
          <div key={index}>
            <span
              title={item?.name ? item?.name : ""}
              className="overflow-ellipsis2 "
            >
              {item?.name ? (
                <Link
                  className="as-text-blue curser feature-name font-weight-bold"
                  to={`/courseAdministration/coursesdetails/${item.intake}/assessments/open/assigned/Details/${item.assignment_id}`}
                >
                  <span className="textLimit100">{item?.name}</span>
                </Link>
              ) : (
                "-"
              )}
            </span>
          </div>
        ))}
        {linkedAssessment.length > maxItemsToShow && (
          <span
            className="see-more cursor-pointer as-text-blue"
            onClick={toggleShowAll}
          >
            {showAll ? "See Less" : "...See More"}
          </span>
        )}
      </div>
    );
  };

  const gradeMixColumns = useMemo(() => [
    {
      name: "Item",
      selector: "name",
      sortField: "name",
      sortable: true,
      cell: (row) => {
        return (
          <span
            title={row?.name ? row.name : "-"}
            className="overflow-ellipsis2 "
          >
            {row.name ? (
              <Link
                className="as-text-blue curser feature-name"
                to={`/courseAdministration/gradeMix/${id}/details/${row?.id}/open`}
              >
                <span className="textLimit100">{row.name}</span>
              </Link>
            ) : (
              "-"
            )}
          </span>
        );
      },
    },
    {
      name: "Linked Assessment",
      selector: "id",
      sortField: "id",
      // sortable: true,
      cell: (row) => <LinkedAssessmentCell row={row} />,
    },
    gradingMethodValue == 1 &&
      aggregationMethodValue == 6 && {
        name: "Weight",
        selector: "ass_weighting",
        sortable: false,
        cell: (row) => (
          <div
            className="input_wt02 resultstatus_flex arrowhide"
            title="Weight"
          >
            <PermissionsGate
              scopes={["capgseedit"]}
              errorProps={{ disabled: true }}
            >
              <input
                className={
                  "form-control custom-select-box" +
                  (false ? " is-invalid" : "")
                }
                type="number"
                defaultValue={row.weight ? row?.weight : ""} // Set the initial value from the data
                // onBlur={(e) => {
                // handleWeightingChange(row.id, e.target.value, row?.pass_required);
                // handleWeightBlur(row, e?.target?.value);
                // }} // Define a function to handle value changes
                onChange={(e) => handleWeightChange(e, row.id)}
                disabled={isGradeLocked}
              />
            </PermissionsGate>
          </div>
        ),
      },
    {
      name: "Maximum Grade",
      selector: "maximumGrade",
      sortable: false,
      sortField: "maximumGrade",
      cell: (row) => (row?.maximumGrade ? row?.maximumGrade : "-"),
    },
    {
      name: "Pass Required",
      selector: "pass_required",
      sortable: false,
      cell: (row) => (
        <div
          className="custom-control custom-checkbox text-left table-curser"
          title="Pass Required"
        >
          <PermissionsGate
            scopes={["capgseedit"]}
            errorProps={{ disabled: true }}
          >
            <input
              type="checkbox"
              className="custom-control-input"
              id={`pass_required${row.id}`}
              name="pass_required"
              checked={row.pass_required} // Set the initial value from the data
              onChange={(e) => {
                // handlePassRequiredBlur(row, e.target?.checked);
                handlePassRequiredChange(
                  e.target?.checked,
                  row.id,
                  "pass_required"
                );
              }} // Define a function to handle value changes
              disabled={isGradeLocked}
            />
          </PermissionsGate>
          <label className="custom-control-label" htmlFor={row.id}></label>
        </div>
      ),
    },
    aggregationMethodValue != 6 && {
      name: "Exclude from calculation",
      selector: "exclude_from_calculation",
      sortable: false,
      cell: (row) => (
        <div
          className="custom-control custom-checkbox text-left table-curser"
          title="Pass Required"
        >
          <PermissionsGate
            scopes={["capgseedit"]}
            errorProps={{ disabled: true }}
          >
            <input
              type="checkbox"
              className="custom-control-input"
              id={`exclude_from_calculation${row.id}`} // change id
              name="exclude_from_calculation"
              defaultChecked={row.exclude_from_calculation} // Set the initial value from the data
              onChange={(e) => {
                handlePassRequiredChange(
                  e.target?.checked,
                  row.id,
                  "exclude_from_calculation"
                );
              }} // Define a function to handle value changes
              disabled={isGradeLocked}
            />
          </PermissionsGate>
          <label className="custom-control-label" htmlFor={row.id}></label>
        </div>
      ),
    },
    {
      name: "Actions",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons">
            <PermissionsGate
              scopes={["cagsview"]}
              RenderError={() => (
                <button className="btn btn-primary rounded-circle" title="Open">
                  <i className="fal fa-folder-open"></i>
                </button>
              )}
            >
              <Link
                className="mr-1"
                to={`/courseAdministration/gradeMix/${id}/details/${row?.id}/open`}
              >
                <button className="btn btn-primary rounded-circle" title="Open">
                  <i className="fal fa-folder-open"></i>
                </button>
              </Link>
            </PermissionsGate>
          </div>
        </div>
      ),
    },
  ]);

  const exportData = (fileType, fileName) => {
    let data = {
      id,
      page: 0,
      limit: 10,
      key: sortKey,
      sort: sortOrder,
      exportStatus: "true",
      search,
    };
    getProgrameGradeDetails(data)
      .then((res) => {
        let assessment_data = res?.data?.courseAndAssessment;
        let data = assessment_data;
        const header = [
          "Item",
          "ID",
          "Type",
          "Weight",
          "Maximum Grade",
          "Pass Required",
          "Course Type",
        ];
        data = data?.map((row) => ({
          ...row,
          Item: row?.name ? row.name : "-",
          ID: row?.id ? row.id : "-",
          Type: row.isAssessment == 0 ? "Course" : "Assessment",
          Weight: row.ass_weighting ? row?.ass_weighting : "-",
          "Maximum Grade": row?.maximumGrade ? row?.maximumGrade : "-",
          "Pass Required": row.pass_required == "0" ? "False" : "True",
          "Course Type": row?.assessment_type
            ? row.isAssessment
              ? RenderAssessmentType(row?.assessment_type).text
              : RenderCourseType(row?.assessment_type).text
            : "-",
        }));

        if (fileType === "csv") {
          const csvString = Papa.unparse({ fields: header, data });
          const blob = new Blob([csvString], {
            type: "text/csv;charset=utf-8,",
          });

          const blobURL = window.URL.createObjectURL(blob);

          // Create new tag for download file
          const anchor = document.createElement("a");
          anchor.download = fileName;
          anchor.href = blobURL;
          anchor.dataset.downloadurl = [
            "text/csv",
            anchor.download,
            anchor.href,
          ].join(":");
          anchor.click();

          // Remove URL.createObjectURL. The browser should not save the reference to the file.
          setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            URL.revokeObjectURL(blobURL);
          }, 1000);
        } else if (fileType === "xlsx") {
          const compatibleData = data.map((row) => {
            const obj = {};
            header.map((col, index) => {
              obj[col] = row[col];
            });
            return obj;
          });

          let wb = XLSX.utils.book_new();
          let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
            header,
          });
          XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
          XLSX.writeFile(wb, `${fileName}.xlsx`);

          return false;
        }
        if (fileType === "pdf") {
          const compatibleData = data.map((row) => {
            return [
              row.Item,
              row.ID,
              row.Type,
              row.Weight,
              row["Maximum Grade"],
              row["Pass Required"],
              row["Assessment Type"],
            ];
          });
          const doc = new JsPDF();
          doc.autoTable({
            head: [header],
            body: compatibleData,
            styles: {
              minCellHeight: 10,
              minCellWidth: 5,
              halign: "left",
              // valign: "center",
              fontSize: 8,
            },
          });
          doc.save(`${fileName}.pdf`);

          return false;
        }
      })
      .catch((error) => {
        console.error("error :", error);
      });
  };

  const exportGradeMixData = (fileType, fileName) => {
    let data = {
      id,
      page: 1,
      limit: 10,
      key: sortKey,
      sort: sortOrder,
      exportStatus: "true",
      search,
      viaAssessment: assessmentIds.arr,
    };
    getProgrameGradeMixDetails(data)
      .then((res) => {
        let assessment_data = res?.data?.gradeMixList || [];
        const header = [
          "Item",
          "Linked Assessments",
          "Weight",
          "Maximum Grade",
          "Pass Required",
        ];
        const data = [];
        assessment_data.map((gradeMix, index) => {
          let details = {
            Item: gradeMix?.name ? gradeMix.name : "-",
            "Linked Assessments": "",
            Weight: gradeMix.weight ? gradeMix?.weight : "-",
            "Maximum Grade": gradeMix?.maximumGrade
              ? gradeMix?.maximumGrade
              : "-",
            "Pass Required": gradeMix.pass_required == "0" ? "False" : "True",
          };
          data.push(details);
          gradeMix.getAssessmentlist.map((item, index) => {
            let assessmentsNames = {
              Item: "",
              "Linked Assessments": item?.name ? item?.name : "-",
              Weight: "",
              "Maximum Grade": "",
              "Pass Required": "",
            };
            data.push(assessmentsNames);
          });
        });
        if (fileType === "csv") {
          const csvString = Papa.unparse({ fields: header, data });
          const blob = new Blob([csvString], {
            type: "text/csv;charset=utf-8,",
          });

          const blobURL = window.URL.createObjectURL(blob);

          // Create new tag for download file
          const anchor = document.createElement("a");
          anchor.download = fileName;
          anchor.href = blobURL;
          anchor.dataset.downloadurl = [
            "text/csv",
            anchor.download,
            anchor.href,
          ].join(":");
          anchor.click();

          // Remove URL.createObjectURL. The browser should not save the reference to the file.
          setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            URL.revokeObjectURL(blobURL);
          }, 1000);
        } else if (fileType === "xlsx") {
          const compatibleData = data.map((row) => {
            const obj = {};
            header.map((col, index) => {
              obj[col] = row[col];
            });
            return obj;
          });

          let wb = XLSX.utils.book_new();
          let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
            header,
          });
          XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
          XLSX.writeFile(wb, `${fileName}.xlsx`);

          return false;
        }
        if (fileType === "pdf") {
          const compatibleData = data.map((row) => {
            return [
              row["Item"],
              row["Linked Assessments"],
              row["Weight"],
              row["Maximum Grade"],
              row["Pass Required"],
            ];
          });
          const doc = new JsPDF();
          doc.autoTable({
            head: [header],
            body: compatibleData,
            styles: {
              minCellHeight: 10,
              minCellWidth: 5,
              halign: "left",
              // valign: "center",
              fontSize: 8,
            },
          });
          doc.save(`${fileName}.pdf`);

          return false;
        }
      })
      .catch((error) => {
        console.error("error :", error);
      });
  };

  const resetFilter = () => {
    setSearch("");
    setAssessmentIds({ arr: [], checkObj: [] });
  };

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setSearch(value);
  };

  const handleUpdateData = () => {
    let tempErrorMessage = errorMessage;

    if (gradeTypeValue === undefined || gradeTypeValue == "5") {
      tempErrorMessage = {
        ...tempErrorMessage,
        gradeType: "Grade Type is Required",
      };
    } else {
      delete tempErrorMessage?.gradeType;
    }
    if (
      aggregationMethodValue === undefined &&
      gradeTypeValue !== undefined &&
      gradeTypeValue != 3
    ) {
      tempErrorMessage = {
        ...tempErrorMessage,
        aggregationMethod: "Aggregation Method is Required",
      };
    } else {
      delete tempErrorMessage?.aggregationMethod;
    }
    if (gradeTypeValue == "2" || gradeTypeValue == "1") {
      if (passMarkValue === "") {
        tempErrorMessage = {
          ...tempErrorMessage,
          passMark: "Pass Mark is Required",
        };
      } else {
        delete tempErrorMessage?.passMark;
      }
    } else if (gradeTypeValue == "0") {
      if (passMark == "") {
        tempErrorMessage = { ...tempErrorMessage, passMark: "Pass Mark is Required" }
      } else if (passMark > 100) {
        tempErrorMessage = { ...tempErrorMessage, passMark: "Pass Mark cannot be more than 100%" }
      } else {
        delete tempErrorMessage?.passMark
      }
    } else {
      if (passMark == "" && (gradeTypeValue == "0" || gradeTypeValue == "3")) {
        tempErrorMessage = {
          ...tempErrorMessage,
          passMark: "Pass Mark is Required",
        };
      } else {
        delete tempErrorMessage?.passMark;
      }
    }
    if (
      gradingStandard != 2 &&
      dropoutValue > totalRows - 1 &&
      gradeTypeValue != 3
    ) {
      tempErrorMessage = {
        ...tempErrorMessage,
        dropoutValue: "Dropout Value Must Be Smaller Than Total Assessments",
      };
    } else {
      delete tempErrorMessage?.dropoutValue;
    }
    if (
      gradingStandard == 2 &&
      dropoutValue > totalRows - 1 &&
      gradeTypeValue != 3
    ) {
      tempErrorMessage = {
        ...tempErrorMessage,
        dropoutValue:
          "Dropout Value Must Be Smaller Than Total Grade Mix Items",
      };
    } else {
      delete tempErrorMessage?.dropoutValue;
    }
    // if (gradingStandard == 2 && dropoutValue > totalRows - 1 && gradeTypeValue != 3) {
    //   tempErrorMessage = { ...tempErrorMessage, dropoutValue: "Dropout Value Must Be Smaller Than Total Grade Mix Items" }
    // } else {
    //   delete tempErrorMessage?.dropoutValue
    // }
    if (!selectedTemplate?.id && gradeTypeValue == "2") {
      tempErrorMessage = {
        ...tempErrorMessage,
        template: "Template is Required",
      };
    } else {
      delete tempErrorMessage?.template;
    }
    setErrorMessage(tempErrorMessage);
    if (Object.keys(tempErrorMessage).length) {
      return;
    }
    setIsDisabled(true);
    let data = {
      id,
      ...switchState,
      drop_lowest_amount: dropoutValue ? dropoutValue : 0,
      exclude_empty_grades: excludeEmptyGrade,
      formula: formulaValue,
      grading_method_id: 1,
      grading_template_id: selectedTemplate?.id ? selectedTemplate?.id : 0,
      grading_type: gradeTypeValue,
      pass_mark:
        gradeTypeValue == "1" || (gradeTypeValue == "2" && passMarkValue)
          ? passMarkValue
          : passMark
          ? passMark
          : 0,
      // assessment: JSON.stringify(editedRows)
    };
    if (brandOverride) {
      data.override_brand = brandOverride
    }else{
      data.override_brand = 0
    }
    if (sorTemplatevalue) {
      data.st_template = sorTemplatevalue
    }else{
      data.st_template = 0
    }
    if (gradingMethodValue == "1") {
      data.aggregation_method_id =
        gradeTypeValue != 3 ? aggregationMethodValue : 1;
    }
    if (gradingStandard == 2) {
      data.gradeMix = JSON.stringify(editedRows);
      updateProgrammeGradeMixDetails(data)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Updated Successfully",
          });
          setIsDisabled(false);
          setUpdateData(!updateData);
          setEditedRows([]);
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Something Went Wrong",
          });
          setIsDisabled(false);
          setUpdateData(!updateData);
          console.error("err: ", err);
        });
    } else {
      data.assessment = JSON.stringify(editedRows);
      updateProgrameGradeDetails(data)
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Updated Successfully",
          });
          setIsDisabled(false);
          setUpdateData(!updateData);
          setEditedRows([]);
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Something Went Wrong",
          });
          setIsDisabled(false);
          setUpdateData(!updateData);
          console.error("err: ", err);
        });
    }
  };

  const handleSort = (column, sortDirection) => {
    setSortKey(column.sortField);
    setSortOrder(sortDirection === "asc" ? "AESC" : "DESC");
  };

  const handleSwitchToggle = (switchName) => {
    setSwitchState((prevState) => ({
      ...prevState,
      [switchName]: !prevState[switchName],
    }));
  };

  return (
    <div className="my-tickets-info-list Tickets-main-wrap tickets-new-custom">
      <div className="l-o-c-t custom-table-div filter-search-icon card card-table-custom">
        <div className="grade-setting-box">
          <div className="course-info-sec">
            <div className="edit-icon new-card-header">
              <div className="card-header">
                Grade Settings{" "}
                {loading ? <i className="fas fa-cog fa-spin"></i> : ""}
              </div>
            </div>
            
            <div className="card-body-inr card-body-info">
              <div className="edit-icon new-card-header">
                <div className="card-header">
                <i className="fas fa-cog heading-theme-color mr-2"></i> 
                  Statement of Results{" "}
                  {loading ? <i className="fas fa-cog fa-spin"></i> : ""}
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-4 col-lg-2">
                  <div className="d-flex align-items" title="Show Assessments">
                    <label className="mb-0">
                      Show Assessments{" "}
                      <i className="fal fa-info-circle grade-icon"></i>
                    </label>
                    <div className="toggle-switch ml-2">
                      <label class="switch">
                        <input
                          type="checkbox"
                          checked={switchState.show_assessments}
                          onChange={() => handleSwitchToggle("show_assessments")}
                        />
                        <span class="slider slider-round"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-lg-2">
                  <div className="d-flex align-items" title="Show Final Results">
                    <label className="mb-0">
                      Show Final Results{" "}
                      <i className="fal fa-info-circle grade-icon"></i>
                    </label>
                    <div className="toggle-switch ml-2">
                      <label class="switch">
                        <input
                          type="checkbox"
                          checked={switchState.show_final_results}
                          onChange={() =>
                            handleSwitchToggle("show_final_results")
                          }
                        />
                        <span class="slider slider-round"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-lg-2">
                  <div className="d-flex align-items" title="Show Empty Grades">
                    <label className="mb-0">
                      Show Empty Grades{" "}
                      <i className="fal fa-info-circle grade-icon"></i>
                    </label>
                    <div className="toggle-switch ml-2">
                      <label class="switch">
                        <input
                          type="checkbox"
                          checked={switchState.show_empty_grades}
                          onChange={() => handleSwitchToggle("show_empty_grades")}
                        />
                        <span class="slider slider-round"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-lg-2">
                  <div className="d-flex align-items" title="Allow Student Portal Gradebook View">
                    <label className="mb-0">
                      Allow Student Portal Gradebook View{" "}
                      <i className="fal fa-info-circle grade-icon"></i>
                    </label>
                    <div className="toggle-switch ml-2">
                      <label class="switch">
                        <input
                          type="checkbox"
                          checked={switchState.allow_student_portal_gradebook_view}
                          onChange={() =>
                            handleSwitchToggle("allow_student_portal_gradebook_view")
                          }
                        />
                        <span class="slider slider-round"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-lg-2">
                  <div className="d-flex align-items" title="Allow Student Portal SOR Download">
                    <label className="mb-0">
                      Allow Student Portal SOR Download{" "}
                      <i className="fal fa-info-circle grade-icon"></i>
                    </label>
                    <div className="toggle-switch ml-2">
                      <label class="switch">
                        <input
                          type="checkbox"
                          checked={switchState.allow_student_portal_sor_to_download}
                          onChange={() =>
                            handleSwitchToggle("allow_student_portal_sor_to_download")
                          }
                        />
                        <span class="slider slider-round"></span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-lg-2">
                  <div className="d-flex align-items" title="Override SOR Brand">
                    <label className="mb-0">
                      Override SOR Brand{" "}
                      <i className="fal fa-info-circle grade-icon"></i>
                    </label>
                    <div className="toggle-switch ml-2">
                      <label class="switch">
                        <input
                          type="checkbox"
                          checked={switchState.override_sor_brand}
                          onChange={() =>
                            handleSwitchToggle("override_sor_brand")
                          }
                        />
                        <span class="slider slider-round"></span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 col-lg-2">
                  <div
                    className="form-icon-group mb-4 d-block"
                    title="Brand Override"
                  >
                    <label>
                      Brand Override{" "}
                      <i className="fal fa-info-circle grade-icon"></i>
                    </label>
                    <Select
                      className={
                        "form-control custom-select-box" +
                        (errorMessage?.brandOverride ? " is-invalid" : "")
                      }
                      name="brand_override"
                      value={brandList.filter(function (option) {
                        return option.value == brandOverride;
                      })}
                      onChange={(value) => {
                        if (value) {
                          setBrandOverride(value.value);
                          // setErrorMessage((prevState) => ({
                          //   ...prevState,
                          //   brandOverride: "",
                          // }));
                        } else {
                          setBrandOverride();
                        }
                      }}
                      isClearable
                      options={brandList}
                      maxMenuHeight={175}
                      placeholder={"Brand Override"}
                      isDisabled={isGradeLocked}
                    />
                  </div>
                </div>
                <div className="col-md-4 col-lg-2">
                  <div
                    className="form-icon-group mb-4 d-block"
                    title="Template"
                  >
                    <label>
                      Template <i className="fal fa-info-circle grade-icon"></i>
                    </label>
                    <Select
                      className={
                        "form-control custom-select-box" +
                        (errorMessage?.sorTemplate ? " is-invalid" : "")
                      }
                      name="sor_template"
                      value={sorTemplateList.filter(function (option) {
                        return option.value == sorTemplatevalue;
                      })}
                      onChange={(value) => setsorTemplatevalue(value ? value.value : "")}
                      isClearable
                      options={sorTemplateList}
                      maxMenuHeight={175}
                      placeholder={"Template"}
                      isDisabled={isGradeLocked}
                    />
                  </div>
                </div>
              </div>
              <div className="edit-icon new-card-header">
                <div className="card-header">
                <i className="fas fa-cog heading-theme-color mr-2" ></i> 
                  Grading{" "}
                  {loading ? <i className="fas fa-cog fa-spin"></i> : ""}
                </div>
              </div>
              <div className="row grade-store-box ">
                <div className="col-md-4 col-lg-2">
                  <div
                    className="form-icon-group mb-4 d-block"
                    title="Grade Type"
                  >
                    <label>Grade Type </label>
                    <Select
                      className={
                        "form-control custom-select-box" +
                        (errorMessage?.gradeType ? " is-invalid" : "")
                      }
                      name="grade_type"
                      value={gradeTypeArr.filter(function (option) {
                        return option.value === gradeTypeValue;
                      })}
                      onChange={(value) => {
                        if (value) {
                          setGradeTypeValue(value.value);
                          setAggregationMethodValue();
                          setDropoutValue("");
                          setPassMarkValue("");
                          setPassMark("");
                          setSelectedTemplate({});
                          setErrorMessage((prevState) => ({
                            ...prevState,
                            gradeType: "",
                          }));
                        } else {
                          setGradeTypeValue();
                          setPassMarkValue("");
                          setPassMark("");
                          setAggregationMethodValue();
                          setDropoutValue("");
                          setSelectedTemplate({});
                          setErrorMessage((prevState) => ({
                            ...prevState,
                            gradeType: "Grade Type is Required",
                          }));
                        }
                      }}
                      isClearable
                      //   onBlur={formik.handleBlur}
                      options={gradeTypeArr}
                      maxMenuHeight={175}
                      placeholder={"Grade Type"}
                      isDisabled={isGradeLocked}
                    />
                  </div>
                </div>
                {gradeTypeValue || +gradeTypeValue === 0 ? (
                  <>
                    {gradeTypeValue != "3" && (
                      <div className="col-md-4 col-lg-2">
                        <div
                          className="form-icon-group mb-4 d-block"
                          title="Aggregation Method"
                        >
                          <label>
                            Aggregation Method{" "}
                            <i
                              className="fal fa-info-circle grade-icon"
                              title={tooltipObj.aggregationMethodTooltip}
                            ></i>
                          </label>
                          <Select
                            className={
                              "form-control custom-select-box" +
                              (errorMessage?.aggregationMethod
                                ? " is-invalid"
                                : "")
                            }
                            name="aggregation_method"
                            value={aggregationTypeArr.filter(function (option) {
                              return option.value == aggregationMethodValue;
                            })}
                            onChange={(value) => {
                              if (value) {
                                setAggregationMethodValue(value.value);
                                setErrorMessage((prevState) => ({
                                  ...prevState,
                                  aggregationMethod: "",
                                }));
                              } else {
                                setAggregationMethodValue();
                                setErrorMessage((prevState) => ({
                                  ...prevState,
                                  aggregationMethod:
                                    "Aggregation Method is Required",
                                }));
                              }
                            }}
                            isClearable
                            //   onBlur={formik.handleBlur}
                            options={aggregationTypeArr}
                            maxMenuHeight={175}
                            placeholder={"Aggregation Method"}
                            isDisabled={isGradeLocked}
                          />
                        </div>
                      </div>
                    )}
                    {gradeTypeValue != "3" && (
                      <div className="col-md-4 col-lg-2" title="Dropout Value">
                        <label>
                          Dropout Value{" "}
                          <i
                            className="fal fa-info-circle grade-icon"
                            title={tooltipObj.dropoutTootltip}
                          ></i>
                        </label>
                        <div className="form-icon-group mb-4">
                          <input
                            className={
                              "form-control" +
                              (errorMessage.dropoutValue?.length
                                ? " is-invalid"
                                : "")
                            }
                            type="number"
                            onChange={(e) => {
                              setDropoutValue(e.target.value);
                            }}
                            value={dropoutValue}
                            placeholder="Dropout Value"
                            disabled={isGradeLocked}
                          />
                        </div>
                      </div>
                    )}
                    {gradeTypeValue == "1" ? (
                      <div className="col-md-4 col-lg-2">
                        <div
                          className="form-icon-group mb-4 d-block"
                          title="Pass Mark"
                        >
                          <label>Pass Mark </label>
                          <Select
                            className={
                              "form-control custom-select-box" +
                              (errorMessage?.passMark ? " is-invalid" : "")
                            }
                            name="pass_mark"
                            value={options.filter(function (option) {
                              return option.value == passMarkValue;
                            })}
                            onChange={(value) => {
                              if (value) {
                                setPassMarkValue(value.value);
                              } else {
                                setPassMarkValue("");
                              }
                            }}
                            isClearable
                            //   onBlur={formik.handleBlur}
                            options={options}
                            maxMenuHeight={175}
                            placeholder={"Mark"}
                            isDisabled={isGradeLocked}
                          />
                        </div>
                      </div>
                    ) : gradeTypeValue == "2" ? (
                      <div className="col-md-4 col-lg-2">
                        <div
                          className="form-icon-group mb-4 d-block"
                          title="Pass Mark"
                        >
                          <label>Pass Mark </label>
                          <Select
                            className={
                              "form-control custom-select-box" +
                              (errorMessage?.passMark ? " is-invalid" : "")
                            }
                            name="pass_mark"
                            value={templatePassMarkArr.filter(function (
                              option
                            ) {
                              return option.value == passMarkValue;
                            })}
                            onChange={(value) => {
                              if (value) {
                                setPassMarkValue(value.value);
                                setErrorMessage((prevState) => ({
                                  ...prevState,
                                  passMark: "",
                                }));
                              } else {
                                setPassMarkValue("");
                                setErrorMessage((prevState) => ({
                                  ...prevState,
                                  passMark: "Pass Mark is Required",
                                }));
                              }
                            }}
                            isClearable
                            //   onBlur={formik.handleBlur}
                            options={templatePassMarkArr}
                            maxMenuHeight={175}
                            placeholder={"Mark"}
                            isDisabled={isGradeLocked}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="col-md-4 col-lg-2" title="Pass Mark">
                        <label>Pass Mark </label>
                        <div className="form-icon-group mb-4">
                          <input
                            className={
                              "form-control" +
                              (errorMessage?.passMark ? " is-invalid" : "")
                            }
                            type="number"
                            onChange={(e) => {
                              if (e.target.value) {
                                setPassMark(e.target.value);
                              } else {
                                setPassMark("");
                              }
                            }}
                            value={passMark}
                            placeholder="Pass Mark"
                            disabled={isGradeLocked}
                          />
                        </div>
                      </div>
                    )}
                    {gradeTypeValue == "2" && (
                      <div className="search-filter-div-right mb-4">
                        <label>&nbsp;</label>
                        <div className="filter-search-bar-blk">
                          <div className="add-ticket-blk dropdown-comman">
                            <button
                              // className="btn btn-primary"
                              data-toggle="modal"
                              data-target="#letterTemplatemodal"
                              className="btn btn-save btn-success"
                              type="button"
                              title="Select Template"
                              disabled={isGradeLocked}
                            >
                              <i className="fal fa-file-alt"></i>Select Template
                            </button>
                            {selectedTemplate && (
                              <>
                                {selectedTemplate && selectedTemplate?.name && (
                                  <div className="d-flex select-delete">
                                    <Link
                                      className="as-text-blue curser"
                                      title="Open"
                                      to={`/courseAdministration/gradingTemplate/edit/${selectedTemplate?.id}`}
                                    >
                                      <span className="textLimit100">
                                        {selectedTemplate?.name}
                                      </span>
                                    </Link>
                                    <button
                                      className="btn btn-danger rounded-circle btn-dropdown-item"
                                      title="Delete"
                                      onClick={() => setSelectedTemplate({})}
                                      disabled={isGradeLocked}
                                    >
                                      <i className="fal fa-trash-alt"></i>
                                    </button>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
              <hr />
            </div>
          </div>
        </div>
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div
                  id="assessment-table-main_wrapper"
                  className="dataTables_wrapper no-footer"
                >
                  <div
                    id="assessment-table-main_filter"
                    className="dataTables_filter"
                  >
                    <label>
                      <input
                        type="search"
                        className=""
                        placeholder="Search"
                        aria-controls="assessment-table-main"
                        onChange={handleSearchFilter}
                        value={search}
                      />
                    </label>
                    <div className="filter-eff filter-data-btn">
                      <button className="filter-buttons">
                        <i className="fal fa-filter"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-button-group">
                <div
                  className="custom-check custom-control custom-checkbox mt-2 mx-4"
                  title="Exclude empty grades"
                >
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="empty_grade"
                    name="select"
                    checked={excludeEmptyGrade}
                    onChange={(e) =>
                      setExcludeEmptyGrade(e.target.checked ? 1 : 0)
                    }
                    disabled={isGradeLocked}
                  />
                  <label className="custom-control-label" htmlFor="empty_grade">
                    Exclude empty grades
                  </label>
                </div>
                {gradingStandard == 2 && (
                  <div className="filter-scroll">
                    <div className={`filter-scroll-inner  filter-custom-new`}>
                      <Tablefilter
                        filterName="Linked Assessment"
                        optionArr={linkedAssessmentDropdown.linkedAssessment}
                        state={assessmentIds}
                        setState={setAssessmentIds}
                        isSearchFilter={true}
                      />
                    </div>
                  </div>
                )}
                <div className="reset-btn-group">
                  <div className="button-reset dropdown-comman">
                    <button
                      className="btn btn-primary"
                      onClick={resetFilter}
                      title="Reset"
                    >
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>
                  <div className="files-export-group">
                    <button
                      type="button"
                      className="btn btn-files"
                      title="Export spreadsheet"
                      onClick={() => {
                        gradingStandard == 2
                          ? exportGradeMixData(
                              "xlsx",
                              "Programme-Grade-settings"
                            )
                          : exportData("xlsx", "Programme-Grade-settings");
                      }}
                    >
                      <i className="fal fa-file-excel icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      title="Export CSV"
                      onClick={() => {
                        gradingStandard == 2
                          ? exportGradeMixData(
                              "csv",
                              "Programme-Grade-settings"
                            )
                          : exportData("csv", "Programme-Grade-settings");
                      }}
                    >
                      <i className="fal fa-file-csv icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      title="Export PDF"
                      onClick={() => {
                        gradingStandard == 2
                          ? exportGradeMixData(
                              "pdf",
                              "Programme-Grade-settings"
                            )
                          : exportData("pdf", "Programme-Grade-settings");
                      }}
                    >
                      <i className="fal fa-file-pdf icon"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="search-filter-div-right">
            <div className="filter-search-bar-blk">
              <div className="add-ticket-blk button-reset dropdown-comman">
                <PermissionsGate
                  scopes={["capgsedit"]}
                  errorProps={{ disabled: true }}
                >
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      lockGradeProgrammeGrademixToggle(
                        id,
                        isGradeLocked ? 0 : 1
                      )
                        .then((res) => {
                          Swal.fire({
                            icon: "success",
                            title: "Success",
                            text: res.data.message,
                          });
                          setUpdateData(!updateData);
                        })
                        .catch((err) => {
                          console.error("error :", err);
                          Swal.fire({
                            icon: "error",
                            title: "Error",
                            text: err.response.data.message,
                          });
                        });
                    }}
                    title={isGradeLocked ? "Unlock Grades" : "Lock Grades"}
                  >
                    {isGradeLocked ? (
                      <>
                        <i class="fal fa-unlock"></i>Unlock Grades
                      </>
                    ) : (
                      <>
                        <i className="fal fa-lock"></i>Lock Grades
                      </>
                    )}
                  </button>
                </PermissionsGate>
              </div>
            </div>
          </div>
        </div>
        <DataTable
          columns={
            gradingStandard == 2
              ? gradeMixColumns.map((item) => item)
              : columns.map((item) => item)
          }
          pagination={false}
          defaultSortField={sortKey}
          defaultSortAsc={true}
          data={assessmentList}
          noDataComponent={Str.noRecord}
          onSort={handleSort}
          sortServer
          // paginationServer
          paginationTotalRows={totalRows}
          highlightOnHover={false}
          progressComponent={<SkeletonTicketList />}
          progressPending={loading}
        />
        <div className="form-group form-group-save-cancel without-pagination-btns">
          <PermissionsGate
            scopes={["capgseedit"]}
            errorProps={{ disabled: true }}
          >
            <button
              className="btn btn-save btn-success"
              type="submit"
              title="Save"
              onClick={handleUpdateData}
              disabled={isDisabled}
            >
              {isDisabled ? (
                <i className="fas fa-cog fa-spin"></i>
              ) : (
                <i className="fal fa-save"></i>
              )}
              Save
            </button>
          </PermissionsGate>
          <button
            className="btn btn-close btn-danger"
            type="button"
            title="Cancel"
            onClick={() => {
              setUpdateData(!updateData);
            }}
          >
            <i className="fal fa-times"></i>Cancel
          </button>
          {errorMessage
            ? Object.keys(errorMessage).map((key) => {
                return errorMessage[key] ? (
                  <div className="invalid-feedback d-block" key={key}>
                    {errorMessage[key]}
                  </div>
                ) : (
                  <React.Fragment key={key}></React.Fragment>
                );
              })
            : ""}
        </div>
      </div>
      <ListOfTemplateModal
        setSelectedTemplate={setSelectedTemplate}
        selectedTemplate={selectedTemplate}
      />
    </div>
  );
};

export default ProgrameGradeSetting;
